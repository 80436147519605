import Table from 'components/Table';
import TableRow from 'components/Table/TableRow';
import {ProjectRolesEnum, ProjectStatusEnum} from 'graphql/types';
import { format, parseISO } from 'date-fns';
import {
  GetProjectsByStatusDocument,
  useCreateProjectStatusMutation,
  useGetProjectsByStatusLazyQuery,
  useUpdateProjectStatusMutation,
} from 'graphql/codegen/graphql';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Dialog, { DialogProps } from '../../components/common/Dialog';
import { Button } from '../../components/common/Button';
import useTypedForm from '../../components/common/Form/components';
import { useAppContext } from '../../AppContext';
import AuthenticationMessage from '../../AuthenticationMessage';
import { useParams } from 'react-router';

const ApprovalList = () => {
  interface FormValues {}

  interface EditDialogProps {
    editProject: null | any;
    onClose: DialogProps['onClose'];
    mode: 'create' | 'edit';
  }
  const { user } = useAppContext();
  const history = useHistory();
  const params = useParams<{ projectId: string }>();

  const [getProjectsByStatus, { data }] = useGetProjectsByStatusLazyQuery();

  const getProjects = async () => {
    await getProjectsByStatus({
      variables: {
        where: {
          OR: [
            {
              projectStatus: {
                some: {
                  sequenceStatusId: {
                    in: [
                      ProjectStatusEnum.IDEA,
                      ProjectStatusEnum.IDEA_ACCEPTED,
                    ],
                  },
                },
              },
              createdBy: {
                equals: user?.kid,
              },
            },

            {
              projectRole: {
                some: {
                  roleId: {
                    in: [
                      ProjectRolesEnum.FACHLICHER_ANSPRECHPARTNER,
                      ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER,
                    ],
                  },
                  employeeId: {
                    equals: user?.kid,
                  },
                },
              },
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    if (user?.kid) {
      getProjects();
    }
  }, [user]);

  const { Form, CancelButton } = useTypedForm<FormValues>();

  const DeleteDialog = ({
    editProject,
    onClose,
  }: Omit<EditDialogProps, 'mode'>) => {
    const [createProjectStatus] = useCreateProjectStatusMutation();
    const [updateProjectStatus] = useUpdateProjectStatusMutation({
      refetchQueries: [GetProjectsByStatusDocument],
    });

    const handleConfirm = async () => {
      if (editProject.id) {
        await updateProjectStatus({
          variables: {
            data: {
              deletedBy: {
                set: user!.kid!,
              },
              deletedAt: {
                set: new Date(),
              },
            },
            where: {
              id: Number(
                editProject.projectStatus.filter(s => s.deletedBy === null)[0]
                  .id,
              ),
            },
          },
        });
        await createProjectStatus({
          variables: {
            data: {
              createdBy: user!.kid!,
              project: {
                connect: {
                  id: editProject.id,
                },
              },
              sequenceStatus: {
                connect: {
                  id: ProjectStatusEnum.DELETED,
                },
              },
            },
          },
        });
        await onClose();
      }
    };

    return (
      <Dialog open={!!editProject} onClose={onClose} title="Löschen">
        <Form onSubmit={handleConfirm} mode="onChange">
          <div className="space-y-4">
            <p>Willst du deine IT-Initiative wirklich löschen?</p>
            <div className="my-8 flex items-center justify-center space-x-5">
              <CancelButton theme="danger" onClick={onClose}>
                Nein, meine IT-Initiative behalten.
              </CancelButton>
              <Button onClick={handleConfirm}>
                Ja, meine IT Initiative löschen.
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    );
  };

  const [deleteProject, setDeleteProject] = useState<string | null>(null);

  if (AuthenticationMessage('meine-it-initiative')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  }

  return (
    <div className="space-y-10 w-full">
      <div className="flex justify-end">
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={() => history.push('/neue-it-initiative')}
        >
          Neue IT-Initiative
        </button>
      </div>

      <Table
        title="IT-Initiativen im Genehmigungsprozess"
        tooltip="Hier siehst du alle deine eingereichten IT-Initiativen und deren aktuellen Status in den verschiedenen Gremien"
      >
        {(data?.masters || [])
          .filter(entry => {
            return (
              entry.projectStatus?.filter(s => s.deletedBy === null) ?? []
            ).find(
              status =>
                status.sequenceStatusId === ProjectStatusEnum.IDEA_ACCEPTED,
            );
          })
          .map(entry => (
            <TableRow
              onClick={() =>
                setDeleteProject(deleteProject === entry.id ? null : entry.id)
              }
              key={entry.id}
              to={`/meine-it-initiative/${entry.id}`}
              left={format(
                parseISO(entry.createdAt as any as string),
                'dd.MM.yyyy HH:mm',
              )}
              center={
                entry.projectDescriptions?.length
                  ? entry.projectDescriptions![0].name
                  : ''
              }
            />
          ))}
      </Table>
      <Table
        title="Eingereichte IT-Initiativen"
        tooltip="Hier siehst du alle deine eingereichten IT-Initiativen, die aktuell beim Local Architecture Board zur Prüfung liegen."
      >
        {(data?.masters || [])
          .filter(entry => {
            return (
              entry.projectStatus?.filter(s => s.deletedBy === null) ?? []
            ).find(
              status => status.sequenceStatusId === ProjectStatusEnum.IDEA,
            );
          })
          .map(entry => (
            <TableRow
              onClick={() =>
                setDeleteProject(deleteProject === entry.id ? null : entry.id)
              }
              key={entry.id}
              to={`/meine-it-initiative/view/${entry.id}`}
              left={format(
                parseISO(entry.createdAt as any as string),
                'dd.MM.yyyy HH:mm',
              )}
              center={
                entry.projectDescriptions?.length
                  ? entry.projectDescriptions![0].name
                  : ''
              }
            />
          ))}
      </Table>

      <Table
        title="Zurückgegebene IT-Initiativen"
        tooltip="Hier siehst du alle deine eingereichten IT-Initiativen, die zur Überarbeitung zurück gegeben wurden."
      >
        {(data?.masters || [])
          .filter(entry => {
            return (
              entry.projectStatus?.filter(s => s.deletedBy === null) ?? []
            ).find(
              status => status.sequenceStatusId === ProjectStatusEnum.RETURNED,
            );
          })
          .map(entry => (
            <TableRow
              onClick={() =>
                setDeleteProject(deleteProject === entry.id ? null : entry.id)
              }
              key={entry.id}
              // to={`/meine-it-initiative/${entry.id}`}
              left={format(
                parseISO(entry.createdAt as any as string),
                'dd.MM.yyyy HH:mm',
              )}
              center={
                entry.projectDescriptions?.length
                  ? entry.projectDescriptions![0].name
                  : ''
              }
              editButton={true}
              onClickEdit={() => {
                history.push({pathname:'/neue-it-initiative',
                search: `projectId=${entry.id}`});
              }}
            />
          ))}
      </Table>

      {deleteProject ? (
        <DeleteDialog
          editProject={
            data?.masters.find(entry => entry.id === deleteProject) || null
          }
          onClose={() => setDeleteProject(null)}
        />
      ) : null}
    </div>
  );
};
export default ApprovalList;
