import React, {
  useContext,
  createContext,
  useState,
  MouseEventHandler,
  useEffect,
} from 'react';

import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import configuration from './Config';
import { getGroups, getUser } from './GraphService';

export interface AppUser {
  displayName?: string;
  email?: string;
  avatar?: string;
  kid?: string;
  groups?: [string];
  department?: string;
  givenName?: string;
  surname?: string;
  photo?: string;
}

export interface AppError {
  message: string;
  debug?: string;
}

type AppContext = {
  user?: AppUser;
  error?: AppError;
  signIn?: MouseEventHandler<HTMLElement>;
  signOut?: MouseEventHandler<HTMLElement>;
  displayError?: Function;
  clearError?: Function;
  authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
};

const appContext = createContext<AppContext>({
  user: undefined,
  error: undefined,
  signIn: undefined,
  signOut: undefined,
  displayError: undefined,
  clearError: undefined,
  authProvider: undefined,
});

export function useAppContext(): AppContext {
  return useContext(appContext);
}

interface ProvideAppContextProps {
  children: React.ReactNode;
}

export default function ProvideAppContext({
  children,
}: ProvideAppContextProps) {
  const auth = useProvideAppContext();
  return <appContext.Provider value={auth}>{children}</appContext.Provider>;
}

function useProvideAppContext() {
  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        try {
          const account = msal.instance.getActiveAccount();
          if (account) {
            await setUserGroup();
          }
        } catch (err: any) {
          displayError(err.message);
        }
      }
    };
    checkUser();
  });

  const msal = useMsal();
  const [user, setUser] = useState<AppUser | undefined>(undefined);
  const [error, setError] = useState<AppError | undefined>(undefined);

  const displayError = (message: string, debug?: string) => {
    setError({ message, debug });
  };

  const clearError = () => {
    setError(undefined);
  };

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  const signIn = async () => {
    await msal.instance.loginPopup({
      scopes: configuration.scopes,
      prompt: 'select_account',
    });

    await setUserGroup();
  };

  const signOut = async () => {
    await msal.instance.logoutPopup();
    setUser(undefined);
  };

  const setUserGroup = async () => {
    const user = await getUser(authProvider);
    // const photo = await getMyPhoto(authProvider);

    const groups = await getGroups(authProvider);
    const [kid] = user.userPrincipalName?.split('@') || ['no-kid'];

    setUser({
      displayName: user.displayName || '',
      email: user.mail || user.userPrincipalName || '',
      kid: kid,
      groups: groups,
      department: user.department || '',
      givenName: user.givenName || '',
      surname: user.surname || '',
      // photo: photo,
    });
  };

  return {
    user,
    error,
    signIn,
    signOut,
    displayError,
    clearError,
    authProvider,
  };
}
