export const getEnv = (varName: string) => {
  if (varName && process.env[varName]) {
    return process.env[varName];
  } else {
    throw new Error(`ENV '${varName}' was not set`);
  }
};

const configuration = {
  appId: getEnv('REACT_APP_CLIENT_ID'),
  redirectUri: getEnv('REACT_APP_REDIRECT_URL'),
  navigateToLoginRequestUrl: true,
  authority: getEnv('REACT_APP_WELL_KNOWN_ENDPOINT'),
  scopes: ['openid', 'profile', 'email', 'offline_access', 'user.read', 'groupMember.read.all', 'user.readBasic.all'],
};

export default configuration;
