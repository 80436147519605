import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, LinkProps } from 'react-router-dom';
import { ReactNode } from 'react';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../stories/Button';

export interface TableRowProps {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  onClick?: () => void;
  to?: LinkProps['to'];
  editButton?: boolean;
  onClickEdit?: () => void;
}

function TableRow({
  left,
  center,
  right,
  onClick,
  to,
  editButton,
  onClickEdit,
}: TableRowProps) {
  const renderRow = (
    <div className="flex space-x-52">
      <div>{left}</div>
      <div>{center}</div>
      <div className="">{right}</div>
    </div>
  );

  return (
    <div
      className={classNames(
        'first:border-t border-b border-darkGrey py-4 px-6',
        {
          'cursor-pointer hover:bg-tableDarkGrey': to,
        },
      )}
    >
      <div className="flex justify-between">
        <div className="w-5/6">
          {to ? (
            <Link className="" to={to}>
              {renderRow}
            </Link>
          ) : (
            renderRow
          )}
        </div>
        {editButton ? (
          <div className="w-1/6 text-right">
            <Button theme="secondary" size="small" onClick={onClickEdit}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        ) : null}
        <div className="w-1/6 text-right">
          <Button theme="secondary" size="small" onClick={onClick}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TableRow;
