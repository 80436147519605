import useTypedForm from '../../common/Form/components';
import { useTranslation } from 'react-i18next';
import {
  ReportingGetProjectByIdQuery, useCreateProjectShortHistoryMutation,
  useUpdateProjectDescriptionMutation,
} from '../../../graphql/codegen/graphql';
import Dialog from '../../common/Dialog';
import React from 'react';
import {useAppContext} from "../../../AppContext";

interface editProjectNameDialogProps {
  editProjectName: boolean;
  onClose: () => void;
  data: ReportingGetProjectByIdQuery | undefined;
}
interface FormValues {
  projectName: string;
}
const EditNameDialog = ({
  editProjectName,
  onClose,
  data,
}: editProjectNameDialogProps) => {
  const { Form, TextInput, SubmitButton } = useTypedForm<FormValues>();
  const { t } = useTranslation();
  const { user } = useAppContext();

  const [updateProjectDescription] = useUpdateProjectDescriptionMutation();
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation({
    refetchQueries: ['reportingGetProjectById'],
  });

  const handleUpdateProjectDescription = (projectNameData: {
    projectName: string;
  }) => {
    updateProjectDescription({
      variables: {
        data: {
          name: {
            set: projectNameData.projectName,
          },
        },
        where: {
          id: data?.masters[0]?.projectDescriptions?.[0].id,
        },
      },
    });
    createProjectShortHistory({
      variables: {
        data: {
          project: {
            connect: {
              id: data?.masters[0].id,
            },
          },
          action: 'Projektname',
          createdBy: user?.kid,
        },
      },
    });
  };

  const handleSubmitName = (data: FormValues) => {
    handleUpdateProjectDescription(data);
    onClose();
  };

  return (
    <Dialog
      open={editProjectName}
      onClose={onClose}
      title={'Projektname bearbeiten'}
    >
      <div>
        <Form onSubmit={handleSubmitName} mode="onChange">
          <div className="space-y-4">
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectoverviewPage.projectName` + ':'}
              </div>
              <div className="w-full">
                <TextInput
                  name="projectName"
                  defaultValue={
                    data?.masters[0]?.projectDescriptions?.[0].name ?? ''
                  }
                  rules={{ required: true }}
                />
              </div>
            </div>

            <SubmitButton>{t`tdReporting.projectoverviewPage.saveButton`}</SubmitButton>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

export default EditNameDialog;
