import { useTranslation } from 'react-i18next';
import React from 'react';
import TableInput from './TableInput';
import ToolTip from '../../common/ToolTip';
import { GeneralCostTableRow } from './GeneralCostSumTable';

interface GeneralCostRowProps {
  data: GeneralCostTableRow;
  firstValueIndex: number;
  tooltip?: string;
}

const GeneralCostSumRow = (props: GeneralCostRowProps) => {
  const { t } = useTranslation();
  const { data, firstValueIndex, tooltip } = props;

  const rows: any[] = [];
  const max = 4;
  for (let i = 0; i < max; i++) {
    rows.push(<td key={i}></td>);
    rows.push(
      <td key={i + max}>
        <TableInput
          data={data.values![i + firstValueIndex]}
          color="tableDarkGrey"
          isBold
        />
      </td>,
    );
  }

  return (
    <tr className="text-right bg-tableDarkGrey">
      <td className="font-semibold text-left firstCol">
        <div className="flex">
          {t(data.costTypeId)}
          {tooltip ? (
            <div className="pl-1">
              <ToolTip mode="hover" color="text-customBlue">
                {tooltip}
              </ToolTip>
            </div>
          ) : null}
        </div>
      </td>
      {rows}
      <td />
      <td>
        <TableInput data={data.estimatedCost!} color="tableDarkGrey" isBold />
      </td>
      <td className="w-1/18" />
    </tr>
  );
};

export default GeneralCostSumRow;
