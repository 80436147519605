import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Table from '../../NewTable';
import { useTranslation } from 'react-i18next';
import { ResourceplanningGetProjectByIdQuery } from '../../../graphql/codegen/graphql';
import ToolTip from '../../common/ToolTip';

export interface ResourceTableProps {
  searchName?: string;
  data?: ResourceplanningGetProjectByIdQuery['masters'][number]['projectResourceCapture'];
  loading: boolean;
  setEditProjectResourcePlanning: (id: string) => void;
  setMode: (mode: 'create' | 'edit' | null) => void;
  setDeleteDialog: (id: string) => void;
}

const ProjectResourceTable: React.VFC<ResourceTableProps> = ({
  searchName,
  data,
  loading,
  setEditProjectResourcePlanning,
  setMode,
  setDeleteDialog,
}) => {
  const { t } = useTranslation();

  const nameFilter = useMemo(() => {
    const filterName = (searchName || '').toLocaleLowerCase();
    return data?.filter(item => {
      return (
        item['projectPhaseId'].toLowerCase().includes(filterName) ||
        item['roleId'].toLowerCase().includes(filterName) ||
        item['employee'].firstName?.toLowerCase().includes(filterName) ||
        item['employee'].lastName?.toLowerCase().includes(filterName)
      );
    });
  }, [data, searchName]);

  return (
    <div>
      <Table
        data={nameFilter ?? []}
        loading={loading}
        columns={[
          {
            label: t`tdReporting.projectResourcePage.phase`,
            render: data => <div>{data.projectPhaseId}</div>,
          },
          {
            label: t`tdReporting.projectResourcePage.department`,
            render: data => <div>{data.department}</div>,
          },
          {
            label: t`tdReporting.projectResourcePage.name`,
            render: data => (
              <div>
                {data?.employee.lastName + ', ' + data?.employee.firstName}
              </div>
            ),
          },
          {
            label: t`tdReporting.projectResourcePage.month`,
            render: data => <div>{data.month?.toFixed(1)}</div>,
          },
          {
            label: t`tdReporting.projectResourcePage.role`,
            render: data => <div>{data.roleId}</div>,
          },
          { label: '%', render: data => <div>{data.rate}</div> },
          {
            label: t`tdReporting.projectResourcePage.communication`,
            render: data => (
              <div>
                <div className="w-36 truncate">{data.communication}</div>
                <ToolTip
                  mode="hover"
                  position="left-under"
                  color="text-customBlue"
                  width="w-96"
                >
                  {data.communication}
                </ToolTip>
              </div>
            ),
          },
          {
            label: t`tdReporting.projectResourcePage.update`,
            render: data => (
              <div>
                <div className="w-36 truncate">{data.update}</div>
                <ToolTip
                  mode="hover"
                  position="left-under"
                  color="text-customBlue"
                  width="w-96"
                >
                  {data.update}
                </ToolTip>
              </div>
            ),
          },
          {
            label: t`tdReporting.projectResourcePage.startDate`,
            render: data => (
              <div>
                {format(
                  parseISO(data?.onboarding as unknown as string),
                  'dd.MM.yyyy',
                )}
              </div>
            ),
          },
          {
            label: t`tdReporting.projectResourcePage.endDate`,
            render: data => (
              <div>
                {data.endDate
                  ? format(
                      parseISO(data?.endDate as unknown as string),
                      'dd.MM.yyyy',
                    )
                  : null}
              </div>
            ),
          },
          {
            label: '',
            render: data => (
              <div
                onClick={() => {
                  setEditProjectResourcePlanning(data.id);
                  setMode('edit');
                }}
                className="cursor-pointer flex items-center justify-center rounded-full hover:bg-slate-200"
              >
                <FontAwesomeIcon icon={faPen} />
              </div>
            ),
          },
          {
            label: '',
            render: data => (
              <div
                onClick={() => {
                  setDeleteDialog(data.id);
                }}
                className="cursor-pointer flex items-center justify-center rounded-full hover:bg-slate-200"
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ProjectResourceTable;
