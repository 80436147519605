import React from 'react';

import './table.css';
import { GeneralCostValue } from './GeneralCostSumTable';

interface TableInputProps {
  data: GeneralCostValue;
  color: string;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  isBold?: boolean;
  handleInput?: (value: number, id: string) => void;
}

const TableInput = (props: TableInputProps) => {
  const { data, color, isHighlighted, isDisabled, isBold, handleInput } = props;
  const [isFocused, setIsFocused] = React.useState(false);

  const onInput = (e: any) => {
    if (handleInput) {
      const regex = new RegExp(/^(|0|[1-9][0-9]*)$/);
      if (!regex.test(e.target.value)) return;
      handleInput(e.target.value, data.id);
    }
  };

  const onBlur = (e: any) => {
    if (handleInput && e.target.value.length === 0) {
      handleInput(0, data.id);
    }
    setIsFocused(false);
  };

  const onFocus = (e: any) => {
    e.target.select();
    setIsFocused(true);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return (
    <input
      type="text"
      onChange={onInput}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      value={
        isFocused
          ? data?.sum?.toString()
          : new Intl.NumberFormat('de-DE').format(data?.sum!)
      }
      disabled={isDisabled}
      pattern="^[0-9]$"
      className={`w-24 h-6 px-1 text-right rounded-md
      focus:text-primary
      focus:outline focus:outline-primary
      ${isBold ? ' font-semibold' : ' font-light'}
      ${isHighlighted && ' text-primary'}
      ${!isDisabled && ' hover:outline-primary hover:outline '}
      bg-${color} `}
    />
  );
};

export default TableInput;
