import React from 'react';

import { DocumentRow, IDocument } from './DocumentRow';

interface DocumentTableProps {
  /**
   * available options to choose from
   */
  docs: IDocument[];
  markError: boolean;
  onReupload: (label: string) => void;
  onDelete: (doc: IDocument) => void;
  handleCriteriaStatus: (status: boolean) => void;
  handleDescChange: (text: string, index: number) => void;
}

/**
 * UI component to select one item from a list of options
 */
export const DocumentTable = ({
  docs,
  markError,
  onReupload,
  onDelete,
  handleCriteriaStatus,
  handleDescChange,
}: DocumentTableProps) => {
  return (
    <div className="flex flex-col flex-grow gap-12 mt-4">
      <table>
        <thead>
          <tr className="text-left">
            <th className="w-7/12">Beschreibung</th>
            <th className="w-1/3">Dateiname</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {docs.map((doc, index) => {
            return (
              <DocumentRow
                index={index}
                key={index}
                doc={doc}
                markError={markError}
                onReupload={onReupload}
                onDelete={onDelete}
                handleCriteriaStatus={handleCriteriaStatus}
                handleDescChange={handleDescChange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
