import { Fragment, PropsWithChildren } from 'react';
import { Dialog as HeadlessUIDialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

export default function Dialog({
  open,
  onClose,
  title,
  children,
}: PropsWithChildren<DialogProps>) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessUIDialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex min-h-screen items-start justify-center px-4 pt-28 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessUIDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-11/12 transform rounded-lg bg-gray-100 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-3/4 sm:align-middle lg:w-1/2">
              <div className="relative z-10 flex items-center justify-between border-b border-gray-200 py-2 pr-4 text-center">
                <HeadlessUIDialog.Title
                  as="h3"
                  className="flex items-center justify-center px-4 align-middle md:px-6 lg:px-8"
                >
                  <span className="flex content-center justify-center align-middle text-lg font-medium leading-9 text-gray-900">
                    {title}
                  </span>
                </HeadlessUIDialog.Title>
                <button className="p-2 outline-none" onClick={onClose}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-lg text-gray-700"
                  />
                </button>
              </div>
              <div className="bg-white px-4 py-4 md:py-6 md:px-6 lg:py-8 lg:px-8">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </HeadlessUIDialog>
    </Transition.Root>
  );
}
