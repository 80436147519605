import { FormDropdownOption } from 'components/common/Form/components/Dropdown';
import { GetProjectTypesQuery } from 'graphql/codegen/graphql';

export const mapProjectTypes = (
  types: GetProjectTypesQuery['projectTypes'] = [],
  description?: () => void,
): FormDropdownOption[] => {
  const mappedTypes: Record<string, FormDropdownOption> = {};
  types
    .filter(type => !type.projectTypeSub)
    .forEach(type => {
      const options = types
        .filter(
          subType =>
            subType.projectType === type.projectType && subType.projectTypeSub,
        )
        .map(subType => ({
          value: subType.id,
          options: [],
          label: subType.projectTypeSub!,
        }));
      mappedTypes[type.id] = options.length
        ? {
            value: type.id,
            label: type.projectType,
            options,
          }
        : description
        ? {
            value: type.id,
            label: type.description
              ? type.projectType + ' - ' + type.description!
              : type.projectType + ' - ' + description(),
          }
        : {
            value: type.id,
            label: type.description
              ? type.projectType + ' - ' + type.description!
              : type.projectType,
          };
    });
  return Object.keys(mappedTypes).reduce(
    (result, key) => [...result, mappedTypes[key]],
    [] as FormDropdownOption[],
  );
};

export const mapProjectSubTypes = (
  types: GetProjectTypesQuery['projectTypes'] = [],
): FormDropdownOption[] => {
  const mappedTypes: Record<string, FormDropdownOption> = {};
  types.forEach(type => {
    mappedTypes[type.id] = {
      value: type.id,
      label: type.projectTypeSub!,
    };
  });
  return Object.keys(mappedTypes).reduce(
    (result, key) => [...result, mappedTypes[key]],
    [] as FormDropdownOption[],
  );
};
