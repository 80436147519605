import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrayElement,
  ProjectRolesEnum,
  ProjectStatusEnum,
} from 'graphql/types';
import config from 'config/config';
import Table, { TableColumn } from 'components/NewTable';
import { getEmployeeByRole } from 'helpers/getEmployeeByRole';
import {
  useDashboardGetFilteredProjectsByResourceLazyQuery,
  DashboardGetFilteredProjectsQuery,
} from 'graphql/codegen/graphql';
import { useAppContext } from '../../../AppContext';
import { getProjectStatusByFilterType } from '../../../pages/Reporting/ProjectSelection/ProjectSelection';
import AuthenticationMessage from '../../../AuthenticationMessage';

export type ProjectFilter =
  | 'all'
  | 'ideas'
  | 'projects'
  | 'inventory'
  | 'activated';

export interface ProjectsTableProps {
  searchName?: string;
}
const TDReportingTable: React.VFC<ProjectsTableProps> = ({ searchName }) => {
  const { user } = useAppContext();
  const [fetchProjects, { data }] =
    useDashboardGetFilteredProjectsByResourceLazyQuery();
  const [isAdmin] = useState(AuthenticationMessage('td-reporting'));

  useEffect(() => {
    isAdmin
      ? fetchProjects({
          variables: {
            projectStatus: getProjectStatusByFilterType('all'),
            projectEmployee: user ? user.kid : '',
          },
        })
      : fetchProjects({
          variables: {
            projectStatus: getProjectStatusByFilterType('all'),
          },
        });
  }, [fetchProjects]);

  const nameFilter = useCallback(() => {
    return data?.masters.filter(item => {
      return (
        item.projectDescriptions?.[0].name
          .toLowerCase()
          .includes((searchName || '').toLowerCase()) &&
        item.projectStatus?.some(
          s =>
            s.sequenceStatusId === ProjectStatusEnum.ACTIVATED &&
            s.deletedBy === null,
        )
      );
    });
  }, [data, searchName]);

  return (
    <div>
      <Table
        data={nameFilter() ?? []}
        columns={getColumnsByFilterType('all')}
      />
    </div>
  );
};

export default TDReportingTable;

const getColumnsByFilterType = (
  filter: ProjectFilter,
): TableColumn<
  ArrayElement<DashboardGetFilteredProjectsQuery['masters']>
>[] => {
  switch (filter) {
    case 'all':
      return [
        {
          label: 'Projektname',
          render: data => (
            <div className={'max-w-xl truncate'}>
              <Link
                className="font-medium text-primary hover:underline hover:opacity-80"
                to={`${config.localRoutes.tdReporting.projectoverview.replace(
                  ':projectId',
                  data.id,
                )}`}
              >
                {data.projectDescriptions
                  ? data.projectDescriptions![0].name
                  : null}
              </Link>
            </div>
          ),
        },

        {
          label: <span>Inhalt/Beschreibung</span>,
          render: data => (
            <div className={'max-w-3xl truncate'}>
              {data.projectDescriptions![0].background}
            </div>
          ),
        },
        {
          label: <span>Projektleitung</span>,
          render: data => (
            <div className={'max-w-xl truncate'}>
              {getEmployeeByRole(data, ProjectRolesEnum.PROJEKTLEITUNG)
                ?.employee.lastName || 'zu benennen'}
            </div>
          ),
        },
      ];
    default:
      return [];
  }
};
