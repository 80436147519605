import classNames from 'classnames';
import Label from 'components/common/Label';
import React, { InputHTMLAttributes } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';
import ToolTip from '../../../ToolTip';

export type FormTextInputProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<UseControllerProps<TFieldValues>, 'control' | 'shouldUnregister'> &
    InputHTMLAttributes<HTMLInputElement> & {
      label?: string;
      tooltip?: string;
    };
function FormTextInput<TFieldValues extends FieldValues = FieldValues>({
  className,
  name,
  defaultValue,
  rules,
  label,
  tooltip,
  onChange,
  onBlur,
  ...rest
}: FormTextInputProps<TFieldValues>) {
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules,
  });
  const value = useWatch({
    name,
  });

  return (
    <div>
      <div className="flex">
        {label && <Label>{label}</Label>}
        {tooltip ? (
          <div className="pl-1">
            <ToolTip mode="hover" color="text-customBlue">
              {tooltip}
            </ToolTip>
          </div>
        ) : null}
      </div>
      <input
        className={classNames(
          className,
          'w-full rounded-md border border-gray-300 px-4 py-2 text-sm placeholder:text-gray-400 hover:border-primary',
        )}
        {...field}
        onBlur={event => {
          onBlur?.(event);
          field.onBlur();
        }}
        onChange={event => {
          onChange?.(event);
          field.onChange(event);
        }}
        {...rest}
      />
      {fieldState.error ? (
        <div className="text-red-500">{fieldState.error.message}</div>
      ) : null}
    </div>
  );
}
export default FormTextInput;
