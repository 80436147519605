import ContentLoader from 'react-content-loader';
import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps,
} from 'react-select';

export type DefaultProps<TOption> = {
  variant: 'default';
  loading?: boolean;
} & ReactSelectProps<TOption, false, GroupBase<TOption>>;
function Default<TOption>({
  variant,
  loading,
  ...selectProps
}: DefaultProps<TOption>) {
  return loading ? (
    <ContentLoader className="w-full h-10">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="38" />
    </ContentLoader>
  ) : (
    <ReactSelect {...selectProps} />
  );
}
export default Default;
