import { useAppContext } from './AppContext';
import { useGetLandingApplicationsQuery } from './graphql/codegen/graphql';

export default function AuthenticationMessage(applicationId: string) {
  const { user } = useAppContext();
  const { loading, data } = useGetLandingApplicationsQuery({
    variables: {
      where: {
        id: {
          equals: applicationId,
        },
      },
    },
  });

  if (loading || !user) {
    return true;
  } else {
    if (data?.landingApplications[0]) {
      return !data?.landingApplications[0].landingApplicationGroup?.some(a =>
        user?.groups?.includes(a.groupId),
      );
    } else {
      return true;
    }
  }
}
