import {
  components,
  ControlProps as ReactSelectControlProps,
  GroupBase,
} from 'react-select';
import './Control.css';

export type ControlProps<TOption> = ReactSelectControlProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function Control<TOption>({ children, ...rest }: ControlProps<TOption>) {
  return (
    <components.Control {...rest} className="dropdown-control">
      {children}
    </components.Control>
  );
}
export default Control;
