import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface TextInputProps {
  /**
   * id of the textbox
   */
  id: string;
  /**
   * No text can be entered
   */
  disabled?: boolean;
  /**
   * Textbox label
   */
  label?: string;
  /**
   * content of the textbox
   */
  value?: string;
  /**
   * placeholder value
   */
  placeholder?: string;
  /**
   * minimum number of characters
   */
  minChar?: number;
  /**
   * displays red border and count of current characters in red
   */
  markError?: boolean;
  /**
   * text of the shown error message below the input field
   */
  errorText?: string;
  /**
   * checks if criterias are fulfilled
   */
  criteriasChecked?: (checked: boolean) => void;
  /**
   * sends current value to parent
   */
  onChange: (text: string) => void;
  /**
   * displays the border when hovering over it
   */
  hover?: boolean;
}

/**
 * UI component to select one item from a list of options
 */
export const TextInput = ({
  id,
  disabled = false,
  label,
  value,
  placeholder,
  minChar,
  markError = false,
  errorText,
  criteriasChecked,
  onChange,
  hover,
  ...props
}: TextInputProps) => {
  let textField: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const location = useLocation();
  const tdReporting = location.pathname.includes('td-reporting');
  const [newValue, setNewValue] = useState(value);

  const handleChange = (e: any) => {
    // todo handle leading and trailing spaces
    checkCriterias(e.target.value.length);
    onChange(e.target.value);
    setNewValue(e.target.value);
  };

  const checkCriterias = (count: number) => {
    if (minChar && criteriasChecked) {
      if (minChar <= count) {
        criteriasChecked(true);
      } else {
        criteriasChecked(false);
      }
    }
  };

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    tdReporting && !value?.length && textField.current?.focus();
  });

  useEffect(() => {
    !tdReporting && textField.current?.focus();
    if (textField.current) {
      checkCriterias(textField.current?.value.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col flex-grow">
      {label && <label htmlFor={`select-${label}`}>{label}</label>}
      <input
        type="text"
        id={id}
        name={id}
        ref={textField}
        onChange={handleChange}
        value={newValue}
        placeholder={placeholder}
        disabled={disabled}
        className={`flex-grow overflow-auto h-8 rounded focus:text-primary focus:border-primary focus:border-1 ${
          markError ? 'border-red-600' : 'border-gray-300'
        } ${hover && 'hover:outline-primary hover:outline'}`}
      />
      {markError && errorText && (
        <div className="flex flex-grow-0 w-full mt-2 text-sm text-gray-300">
          <span className={`${markError && 'text-red-600'}`}>{errorText}</span>
        </div>
      )}
    </div>
  );
};
