import ConfirmIcon from 'components/ConfirmIcon';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface ProgressListItemProps {
  enabled?: boolean;
  finished?: boolean;
  to: string;
}
const ProgressListItem = ({
  children,
  enabled = false,
  finished = false,
  to,
}: PropsWithChildren<ProgressListItemProps>) => {
  const content = (
    <div className="flex items-center my-3 space-x-14">
      <ConfirmIcon enabled={enabled} active={finished} />
      <li
        className={classNames('mb-0 text-2xl font-normal', {
          'text-primary': enabled,
          'text-gray-300': !enabled,
        })}
      >
        {children}
      </li>
    </div>
  );
  return enabled ? <Link to={to}>{content}</Link> : content;
};

ProgressListItem.displayName = 'ProgressList.Item';

export default ProgressListItem;
