import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useState } from 'react';

export interface ToolTipProps {
  mode?: 'click' | 'hover';
  position?: 'left' | 'right' | 'left-under';
  color?: string;
  width?: string;
}

function ToolTip({
  mode,
  position,
  color,
  children,
  width,
}: PropsWithChildren<ToolTipProps>) {
  const [open, setOpen] = useState(false);

  const decideBehaviour = {
    click: {
      onClick: () => setOpen(!open),
    },
    hover: {
      onMouseEnter: () => setOpen(true),
      onMouseLeave: () => setOpen(false),
    },
  };

  return (
    <div className="relative w-6 h-6" {...decideBehaviour[mode || 'click']}>
      <button type="button" className="">
        <FontAwesomeIcon
          className={`left-1 top-1 h-4 w-4 absolute ${color ? color : ''}`}
          icon={faCircle}
        />
        <FontAwesomeIcon
          className={`left-2 top-2 h-2 w-2 absolute ${color ? color : ''}`}
          icon={faInfo}
        />
      </button>
      {open ? (
        <div
          className={`absolute z-10 ${
            width ? width : 'w-64'
          } px-3 py-2 text-sm font-medium text-white rounded-lg shadow-xl ${
            position === 'left'
              ? 'right-full bottom-4'
              : position === 'left-under'
              ? 'right-full top-4'
              : 'left-full bottom-4'
          } min-w-24 bg-grey whitespace-normal`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default ToolTip;
