import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import config from 'config/config';
import DescriptionPage from './Description';
import DocumentsPage from './Description/documents';
import TabBar from 'components/TabBar';
import { useDashboardGetProjectByIdLazyQuery } from 'graphql/codegen/graphql';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import TDReportingAuth from '../../../../components/TDReporting/TDReportingAuth';
export type GeneralPageProps = {
  projectId: string;
};

export interface StepProps<TData = any> {
  values?: Partial<TData>;
  onSubmit: (data: TData) => void;
  onPrev?: () => void;
  projectData?: any;
  projectId: string;
}

const GeneralPage = ({ projectId }: GeneralPageProps) => {
  const basePath = config.localRoutes.tdReporting.general.replace(
    ':projectId',
    projectId,
  );
  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState(false);

  const [projectDataById, { data: projectData }] =
    useDashboardGetProjectByIdLazyQuery({
      variables: {
        id: projectId,
      },
    });

  useEffect(() => {
    if (authenticated) {
      projectDataById();
    }
  }, [authenticated]);

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div className="w-full">
      {projectData?.masters?.[0].projectDescriptions?.[0].projectType
        .projectType === 'Pilotprojekt' ? (
        <TabBar
          isProjectSelected={projectId !== undefined}
          data={[
            {
              id: 1,
              text: t`tdReporting.subpages.summary`,
              route: [basePath, 'summary'].join('/'),
            },
            {
              id: 2,
              text: t`tdReporting.subpages.documents`,
              route: [basePath, 'documents'].join('/'),
            },
          ]}
        />
      ) : (
        <TabBar
          isProjectSelected={projectId !== undefined}
          data={[
            {
              id: 1,
              text: t`tdReporting.subpages.summary`,
              route: [basePath, 'summary'].join('/'),
            },
            {
              id: 2,
              text: t`tdReporting.subpages.background`,
              route: [basePath, 'background'].join('/'),
            },
            {
              id: 3,
              text: t`tdReporting.subpages.flow`,
              route: [basePath, 'flow'].join('/'),
            },
            {
              id: 4,
              text: t`tdReporting.subpages.documents`,
              route: [basePath, 'documents'].join('/'),
            },
          ]}
        />
      )}

      <Switch>
        <Route
          path={[basePath, 'summary'].join('/')}
          exact
          component={() => <DescriptionPage projectId={projectId} />}
        />
        <Route
          path={[basePath, 'background'].join('/')}
          exact
          component={() => <DescriptionPage projectId={projectId} />}
        />
        <Route
          path={[basePath, 'flow'].join('/')}
          exact
          component={() => <DescriptionPage projectId={projectId} />}
        />
        <Route
          path={[basePath, 'documents'].join('/')}
          exact
          component={() => <DocumentsPage projectId={projectId} />}
        />
      </Switch>
    </div>
  );
};
export default GeneralPage;
