import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Characteristic from './characteristic';

import {
  useGetInterfaceDetailLazyQuery,
  useGetProjectInterfacesQuery,
} from 'graphql/codegen/graphql';
import {
  ProjectInterfaceExportDetail,
  ProjectInterfaceImportDetail,
} from '../interfaceTypes';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import config from 'config/config';
export interface InterfacesPageProps {
  projectId: string;
  interfaceId: string;
}

const EditCharacteristicPage: React.VFC<InterfacesPageProps> = ({
  projectId,
  interfaceId,
}) => {
  const basePath = `${config.localRoutes.tdReporting.editInterface.replace(
    ':projectId',
    projectId,
  )}/edit`;
  const { t } = useTranslation();

  const [fetchInterfaceDetails] = useGetInterfaceDetailLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      data.interfaceDetails
        .filter(
          entry =>
            entry.deletedBy === null &&
            entry.isExport === true &&
            entry.id !== interfaceDetailId,
        )
        .map(lastEntry => {
          return setExportDescriptions({
            projectInterfaceId: lastEntry.projectInterfaceId,
            id: lastEntry.id,
            leanixName: {
              label: lastEntry.leanixMappingId || '',
              value: lastEntry.leanixMappingId || '',
            },
            interfaceTypeId: {
              label: lastEntry.interfaceTypeId,
              value: lastEntry.interfaceTypeId,
            },
            interfaceFormatId: {
              label: lastEntry.interfaceFormatId,
              value: lastEntry.interfaceFormatId,
            },
            exportDescription: lastEntry.description,
            isExport: true,
          });
        });
      data.interfaceDetails
        .filter(
          entry =>
            entry.deletedBy === null &&
            entry.isExport === false &&
            entry.id !== interfaceDetailId,
        )
        .map(lastEntry => {
          return setImportDescriptions({
            projectInterfaceId: lastEntry.projectInterfaceId,
            id: lastEntry.id,
            leanixName: {
              label: lastEntry.leanixMappingId || '',
              value: lastEntry.leanixMappingId || '',
            },
            interfaceTypeId: {
              label: lastEntry.interfaceTypeId,
              value: lastEntry.interfaceTypeId,
            },
            interfaceFormatId: {
              label: lastEntry.interfaceFormatId,
              value: lastEntry.interfaceFormatId,
            },
            importDescription: lastEntry.description,
            isExport: false,
          });
        });
    },
  });

  useEffect(() => {
    fetchInterfaceDetails();
  }, [fetchInterfaceDetails]);

  const [exportDescriptions, setExportDescriptions] =
    useState<ProjectInterfaceExportDetail>({
      projectInterfaceId: interfaceId,
      id: '',
      leanixName: {
        label: '',
        value: '',
      },
      interfaceTypeId: {
        label: '',
        value: '',
      },
      interfaceFormatId: {
        label: '',
        value: '',
      },
      exportDescription: '',
      isExport: true,
    });
  const [importDescriptions, setImportDescriptions] =
    useState<ProjectInterfaceImportDetail>({
      projectInterfaceId: interfaceId,
      id: '',
      leanixName: {
        label: '',
        value: '',
      },
      interfaceTypeId: {
        label: '',
        value: '',
      },
      interfaceFormatId: {
        label: '',
        value: '',
      },
      importDescription: '',
      isExport: false,
    });

  const [interfaceDetailId, setInterfaceDetailId] = useState<string>();
  return (
    <div>
      <Breadcrumb
        label={t('tdReporting.interfaces.characteristic')}
        route={basePath}
      />
      <div className=" px-4 bg-white">
        <dl>
          <div>
            <div key={'characteristic'}>
              <Characteristic
                exportDetails={exportDescriptions}
                importDetails={importDescriptions}
                projectId={projectId}
                onSubmit={data => {
                  setInterfaceDetailId(data);
                  fetchInterfaceDetails();
                }}
                interfaceId={interfaceId}
              ></Characteristic>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};
export default EditCharacteristicPage;
