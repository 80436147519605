import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import ProjectsTable, {
  ProjectFilter,
} from 'components/dashboard/ProjectTable';
import AuthenticationMessage from '../../AuthenticationMessage';
import { useAppContext } from '../../AppContext';
import { useGetLandingApplicationsQuery } from '../../graphql/codegen/graphql';

const DashboardPage = ({ match }) => {
  const { t } = useTranslation();
  const { location, replace } = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [isAllowed, setIsAllowed] = useState(
    process.env.NODE_ENV === 'development'
      ? true
      : AuthenticationMessage('td-reporting'),
  );

  // ToDo: Remove tmp hotfix
  const { user } = useAppContext();
  const { loading, data: landingApplicationData } =
    useGetLandingApplicationsQuery({
      variables: {
        where: {
          id: {
            equals: 'td-reporting',
          },
        },
      },
    });

  useEffect(() => {
    if (loading || !user) {
      setIsAllowed(true);
    } else {
      if (
        landingApplicationData?.landingApplications[0].landingApplicationGroup
      ) {
        setIsAllowed(
          process.env.NODE_ENV === 'development'
            ? true
            : landingApplicationData?.landingApplications[0].landingApplicationGroup?.some(
                a => user?.groups?.includes(a.groupId),
              ),
        );
      } else {
        setIsAllowed(true);
      }
    }
  }, [isAllowed, landingApplicationData]);

  const onFilterChange = (filter: ProjectFilter) => {
    replace({
      pathname: location.pathname,
      search: `filter=${filter}`,
    });

    localStorage.setItem('DashboardProjectFilter', filter);
  };

  const onSearchChange = value => {
    setSearchValue(value);
  };

  if (AuthenticationMessage('it-projects')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  }

  return (
    <div>
      <div className="mb-5 border-b border-gray-300 pb-5">
        <div className="flex items-center justify-between">
          <div className="sm:w-0 sm:flex-1">
            <h3 className="text-lg font-medium leading-9 text-gray-900">
              {t`itProjects.dashboard.headline`}
            </h3>
            <p className="mt-2 max-w-4xl text-sm text-gray-600">
              {t`itProjects.dashboard.description`}
            </p>
          </div>
          <div className="ml-6 flex flex-shrink-0">
            {isAllowed && (
              <Menu as="div" className="relative ml-3 inline-block text-left">
                <div>
                  <Menu.Button className="flex items-center rounded-full p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary">
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/it-projects/recipient-approver`}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex justify-between px-4 py-2 text-sm',
                            )}
                          >
                            <span>{t`itProjects.dashboard.actions.recipients`}</span>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/it-projects/documents`}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex justify-between px-4 py-2 text-sm',
                            )}
                          >
                            <span>{t`itProjects.dashboard.actions.documents`}</span>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/it-projects/approver`}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex justify-between px-4 py-2 text-sm',
                            )}
                          >
                            <span>{t`itProjects.dashboard.actions.approver`}</span>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/it-projects/process`}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex justify-between px-4 py-2 text-sm',
                            )}
                          >
                            <span>{t`itProjects.dashboard.actions.processOverview`}</span>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/it-projects/applications`}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'flex justify-between px-4 py-2 text-sm',
                            )}
                          >
                            <span>{t`itProjects.dashboard.actions.landingApplications`}</span>
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
      </div>
      <div className="w-96">
        <input
          className="w-full rounded-md border border-gray-300 px-4 py-2 text-sm placeholder:text-gray-400"
          name="search"
          onChange={event => onSearchChange(event.currentTarget.value)}
          placeholder="Suche nach Projektname"
          autoComplete="off"
        />
      </div>
      <ProjectsTable
        filter={matchProjectFilter(
          localStorage.getItem('DashboardProjectFilter'),
        )}
        searchName={searchValue}
        onChange={onFilterChange}
      />
    </div>
  );
};
export default DashboardPage;

const matchProjectFilter = (filter: any): ProjectFilter => {
  if (filter == null) {
    filter = 'ideas';
  }

  const allowedFilters: ProjectFilter[] = [
    'all',
    'ideas',
    'projects',
    'inventory',
    'activated',
    'declined',
    'returned',
    'deleted',
    'accepted_by_committee',
    'accepted_by_committee_and_budget_release',
    'budget_release',
  ];
  return allowedFilters.includes(filter) ? (filter as ProjectFilter) : 'ideas';
};
