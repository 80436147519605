import React, { useEffect, useState } from 'react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { StepProps } from '.';
import { User } from 'microsoft-graph';
import { debounce } from 'lodash';
import { useMsal } from '@azure/msal-react';
import { useTranslation, Trans } from 'react-i18next';

import configuration from '../../Config';
import useTypedForm from 'components/common/Form/components';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Button } from 'components/common/Button';
import { findUser } from '../../GraphService';
import { useProjectDescriptionNamesQuery } from '../../graphql/codegen/graphql';

export type FormData = {
  name: string;
  contact: {
    enabled: boolean;
    kid: string;
    user: any;
  };
  startDate: string;
  duration: number;
  comments: string;
};

const SHORT_KID_LENGTH = 5;
const LONG_KID_LENGTH = 6;

const Details = ({ values, onSubmit, onPrev }: StepProps<FormData>) => {
  const { t } = useTranslation();
  const { DatePicker, Value, Switch, TextInput, Form, TextArea, SubmitButton } =
    useTypedForm<FormData>();
  const [employee, setEmployee] = useState<User>();
  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );
  const [nameArray, setNameArray] = useState<any>();
  const [commentsDescription, setCommentsDescription] = useState(
    values?.comments,
  );
  const {
    data: projectDescriptionNamesData,
    loading: projectDescriptionNamesLoading,
  } = useProjectDescriptionNamesQuery({
    variables: {
      where: {
        deletedBy: {
          equals: null,
        },
      },
    },
    onCompleted: data => {
      let hase: string[] = data.projectDescriptions.map(d => {
        return d.name;
      });
      setNameArray(hase);
    },
  });

  // const handleNameFieldBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // TODO: Check Project Name against Leanix-Mappings?
  //   console.log(event.target.value);
  // };

  const requestEmployee = async (value: string) => {
    setEmployee(undefined);
    if (value) {
      const user = await findUser(authProvider, value);
      if (user) {
        setEmployee(user);
        if (values?.contact) {
          values.contact.user = user;
        }
      }
    }
  };
  const debouncedRequestEmployee = debounce(requestEmployee, 1000);

  const handleKIDFieldChange = (value: string) => {
    setEmployee(undefined);
    if (value.length === SHORT_KID_LENGTH || value.length === LONG_KID_LENGTH)
      debouncedRequestEmployee(value);
  };

  useEffect(() => {
    requestEmployee(values?.contact?.kid || '');
  }, []);

  const [nameValue, setNameValue] = useState<string>();

  return (
    <div className="flex flex-col flex-1 w-full">
      <Breadcrumb label="Eckdaten" route="neue-it-initiative/details" />

      <Form
        onSubmit={onSubmit}
        defaultValues={{
          ...values,
          contact: {
            enabled:
              values?.contact?.enabled !== undefined
                ? values?.contact?.enabled
                : true,
            kid: values?.contact?.kid ? values?.contact?.kid : '',
            user: values?.contact?.user,
          },
        }}
        mode="onChange"
        className="flex flex-col h-full"
      >
        <div className="flex flex-col flex-1 w-full space-y-10">
          <p className="text-base">{t`myItIdea.details.description`}</p>
          <div>
            <TextInput
              name="name"
              label={t`myItIdea.details.fields.name`}
              onChange={v => setNameValue(v.target.value)}
              rules={{
                required: true,
                validate: () => {
                  if (nameArray) {
                    if (nameArray.length === 0) {
                      return true;
                    } else {
                      return !nameArray.includes(nameValue);
                    }
                  } else {
                    return true;
                  }

                },
              }}
            />
            {nameArray?.includes(nameValue) && (
              <p className="text-red-600">{t`myItIdea.details.fields.nameErrorMessage`}</p>
            )}
          </div>
          <div className="py-5 lg:-mx-12 xl:-mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="grid items-start w-full grid-cols-1 gap-5 lg:grid-cols-2">
            <Switch
              name="contact.enabled"
              rules={{
                validate: () => true,
              }}
            >
              <span className="text-primary">{t`myItIdea.details.fields.contact`}</span>
            </Switch>
            <Value name="contact.enabled">
              {enabled =>
                enabled ? null : (
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                    <TextInput
                      name="contact.kid"
                      label={t`myItIdea.details.fields.kid`}
                      onChange={e => {
                        handleKIDFieldChange(e.target.value);
                      }}
                      rules={{
                        required: {
                          value: !enabled,
                          message: 'Dieses Feld darf nicht leer sein',
                        },
                        pattern: {
                          value:
                            process.env.NODE_ENV === 'development'
                              ? /./
                              : /^[A-Z][0-9]{4,6}$/,
                          message:
                            'Die eingegebene KID ist nicht im richtigen Format',
                        },
                        validate: () => {
                          // empty error message because it would be displayed at wrong times
                          return (
                            (employee?.displayName !== null &&
                              employee?.displayName !== undefined) ||
                            ''
                          );
                        },
                      }}
                    />
                    <div className="self-end px-5 mb-2 text-gray-400">
                      {employee ? employee?.displayName : ''}
                    </div>
                  </div>
                )
              }
            </Value>
          </div>
          <div className="py-5 lg:-mx-12 xl:-mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="grid items-start grid-cols-2 gap-5">
            <DatePicker
              name="startDate"
              label={t`myItIdea.details.fields.startDate`}
              rules={{ required: true }}
            />
            <TextInput
              name="duration"
              label={t`myItIdea.details.fields.duration`}
              tooltip={t`myItIdea.details.fields.durationTooltip`}
              rules={{
                required: {
                  value: true,
                  message: 'Dieses Feld darf nicht leer sein',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Nur ganze und positive Zahlen sind zulässig',
                },
              }}
            />
          </div>
          <div className="py-5 lg:-mx-12 xl:-mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="w-full">
            <p className="pb-5" ><Trans i18nKey="myItIdea.details.fields.comments" /></p>
            <TextArea
              name="comments"
              rules={{
                required: false,
              }}
              value={commentsDescription}
              onChange={e => {
                setCommentsDescription(e.target.value);
              }}
            />

          </div>
          <div className="py-5 lg:-mx-12 xl:-mx-24">
            <hr className="border-gray-300" />
          </div>
        </div>
        <div className="flex justify-end flex-grow-0 pt-5 space-x-7">
          {onPrev && (
            <Button onClick={onPrev} theme="secondary">
              {t('myItIdea.actions.prev')}
            </Button>
          )}
          <SubmitButton>{t('myItIdea.actions.next')}</SubmitButton>
        </div>
      </Form>
    </div>
  );
};
export default Details;
