import { useTranslation } from 'react-i18next';
import {
  ReportingGetProjectByIdQuery,
  useCreateProjectShortHistoryMutation,
  useUpdateProjectDescriptionMutation,
} from '../../../../graphql/codegen/graphql';
import { useCallback, useEffect, useState } from 'react';
import SkeletonLoader from '../../../../components/SkeletonLoader';

import EditNameDialog from '../../../../components/TDReporting/Dialog/EditProjectNameDialog';

import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import useTypedForm from 'components/common/Form/components';
import { debounce } from 'lodash';
import { useAppContext } from 'AppContext';

export interface ProjectOverviewPageProps {
  projectId: string;
  data: ReportingGetProjectByIdQuery | undefined;
  loading: boolean;
}
interface FormValues {
  projectName: string;
}
const OverviewPage = ({
  projectId,
  data,
  loading,
}: ProjectOverviewPageProps) => {
  const { t } = useTranslation();
  const [editProjectName, setEditProjectName] = useState<boolean>(false);
  const basePath = config.localRoutes.tdReporting.projectoverview.replace(
    ':projectId',
    projectId,
  );
  const { user } = useAppContext();

  const PROJECT_NAME_MAX_LENGTH = 100;
  const DEBOUNCE_TIME = 500;

  const { TextInput, Form } = useTypedForm();
  const [updateProjectDescription] = useUpdateProjectDescriptionMutation();
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();

  let sortArrayShortHistory: any = [];
  data?.masters[0].projectShortHistory?.forEach(h =>
    sortArrayShortHistory.push(h),
  );

  const [projectName, setProjectName] = useState<FormValues>({
    projectName: data?.masters[0]?.projectDescriptions?.[0].name
      ? data?.masters[0]?.projectDescriptions?.[0].name
      : '',
  });

  const [countSummaryInput, setSummaryCount] = useState(
    projectName.projectName !== undefined ? projectName.projectName!.length : 0,
  );

  useEffect(() => {
    if (data && data?.masters[0]?.projectDescriptions?.[0]) {
      setProjectName({
        projectName: data?.masters[0]?.projectDescriptions?.[0].name,
      });
    }
  }, [data]);

  const handleInput = (data: FormValues) => {
    handleUpdateProjectDescription(data);
  };

  const debouncedhandleInput = useCallback(
    debounce(value => handleInput(value), DEBOUNCE_TIME),
    [],
  );

  const handleUpdateProjectDescription = async (projectNameData: {
    projectName: string;
  }) => {
    await updateProjectDescription({
      variables: {
        data: {
          name: {
            set: projectNameData.projectName,
          },
        },
        where: {
          id: data?.masters[0]?.projectDescriptions?.[0].id,
        },
      },
    });
    await createProjectShortHistory({
      variables: {
        data: {
          project: {
            connect: {
              id: data?.masters[0].id,
            },
          },
          action: 'Projektname',
          createdBy: user?.kid,
        },
      },
    });
  };

  return (
    <Form onSubmit={() => console.log('')}>
      <div className="w-full">
        <Breadcrumb
          label={t('tdReporting.pages.projectoverview')}
          route={basePath}
        />
        <div className="w-min-40 px-4 bg-white">
          <dl>
            <div className="sm:col-span-1 space-y-5">
              <div className="w-1/2 space-y-5">
                <dt className="pt-10 text-primary">{t`tdReporting.projectoverviewPage.projectName`}</dt>
                <dd>
                  <TextInput
                    name="ProjectOverviewName"
                    value={projectName.projectName}
                    className={`${
                      countSummaryInput > PROJECT_NAME_MAX_LENGTH &&
                      'border-red-600 truncate'
                    }`}
                    onChange={e => {
                      setSummaryCount(e.target.value.length);
                      setProjectName({ projectName: e.target.value });
                      if (e.target.value.length <= PROJECT_NAME_MAX_LENGTH) {
                        debouncedhandleInput({ projectName: e.target.value });
                      }
                    }}
                    rules={{ required: true }}
                  />
                  <div className="flex flex-row pt-2">
                    {countSummaryInput > PROJECT_NAME_MAX_LENGTH && (
                      <p className="text-red-600 text-sm">
                        {t`tdReporting.projectResourcePage.errorMessageCommunication`}
                      </p>
                    )}
                    <p
                      className={`${
                        countSummaryInput > PROJECT_NAME_MAX_LENGTH
                          ? 'text-red-600'
                          : 'text-gray-500'
                      } justify-end mt-0.5  text-sm ml-auto`}
                    >
                      {`${countSummaryInput}/${PROJECT_NAME_MAX_LENGTH}`}
                    </p>
                  </div>
                </dd>
              </div>
            </div>
            <div className="sm:col-span-1 space-y-5">
              <div className="w-1/2 space-y-5">
                <dt className="pt-10 text-primary">{t`tdReporting.projectoverviewPage.projectType`}</dt>
                <dd className="flex mt-1 text-sm text-gray-900 space-y-4">
                  <div>
                    {
                      data?.masters[0]?.projectDescriptions?.[0].projectType
                        .projectType
                    }
                  </div>
                  <div>
                    {data?.masters[0]?.projectDescriptions?.[0].projectType
                      .projectType === 'Infrastrukturprojekte' ? (
                      <div>
                        {': '}
                        <SkeletonLoader
                          className="h-10 w-full"
                          loading={loading}
                        >
                          {
                            data?.masters[0]?.projectDescriptions?.[0]
                              .projectType.projectTypeSub
                          }
                        </SkeletonLoader>
                      </div>
                    ) : null}
                  </div>
                </dd>
              </div>
            </div>
            <div className="sm:col-span-1 space-y-5">
              <div className="w-1/2 space-y-5">
                <dt className="pt-10 text-primary">{t`tdReporting.projectoverviewPage.projectMethod`}</dt>
                <dd className="text-sm text-gray-900 ">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {data?.masters[0]?.projectDescriptions?.[0].method
                      ?.methodName
                      ? data?.masters[0]?.projectDescriptions?.[0].method
                          ?.methodName
                      : t`tdReporting.projectoverviewPage.notSpecified`}
                  </SkeletonLoader>
                </dd>
              </div>
            </div>
          </dl>
          <EditNameDialog
            editProjectName={editProjectName}
            onClose={() => setEditProjectName(false)}
            data={data}
          />
        </div>
      </div>
    </Form>
  );
};
export default OverviewPage;
