import {
  components,
  GroupBase,
  GroupHeadingProps as ReactSelectGroupHeadingProps,
} from 'react-select';
import './GroupHeading.css';

export type GroupHeadingProps<TOption> = ReactSelectGroupHeadingProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function GroupHeading<TOption>({
  children,
  ...rest
}: GroupHeadingProps<TOption>) {
  return (
    <components.GroupHeading {...rest} className="dropdown-group-heading">
      {children}
    </components.GroupHeading>
  );
}
export default GroupHeading;
