import React from 'react';
import { FinancialValue } from './FinancialTable';
import './table.css';
import { useHistory } from 'react-router-dom';

interface TableInputProps {
  data: FinancialValue;
  financialDelta?: any;
  color: string;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  isBold?: boolean;
  handleInput?: (value: number, id: string) => void;
  disableDelta?: boolean;
}

const TableInput = (props: TableInputProps) => {
  const {
    data,
    color,
    isHighlighted,
    isDisabled,
    isBold,
    handleInput,
    disableDelta,
  } = props;
  const { location } = useHistory();
  const [isFocused, setIsFocused] = React.useState(false);

  const onInput = (e: any) => {
    if (handleInput) {
      const regex = new RegExp(/^(|0|[1-9][0-9]*)$/);
      if (!regex.test(e.target.value)) return;
      handleInput(e.target.value, data.id);
    }
  };

  const onBlur = (e: any) => {
    if (handleInput && e.target.value.length === 0) {
      handleInput(0, data.id);
    }
    setIsFocused(false);
  };

  const onFocus = (e: any) => {
    e.target.select();
    setIsFocused(true);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const delta =
    location.pathname.substring(0, 22) === '/dashboard-controller/' &&
    !!document
      .getElementById('financeDatePicker')
      ?.attributes.getNamedItem('value')?.value
      ? data?.isEstimatedCost
        ? data
          ? data.value! - data.delta!
          : null
        : data?.id?.substring(0, 19) === 'key-estimated-pages'
        ? null
        : data?.id?.substring(0, 19) === 'key-estimated-Summe'
        ? data.delta
        : data?.id?.substring(0, 11) === 'key-summary'
        ? data?.value! - data?.delta!
        : data?.id?.substring(0, 8) !== 'project-'
        ? data
          ? data.value! - data.delta!
          : NaN
        : data.delta
      : null;

  return (
    <div className="flex">
      <input
        type="text"
        onChange={onInput}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={
          isFocused
            ? data?.value?.toString()
            : new Intl.NumberFormat('de-DE').format(data?.value!)
        }
        disabled={isDisabled}
        pattern="^[0-9]$"
        className={`w-24 h-6 px-1 text-right rounded-md
        focus:text-primary
        focus:outline focus:outline-primary
        ${isBold ? ' font-semibold' : ' font-light'}
        ${isHighlighted && ' text-primary'}
        ${!isDisabled && ' hover:outline-primary hover:outline '}
        bg-${color} `}
      />
      <p
        className={`${
          delta! > 0 ? 'text-red-600 font-bold' : 'text-green-600 font-bold'
        }`}
      >
        {!disableDelta && delta ? `(${delta})` : null}
      </p>
    </div>
  );
};

export default TableInput;

// TableInput.defaultProps = {
//   isDisabled: false,
//   isBold: false,
// };
