import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmIcon from 'components/ConfirmIcon';
import useTypedForm from 'components/common/Form/components';
import { useAppContext } from 'AppContext';
import {
  useGetProjectDescriptionsLazyQuery,
  useUpdateApprovalStatusMutation,
  useGetMailInfoByProjectLazyQuery,
  useGetProjectApprovalsByIdLazyQuery,
} from 'graphql/codegen/graphql';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export interface ApprovalInformationSecurityManagementProps {
  approved: boolean;
  projectId: string;
  confirmed: boolean;
}

const ApprovalInformationSecurityManagement = ({
  approved,
  projectId,
  confirmed,
}: ApprovalInformationSecurityManagementProps) => {
  const { Form, IconSubmitButton } = useTypedForm();
  const { t } = useTranslation();
  const { user } = useAppContext();

  const [getProjectDescription] = useGetProjectDescriptionsLazyQuery({
    variables: {
      where: {
        projectId: {
          equals: projectId,
        },
      },
    },
  });
  const [updateApprovalStatusMutation] = useUpdateApprovalStatusMutation();
  const [projectApproval] = useGetProjectApprovalsByIdLazyQuery();
  const [getMailInfo, { data: mailInfoData }] =
    useGetMailInfoByProjectLazyQuery();

  const options = [
    { value: '1', label: t('myItInventory.fields.sbkStatus.low') },
    { value: '2', label: t('myItInventory.fields.sbkStatus.medium') },
    { value: '3', label: t('myItInventory.fields.sbkStatus.high') },
    { value: '4', label: t('myItInventory.fields.sbkStatus.veryHigh') },
  ];

  const [sbk, setSbk] = useState<{ value: string; label: string }>({
    value: '0',
    label: '',
  });

  useEffect(() => {
    loadContent();
  }, [mailInfoData]);

  useEffect(() => {
    projectApproval({
      variables: {
        projectId: projectId,
        approvalBoardId: 'ism',
      },
    });
  }, []);

  const loadContent = () => {
    getMailInfo({
      variables: {
        where: {
          id: projectId,
        },
        where2: {
          projectId: {
            equals: projectId,
          },
        },
      },
    });

    getProjectDescription().then(response => {
      const description = response && response.data!.projectDescriptions[0]!;
      const sbkId = options.find(obj => {
        return obj.label === description.sbk;
      });
      setSbk({
        value: sbkId ? sbkId.value : '0',
        label: description.sbk || '',
      });
    });
  };

  const handleSubmit = async () => {
    await updateApprovalStatusMutation({
      variables: {
        data: {
          projectApproval: {
            create: [
              {
                createdBy: user!.kid!,
                ApprovalBoard: { connect: { id: 'ism' } },
                approvalStatus:
                  process.env.NODE_ENV === 'development'
                    ? { connect: { id: 'angenommen' } }
                    : undefined,
              },
            ],
          },
        },
        projectId,
      },
      refetchQueries: [
        'getMasterWithApprovals',
        'getProjectDescriptions',
        'getEmailHistory',
      ],
    });
  };

  return (
    <>
      <Breadcrumb
        label={t('myItProject.steps.ism')}
        route={`/meine-it-initiative/${projectId}/ism`}
      />
      <Form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <p className="text-xl">{t`myItProject.informationSecurityManagement.description`}</p>

          <p className="text-xl font-medium pb-4">{t`myItProject.informationSecurityManagement.description3`}</p>
          <div className="flex items-center space-x-5">
            <IconSubmitButton
              active={sbk.value === '0'}
              disabled={sbk.value !== '0'}
              approved={confirmed}
            >{t`myItProject.informationSecurityManagement.confirmedLabel`}</IconSubmitButton>
          </div>

          <ConfirmIcon
            enabled={true}
            active={approved}
          >{t`myItProject.informationSecurityManagement.approvedLabel`}</ConfirmIcon>
        </div>
      </Form>
    </>
  );
};
export default React.memo(ApprovalInformationSecurityManagement);
