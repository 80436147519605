import React, { useEffect, useState } from 'react';

import FinancialSumTable from '../../financialData/FinancialSumTable';

import {
  SortOrder,
  useDashboardGetFilteredProjectsLazyQuery,
  useGetFinancialSumByProjectLazyQuery,
} from 'graphql/codegen/graphql';

export interface ProjectsTableProps {
  projectId: string;
}

const ApproverTable: React.VFC<ProjectsTableProps> = ({ projectId }) => {
  const [fetchProjects, { data: projectData }] =
    useDashboardGetFilteredProjectsLazyQuery();
  const [getFinancialData, { data: financialData }] =
    useGetFinancialSumByProjectLazyQuery();

  const [showProjectData, setShowProjectData] = useState(true);

  useEffect(() => {
    fetchProjects({
      variables: {
        projectStatus: 'getProjectStatusByFilterType(filter)',
      },
    });

    getFinancialData({
      variables: {
        where: {
          projectId: {
            equals: projectId,
          },
          asset: {
            isProjectCosts: {
              equals: showProjectData,
            },
          },
        },
        assetsOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
        companiesOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
      },
    });
  }, [fetchProjects, getFinancialData, projectId, showProjectData]);

  const projectCostChange = (isProjectCost: boolean) => {
    setShowProjectData(isProjectCost);
  };

  return (
    <div>
      <FinancialSumTable
        projectId={projectId}
        financialData={financialData ? financialData.finances : []}
        companiesData={financialData ? financialData.companies : []}
        assetsData={financialData ? financialData.assets : []}
        projectCostChange={projectCostChange}
      />
    </div>
  );
};
export default ApproverTable;
