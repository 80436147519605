import { useTranslation } from 'react-i18next';

import { Route, Switch } from 'react-router';

import TabBar from 'components/TabBar';
import config from 'config/config';
import ReportData from './reportData';

export interface ReportPageProps {
  projectId: string;
}

const ReportPage: React.VFC<ReportPageProps> = ({ projectId }) => {
  const { t } = useTranslation();

  const basePath = config.localRoutes.tdReporting.report.replace(
    ':projectId',
    projectId,
  );

  return (
    <div className="w-full">
      <TabBar
        isProjectSelected={projectId !== undefined}
        data={[
          {
            id: 1,
            text: t`tdReporting.pages.report`,
            route: `${basePath}`,
          },
        ]}
      />

      <Switch>
        <Route
          path={`${basePath}`}
          exact
          component={() => <ReportData projectId={projectId} />}
        />
      </Switch>
    </div>
  );
};
export default ReportPage;
