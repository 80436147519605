import { useAppContext } from 'AppContext';
import {
  useReportingGetProjectByIdLazyQuery,
  useCreateAddresseeGroupMutation,
} from 'graphql/codegen/graphql';

import { useEffect, useState } from 'react';
import ReportTable from './table';
export type AddresseeGroupType = {
  createdBy: string;
  employeeId: string;
};
export type ReportTableData = {
  reportTitle: string;
  createdBy: string;
  id: string;
};

const ReportData = ({ projectId }) => {
  const [report, setReport] = useState<ReportTableData[]>([]);
  const [createAddresseeGroupMutation] = useCreateAddresseeGroupMutation();
  const [lastReportId, setLastReportId] = useState<number>();
  const [projectName, setProjectName] = useState<string>();

  const { user } = useAppContext();

  const handleSubmit = (addresseeGroup: AddresseeGroupType[]) => {
    if (lastReportId) {
      createAddresseeGroup(addresseeGroup);
    }
  };

  const createAddresseeGroup = (addresseeGroup: AddresseeGroupType[]) => {
    addresseeGroup.forEach(addressee => {
      if (user && user.kid && addresseeGroup) {
        createAddresseeGroupMutation({
          variables: {
            data: {
              report: {
                connect: {
                  id: lastReportId,
                },
              },
              createdBy: user.kid,
              employee: {
                connect: {
                  id: addressee.employeeId,
                },
              },
            },
          },
        });
      }
    });
    fetchProjects();
  };

  const [fetchProjects, { loading }] = useReportingGetProjectByIdLazyQuery({
    variables: {
      id: projectId,
    },
    onCompleted(data) {
      if (data && data.masters[0].report) {
        data.masters[0].report
          .filter(entry => entry.deletedBy === null)
          .map(entry => {
            if (!report.includes(entry)) {
              setLastReportId(Number(entry.id));
              if (entry.project.projectDescriptions)
                setProjectName(entry.project.projectDescriptions[0].name);
              return setReport(prev => {
                return [{ ...prev, ...entry }];
              });
            } else {
              return '';
            }
          });
      }
    },
  });

  useEffect(() => {
    fetchProjects({
      variables: {
        id: projectId,
      },
    });
  }, [fetchProjects, projectId]);

  return (
    <div className="w-full pt-10 space-y-10">
      <ReportTable
        report={report}
        projectName={projectName || ''}
        loading={loading}
        onSubmit={addresseeGroup => handleSubmit(addresseeGroup)}
      ></ReportTable>
    </div>
  );
};
export default ReportData;
