import Dialog from 'components/common/Dialog';
import useTypedForm from 'components/common/Form/components';
import {
  DashboardGetProjectByIdDocument,
  useCreateProjectApprovalMutation,
  useUpdateProjectApprovalMutation,
} from 'graphql/codegen/graphql';
import { useAppContext } from 'AppContext';

interface FormValues {}

const IdeaBudgetAcceptDialog: React.VFC<{
  open: boolean;
  onClose: () => void;
  projectApprovalId: number;
  projectId?: string;
}> = ({ open, onClose, projectApprovalId, projectId }) => {
  const { Form, SubmitButton } = useTypedForm<FormValues>();
  const { user } = useAppContext();

  const [createBudgetAccept] = useCreateProjectApprovalMutation({
    variables: {
      data: {
        createdBy: user?.kid ? user!.kid : '',
        project: {
          connect: {
            id: projectId,
          },
        },
        ApprovalBoard: {
          connect: {
            id: 'con',
          },
        },
        approvalStatus: {
          connect: {
            id: 'angenommen',
          },
        },
      },
    },
  });
  const [updateBudgetAccept] = useUpdateProjectApprovalMutation({
    variables: {
      data: {
        deletedBy: {
          set: user?.kid ? user!.kid : '',
        },
        deletedAt: {
          set: new Date(),
        },
      },
      where: {
        id: projectApprovalId,
      },
    },
  });

  const handleSubmit = async data => {
    if (projectApprovalId) {
      await updateBudgetAccept();
    }
    await createBudgetAccept({
      refetchQueries: [DashboardGetProjectByIdDocument],
    });
    onClose();
  };

  return (
    <Dialog title="Budget freigeben" open={open} onClose={onClose}>
      <Form onSubmit={handleSubmit} mode="onChange">
        <div className="text-base font-normal text-gray-900 text-center sm:text-lg sm:text-gray-700">
          Willst du das Budget wirklich freigeben? Das Budget kann bis zum
          Projektstart nicht mehr geändert werden
        </div>
        <div className="pt-6 sm:pt-5">
          <div role="group" aria-labelledby="label-notifications">
            <div className="my-8 flex items-center justify-center">
              <SubmitButton>Freigeben</SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </Dialog>
  );
};
export default IdeaBudgetAcceptDialog;
