import {
  components,
  GroupBase,
  PlaceholderProps as ReactSelectPlaceholderProps,
} from 'react-select';
import './Placeholder.css';

export type PlaceholderProps<TOption> = ReactSelectPlaceholderProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function Placeholder<TOption>({
  children,
  ...rest
}: PlaceholderProps<TOption>) {
  return (
    <components.Placeholder {...rest} className="dropdown-placeholder">
      {children}
    </components.Placeholder>
  );
}
export default Placeholder;
