import CommonSwitch from 'components/common/Switch';
import { PropsWithChildren } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';

export type FormSwitchProps<TFieldValues extends FieldValues = FieldValues> =
  PropsWithChildren<
    Omit<UseControllerProps<TFieldValues>, 'control' | 'shouldUnregister'>
  > & {
    disabled?: boolean;
  };
function FormSwitch<TFieldValues extends FieldValues = FieldValues>({
  children,
  disabled,
  ...controllerProps
}: FormSwitchProps<TFieldValues>) {
  const { field } = useController<TFieldValues>(controllerProps);
  const value = useWatch({
    name: controllerProps.name,
  });

  return (
    <CommonSwitch checked={value} onChange={field.onChange} disabled={disabled}>
      {children}
    </CommonSwitch>
  );
}
export default FormSwitch;
