import Control, { ControlProps } from './Control/Control';
import GroupHeading, { GroupHeadingProps } from './GroupHeading/GroupHeading';
import Input, { InputProps } from './Input/Input';
import NoOptionsMessage, {
  NoOptionsMessageProps,
} from './NoOptions/NoOptionsMessage';
import Option, { OptionProps } from './Option/Option';
import Placeholder, { PlaceholderProps } from './Placeholder/Placeholder';
import SingleValue, { SingleValueProps } from './SingleValue/SingleValue';
import ValueContainer, {
  ValueContainerProps,
} from './ValueContainer/ValueContainer';

function createComponents<TOption>() {
  return {
    Control(props: ControlProps<TOption>) {
      return <Control {...props} />;
    },
    GroupHeading(props: GroupHeadingProps<TOption>) {
      return <GroupHeading {...props} />;
    },
    Input(props: InputProps<TOption>) {
      return <Input {...props} />;
    },
    NoOptionsMessage(props: NoOptionsMessageProps<TOption>) {
      return <NoOptionsMessage {...props} />;
    },
    Option(props: OptionProps<TOption>) {
      return <Option {...props} />;
    },
    Placeholder(props: PlaceholderProps<TOption>) {
      return <Placeholder {...props} />;
    },
    SingleValue(props: SingleValueProps<TOption>) {
      return <SingleValue {...props} />;
    },
    ValueContainer(props: ValueContainerProps<TOption>) {
      return <ValueContainer {...props} />;
    },
  };
}
export default createComponents;
