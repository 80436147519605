import React, { PropsWithChildren, ReactNode } from 'react';
import ToolTip from '../common/ToolTip';

export interface TableProps {
  title?: ReactNode;
  tooltip?: string;
}

function Table({ children, title, tooltip }: PropsWithChildren<TableProps>) {
  return (
    <div className="w-full">
      <div className="flex">
        {title ? <div className="py-4 pl-6 text-primary">{title}</div> : null}
        {tooltip ? (
          <div className="py-4">
            <ToolTip mode="hover" color="text-customBlue">
              {tooltip}
            </ToolTip>
          </div>
        ) : null}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default Table;
