import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import AuthenticationMessage from '../../AuthenticationMessage';
import GeneralCostSumTable, {
  GeneralCostTableRow,
  GeneralCostValue,
} from '../../components/dashboardController/ControllerTable/GeneralCostSumTable';
import {
  useCreateGeneralItCostMutation,
  useCreateGeneralCostShortHistoryMutation,
  useGetGeneralItCostsLazyQuery,
  useRemoveGeneralItCostMutation,
  useUpdateGeneralItCostMutation,
} from '../../graphql/codegen/graphql';
import { useAppContext } from '../../AppContext';

const GeneralCostPage = () => {
  const app = useAppContext();
  const [getGeneralItCostsData] = useGetGeneralItCostsLazyQuery({
    onCompleted: data => {
      setGeneralItCosts(data);
    },
  });

  const [updateGeneralItCost] = useUpdateGeneralItCostMutation();
  const [createGeneralItCost] = useCreateGeneralItCostMutation();
  const [removeGeneralItCost] = useRemoveGeneralItCostMutation();
  const [createGeneralCostShortHistory] =
    useCreateGeneralCostShortHistoryMutation();

  const [generalItCosts, setGeneralItCosts] = useState<any>();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    getGeneralItCostsData();
  }, [getGeneralItCostsData]);

  const updateGeneralItCostData = async (data: { sum: number; id: string }) => {
    await updateGeneralItCost({
      variables: {
        where: {
          id: data.id,
        },
        data: {
          deletedBy: {
            set: app!.user!.kid!,
          },
          deletedAt: {
            set: new Date(),
          },
        },
      },
    });
  };

  const createGeneralItCostData = async (data: {
    costTypeId: string;
    costSubtypeId: string;
    valueData: GeneralCostValue;
  }) => {
    await createGeneralItCost({
      variables: {
        data: {
          createdBy: app!.user!.kid!,
          costType: {
            connect: {
              id: data.costTypeId,
            },
          },
          costSubtype: {
            connect: {
              id: data.costSubtypeId,
            },
          },
          sum: +data.valueData.sum!,
          year: +data.valueData.year!,
        },
      },
    });
  };

  const removeGeneralItCostData = async (id: string) => {
    await removeGeneralItCost({
      variables: {
        where: {
          id: id,
        },
      },
    });
  };

  const createGeneralCostShortHistoryData = () => {
    createGeneralCostShortHistory({
      variables: {
        data: {
          action: 'AllgemeineKosten',
          createdBy: app.user!.kid!,
        },
      },
    });
  };

  const saveTableData = async (
    tableData: GeneralCostTableRow[],
    initialTable: GeneralCostTableRow[],
  ) => {
    setIsUpdating(true);
    enum Operation {
      create,
      update,
      remove,
    }
    let array: { operation: Operation; data: any }[] = [];
    tableData.forEach((rowData: GeneralCostTableRow, rowIndex: number) => {
      rowData.values?.forEach((vData: GeneralCostValue, vIndex: number) => {
        if (rowData.isSummary) return;
        if (vData.sum !== initialTable[rowIndex].values![vIndex].sum) {
          // id of values which are not in the database are generated in the frontend and start with "key-"
          if (vData.id.substring(0, 4) === 'key-') {
            if (!vData.sum) return;
            array.push({
              operation: Operation.create,
              data: {
                costTypeId: rowData.costTypeId,
                costSubtypeId: rowData.costSubtypeId || '',
                valueData: vData,
              },
            });
          } else {
            if (vData.sum === 0 || !vData.sum) {
              array.push({
                operation: Operation.update,
                data: {
                  sum: vData.sum,
                  id: vData.id,
                  costTypeId: rowData.costTypeId,
                  costSubtypeId: rowData.costSubtypeId || '',
                  valueData: vData,
                },
              });
            } else {
              array.push({
                operation: Operation.update,
                data: {
                  sum: vData.sum,
                  id: vData.id,
                  costTypeId: rowData.costTypeId,
                  costSubtypeId: rowData.costSubtypeId || '',
                  valueData: vData,
                },
              });
            }
          }
        }
      });
    });
    const promiseArray = array.map(item => {
      switch (item.operation) {
        case Operation.create: {
          return createGeneralItCostData({
            costTypeId: item.data.costTypeId,
            costSubtypeId: item.data.costSubtypeId,
            valueData: item.data.valueData,
          });
        }
        case Operation.update: {
          updateGeneralItCostData({
            sum: item.data.sum,
            id: item.data.id,
          });
          return createGeneralItCostData({
            costTypeId: item.data.costTypeId,
            costSubtypeId: item.data.costSubtypeId,
            valueData: item.data.valueData,
          });
        }
        case Operation.remove: {
          return removeGeneralItCostData(item.data);
        }
        default: {
          return undefined;
        }
      }
    });
    Promise.all(promiseArray).then(() => {
      setIsUpdating(false);
      getGeneralItCostsData();
    });
    if (array.length === 0) return;
    createGeneralCostShortHistoryData();
  };

  if (AuthenticationMessage('dashboard-controller')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  }

  return (
    <div>
      <div className="relative flex flex-col w-full min-h-full font-primary pt-10">
        <GeneralCostSumTable
          generalCostData={generalItCosts ? generalItCosts.generalItCosts : []}
          costTypeData={generalItCosts ? generalItCosts.generalItCostTypes : []}
          isUpdating={isUpdating}
          isDisabled={false}
          handleSaveTableData={saveTableData}
        />
      </div>
    </div>
  );
};
export default GeneralCostPage;
