import { nanoid } from 'nanoid';
import { createContext, useCallback, useMemo, useState } from 'react';

export interface BreadcrumbsItem {
  id: string;
  label: string;
  route: string;
}
export type BreadcrumbsContextType = {
  items: BreadcrumbsItem[];
  register: (params: { route: string; label: string }) => () => void;
};
export const BreadcrumbsContext = createContext<BreadcrumbsContextType>(
  {} as BreadcrumbsContextType,
);

export const BreadcrumbsProvider: React.FC = ({ children }) => {
  const [items, setItems] = useState<BreadcrumbsItem[]>([]);

  const register = useCallback(
    ({ route, label }: { route: string; label: string }) => {
      const id = nanoid(20);
      setItems(prev => [
        ...prev,
        {
          id,
          route,
          label,
        },
      ]);
      return () =>
        setItems(prev => {
          // remove element with id
          const next = prev.slice(0);
          next.splice(next.findIndex(item => item.id === id));
          return next;
        });
    },
    [],
  );

  const value = useMemo(
    () => ({
      items,
      register,
    }),
    [items, register],
  );

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
