import React, { useState } from 'react';
import { useAppContext } from 'AppContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Dialog from 'components/common/Dialog';
import useTypedForm from 'components/common/Form/components';
import { Button } from 'components/common/Button';
import { IDocument } from 'components/myItIdea/components/DocumentRow';
import {
  useCreateEmailHistoryMutation,
  useCreateContactHistoryMutation,
} from 'graphql/codegen/graphql';

interface MailPopupProps {
  docs?: IDocument[];
  cc?: string;
  subject: string;
  body: string;
  isDisabled?: boolean;
  projectId: string;
  approverId: string;
  setIsContacted?: () => void;
}

const MailPopUp = ({
  subject,
  docs,
  isDisabled,
  body,
  projectId,
  approverId,
  setIsContacted,
}: MailPopupProps) => {
  const { Form, TextArea } = useTypedForm();

  const [createEmailHistoryMutation] = useCreateEmailHistoryMutation();
  const [createContactHistoryMutation] = useCreateContactHistoryMutation();

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [defaultBody, setBody] = useState(body);
  const [submitButton, setSubmitButton] = useState(false);

  const emailHistoryDescription = () => {
    if (subject === 'SBK anfordern') {
      return 'SBK anfordern';
    }
    return 'E-Mail';
  };

  const handleKeyDown = e => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const mailButtonTitle = (approverId: string) => {
    switch (approverId) {
      case 'ism':
        return t('myItProject.informationSecurityManagement.mail_button');
      case 'ds':
        return t('myItProject.dataSecurity.mail_button');
      case 'gbr':
        return t('myItProject.generalWorksCouncil.mail_button');
      case 'iuk':
        return t('myItProject.informationCommunicationCommittee.mail_button');
      case 'SBK':
        return t('myItProject.informationSecurityManagement.mail_button2');
      default:
        return '';
    }
  };

  const rowNumber = (approverId: string) => {
    switch (approverId) {
      case 'gbr':
        return 15;
      case 'iuk':
        return 8;
      default:
        return 2;
    }
  };

  const handleTextEdit = e => {
    setBody(e.target.value);
    localStorage.setItem('emailDefaultBodyStorage', defaultBody);
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (localStorage.getItem('emailDefaultBodyStorage') != null) {
      setBody(localStorage.getItem('emailDefaultBodyStorage')!);
    } else {
      setBody(body);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBody(defaultBody);
    localStorage.removeItem('emailDefaultBodyStorage');
  };

  const { user } = useAppContext();

  const handleSubmit = () => {
    createEmailHistoryMutation({
      variables: {
        data: {
          id: uuidv4(),
          description: subject,
          createdBy: user!.kid!,
          input: defaultBody,
          project: {
            connect: {
              id: projectId,
            },
          },
          approver: {
            connect: {
              id: approverId,
            },
          },
        },
      },
    });

    if (approverId === 'SBK') {
      createContactHistoryMutation({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            approverProcess: {
              connect: {
                id: 'SBK',
              },
            },
            isMail: false,
            createdBy: user!.kid!,
          },
        },
      });
    }

    setSubmitButton(true);
    localStorage.removeItem('emailDefaultBodyStorage');
    setIsContacted && setIsContacted();
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleClickOpen} disabled={isDisabled || submitButton}>
        {mailButtonTitle(approverId)}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        title={mailButtonTitle(approverId)}
      >
        <Form onSubmit={handleSubmit} mode="onChange">
          <div className="space-y-4">
            <div className="flex flex-grow items-center space-x-4">
              {approverId !== 'sbk' ?? (
                <div className="text-primary w-1/6">Inhalt:</div>
              )}
              <div className="w-full">
                {approverId === 'SBK' ? (
                  <p>
                    {' '}
                    {t(
                      'myItProject.informationSecurityManagement.description5',
                    )}{' '}
                  </p>
                ) : (
                  <TextArea
                    name="summary"
                    rows={rowNumber(approverId)}
                    onChange={handleTextEdit}
                    onKeyDown={handleKeyDown}
                    value={defaultBody}
                    autoHeight={true}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-grow-0 justify-end pt-5 space-x-7">
              <Button theme="secondary" onClick={handleClose}>
                {t('myItProject.mailPopUp.abort_button')}
              </Button>
              <Button theme="primary" onClick={handleSubmit}>
                {approverId === 'SBK'
                  ? t('myItProject.mailPopUp.sbk_button')
                  : t('myItProject.mailPopUp.submit_button')}
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    </div>
  );
};

export default MailPopUp;
