import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import InventoryDetailPage from './Detail';
import InventoryList from './List';

export type InventoryPageProps = RouteComponentProps;

const InventoryPage = ({ match }: InventoryPageProps) => {
  return (
    <Switch>
      <Route path={match.url} exact component={InventoryList} />
      <Route path={`${match.url}/:projectId`} component={InventoryDetailPage} />
    </Switch>
  );
};
export default InventoryPage;
