/* eslint-disable no-case-declarations */
import classNames from 'classnames';
import {
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';
import { GroupBase, SingleValue } from 'react-select';
import { isObject, cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AsyncCreatable, { AsyncCreatableProps } from './variants/AsyncCreatable';
import Creatable, { CreatableProps } from './variants/Creatable';
import Default, { DefaultProps } from './variants/Default';
import Label from 'components/common/Label';
import createComponents from './components';

export type FormDropdownOption =
  | {
      label: string;
      value: string;
    }
  | GroupBase<{ value: string; label: string }>;

export type FormDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
  TOption = FormDropdownOption,
> = { label?: string; wrapperClassName?: string; } & Omit<
  UseControllerProps<TFieldValues>,
  'control' | 'shouldUnregister'
> &
  (
    | DefaultProps<TOption>
    | CreatableProps<TOption>
    | AsyncCreatableProps<TOption>
  );

function FormDropdown<
  TFieldValues extends FieldValues = FieldValues,
  TOption = FormDropdownOption,
>(props: FormDropdownProps<TFieldValues, TOption>) {
  const { t } = useTranslation();
  const components = useMemo(() => createComponents<TOption>(), []);
  const { name, defaultValue, rules, label, wrapperClassName, ...selectProps } =
    props;
  const { field } = useController({
    name,
    defaultValue,
    rules,
  });
  const dropdownValue = useWatch<TFieldValues>({
    name,
  });

  const onChange = (value: SingleValue<TOption>) => {
    if (isObject(value)) {
      field.onChange?.(cloneDeep(value));
    } else {
      field.onChange?.(value);
    }
  };

  const renderDropdown = () => {
    switch (selectProps.variant) {
      case 'default':
        return (
          <Default
            components={components}
            placeholder={t`dropdown.placeholder`}
            noOptionsMessage={() => t`dropdown.noOptions`}
            defaultValue={defaultValue}
            onBlur={field.onBlur}
            onChange={onChange}
            {...selectProps}
          />
        );
      case 'creatable':
        const { getNewOptionData: getCreateNewOptionData, ...creatableProps } =
          selectProps;
        return (
          <Creatable
            components={components}
            placeholder={t`dropdown.placeholder`}
            noOptionsMessage={() => t`dropdown.noOptions`}
            formatCreateLabel={value => t('dropdown.create', { value })}
            {...creatableProps}
            value={dropdownValue}
            onBlur={field.onBlur}
            onChange={onChange}
            onCreateOption={inputValue =>
              onChange(
                getCreateNewOptionData
                  ? getCreateNewOptionData(inputValue, undefined)
                  : (inputValue as any),
              )
            }
          />
        );
      case 'async-creatable':
        const {
          getNewOptionData: getCreateAsyncNewOptionData,
          ...asyncCreatableProps
        } = selectProps;
        return (
          <AsyncCreatable
            components={components}
            placeholder={t`dropdown.placeholder`}
            noOptionsMessage={() => t`dropdown.noOptions`}
            formatCreateLabel={value => t('dropdown.create', { value })}
            {...asyncCreatableProps}
            value={dropdownValue}
            onBlur={field.onBlur}
            onChange={onChange}
            onCreateOption={inputValue =>
              onChange(
                getCreateAsyncNewOptionData
                  ? getCreateAsyncNewOptionData(inputValue, undefined)
                  : (inputValue as any),
              )
            }
          />
        );
    }
  };

  return (
    <div className={classNames('w-full', wrapperClassName)}>
      {label && <Label>{label}</Label>}
      {renderDropdown()}
    </div>
  );
}
export default FormDropdown;
