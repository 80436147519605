import { Switch as HeadlessUISwitch } from '@headlessui/react';
import classNames from 'classnames';
import { PropsWithChildren, useState } from 'react';

export interface SwitchProps {
  onChange?: (value: boolean) => void;
  checked: boolean;
  disabled?: boolean;
}

function Switch({
  disabled,
  checked,
  onChange,
  children,
}: PropsWithChildren<SwitchProps>) {
  const [internalChecked, setInternalChecked] = useState(checked);

  const handleOnChange = (value: boolean) => {
    setInternalChecked(prevChecked => !prevChecked);
    onChange && onChange(value);
  };

  return (
    <HeadlessUISwitch.Group as="div" className="flex items-center">
      <HeadlessUISwitch
        checked={internalChecked}
        disabled={disabled}
        onChange={handleOnChange}
        className={classNames(
          'relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full focus:outline-none cursor-pointer transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-primary focus:ring-offset-2',
          {
            'bg-primary': internalChecked,
            'bg-gray-200': !internalChecked,
          },
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            'inline-block w-5 h-5 bg-white rounded-full shadow pointer-events-none transform transition duration-200 ease-in-out ring-0',
            {
              'translate-x-5': internalChecked,
              'translate-x-0': !internalChecked,
            },
          )}
        />
      </HeadlessUISwitch>
      <HeadlessUISwitch.Label as="span" className="ml-3">
        {children}
      </HeadlessUISwitch.Label>
    </HeadlessUISwitch.Group>
  );
}

export default Switch;
