import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

export type FormHiddenProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<UseControllerProps<TFieldValues>, 'shouldUnregister' | 'control'>;

function FormHidden<TFieldValues extends FieldValues = FieldValues>(
  props: FormHiddenProps<TFieldValues>,
) {
  const { field } = useController<TFieldValues>(props);
  return <input type="hidden" {...field} />;
}
export default FormHidden;
