import { useTranslation } from 'react-i18next';
import ProjectSelectionTable from './ProjectSelection';
import { Route, Switch } from 'react-router';

import TabBar from 'components/TabBar';
import config from 'config/config';

export interface ProjectSelectionProps {
  project: string;
}

const ProjectSelectionPage: React.VFC<ProjectSelectionProps> = ({
  project,
}) => {
  const { t } = useTranslation();
  const basePath = config.localRoutes.tdReporting.home;
  return (
    <div className="w-full">
      <TabBar
        isProjectSelected={project !== undefined}
        data={[
          {
            id: 1,
            text: t`tdReporting.pages.projectselection`,
            route: `${basePath}/${project}/projectselection`,
          },
        ]}
      />

      <Switch>
        {project ? (
          <Route
            path={`${basePath}/${project}/projectselection`}
            exact
            component={() => <ProjectSelectionTable projectId={project} />}
          />
        ) : (
          <Route path={basePath} exact component={ProjectSelectionTable} />
        )}
        <Route
          path={`${basePath}/${project}`}
          exact
          component={() => <ProjectSelectionTable projectId={project} />}
        />
      </Switch>
    </div>
  );
};
export default ProjectSelectionPage;
