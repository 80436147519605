import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'components/Breadcrumbs';

import { Route, Switch, RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom';
import SideBar from 'components/SideBar';

import ProjectSelectionPage from '../ProjectSelection';
import ProjectOverviewPage from './ProjectOverview';
import GeneralPage from './General';
import ResourcePlaningPage from './ResourcePlaning';
import BudgetPage from './Budget';
import FinancialDataPage from './FinancialData';
import ReportPage from '../Report';
import RisksPage from './Risks';

import config from 'config/config';

import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import InterfaceSelectionPage from './Interfaces';
import TDReportingAuth from '../../../components/TDReporting/TDReportingAuth';
import { Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import {
  ReportingGetProjectByIdQuery,
  useReportingGetProjectByIdLazyQuery,
} from 'graphql/codegen/graphql';

export type TDReportingDetailProps = RouteComponentProps & {};

const TDReportingDetail = ({ match }: TDReportingDetailProps) => {
  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState(false);
  const basePath = config.localRoutes.tdReporting.home;
  const { projectId } = useParams<{ projectId: string }>();
  const [projectData, setProjectData] =
    useState<ReportingGetProjectByIdQuery>();

  const [fetchReportingGetProjectById, { loading }] =
    useReportingGetProjectByIdLazyQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        id: projectId,
      },
      onCompleted(data) {
        if (data) {
          setProjectData(data);
        }
      },
    });

  useEffect(() => {
    fetchReportingGetProjectById();
  }, []);

  if (TDReportingAuth('td-reporting')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }
  return (
    <div>
      <div className="pb-3">
        <Breadcrumbs />
        <Breadcrumb
          label={'TD Reporting'}
          route={`/td-reporting/${projectId}`}
        />
        <div className="border-b border-gray pt-1"></div>
      </div>
      <div className="relative flex w-full min-h-full font-primary">
        <div className="relative flex flex-col min-h-full font-primary">
          <SideBar
            isProjectSelected={projectId != null}
            data={[
              {
                id: 1,
                text: t`tdReporting.pages.projectselection`,
                route: `${match.url}/projectselection`,
                pageId: 'projectselection',
              },
              {
                id: 2,
                text: t`tdReporting.pages.projectoverview`,
                route: `${match.url}/overview`,
                pageId: 'overview',
              },
              {
                id: 3,
                text: t`tdReporting.pages.general`,
                route: `${match.url}/general/summary`,
                pageId: 'general',
              },
              {
                id: 4,
                text: t`tdReporting.pages.resourceplaning`,
                route: `${match.url}/resourceplaning`,
                pageId: 'resourceplaning',
              },
              {
                id: 5,
                text: t`tdReporting.pages.financedata`,
                route: `${match.url}/financialdata`,
                pageId: 'financialdata',
              },
              {
                id: 7,
                text: t`tdReporting.pages.interfaces`,
                route: `${match.url}/interfaces`,
                pageId: 'interfaces',
              },
              /*Will be used once the pages are implemented*/
              /*               {
                id: 6,
                text: t`tdReporting.pages.budget`,
                route: `${match.url}/budget`,
                pageId: 'budget',
              },
              {
                id: 7,
                text: t`tdReporting.pages.interfaces`,
                route: `${match.url}/interfaces`,
                pageId: 'interfaces',
              },
              {
                id: 8,
                text: t`tdReporting.pages.risks`,
                route: `${match.url}/risks`,
                pageId: 'risks',
              }, */
              {
                id: 9,
                text: t`tdReporting.pages.report`,
                route: `${match.url}/report`,
                pageId: 'reportPage',
              },
            ]}
          />
        </div>

        <Switch>
          <Route
            path={match.url}
            exact
            component={() => <ProjectSelectionPage project={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/projectselection`}
            exact
            component={() => <ProjectSelectionPage project={projectId} />}
          />
          <Route
            path={`${match.url}/projectselection/information`}
            exact
            component={() => <ProjectSelectionPage project={projectId} />}
          />

          <Route
            path={`${basePath}/${projectId}/overview`}
            exact
            component={() => (
              <ProjectOverviewPage
                projectId={projectId}
                data={projectData}
                loading={loading}
              />
            )}
          />
          <Route
            path={`${basePath}/${projectId}/overview/planning`}
            exact
            component={() => (
              <ProjectOverviewPage
                projectId={projectId}
                data={projectData}
                loading={loading}
              />
            )}
          />
          <Route
            path={`${basePath}/${projectId}/overview/roles`}
            exact
            component={() => (
              <ProjectOverviewPage
                projectId={projectId}
                data={projectData}
                loading={loading}
              />
            )}
          />
          <Route
            path={`${basePath}/${projectId}/overview/history`}
            exact
            component={() => (
              <ProjectOverviewPage
                projectId={projectId}
                data={projectData}
                loading={loading}
              />
            )}
          />
          <Route
            path={`${match.url}/general/background`}
            exact
            component={() => <GeneralPage projectId={projectId} />}
          />
          <Route
            path={`${match.url}/general/summary`}
            exact
            component={() => <GeneralPage projectId={projectId} />}
          />
          <Route
            path={`${match.url}/general/flow`}
            exact
            component={() => <GeneralPage projectId={projectId} />}
          />
          <Route
            path={`${match.url}/general/documents`}
            exact
            component={() => <GeneralPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/resourceplaning`}
            exact
            component={() => <ResourcePlaningPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/financialdata`}
            exact
            component={() => <FinancialDataPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/financialdata/projectcosts`}
            exact
            component={() => <FinancialDataPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/financialdata/operatingcosts`}
            exact
            component={() => <FinancialDataPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/budget`}
            component={() => <BudgetPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/interfaces`}
            component={() => <InterfaceSelectionPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/risks`}
            component={() => <RisksPage projectId={projectId} />}
          />
          <Route
            path={`${basePath}/${projectId}/report`}
            component={() => <ReportPage projectId={projectId} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default TDReportingDetail;
