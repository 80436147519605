import { Trans, useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import useTypedForm from 'components/common/Form/components';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Button } from 'components/common/Button';
import { StepProps } from '.';
import { useHistory, useLocation } from 'react-router-dom';

export type FormData = {
  summary: string;
  projectType?: string;
};

const Summary = ({ values, onSubmit, onPrev }: StepProps<FormData>) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { TextArea, Form, SubmitButton } = useTypedForm<FormData>();
  const location = useLocation();
  const tdReporting = location.pathname.includes('td-reporting');
  const SUMMARY_MIN_LENGTH = tdReporting
    ? 0
    : process.env.NODE_ENV === 'development'
    ? 5
    : 500;
  const [description, setDescription] = useState<string | undefined>(
    values?.summary,
  );
  const countInput = description ? description.length : 0;

  const countDescriptionInput = e => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    // @ts-ignore
    const unblock = history.block(() => {
      if (tdReporting && description !== values?.summary) {
        return window.confirm(t('tdReporting.unsavedDataMessage'));
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [description]);

  return (
    <div className="w-full flex flex-1 flex-col">
      <Breadcrumb label="Beschreibung" route="neue-it-initiative/summary" />

      <Form
        onSubmit={onSubmit}
        defaultValues={values}
        mode="onChange"
        className="h-full flex flex-col"
      >
        <div className="w-full flex flex-1 flex-col space-y-5">
          <p className="text-base">
            {!tdReporting && <Trans i18nKey="myItIdea.summary.description" />}
          </p>
          <TextArea
            name="summary"
            rows={tdReporting ? 10 : 4}
            rules={{
              required: true,
              minLength: SUMMARY_MIN_LENGTH,
            }}
            value={description}
            onChange={countDescriptionInput}
          />
          <p className="text-gray-500">
            {!tdReporting && `${countInput}/${SUMMARY_MIN_LENGTH}`}
          </p>
        </div>
        <div className="flex flex-grow-0 justify-end pt-5 space-x-7">
          {onPrev && (
            <Button onClick={onPrev} theme="secondary">
              {t('myItIdea.actions.prev')}
            </Button>
          )}
          {tdReporting ? (
            <SubmitButton>{t('tdReporting.actions.submit')}</SubmitButton>
          ) : (
            <SubmitButton>{t('myItIdea.actions.next')}</SubmitButton>
          )}
        </div>
      </Form>
    </div>
  );
};
export default Summary;
