import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import useTypedForm from 'components/common/Form/components';
import { ProjectInterfaceDescription } from '../interfaceTypes';

import { debounce } from 'lodash';

export interface InterfaceDescriptionPageProps {
  onInput: (data: ProjectInterfaceDescription) => void;
  interfaceDetails: ProjectInterfaceDescription;
}
const InterfaceDescription: React.VFC<InterfaceDescriptionPageProps> = ({
  onInput,
  interfaceDetails,
}) => {
  const { t } = useTranslation();

  const { TextArea } = useTypedForm();

  const [interfaceDescriptions, setInterfaceDescriptions] =
    useState<ProjectInterfaceDescription>({ ...interfaceDetails });

  const handleInput = (data: ProjectInterfaceDescription) => {
    setInterfaceDescriptions({ ...data });
    onInput({ ...data });
  };
  useEffect(() => {
    setInterfaceDescriptions({ ...interfaceDetails });
  }, [interfaceDetails]);

  const debouncedhandleInput = useCallback(
    debounce(value => handleInput(value), 1000),
    [],
  );

  return (
    <div>
      <div>
        <div className="flex">
          <div className="w-10/12 mr-5">
            <dt className="pt-10 text-primary pb-5">
              {t('tdReporting.interfaces.cause')}
            </dt>
            <dd>
              <TextArea
                name="causes"
                rows={5}
                value={interfaceDescriptions.cause || ''}
                onChange={e => {
                  setInterfaceDescriptions({
                    ...interfaceDescriptions,
                    cause: e.target.value,
                  });
                  debouncedhandleInput({
                    ...interfaceDescriptions,
                    cause: e.target.value,
                  });
                }}
              />
            </dd>
          </div>
          <div className="w-10/12 ml-5 ">
            <dt className="pt-10 text-primary pb-5">
              {t('tdReporting.interfaces.collaboration')}
            </dt>
            <dd>
              <TextArea
                name="collaboration"
                rows={5}
                value={interfaceDescriptions.collaboration || ''}
                onChange={e => {
                  setInterfaceDescriptions({
                    ...interfaceDescriptions,
                    collaboration: e.target.value,
                  });
                  debouncedhandleInput({
                    ...interfaceDescriptions,
                    collaboration: e.target.value,
                  });
                }}
              />
            </dd>
          </div>
        </div>
        <div className="flex">
          <div className="w-10/12 mr-5">
            <dt className="pt-10 text-primary pb-5">
              {t('tdReporting.interfaces.challenge')}
            </dt>
            <dd>
              <TextArea
                name="challenges"
                rows={5}
                value={interfaceDescriptions.challenges || ''}
                onChange={e => {
                  setInterfaceDescriptions({
                    ...interfaceDescriptions,
                    challenges: e.target.value,
                  });
                  debouncedhandleInput({
                    ...interfaceDescriptions,
                    challenges: e.target.value,
                  });
                }}
              />
            </dd>
          </div>
          <div className="w-10/12 ml-5">
            <dt className="pt-10 text-primary pb-5">
              {t('tdReporting.interfaces.description')}
            </dt>
            <dd>
              <TextArea
                name="description"
                rows={5}
                value={interfaceDescriptions.description || ''}
                onChange={e => {
                  setInterfaceDescriptions({
                    ...interfaceDescriptions,
                    description: e.target.value,
                  });
                  debouncedhandleInput({
                    ...interfaceDescriptions,
                    description: e.target.value,
                  });
                }}
              />
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InterfaceDescription;
