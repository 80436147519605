import { CheckIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { InputHTMLAttributes, PropsWithChildren } from 'react';
import './Checkbox.css';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

/**
 * Primary UI component for user interaction
 */
export const Checkbox = ({
  children,
  className,
  ...props
}: PropsWithChildren<CheckboxProps>) => {
  return (
    <div className="relative flex items-center justify-start">
      <input
        type="checkbox"
        className={classNames('checkbox', className)}
        {...props}
      />
      <CheckIcon className="absolute top-0 left-0 w-4 h-4 mt-1 ml-1 text-white pointer-events-none icon" />
      <span className="ml-3">{children}</span>
    </div>
  );
};
export default Checkbox;
