import { Breadcrumbs } from 'components/Breadcrumbs';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import ApprovalDetail from './Detail';
import ApprovalList from './List';
import ViewPage from './View';

export type ApprovalPageProps = RouteComponentProps;
const ApprovalPage = ({ match }: ApprovalPageProps) => {
  return (
    <div>
      <Breadcrumbs />
      <div className="mb-4">
        <Breadcrumb label="Eingereichte IT-Initiativen" route="/meine-it-initiative" />
      </div>
      <Switch>
        <Route path={match.url} exact component={ApprovalList} />
        <Route path={`${match.url}/view/:projectId`} component={ViewPage} />
        <Route path={`${match.url}/:projectId`} component={ApprovalDetail} />
      </Switch>
    </div>
  );
};
export default ApprovalPage;
