import { ITProject } from 'interfaces/project';
import { Route, Switch, useLocation, RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ApprovalActivationQuestionnaire from './ActivationQuestionnaire';
import ApprovalDashboard from './Dashboard';
import ApprovalDataSecurity from './DataSecurity';
import ApprovalEducationTrainingCommittee from './EducationTrainingCommittee';
import ApprovalGeneralWorksCouncil from './GeneralWorksCouncil';
import ApprovalInformationCommunicationCommittee from './InformationCommunicationsCommittee';
import ApprovalInformationSecurityManagement from './InformationSecurityManagement';
import Completion from './Completion';
import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import {
  SortOrder,
  useCheckProjectApprovalsLazyQuery,
  useGetMasterWithApprovalsLazyQuery,
} from 'graphql/codegen/graphql';
import { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import React from 'react';

export type ApprovalDetailProps = RouteComponentProps & {};

const CheckApproval = ({ changed, projectId }) => {
  const [lastCheck, setLastCheck] = useState<number>(NaN);
  const [checkApproval, { data: approvalsCheck }] =
    useCheckProjectApprovalsLazyQuery({
      variables: {
        take: 1,
        orderBy: [
          {
            id: SortOrder.Desc,
          },
        ],
        where: {
          projectId: {
            equals: projectId,
          },
        },
      },
    });

  useEffect(() => {
    if (
      approvalsCheck?.projectApprovals.length &&
      lastCheck !== toNumber(approvalsCheck?.projectApprovals[0].id)
    ) {
      setLastCheck(toNumber(approvalsCheck?.projectApprovals[0].id) ?? NaN);
      changed(toNumber(approvalsCheck?.projectApprovals[0].id));
    }
  }, [approvalsCheck?.projectApprovals]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkApproval();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return <div></div>;
};

const ApprovalDetail = ({ match }: ApprovalDetailProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [changed, setChanged] = useState<any>();

  const [getMaster, { data }] = useGetMasterWithApprovalsLazyQuery({
    variables: {
      where: {
        id: {
          equals: projectId,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getMaster();
  }, [changed]);

  // TODO: Fetch project from api
  const project: ITProject = {
    title: 'IT Idee 1',
    steps: {
      dt: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      auf: {
        id: 0,
        confirmed: false,
        approved: false,
        approvalStatus: '',
      },
      ds: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      gbr: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      ism: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      iuk: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      vf: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      af: {
        id: 0,
        confirmed: false,
        approved: false,
      },
      con: {
        id: 0,
        confirmed: false,
        approved: false,
      },
    },
  };

  data?.masters[0].projectApproval?.forEach(entry => {
    if (entry.ApprovalBoard?.id) {
      project.steps[entry.ApprovalBoard.id].id = entry.id;
      project.steps[entry.ApprovalBoard.id].confirmed = true;
      project.steps[entry.ApprovalBoard.id].approved =
        entry.approvalStatus?.id === 'angenommen' ||
        entry.approvalStatus?.id === 'uebersprungen';
      project.steps[entry.ApprovalBoard.id].approvalStatus =
        entry.approvalStatus?.id || '';
      project.steps[entry.ApprovalBoard.id].projectApprovalId = entry.id;
    }
  });

  const location = useLocation();
  const { t } = useTranslation();

  let currentStep = location.pathname.replace(
    config.localRoutes.myItProject.home,
    '',
  );
  if (currentStep.indexOf('/') === 0)
    currentStep = currentStep.split('/')[2] || '';

  return (
    <>
      <Breadcrumb
        label={`Dashboard - ${data?.masters?.[0]?.projectDescriptions?.[0]?.name}`}
        route={`/meine-it-initiative/${projectId}`}
      />
      <CheckApproval changed={setChanged} projectId={projectId} />
      <div className="relative w-full px-4 lg:px-12 xl:px-24">
        <div className="relative w-full font-primary">
          <h1 className="text-2xl font-medium text-primary">
            {t(`myItProject.steps.${currentStep}`, {
              defaultValue: t`myItProject.dashboard.title`,
            })}
          </h1>
          <div className="mt-8">
            <Switch>
              <Route
                path={`${match.url}`}
                exact
                component={() => (
                  <ApprovalDashboard
                    steps={project.steps}
                    basePath={match.url}
                    project={data?.masters?.[0]}
                  />
                )}
              />
              <Route
                path={`${match.url}/ism`}
                component={() => (
                  <ApprovalInformationSecurityManagement
                    projectId={projectId}
                    {...project.steps.ism}
                  />
                )}
              />
              <Route
                path={`${match.url}/ds`}
                component={() => (
                  <ApprovalDataSecurity
                    {...project.steps.ds}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/gbr`}
                component={() => (
                  <ApprovalGeneralWorksCouncil
                    {...project.steps.gbr}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/iuk`}
                component={() => (
                  <ApprovalInformationCommunicationCommittee
                    {...project.steps.iuk}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/auf`}
                component={() => (
                  <ApprovalEducationTrainingCommittee
                    projectId={projectId}
                    {...project.steps.auf}
                  />
                )}
              />
              <Route
                path={`${match.url}/af`}
                component={() => (
                  <ApprovalActivationQuestionnaire
                    projectId={projectId}
                    {...project.steps.af}
                  />
                )}
              />
              <Route
                path={`${match.url}/completion`}
                component={() => <Completion />}
              />
              {/* <Route
              path={`${match.url}/vf`}
              component={() => (
                <ApprovalBoardRelease
                {...project.steps.vf}
                onConfirmed={() => console.log('Set step as confirmed')}
                />
                )}
              /> */}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(ApprovalDetail);
