import { createContext, useContext } from 'react';
import { AppUser } from 'AppContext';

type UserInfo = {
  email: string;
  kid: string;
  name: string;
};
export const UserInfoContext = createContext<UserInfo>({
  name: '',
  email: '',
  kid: '',
});

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export const useUserName = () => {
  const { name } = useContext(UserInfoContext);
  return name;
};

export function getInitials(name: string) {
  let names = !isEmpty(name) ? name.trim().split(' ') : '?';
  let initials = names[0].substring(0, 1).toUpperCase();
  initials +=
    names.length > 1
      ? names[names.length - 1].substring(0, 1).toUpperCase()
      : '';
  return initials;
}

export function isUserInGroup(groups: string[], user?: AppUser) {
  if (user == null || user.groups == null) {
    return false;
  }
  if (groups.length === 0) {
    return true;
  }
  return user.groups.some(group => groups.includes(group));
}
