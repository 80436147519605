import { useEffect, useState } from 'react';

import {
  useGetInterfaceDetailLazyQuery,
  useGetProjectInterfaceByIdLazyQuery,
} from 'graphql/codegen/graphql';

import {
  ProjectInterfaceDescription,
  ProjectInterfaceExportDetail,
  ProjectInterfaceImportDetail,
} from '../interfaceTypes';

import InterfacePage from './interface';
import { useHistory } from 'react-router-dom';
import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { useTranslation } from 'react-i18next';

export interface EditInterfacesPageProps {
  projectId: string;
}

const EditInterfacesPage: React.VFC<EditInterfacesPageProps> = ({
  projectId,
}) => {
  const { t } = useTranslation();
  const { location } = useHistory();
  const basePath = `${config.localRoutes.tdReporting.editInterface.replace(
    ':projectId',
    projectId,
  )}/edit`;
  const params = new URLSearchParams(location.search);

  const interfaceId = params.get('interfaceId');
  const [interfaceDescriptions, setInterfaceDescriptions] =
    useState<ProjectInterfaceDescription>({
      id: '',
      cause: '',
      employeeId: '',
      riskData: {
        riskOccurrence: '',
        riskDamagePotential: '',
        riskStatus: '',
        status: undefined,
      },
      name: '',
      description: '',
      challenges: '',
      collaboration: '',
      isAutomatic: true,
      interfaceId: '',
      interfaceReferenceId: '',
    });

  const [exportDescriptions, setExportDescriptions] =
    useState<ProjectInterfaceExportDetail>({
      projectInterfaceId: interfaceId || '',
      id: '',
      leanixName: {
        label: '',
        value: '',
      },
      interfaceTypeId: {
        label: '',
        value: '',
      },
      interfaceFormatId: {
        label: '',
        value: '',
      },
      exportDescription: '',
      isExport: true,
    });

  const [importDescriptions, setImportDescriptions] =
    useState<ProjectInterfaceImportDetail>({
      projectInterfaceId: interfaceId || '',
      id: '',
      leanixName: {
        label: '',
        value: '',
      },
      interfaceTypeId: {
        label: '',
        value: '',
      },
      interfaceFormatId: {
        label: '',
        value: '',
      },
      importDescription: '',
      isExport: false,
    });

  const [fetchInterfaceDetails] = useGetInterfaceDetailLazyQuery({
    onCompleted(data) {
      data.interfaceDetails
        .filter(entry => entry.deletedBy === null && entry.isExport)
        .map(lastEntry => {
          return setExportDescriptions({
            projectInterfaceId: lastEntry.projectInterfaceId,

            id: lastEntry.id,
            leanixName: {
              label: lastEntry.leanixMappingId || '',
              value: lastEntry.leanixMappingId || '',
            },
            interfaceTypeId: {
              label: lastEntry.interfaceTypeId,
              value: lastEntry.interfaceTypeId,
            },
            interfaceFormatId: {
              label: lastEntry.interfaceFormatId,
              value: lastEntry.interfaceFormatId,
            },
            exportDescription: lastEntry.description,
            isExport: true,
          });
        });
      data.interfaceDetails
        .filter(entry => entry.deletedBy === null && !entry.isExport)
        .map(lastEntry => {
          return setImportDescriptions({
            projectInterfaceId: lastEntry.projectInterfaceId,
            id: lastEntry.id,
            leanixName: {
              label: lastEntry.leanixMappingId || '',
              value: lastEntry.leanixMappingId || '',
            },
            interfaceTypeId: {
              label: lastEntry.interfaceTypeId,
              value: lastEntry.interfaceTypeId,
            },
            interfaceFormatId: {
              label: lastEntry.interfaceFormatId,
              value: lastEntry.interfaceFormatId,
            },
            importDescription: lastEntry.description,
            isExport: false,
          });
        });
    },
  });

  useEffect(() => {
    fetchInterfaceDetails();
  }, [fetchInterfaceDetails]);

  const [fetchProjectInterface] = useGetProjectInterfaceByIdLazyQuery({
    variables: {
      id: interfaceId ?? '',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data.projectInterface) {
        const lastEntry = data.projectInterface;
        setInterfaceDescriptions({
          id: lastEntry.id,
          cause: lastEntry.cause || '',
          employeeId: lastEntry.employeeId || '',
          name: lastEntry.name || '',
          description: lastEntry.description || '',
          challenges: lastEntry.challenges || '',
          collaboration: lastEntry.collaboration || '',
          riskData: {
            status: lastEntry.status || undefined,
            riskStatus: lastEntry.riskStatusId || undefined,
            riskOccurrence: lastEntry.riskOccuranceId || undefined,
            riskDamagePotential: lastEntry.riskDamagePotentialId || undefined,
          },
          isAutomatic: lastEntry.isAutomatic || true,
          interfaceReferenceId: lastEntry.interfaceReferenceId || '',
          interfaceId: lastEntry.interfaceId,
        });
      }
    },
  });
  const history = useHistory();
  const switchRoute = async (id: string, pageId: string) => {
    await history.push({
      pathname: `${
        (config.localRoutes.tdReporting.editInterface.replace(
          ':projectId',
          projectId,
        ),
        '/',
        pageId)
      }`,
      search: `interfaceId=${id}`,
    });
    await fetchProjectInterface();
  };
  useEffect(() => {
    fetchProjectInterface();
  }, [fetchProjectInterface]);

  return (
    <div>
      <Breadcrumb
        label={t('tdReporting.pages.interfacesEdit')}
        route={basePath}
      />
      <InterfacePage
        projectId={projectId}
        interfaceDetails={interfaceDescriptions}
        exportDetails={exportDescriptions}
        importDetails={importDescriptions}
        onInput={async data => {
          if (data) {
            if (location.pathname.includes('description')) {
              await switchRoute(data, 'description');
            } else if (location.pathname.includes('edit')) {
              await switchRoute(data, 'edit');
            }
          }
        }}
      />
    </div>
  );
};
export default EditInterfacesPage;
