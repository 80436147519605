import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { User } from 'microsoft-graph';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import {
  ApproversQuery,
  useApprovalBoardsQuery,
  useApproversQuery,
  useCreateApproverMutation,
  useRemoveApproverMutation,
  useUpdateApproverMutation,
} from 'graphql/codegen/graphql';

import Dialog, { DialogProps } from 'components/common/Dialog';
import Table, { TableColumn } from 'components/NewTable';
import configuration from '../../Config';
import useTypedForm from 'components/common/Form/components';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Button } from 'components/common/Button';
import { findUser } from '../../GraphService';

interface EditDialogProps {
  editApprover: null | ApproversQuery['approvers'][number];
  onClose: DialogProps['onClose'];
  mode: 'create' | 'edit';
}

const DeleteDialog = ({
  editApprover,
  onClose,
}: Omit<EditDialogProps, 'mode'>) => {
  const [removeApprover] = useRemoveApproverMutation({
    refetchQueries: ['Approvers'],
  });

  const handleConfirm = () => {
    if (editApprover?.id)
      removeApprover({ variables: { id: editApprover?.id } });
  };

  return (
    <Dialog open={!!editApprover} onClose={onClose} title="Löschen">
      <div className="space-y-4">
        <p>
          Wollen Sie den User mit der KID
          <span className="font-bold mx-1">{editApprover?.kid}</span>wirklich
          löschen?
        </p>
        <Button onClick={handleConfirm}>Ja, wirklich löschen.</Button>
      </div>
    </Dialog>
  );
};

const CreateEditDialog = ({ editApprover, onClose, mode }: EditDialogProps) => {
  const { Form, Dropdown, TextInput, SubmitButton } = useTypedForm();

  const [createApprover] = useCreateApproverMutation({
    refetchQueries: ['Approvers'],
  });

  const [updateApprover] = useUpdateApproverMutation({
    refetchQueries: ['Approvers'],
  });

  const [employee, setEmployee] = useState<User>({
    givenName: '',
    surname: '',
  });

  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  useEffect(() => {
    requestEmployee(editApprover?.kid || '');
  }, []);

  const handleEdit = data => {
    if (editApprover?.id)
      updateApprover({
        variables: {
          data: {
            approvalBoard: {
              connect: {
                id: data.gremium.value,
              },
            },
            firstName: { set: employee.givenName! },
            lastName: { set: employee.surname! },
            kid: { set: data.kid },
          },
          id: editApprover?.id,
        },
      });
  };
  const handleCreate = data => {
    createApprover({
      variables: {
        data: {
          approvalBoard: {
            connect: {
              id: data.gremium.value,
            },
          },
          firstName: employee.givenName!,
          lastName: employee.surname!,
          kid: data.kid,
        },
      },
    });
  };

  const requestEmployee = async (value: string) => {
    setEmployee({ givenName: '', surname: '' });
    if (value.length === 5 || value.length === 6) {
      const user = await findUser(authProvider, value);
      setEmployee(user);
    }
  };

  const handleKIDFieldBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    requestEmployee(event.target.value);
  };

  const handleSubmit = data => {
    if (mode === 'edit') {
      handleEdit(data);
    } else if (mode === 'create') {
      handleCreate(data);
    }
    onClose();
  };

  const { data: approvalBoardData } = useApprovalBoardsQuery();
  return (
    <Dialog
      open={!!editApprover}
      onClose={onClose}
      title={mode === 'edit' ? 'Editieren' : 'Erstellen'}
    >
      <Form onSubmit={handleSubmit} mode="onChange">
        <div className="space-y-4">
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">KID:</div>
            <div className="w-full">
              <TextInput
                name="kid"
                defaultValue={editApprover?.kid}
                onChange={handleKIDFieldBlur}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">Vorname:</div>
            <div className="w-full">
              <TextInput
                name="firstname"
                defaultValue={employee!.givenName!}
                value={employee!.givenName!}
                disabled={true}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">Name:</div>
            <div className="w-full">
              <TextInput
                name="lastname"
                defaultValue={employee!.surname!}
                value={employee!.surname!}
                disabled={true}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">Gremium:</div>
            <div className="w-full">
              <Dropdown
                name="gremium"
                rules={{ required: true }}
                variant="default"
                options={approvalBoardData?.approvalBoards.map(entry => ({
                  label: entry.description,
                  value: entry.id,
                }))}
                defaultValue={approvalBoardData?.approvalBoards
                  .map(entry => ({
                    label: entry.description,
                    value: entry.id,
                  }))
                  .find(
                    entry =>
                      entry.label === editApprover?.approvalBoard.description,
                  )}
              />
            </div>
          </div>
          <SubmitButton>Speichern</SubmitButton>
        </div>
      </Form>
    </Dialog>
  );
};

const ApproverPage = () => {
  const { t } = useTranslation();

  const [editApprover, setEditApprover] = useState<string | null>(null);
  const [deleteApprover, setDeleteApprover] = useState<string | null>(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { data: approversData } = useApproversQuery();

  const columns: TableColumn<ApproversQuery['approvers'][number]>[] = [
    { label: 'KID', render: data => <div>{data.kid}</div> },
    { label: 'Vorname', render: data => <div>{data.firstName}</div> },
    {
      label: 'Name',
      render: data => <div>{data.lastName}</div>,
    },
    {
      label: 'Gremium',
      render: data => <div>{data.approvalBoard.description}</div>,
    },
    {
      label: '',
      render: data => (
        <div className="flex items-center justify-evenly -m-4">
          <div
            onClick={() =>
              setEditApprover(editApprover === data.id ? null : data.id)
            }
            className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200"
          >
            <FontAwesomeIcon icon={faPen} />
          </div>
          <div
            onClick={() =>
              setDeleteApprover(deleteApprover === data.id ? null : data.id)
            }
            className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200"
          >
            <FontAwesomeIcon className="text-red-600" icon={faTrash} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb
        label={t`itProjects.dashboard.actions.approver`}
        route="/approver"
      />
      <div className="flex items-center justify-end mb-2">
        <Button onClick={() => setOpenCreateDialog(true)}>Erstellen</Button>
      </div>
      <Table columns={columns} data={approversData?.approvers || []} />
      {editApprover ? (
        <CreateEditDialog
          editApprover={
            approversData?.approvers.find(entry => entry.id === editApprover) ||
            null
          }
          onClose={() => setEditApprover(null)}
          mode="edit"
        />
      ) : null}
      {deleteApprover ? (
        <DeleteDialog
          editApprover={
            approversData?.approvers.find(
              entry => entry.id === deleteApprover,
            ) || null
          }
          onClose={() => setDeleteApprover(null)}
        />
      ) : null}
      <CreateEditDialog
        editApprover={
          openCreateDialog
            ? {
                id: '',
                firstName: '',
                approvalBoard: { description: '', id: '' },
                kid: '',
                lastName: '',
              }
            : null
        }
        onClose={() => setOpenCreateDialog(false)}
        mode="create"
      />
    </div>
  );
};
export default ApproverPage;
