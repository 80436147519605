import {
  components,
  GroupBase,
  NoticeProps as ReactSelectNoOptionsMessageProps,
} from 'react-select';
import './NoOptionsMessage.css';

export type NoOptionsMessageProps<TOption> = ReactSelectNoOptionsMessageProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function NoOptionsMessage<TOption>({
  children,
  ...rest
}: NoOptionsMessageProps<TOption>) {
  return (
    <components.NoOptionsMessage
      {...rest}
      className="dropdown-no-options-message"
    >
      {children}
    </components.NoOptionsMessage>
  );
}
export default NoOptionsMessage;
