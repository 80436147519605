import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface ConfirmIconProps {
  active?: boolean;
  enabled?: boolean;
  onClick?: () => void;
}

export default function ConfirmIcon({
  active = false,
  children,
  enabled = false,
  onClick,
}: PropsWithChildren<ConfirmIconProps>) {
  return (
    <div className="flex items-center justify-start space-x-5">
      <span
        className={classNames(
          'flex items-center justify-center w-12 h-9 rounded-3xl space-x-5',
          {
            'bg-tableDarkGrey': !active && !enabled,
            'bg-grey': !active && enabled,
            'bg-primary': active,
            ' cursor-pointer': !active && onClick,
          },
        )}
        onClick={() => !active && onClick?.()}
      >
        {active && (
          <FontAwesomeIcon icon={faCheck} className="text-white text-xs" />
        )}
      </span>
      <span
        className={classNames('text-xl', {
          'text-tableDarkGrey': !active && !enabled,
          'text-grey': !active && enabled,
        })}
      >
        {children}
      </span>
    </div>
  );
}
