import FormDropdown, {
  FormDropdownProps,
} from 'components/common/Form/components/Dropdown';
import { CreatableProps } from 'components/common/Form/components/Dropdown/variants/Creatable';
import {
  LeanixMapping,
  useGetApplicationsLazyQuery,
} from 'graphql/codegen/graphql';
import { FieldValues } from 'react-hook-form';

export type Application = Pick<
  LeanixMapping,
  '__typename' | 'id' | 'description' | 'name'
>;

export type ApplicationDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
> = Pick<FormDropdownProps<TFieldValues, Application>, 'name' | 'label'> &
  Pick<CreatableProps<Application>, 'formatCreateLabel'>;
function ApplicationDropdown<TFieldValues extends FieldValues = FieldValues>(
  props: ApplicationDropdownProps<TFieldValues>,
) {
  const [
    fetchApplications,
    { refetch: refetchApplications, called: fetchApplicationsCalled },
  ] = useGetApplicationsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const loadApplications = async (inputValue: string) => {
    try {
      const result = await (fetchApplicationsCalled
        ? refetchApplications({
            where: inputValue
              ? {
                  name: {
                    startsWith: inputValue,
                  },
                }
              : undefined,
          })
        : fetchApplications({
            variables: {
              where: inputValue
                ? {
                    name: {
                      startsWith: inputValue,
                    },
                  }
                : undefined,
            },
          }));
      return result.data?.leanixMappings;
    } catch (err) {
      console.log('Error fetching applications', err);
    }
  };

  return (
    <FormDropdown<TFieldValues, Application>
      variant="async-creatable"
      defaultOptions
      loadOptions={loadApplications}
      getOptionLabel={(option: any) =>
        option.__isNew__ ? option.label : option.name
      }
      getOptionValue={option => option.id!}
      isSearchable
      getNewOptionData={inputValue => ({
        __typename: 'LeanixMapping',
        id: 'TO_BE_ANNOUNCED',
        name: inputValue,
        description: '',
      })}
      isClearable
      {...props}
    />
  );
}
export default ApplicationDropdown;
