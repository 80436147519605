import Dropdown from '../common/Form/components/Dropdown/variants/Default';
import {
  RiskCategoriesQuery,
  useRiskCategoriesQuery,
} from '../../graphql/codegen/graphql';
import { useEffect, useState } from 'react';
import { FormDropdownOption } from '../common/Form/components/Dropdown';
import { t } from 'i18next';

export interface RiskDataProps {
  riskOccurrence: string | undefined;
  riskDamagePotential: string | undefined;
  riskStatus: string | undefined;
  status: number | undefined;
}

interface StatusDisplayProps {
  riskData: RiskDataProps | undefined;
  onInput: (data: RiskDataProps) => void;
}

const StatusDisplay = ({ riskData, onInput }: StatusDisplayProps) => {
  const { data: riskDropdownData } = useRiskCategoriesQuery();
  const [riskDetails, setRiskDetails] = useState<RiskDataProps>(
    riskData
      ? { ...riskData }
      : {
          riskDamagePotential: '',
          riskOccurrence: '',
          riskStatus: '',
          status: 0,
        },
  );
  const [status, setStatus] = useState(riskData?.status);
  const mapTableData = (
    data:
      | RiskCategoriesQuery['riskStatuses']
      | RiskCategoriesQuery['riskOccurances']
      | RiskCategoriesQuery['riskDamagePotentials'],
  ): FormDropdownOption[] => {
    return data?.map(r => {
      return { value: r.id, label: r.id };
    });
  };

  const handleInput = (data: RiskDataProps) => {
    if (riskDetails.riskDamagePotential && riskDetails.riskOccurrence) {
      if (
        data.riskDamagePotential === 'Hoch' &&
        data.riskOccurrence !== 'Unwahrscheinlich'
      ) {
        onInput({
          ...data,
          status: 3,
        });
        setStatus(3);
      } else if (
        data.riskDamagePotential === 'Niedrig' &&
        data.riskOccurrence === 'Unwahrscheinlich'
      ) {
        onInput({
          ...data,
          status: 1,
        });
        setStatus(1);
      } else {
        onInput({
          ...data,
          status: 2,
        });
        setStatus(2);
      }
    }
  };

  useEffect(() => {
    setStatus(riskDetails.status);
  }, [riskData, riskDetails.status]);

  useEffect(() => {
    if (riskData) setRiskDetails({ ...riskData });
  }, [riskData]);

  return (
    <div className="space-y-10">
      <div className="flex gap-2">
        <div
          className={`w-6 h-6 rounded-full flex justify-center items-center border-4 border-green-600 ${
            status === 1 ? 'bg-green-600' : ''
          }`}
        />
        <div
          className={`w-6 h-6 rounded-full flex justify-center items-center border-4 border-yellow-400 ${
            status === 2 ? 'bg-yellow-400' : ''
          }`}
        />
        <div
          className={`w-6 h-6 rounded-full flex justify-center items-center border-4 border-red-700 ${
            status === 3 ? 'bg-red-700' : ''
          }`}
        />
      </div>
      <div className="flex gap-2">
        <div className="w-full">
          <p className="text-primary pb-10">{t`tdReporting.interfaces.riskOccurrence`}</p>
          <Dropdown
            name={'riskOccurrences'}
            className="w-full"
            variant="default"
            value={{
              label: riskDetails?.riskOccurrence || 'Bitte auswählen',
              value: riskDetails?.riskOccurrence || 'Bitte auswählen',
            }}
            options={mapTableData(riskDropdownData?.riskOccurances ?? [])}
            onChange={e => {
              setRiskDetails({
                ...riskDetails,
                riskOccurrence: e?.label,
              });
              handleInput({
                ...riskDetails,
                riskOccurrence: e?.label,
              });
            }}
          />
        </div>
        <div className="w-full">
          <p className="text-primary pb-10">{t`tdReporting.interfaces.riskDamagePotential`}</p>
          <Dropdown
            name={'riskDamagePotentials'}
            className="w-full"
            variant="default"
            value={{
              label: riskDetails?.riskDamagePotential || 'Bitte auswählen',
              value: riskDetails?.riskDamagePotential || 'Bitte auswählen',
            }}
            options={mapTableData(riskDropdownData?.riskDamagePotentials ?? [])}
            onChange={e => {
              setRiskDetails({
                ...riskDetails,
                riskDamagePotential: e?.label,
              });
              handleInput({
                ...riskDetails,
                riskDamagePotential: e?.label,
              });
            }}
          />
        </div>
        <div className="w-full">
          <p className="text-primary pb-10">{t`tdReporting.interfaces.riskStatus`}</p>
          <Dropdown
            name={'riskStatuses'}
            variant="default"
            value={{
              label: riskDetails?.riskStatus || 'Bitte auswählen',
              value: riskDetails?.riskStatus || 'Bitte auswählen',
            }}
            options={mapTableData(riskDropdownData?.riskStatuses ?? [])}
            onChange={e => {
              setRiskDetails({
                ...riskDetails,
                riskStatus: e?.label,
              });
              handleInput({
                ...riskDetails,
                riskStatus: e?.label,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default StatusDisplay;
