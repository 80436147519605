import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useTypedForm from 'components/common/Form/components';
import Dropdown from 'components/common/Form/components/Dropdown/variants/Default';
import { v4 as uuidv4 } from 'uuid';

import {
  useGetApplicationsQuery,
  useUpdateInterfaceDetailMutation,
  useCreateInterfaceDetailMutation,
} from 'graphql/codegen/graphql';

import ImportDetail from './details/importDetail';
import ExportDetail from './details/exportDetail';
import {
  ProjectInterfaceExportDetail,
  ProjectInterfaceImportDetail,
} from '../interfaceTypes';
import { useAppContext } from 'AppContext';
import { Button } from 'stories/Button';

export interface CharacteristicProps {
  projectId: string;
  interfaceId: string;
  exportDetails: ProjectInterfaceExportDetail;
  importDetails: ProjectInterfaceImportDetail;
  onSubmit: (createId: string) => void;
}
const Characteristic: React.VFC<CharacteristicProps> = ({
  interfaceId,
  onSubmit,
  exportDetails,
  importDetails,
}) => {
  const { t } = useTranslation();
  const { Form } = useTypedForm<FormData>();
  const { user } = useAppContext();
  const [exportData, setExportData] = useState(false);
  const [importData, setImportData] = useState(false);

  const [createInterfaceDetail] = useCreateInterfaceDetailMutation();
  const [updateInterfaceDetail] = useUpdateInterfaceDetailMutation();
  const { data: leanixData, loading: leanixLoading } =
    useGetApplicationsQuery();

  const handleExportInput = (data: ProjectInterfaceExportDetail) => {
    setExportDescriptions({ ...exportDescriptions, ...data });
  };
  const handleImportInput = (data: ProjectInterfaceImportDetail) => {
    setImportDescriptions({ ...importDescriptions, ...data });
  };

  const [exportDescriptions, setExportDescriptions] =
    useState<ProjectInterfaceExportDetail>(exportDetails);

  const [importDescriptions, setImportDescriptions] =
    useState<ProjectInterfaceImportDetail>(importDetails);

  useEffect(() => {
    setExportDescriptions({ ...exportDetails });
    setImportDescriptions({ ...importDetails });
  }, [exportDetails, importDetails]);

  useEffect(() => {
    if (exportDetails.id) {
      setExportData(true);
    }
    if (importDetails.id) {
      setImportData(true);
    }
  }, [exportDetails, importDetails]);

  const createAndUpdateInterfaceDetail = async (
    data: ProjectInterfaceImportDetail | ProjectInterfaceExportDetail,
    description: string | null | undefined,
  ) => {
    if (user && user.kid) {
      if (data.id !== '') {
        await updateInterfaceDetail({
          variables: {
            where: {
              id: data.id,
            },
            data: {
              deletedBy: {
                set: user?.kid,
              },
              deletedAt: {
                set: new Date(),
              },
            },
          },
        });
      }

      const createId = uuidv4();

      await createInterfaceDetail({
        variables: {
          data: {
            id: createId,
            createdBy: user?.kid,
            isExport: data.isExport,
            interfaceFormat: {
              connect: {
                id: data.interfaceFormatId.value,
              },
            },
            interfaceType: {
              connect: {
                id: data.interfaceTypeId.value,
              },
            },
            projectInterface: {
              connect: {
                id: interfaceId,
              },
            },
            leanixMappingId: data.leanixName.value,
            description: description,
          },
        },
      });
      onSubmit(createId);
    }
  };
  const onlyExport =
    importDescriptions.interfaceTypeId.value === '' ||
    importDescriptions.interfaceFormatId.value === '';
  const onlyImport =
    exportDescriptions.interfaceTypeId.value === '' ||
    exportDescriptions.interfaceFormatId.value === '';
  const handleSubmit = async () => {
    if (user?.kid) {
      if (onlyExport) {
        createAndUpdateInterfaceDetail(
          exportDescriptions,
          exportDescriptions.exportDescription,
        );
      } else if (onlyImport) {
        createAndUpdateInterfaceDetail(
          importDescriptions,

          importDescriptions.importDescription,
        );
      } else if (!onlyExport && !onlyImport) {
        createAndUpdateInterfaceDetail(
          importDescriptions,
          importDescriptions.importDescription,
        );
        createAndUpdateInterfaceDetail(
          exportDescriptions,
          exportDescriptions.exportDescription,
        );
      } else {
        return;
      }
    }
  };

  const changeInExportData =
    exportDescriptions.exportDescription === exportDetails.exportDescription &&
    exportDescriptions.interfaceFormatId.value ===
      exportDetails.interfaceFormatId.value &&
    exportDescriptions.interfaceTypeId.value ===
      exportDetails.interfaceTypeId.value;

  const changeInImportData =
    importDescriptions.importDescription === importDetails.importDescription &&
    importDescriptions.interfaceFormatId.value ===
      importDetails.interfaceFormatId.value &&
    importDescriptions.interfaceTypeId.value ===
      importDetails.interfaceTypeId.value;

  return (
    <div className="w-full">
      <div className="w-min-40 px-4 bg-white">
        <Form onSubmit={() => onSubmit}>
          <dl>
            <div className="w-10/12">
              <div className="w-1/2 pb-5">
                <dt className="pt-10 text-primary pb-5">
                  {t('tdReporting.interfaces.system')}
                </dt>
                <dd>
                  <Dropdown
                    variant="default"
                    name="interfaces.interfaces"
                    loading={leanixLoading}
                    options={leanixData?.leanixMappings.map(systems => ({
                      label: systems.name,
                      value: systems.leanixId,
                    }))}
                    onChange={e => {
                      handleImportInput({
                        ...importDescriptions,
                        leanixName: { label: e?.label!, value: e?.value! },
                      });
                      handleExportInput({
                        ...exportDescriptions,
                        leanixName: { label: e?.label!, value: e?.value! },
                      });
                    }}
                  />
                </dd>
              </div>
            </div>
            <div className="space-y-10">
              <div className="pt-4 text-primary">
                <dt className="pb-4">
                  {t('tdReporting.interfaces.isExportDetails')}
                </dt>
                <dd>
                  <input
                    className="mr-2"
                    type="radio"
                    name="Exportdaten"
                    checked={exportData}
                    onChange={() => setExportData(!exportData)}
                    onClick={() => setExportData(!exportData)}
                  />
                </dd>
              </div>
              {exportData && (
                <ExportDetail
                  exportDetails={exportDescriptions}
                  interfaceId={interfaceId}
                  onInput={handleExportInput}
                />
              )}
              <div className="pt-4 text-primary">
                <dt className="pb-4">
                  {t('tdReporting.interfaces.isImportDetails')}
                </dt>
                <dd>
                  <input
                    className="mr-2"
                    type="radio"
                    name="Importdaten"
                    checked={importData}
                    onChange={() => setImportData(!importData)}
                    onClick={() => setImportData(!importData)}
                  />
                </dd>
              </div>

              {importData && (
                <ImportDetail
                  importDetails={importDescriptions}
                  interfaceId={interfaceId}
                  onInput={handleImportInput}
                />
              )}
              <div className="flex items-end justify-end">
                <Button
                  disabled={
                    (exportData &&
                      (exportDescriptions.interfaceFormatId.value === '' ||
                        exportDescriptions.interfaceTypeId.value === '')) ||
                    (importData &&
                      (importDescriptions.interfaceFormatId.value === '' ||
                        importDescriptions.interfaceTypeId.value === '')) ||
                    (changeInExportData && changeInImportData)
                  }
                  onClick={handleSubmit}
                >{t`itProjects.dashboard.actions.submitButton`}</Button>
              </div>
            </div>
          </dl>
        </Form>
      </div>
    </div>
  );
};
export default Characteristic;
