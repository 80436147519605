import { toNumber } from 'lodash';
import { FormValues } from './CreateEditDialog';
import { IIcon } from '../../../pages/Dashboard/LandingApplications';
import { v4 as uuidv4 } from 'uuid';
import { AppUser } from '../../../AppContext';

interface CreateProps {
  data: FormValues;
  iconFile: IIcon | undefined | null;
  user?: AppUser;
}
interface EditProps {
  data: FormValues;
  editApplicationId: string | undefined;
  icon: IIcon | undefined | null;
  createClusterMapping?;
  updateClusterMapping?;
  createClusterGroup?;
  updateClusterGroup?;
}

export function handleCreate({ data, iconFile }: CreateProps) {
  return {
    variables: {
      data: {
        id: uuidv4().toString(),
        name: data.name,
        landingApplicationGroup: {
          createMany: {
            data: [
              {
                groupId: '7d8698fc-900d-4d20-b0d4-49636c2cd109',
              },
              {
                groupId: 'e1630444-ac30-427e-9699-2337fe57bca4',
              },
              {
                groupId: '8b6a5eb2-af28-4cfa-b731-3789c666e91d',
              },
              {
                groupId: 'ce4585ae-3edf-4b46-b8d4-50ca53ab1229',
              },
              {
                groupId: 'eae512de-c9d8-4b32-8524-c5aa33b6b94c',
              },
            ],
          },
        },
        url: data.url,
        icon: data.icon === '' ? iconFile?.file?.name : data.icon,
        tooltip: data.tooltip,
        order: toNumber(data.order),
        label: data.label,
        isActive: true,
        isIframe: false,
        landingApplicationClusterMapping: {
          create: data.cluster,
        },
      },
      file: iconFile?.file,
    },
  };
}

export function handleEdit({
  data,
  editApplicationId,
  icon,
  createClusterMapping,
  updateClusterMapping,
}: EditProps) {
  if (editApplicationId)
    return {
      variables: {
        id: editApplicationId,
        data: {
          name: {
            set: data.name,
          },
          url: {
            set: data.url,
          },
          tooltip: {
            set: data.tooltip,
          },
          icon: {
            set: icon?.file?.name ?? data.icon,
          },
          order: {
            set: toNumber(data.order),
          },
          label: {
            set: data.label,
          },
          landingApplicationClusterMapping: {
            create: createClusterMapping,
            update: updateClusterMapping,
          },
        },
        file: icon?.file,
      },
    };
}

export function handleCreateCluster({ data, user, iconFile }: CreateProps) {
  return {
    variables: {
      data: {
        id: uuidv4().toString(),
        clusterName: data.name,
        createdBy: user?.kid!,
        icon: data.icon === '' ? iconFile?.file?.name! : data.icon,
        isActive: true,
        label: data.label,
        tooltip: data.tooltip,
        uri: '',
        order: toNumber(data.order),
        landingApplicationClusterGroup: {
          create: data.securityGroup,
        },
      },
      file: iconFile?.file,
    },
  };
}

export function handleUpdateClusterMapping({ clusterId, user }) {
  return {
    data: {
      deletedBy: {
        set: user?.kid,
      },
      deletedAt: {
        set: new Date(),
      },
    },
    where: {
      id: clusterId,
    },
  };
}

export function handleCreateLandingApplicationClusterMapping({
  clusterId,
  user,
}) {
  return {
    cluster: {
      connect: {
        id: clusterId,
      },
    },
    createdBy: user?.kid!,
  };
}

export function handleEditCluster({
  data,
  editApplicationId,
  icon,
  createClusterGroup,
  updateClusterGroup,
}: EditProps) {
  return {
    variables: {
      data: {
        clusterName: {
          set: data.name,
        },
        tooltip: {
          set: data.tooltip,
        },
        icon: {
          set: icon?.file?.name ?? data.icon,
        },
        label: {
          set: data.label,
        },
        order: {
          set: toNumber(data.order),
        },
        landingApplicationClusterGroup: {
          create: createClusterGroup,
          update: updateClusterGroup,
        },
      },
      file: icon?.file,
      where: {
        id: editApplicationId,
      },
    },
  };
}

export function handleUpdateClusterGroup({ id, user }) {
  return {
    data: {
      deletedBy: {
        set: user?.kid,
      },
      deletedAt: {
        set: new Date(),
      },
    },
    where: {
      id: id,
    },
  };
}

export function handleCreateClusterGroup({ groupId, user }) {
  return {
    groupId: groupId,
    createdBy: user?.kid!,
  };
}
