export enum ProjectStatusEnum {
  IDEA = "Eingereicht durch IT-Idee",
  INVENTORY = "Erfasst durch IT-Inventur",
  BOARD_ACCEPTED = "Akzeptiert durch alle Gremien",
  BOARD_AND_BUDGET_ACCEPTED = "Akzeptiert durch alle Gremien und Budgetfreigabe",
  BUDGET_RELEASE = "Budgetaktivierung",
  IDEA_ACCEPTED = "IT-Idee akzeptiert",
  ACTIVATED = "Projektaktivierung",
  DELETED = "Zurückgenommen Projekte",
  DECLINED = "Abgelehnte Projekte",
  RETURNED = "Zurückgegebene Projekte",
}

export enum ProjectRolesEnum {
  CHANGEMANAGERIN = "ChangemanagerIn",
  DATA_OBJECT_OWNER = "Data Object Owner",
  ENTERPRISE_MANAGERIN = "Enterprise ManagerIn",
  EXPERTEIN = "ExperteIn",
  FACHLICHER_ANSPRECHPARTNER = "Fachlicher Ansprechpartner",
  MIGRATIONSMANAGERIN = "MigrationsmanagerIn",
  PMO = "PMO",
  PROJEKTLEITUNG = "Projektleitung",
  PROZESSMANAGERIN = "ProzessmanagerIn",
  PROZESSVERANTWORTLICHER = "Prozessverantwortlicher",
  PSG = "PSG",
  TECHNISCHER_ANSPRECHPARTNER = "Technischer Ansprechpartner",
  TEILPROJEKTLEITUNG = "Teilprojektleitung",
  TESTMANAGERIN = "TestmanagerIn",
}

export enum Segments {
  DECLINE_PROJECT = "Abgelehnte Projekte",
  RETURN_PROJECT = "Zurückgegebene Projekte",
  SUBMIT_PROJECT = "IT-Idee akzeptiert",
  TD_CONTROLLER = "TD Controller",
  DEFERRED_PROJECTS = "Zurückgenommen Projekte",
}

/**
 * Utility type to extract the type information of an element of an array
 */
 export type ArrayElement<ArrayType extends readonly unknown[]> =
 ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
