import CommonCheckbox from 'components/common/Checkbox';
import { PropsWithChildren } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';

export type FormCheckboxProps<TFieldValues extends FieldValues = FieldValues> =
  PropsWithChildren<
    Omit<UseControllerProps<TFieldValues>, 'control' | 'shouldUnregister'>
  > & {
    disabled?: boolean;
  };
function FormCheckbox<TFieldValues extends FieldValues = FieldValues>({
  children,
  disabled,
  ...controllerProps
}: FormCheckboxProps<TFieldValues>) {
  const { field } = useController<TFieldValues>(controllerProps);
  const value = useWatch({
    name: controllerProps.name,
  });

  return (
    <CommonCheckbox
      checked={value || false}
      onChange={field.onChange}
      disabled={disabled}
    >
      <span className="cursor-pointer" onClick={() => field.onChange(!value)}>
        {children}
      </span>
    </CommonCheckbox>
  );
}
export default FormCheckbox;
