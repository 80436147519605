import ApprovalPage from '../pages/Approval';
import ControllerPage from 'pages/Controller';
import DashboardProjectPage from 'pages/DashboardProject/DashboardProject';
import FinancialDataPage from '../pages/FinancialDataPage';
import IRoute from '../interfaces/route';
import IdeaPage from 'pages/Idea';
import InventoryPage from 'pages/Inventory';
import LandingPage from '../pages/Landing';
import PowerAppPage from '../pages/PowerAppPage';
import TDDashboardPage from 'pages/Dashboard';
import TDReportingPage from 'pages/Reporting';
import config from '../config/config';

const routes: IRoute[] = [
  {
    path: config.localRoutes.landing,
    name: 'Landing Page',
    component: LandingPage,
    exact: true,
  },
  {
    path: config.localRoutes.financialData,
    name: 'Financial Data',
    component: FinancialDataPage,
    exact: false,
  },
  {
    path: config.localRoutes.apus,
    name: 'APUS',
    component: PowerAppPage,
    exact: false,
  },
  {
    path: config.localRoutes.itLandingPage,
    name: 'IT Landingpage',
    component: PowerAppPage,
    exact: false,
  },
  {
    path: config.localRoutes.tdReporting.home,
    name: 'TD Reporting',
    component: TDReportingPage,
    exact: false,
  },
  {
    path: config.localRoutes.myItProject.home,
    name: 'My IT Project',
    component: ApprovalPage,
    exact: false,
  },
  {
    path: config.localRoutes.myItInventory.home,
    name: 'My IT Inventory',
    component: InventoryPage,
    exact: false,
  },
  {
    path: [config.localRoutes.myItIdea.home, ':step?'].join('/'),
    name: 'My IT Idea',
    component: IdeaPage,
    exact: false,
  },
  {
    path: [config.localRoutes.dashboard.home, ':page?'].join('/'),
    name: 'TD-Projekte Dashboard',
    component: TDDashboardPage,
    exact: false,
  },
  {
    path: [config.localRoutes.dashboardController.home, ':page?'].join('/'),
    name: 'Dashboard Controller',
    component: ControllerPage,
    exact: false,
  },
  {
    path: [config.localRoutes.dashboardProject.home, ':page?'].join('/'),
    name: 'Dashboard IT Projekte',
    component: DashboardProjectPage,
    exact: false,
  },
];

export default routes;
