import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import useTypedForm from 'components/common/Form/components';
import Dropdown from 'components/common/Form/components/Dropdown/variants/Default';

import {
  useGetInterfaceTypesQuery,
  useGetInterfaceFormatsQuery,
} from 'graphql/codegen/graphql';

import { debounce } from 'lodash';
import { ProjectInterfaceImportDetail, FormData } from '../../interfaceTypes';

export interface CharacteristicProps {
  importDetails: ProjectInterfaceImportDetail;
  interfaceId: string;
  onInput: (data: ProjectInterfaceImportDetail) => void;
}
const ImportDetail: React.VFC<CharacteristicProps> = ({
  importDetails,
  onInput,
}) => {
  const { t } = useTranslation();
  const { TextArea } = useTypedForm<FormData>();

  const { data: typesData, loading: typesLoading } =
    useGetInterfaceTypesQuery();
  const { data: formatData, loading: formatLoading } =
    useGetInterfaceFormatsQuery();

  const [importDescriptions, setImportDescriptions] =
    useState<ProjectInterfaceImportDetail>({ ...importDetails });

  useEffect(() => {
    setImportDescriptions({ ...importDetails });
  }, [importDetails]);

  const handleInput = (data: ProjectInterfaceImportDetail) => {
    onInput(data);
  };

  const debouncedhandleInput = useCallback(
    debounce(value => handleInput(value), 1000),
    [],
  );

  return (
    <div className="w-full">
      <div className="w-min-40 px-4 bg-white">
        <div>
          <div>
            <div className="pt-4 text-primary">
              <dt className="pb-4">
                {t('tdReporting.interfaces.importDescription')}
              </dt>
              <dd>
                <TextArea
                  name="importDescription"
                  rows={5}
                  value={importDescriptions.importDescription || ''}
                  onChange={e => {
                    setImportDescriptions({
                      ...importDescriptions,
                      importDescription: e.target.value || '',
                    });
                    debouncedhandleInput({
                      ...importDescriptions,
                      importDescription: e.target.value || '',
                    });
                  }}
                />
              </dd>
            </div>
            <div className="pt-4 text-primary">
              <dt className="pb-4">
                {t('tdReporting.interfaces.importInterface')}
              </dt>
              <dd>
                <Dropdown
                  variant="default"
                  name="importInterface"
                  loading={typesLoading}
                  value={importDescriptions.interfaceTypeId}
                  options={typesData?.interfaceTypes.map(type => ({
                    label: type.id,
                    value: type.id,
                  }))}
                  onChange={e => {
                    setImportDescriptions({
                      ...importDescriptions,
                      interfaceTypeId: {
                        label: e?.label!,
                        value: e?.value!,
                      },
                    });

                    debouncedhandleInput({
                      ...importDescriptions,
                      interfaceTypeId: {
                        label: e?.label!,
                        value: e?.value!,
                      },
                    });
                  }}
                />
              </dd>
            </div>
            <div className="pt-4 text-primary">
              <dt className="pb-4">
                {t('tdReporting.interfaces.importFormat')}
              </dt>
              <dd>
                <Dropdown
                  variant="default"
                  name="importFormat"
                  loading={formatLoading}
                  value={importDescriptions.interfaceFormatId}
                  options={formatData?.interfaceFormats.map(format => ({
                    label: format.id,
                    value: format.id,
                  }))}
                  onChange={e => {
                    setImportDescriptions({
                      ...importDescriptions,
                      interfaceFormatId: {
                        label: e?.label!,
                        value: e?.value!,
                      },
                    });
                    debouncedhandleInput({
                      ...importDescriptions,

                      interfaceFormatId: {
                        label: e?.label!,
                        value: e?.value!,
                      },
                    });
                  }}
                />
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ImportDetail;
