import './button.css';

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  theme?: 'primary' | 'secondary' | 'grey' | 'grey-secondary' | 'danger';
  /**
   * Is the button not clickable
   */
  disabled?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Optional FontawesomeIcon
   */
  icon?: string;
  children: React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  theme = 'primary',
  disabled = false,
  size = 'medium',
  backgroundColor,
  icon,
  children,
  ...props
}: ButtonProps) => {
  const themeStyle = {
    primary: 'storybook-button--primary',
    secondary: 'storybook-button--secondary',
    grey: 'bg-darkGrey text-white',
    'grey-secondary': 'border-2 border-darkGrey text-darkGrey',
  };
  const disabledMode = disabled ? 'storybook-button--disabled' : '';
  return (
    <button
      type="button"
      className={[
        'storybook-button',
        `storybook-button--${size}`,
        themeStyle[theme],
        disabledMode,
      ].join(' ')}
      disabled={disabled}
      style={{ backgroundColor }}
      {...props}
    >
      {children}
    </button>
  );
};
