import TDReportingAuth from '../../../components/TDReporting/TDReportingAuth';
import { Alert } from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import { t } from 'i18next';

export interface BudgetPageProps {
  projectId: string;
}

const BudgetPage = ({ projectId }: BudgetPageProps) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authenticated) {
      //fetchQueries
    }
  },[authenticated]);

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div className="w-min-40 px-4 bg-white">
      <p>This will be the BudgetPage</p>
    </div>
  );
};
export default BudgetPage;
