import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ConfirmIcon from 'components/ConfirmIcon';
import FileUploadList from 'components/FileUploadList';
import MailPopUp from 'components/MailPopUp';
import UploadButton from 'components/UploadButton';
import useTypedForm from 'components/common/Form/components';
import { IDocument } from '../../../components/myItIdea/components/DocumentRow';
import { useAppContext } from 'AppContext';

import {
  GetMasterWithApprovalsDocument,
  useCreateProjectDocumentMutation,
  useGetMailInfoByProjectLazyQuery,
  useGetProjectDocumentsLazyQuery,
  useRemoveProjectDocumentMutation,
  useUpdateApprovalStatusMutation,
} from 'graphql/codegen/graphql';
import config, { getFrontendUrl } from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export interface ApprovalInformationCommunicationCommitteeProps {
  approved: boolean;
  confirmed: boolean;
  projectId: string;
}

type Mail = {
  cc?: string;
  subject: string;
  body: string;
};

const ApprovalInformationCommunicationCommittee = ({
  approved,
  confirmed,
  projectId,
}: ApprovalInformationCommunicationCommitteeProps) => {
  const { Form, IconSubmitButton } = useTypedForm();
  const { user } = useAppContext();

  const [getProjectDocuments, { data: projectDocumentsData }] =
    useGetProjectDocumentsLazyQuery();
  const [updateApprovalStatusMutation] = useUpdateApprovalStatusMutation();
  const [createProjectDocumentMutation] = useCreateProjectDocumentMutation();
  const [removeProjectDocumentMutation] = useRemoveProjectDocumentMutation();
  const [getMailInfo, { data: mailInfoData }] =
    useGetMailInfoByProjectLazyQuery();

  const [docs, setDocs] = useState<(IDocument & { uri?: string })[]>(
    [] || ([] as any),
  );

  const loadProjectDocuments = async () => {
    return await getProjectDocuments({
      variables: {
        where: {
          projectId: {
            equals: projectId,
          },
          location: {
            contains: 'iuk-',
          },
        },
      },
    });
  };

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    loadMailInfo();
  }, [docs]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadProjectDocuments();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const loadContent = () => {
    loadProjectDocuments().then(response => {
      const documents = response && response.data!.projectDocuments!;
      if (documents.length !== 0) {
        setDocs(documents);
      }
    });
    getMailInfo({
      variables: {
        where: {
          id: projectId,
        },
        where2: {
          projectId: {
            equals: projectId,
          },
        },
      },
    });
  };

  useEffect(() => {
    setDocs(projectDocumentsData?.projectDocuments ?? []);
  }, [projectDocumentsData]);

  const [mail, setMail] = useState<Mail>({
    cc: '',
    subject: '',
    body: '',
  });

  const [isDuplicateFile, setIsDuplicateFile] = useState<boolean>(false);

  const loadMailInfo = () => {
    if (
      !mailInfoData?.employeeMasters[0] ||
      !mailInfoData.master.projectDescriptions![0]
    ) {
      const body: string =
        'Lieber Informations- und Kommunikationsausschuss,\n\nanbei finden Sie den Link für den Standardfragenkatalog und die Beschlussempfehlung für folgendes Projekt:\nProjektname: ' +
        '<hier Projektname einfügen>' +
        '\nProjektleiter: <hier Projektleiter einfügen>' +
        '\nLinks:\n<hier Links einfügen>' +
        '\n\nViele Grüße\n ' +
        user?.givenName +
        ' ' +
        user?.surname;
      setMail({
        subject:
          '[Informations- und Kommunikationsauschuss] - IT-Projekt: <hier Projektname einfügen>',
        body,
      });
      return;
    }

    const cc: string = mailInfoData?.employeeMasters[0].employee.email || '';
    const subject: string = `[Informations- und Kommunikationsauschuss] - IT-Projekt: ${
      mailInfoData?.master.projectDescriptions![0].name
    }`;

    let body: string =
      'Lieber Informations- und Kommunikationsausschuss,\n\nanbei finden Sie den Link für den Standardfragenkatalog und die Beschlussempfehlung für folgendes Projekt:\nProjektname: ' +
      mailInfoData?.master.projectDescriptions![0].name +
      '\nProjektleiter: ';
    const projectLead: string =
      (mailInfoData?.employeeMasters[0].employee.firstName || '') +
      ' ' +
      (mailInfoData?.employeeMasters[0].employee.lastName || '');
    body = body + projectLead;
    docs.forEach((d, index) => {
      if (index === 0) {
        if (docs.length > 1) {
          body = body + '\nLinks:';
        } else {
          body = body + '\nLink:';
        }
      }
      body =
        body +
        '\n' +
        getFrontendUrl(
          `${config.localRoutes.dashboard.detail.replace(
            ':projectId',
            projectId,
          )}?download=${d.uri}`,
        );
    });
    body = body + '\n\nViele Grüße\n' + user?.givenName + ' ' + user?.surname;
    setMail({
      cc,
      subject,
      body,
    });
  };

  const handleUploadQuestions = (files: FileList | null) => {
    handleUpload(files, 'iuk-questions');
  };

  const handleUploadRecommend = (files: FileList | null) => {
    handleUpload(files, 'iuk-recommend');
  };

  const handleUpload = (files: FileList | null, type: string) => {
    const newDocs = [...docs];

    if (files?.length) {
      Array.from(files).map(f => {
        setIsDuplicateFile(false);
        const doesExist = newDocs.some(n => n.description === f.name);
        if (!doesExist) {
          newDocs.push({
            id: uuidv4(),
            description: f.name,
            file: f,
            location: type,
          });
        } else {
          setIsDuplicateFile(true);
        }
      });

      newDocs.map(async (doc, index) => {
        if (doc.file && !doc.uri) {
          await createProjectDocumentMutation({
            variables: {
              data: {
                id: doc.id,
                name: doc.file!.name,
                description: doc.description,
                location: type,
                uri: 'https://www.dummy-url.com',
                createdBy: user!.kid!,
                project: {
                  connect: {
                    id: projectId,
                  },
                },
              },
              file: doc.file,
            },
          }).then(res => {
            const allDocs = [...docs];
            allDocs.push({
              id: doc.id,
              description: doc.description,
              file: doc.file,
              uri: res.data?.createProjectDocument.uri,
              location: type,
            });
            setDocs(allDocs);
          });
        }
      });
    }
    loadContent();
  };

  const handleDelete = async doc => {
    await removeProjectDocumentMutation({
      variables: {
        where: {
          id: doc.id,
        },
      },
    });
    setDocs(docs.filter(f => f.id !== doc.id));
    loadContent();
  };

  const handleSubmit = () => {
    updateApprovalStatusMutation({
      variables: {
        data: {
          projectApproval: {
            create: [
              {
                createdBy: user!.kid!,
                ApprovalBoard: { connect: { id: 'iuk' } },
                approvalStatus:
                  process.env.NODE_ENV === 'development'
                    ? { connect: { id: 'angenommen' } }
                    : undefined,
              },
            ],
          },
        },
        projectId,
      },
      refetchQueries: ['getProjectDocuments', GetMasterWithApprovalsDocument],
    }).then(() => {
      loadContent();
    });
  };

  const handleDisabled = () => {
    let docsLocation: string[] = [];
    docs.forEach(data => {
      if (data.location) {
        docsLocation.push(data.location);
      }
    });
    if (docsLocation.length > 1) {
      return docsLocation[0] !== docsLocation[1] ? true : false;
    } else {
      return false;
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb
        label={t`myItProject.steps.iuk`}
        route={`/meine-it-initiative/${projectId}/iuk`}
      />

      <Form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <p className="text-xl font-medium">{t`myItProject.informationCommunicationCommittee.description`}</p>
          <p className="text-xl font-medium">
            <Trans
              i18nKey={
                'myItProject.informationCommunicationCommittee.description3'
              }
              components={{
                href: (
                  <a
                    href="https://connect.eon.com/workspaces/ws-iuk-kbr/apps/file-library/dokumente"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </p>
          <p className="text-xl font-medium">
            <Trans
              i18nKey={
                'myItProject.informationCommunicationCommittee.description2'
              }
              components={{
                href: (
                  <a
                    href="https://connect.eon.com/pages/pg-haw-it-themen/apps/wiki/einbindung-br-dsb-isms/list/view/ea2be170-14b8-4d3f-a459-ae81d07618c1#"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </p>
          <div className="flex flex-wrap">
            <div className="space-x-4">
              <UploadButton
                onUpload={handleUploadQuestions}
                theme="secondary"
                disabled={confirmed}
              >
                <div className="space-x-2">
                  <FontAwesomeIcon icon={faUpload} />
                  <span>
                    {t(
                      'myItProject.informationCommunicationCommittee.questions_upload_button',
                    )}
                  </span>
                </div>
              </UploadButton>
              <UploadButton
                onUpload={handleUploadRecommend}
                theme="secondary"
                disabled={confirmed}
              >
                <div className="space-x-2">
                  <FontAwesomeIcon icon={faUpload} />
                  <span>
                    {t(
                      'myItProject.informationCommunicationCommittee.recommend_upload_button',
                    )}
                  </span>
                </div>
              </UploadButton>
            </div>
          </div>
          {isDuplicateFile && (
            <p className="text-red-600">{t`myItProject.informationCommunicationCommittee.duplicate_name`}</p>
          )}

          <FileUploadList
            files={docs}
            onDelete={handleDelete}
            disabled={confirmed}
          />
          <MailPopUp
            docs={docs}
            cc={mail.cc}
            subject={mail.subject}
            body={mail.body}
            projectId={projectId}
            approverId="iuk"
            isDisabled={docs.length < 2 || confirmed}
          ></MailPopUp>
          <p className="text-xl font-medium">
            {t('myItProject.informationCommunicationCommittee.description5')}
          </p>
          <div className="flex items-center space-x-5">
            <IconSubmitButton
              active
              disabled={!handleDisabled()}
              approved={confirmed}
            >{t`myItProject.informationCommunicationCommittee.confirmedLabel`}</IconSubmitButton>
          </div>
          <p className="text-xl font-medium">
            {t`myItProject.informationCommunicationCommittee.description4`}
          </p>
          <ConfirmIcon
            enabled
            active={approved}
          >{t`myItProject.informationCommunicationCommittee.approvedLabel`}</ConfirmIcon>
        </div>
      </Form>
    </>
  );
};
export default ApprovalInformationCommunicationCommittee;
