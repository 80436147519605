import { useCallback, useEffect, useState } from 'react';

import Documents from 'pages/Idea/Documents';
import { useAppContext } from 'AppContext';

import { IDocument } from 'components/myItIdea/components/DocumentRow';
import {
  useGetProjectDocumentsLazyQuery,
  useCreateProjectDocumentMutation,
  useUpdateProjectDocumentMutation,
  useCreateProjectShortHistoryMutation,
} from 'graphql/codegen/graphql';
import { t } from 'i18next';

export type DocumentData = {
  documents: IDocument[];
};

export type FormData = {
  id?: string;
  name?: string;
  description?: string;
  uri?: string;
};

export type DocumentsPageProps = {
  projectId: string;
};
const DocumentsPage: React.VFC<DocumentsPageProps> = ({ projectId }) => {
  const [getProjectDocuments, { data: projectDocuments }] =
    useGetProjectDocumentsLazyQuery();
  const { user } = useAppContext();
  const [createProjectDocument] = useCreateProjectDocumentMutation({
    refetchQueries: ['getProjectDocuments'],
  });
  const [updateProjectDocument] = useUpdateProjectDocumentMutation({
    refetchQueries: ['getProjectDocuments'],
  });
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();
  const [documentId, setDocumentIds] = useState<string>();
  const [documents, setDocuments] = useState<IDocument[]>(
    projectDocuments?.projectDocuments.filter(
      d => d.id !== null,
    ) as IDocument[],
  );

  const loadProjectDocuments = useCallback(
    async (projectId: string) => {
      await getProjectDocuments({
        variables: {
          where: {
            projectId: {
              equals: projectId,
            },
            location: {
              equals: 'neue-it-initiative',
            },
          },
        },
      });
      if (projectDocuments) {
        projectDocuments.projectDocuments.forEach(doc => {
          setDocumentIds(doc.id);
        });
        return setDocuments(projectDocuments.projectDocuments);
      }
    },
    [getProjectDocuments, projectDocuments],
  );

  const handleSubmit = (files: { documents: IDocument[] }) => {
    files.documents.map(async docs => {
      if (!documentId?.includes(docs.id) && docs.file && user && user.kid) {
        return await createProjectDocument({
          variables: {
            data: {
              id: docs.id,
              name: docs.file.name,
              description: docs.description,
              location: 'neue-it-initiative',
              uri: '',
              createdBy: user.kid,
              project: {
                connect: {
                  id: projectId,
                },
              },
            },
            file: docs.file,
          },
        });
      } else if (
        !documentId?.includes(docs.id) &&
        !docs.file &&
        user &&
        user.kid
      ) {
        return await updateProjectDocument({
          variables: {
            data: {
              description: { set: docs.description },
            },
            where: {
              id: docs.id,
            },
          },
        });
      } else {
        return;
      }
    });

    createProjectShortHistory({
      variables: {
        data: {
          project: {
            connect: {
              id: projectId,
            },
          },
          action: t`tdReporting.subpages.documents`,
          createdBy: user?.kid,
        },
      },
    });
    setDocuments(files.documents);
  };

  useEffect(() => {
    loadProjectDocuments(projectId);
  }, [loadProjectDocuments, projectId]);

  return (
    <div className="flex flex-col flex-1 w-full pt-2">
      <Documents
        onSubmit={data => handleSubmit({ ...data })}
        documents={documents}
        values={{
          documents: documents ?? undefined,
        }}
        projectId={projectId}
      ></Documents>
    </div>
  );
};

export default DocumentsPage;
