import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import useTypedForm from 'components/common/Form/components';
import { ProjectInterfaceDescription } from '../interfaceTypes';

import { debounce } from 'lodash';
import StatusDisplay from 'components/TDReporting/Statusdisplay';
import DebouncedLookup from 'components/debouncedKidLookup';

export interface InterfaceDescriptionPageProps {
  onInput: (data: ProjectInterfaceDescription) => void;
  interfaceDetails: ProjectInterfaceDescription;
}
const InterfaceEdit: React.VFC<InterfaceDescriptionPageProps> = ({
  onInput,
  interfaceDetails,
}) => {
  const { t } = useTranslation();

  const isAutomaticOrManuell = [
    t('tdReporting.interfaces.dropdown.manuell'),
    t('tdReporting.interfaces.dropdown.automatic'),
  ];
  const { TextInput, Dropdown } = useTypedForm();

  const [interfaceDescriptions, setInterfaceDescriptions] =
    useState<ProjectInterfaceDescription>({ ...interfaceDetails });

  const handleInput = (data: ProjectInterfaceDescription) => {
    setInterfaceDescriptions({ ...data });
    onInput({ ...data });
  };
  useEffect(() => {
    setInterfaceDescriptions({ ...interfaceDetails });
  }, [interfaceDetails]);

  const debouncedhandleInput = useCallback(
    debounce(value => handleInput(value), 1000),
    [],
  );

  const employeeKid = interfaceDescriptions.employeeId;
  return (
    <div>
      <div className="w-10/12">
        <div className="w-1/2">
          <dt className="pt-10 text-primary pb-5">
            {t('tdReporting.interfaces.name')}
          </dt>
          <dd>
            <TextInput
              name="name"
              value={interfaceDescriptions.name ?? ''}
              onChange={e => {
                setInterfaceDescriptions({
                  ...interfaceDescriptions,
                  name: e.target.value,
                });
                debouncedhandleInput({
                  ...interfaceDescriptions,
                  name: e.target.value,
                });
              }}
            />
          </dd>
        </div>
      </div>
      <div className="w-10/12">
        <div className="w-1/2">
          <dt className="pt-10 text-primary pb-5">
            {t('tdReporting.interfaces.automatic')}
          </dt>
          <dd>
            <Dropdown
              variant="default"
              name="isAutomatic"
              options={isAutomaticOrManuell.map(data => ({
                label: data,
                value: data,
              }))}
              value={
                interfaceDescriptions.isAutomatic
                  ? {
                      label: isAutomaticOrManuell[0],
                      value: isAutomaticOrManuell[0],
                    }
                  : {
                      label: isAutomaticOrManuell[1],
                      value: isAutomaticOrManuell[1],
                    }
              }
              onChange={e => {
                setInterfaceDescriptions({
                  ...interfaceDescriptions,
                  isAutomatic: e?.label === 'Manuell' && e.value === 'Manuell',
                });
                handleInput({
                  ...interfaceDescriptions,
                  isAutomatic: e?.label === 'manuell' ?? true,
                });
              }}
            />
          </dd>
        </div>
      </div>
      <div className="w-10/12">
        <div className="w-1/2">
          <dt className="pt-10 text-primary pb-5">
            {t('tdReporting.interfaces.supervisor')}
          </dt>
          <dd>
            <DebouncedLookup
              name="Interface"
              employeeName={employeeKid}
              onInput={value => {
                debouncedhandleInput({
                  ...interfaceDescriptions,
                  employeeId: value,
                });
              }}
            />
          </dd>
        </div>
      </div>
      <div className="w-10/12  mr-5">
        <dt className="pt-10 text-primary pb-5">
          {t('tdReporting.interfaces.status')}
        </dt>
        <dd>
          <StatusDisplay
            riskData={interfaceDescriptions.riskData}
            onInput={data => {
              handleInput({
                ...interfaceDescriptions,
                riskData: data,
              });
            }}
          />
        </dd>
      </div>
    </div>
  );
};
export default InterfaceEdit;
