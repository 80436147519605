import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Dialog, { DialogProps } from '../../../components/common/Dialog';
import ProgressList from 'components/ProgressList';
import useTypedForm from '../../../components/common/Form/components';
import { Button } from 'stories/Button';
import { ITProject } from 'interfaces/project';
import {
  GetProjectsByStatusDocument,
  useCreateProjectStatusMutation,
  useUpdateProjectStatusMutation,
} from '../../../graphql/codegen/graphql';
import { ProjectStatusEnum } from '../../../graphql/types';
import { useAppContext } from '../../../AppContext';

export interface ApprovalDashboardProps {
  steps: ITProject['steps'];
  basePath: string;
  project: any;
}
const ApprovalDashboard = ({
  basePath,
  steps,
  project,
}: ApprovalDashboardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  interface FormValues {}
  interface ApproveDialogProps {
    approveProject: null | any;
    onClose: DialogProps['onClose'];
  }
  const [approveProject, setApproveProject] = useState<string | null>(null);
  const { Form, CancelButton } = useTypedForm<FormValues>();
  const { user } = useAppContext();

  const ApproveDialog = ({
    approveProject,
    onClose,
  }: Omit<ApproveDialogProps, 'mode'>) => {
    const [createProjectStatus] = useCreateProjectStatusMutation();
    const [updateProjectStatus] = useUpdateProjectStatusMutation({
      refetchQueries: [GetProjectsByStatusDocument],
    });

    const handleConfirm = async () => {
      if (approveProject.id) {
        await updateProjectStatus({
          variables: {
            data: {
              deletedBy: {
                set: user!.kid!,
              },
              deletedAt: {
                set: new Date(),
              },
            },
            where: {
              id: Number(
                approveProject.projectStatus.filter(
                  s => s.deletedBy === null,
                )[0].id,
              ),
            },
          },
        });
        await createProjectStatus({
          variables: {
            data: {
              createdBy: user!.kid!,
              project: {
                connect: {
                  id: approveProject.id,
                },
              },
              sequenceStatus: {
                connect: {
                  id: ProjectStatusEnum.BOARD_ACCEPTED,
                },
              },
            },
          },
        });
        await onClose();
        history.push(`${basePath}/completion`);
      }
    };

    return (
      <Dialog
        open={!!approveProject}
        onClose={onClose}
        title={t('myItProject.dashboard.project_activation')}
      >
        <Form onSubmit={handleConfirm} mode="onChange">
          <div className="space-y-4">
            <p>Möchten Sie den Projektstart anfordern?</p>
            <div className="my-8 flex items-center justify-center space-x-5">
              <CancelButton theme="danger" onClick={onClose}>
                Nicht anfordern.
              </CancelButton>
              <Button onClick={handleConfirm}>
                {t('myItProject.dashboard.project_activation')}
              </Button>
            </div>
          </div>
        </Form>
      </Dialog>
    );
  };

  return (
    <div>
      <ProgressList>
        {['ism', 'ds', 'gbr', 'iuk', 'auf', 'af'].map(step => (
          <ProgressList.Item
            key={step}
            enabled={true}
            finished={steps[step].approved}
            to={`${basePath}/${step}`}
          >
            {t(`myItProject.steps.${step}`)}{' '}
          </ProgressList.Item>
        ))}
      </ProgressList>
      <div className="absolute space-x-4 -bottom-20 right-4">
        {/*<Link to={config.localRoutes.myItProject.files}>*/}
        {/*  <Button theme="secondary">*/}
        {/*    {t('myItProject.dashboard.overview')}*/}
        {/*  </Button>*/}
        {/*</Link>*/}
        <Button
          theme="primary"
          disabled={['ism', 'ds', 'gbr', 'iuk', 'auf', 'af', 'con'].some(
            step => !steps[step].approved,
          )}
          onClick={() =>
            setApproveProject(approveProject === project.id ? null : project.id)
          }
        >
          {t('myItProject.dashboard.project_activation')}
        </Button>

        {approveProject ? (
          <ApproveDialog
            approveProject={project}
            onClose={() => setApproveProject(null)}
          />
        ) : null}
      </div>
    </div>
  );
};
export default React.memo(ApprovalDashboard);
