import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button } from 'stories/Button';
import { useAppContext } from 'AppContext';
import useTypedForm from 'components/common/Form/components';
import {
  useCreateInterfaceDetailMutation,
  useCreateProjectInterfaceMutation,
  useUpdateProjectInterfaceMutation,
} from 'graphql/codegen/graphql';

import { v4 as uuidv4 } from 'uuid';
import InterfaceDescription from './interfaceDescription';
import {
  ProjectInterfaceDescription,
  ProjectInterfaceExportDetail,
  ProjectInterfaceImportDetail,
} from '../interfaceTypes';
import { useHistory } from 'react-router-dom';
import InterfaceEdit from './interfaceEdit';

export interface EditInterfacesPageProps {
  projectId: string;
  importDetails: ProjectInterfaceImportDetail;
  exportDetails: ProjectInterfaceExportDetail;
  interfaceDetails: ProjectInterfaceDescription;
  onInput: (id: string) => void;
}

const InterfacePage: React.VFC<EditInterfacesPageProps> = ({
  projectId,
  importDetails,
  exportDetails,
  interfaceDetails,
  onInput,
}) => {
  const [updateProjectInterface] = useUpdateProjectInterfaceMutation();
  const { user } = useAppContext();
  const { t } = useTranslation();
  const { location } = useHistory();

  const [previousInterface, setPreviousInterface] = useState<boolean>(false);

  const { Form } = useTypedForm();

  const [createProjectInterface] = useCreateProjectInterfaceMutation();

  const [interfaceDescriptions, setInterfaceDescriptions] =
    useState<ProjectInterfaceDescription>(interfaceDetails);

  useEffect(() => {
    setInterfaceDescriptions(interfaceDetails);
  }, [interfaceDetails]);

  const exportDescriptions = exportDetails;

  const importDescriptions = importDetails;

  const onlyExport = importDescriptions.interfaceTypeId.value === '';
  const onlyImport = exportDescriptions.interfaceTypeId.value === '';
  const noInterfaceDetail = onlyExport && onlyImport;

  const handleInterfaceInput = (data: ProjectInterfaceDescription) => {
    setInterfaceDescriptions({ ...interfaceDescriptions, ...data });
  };
  const handleSubmit = async (data: ProjectInterfaceDescription) => {
    if (interfaceDetails.interfaceId !== '') {
      await updateProjectInterface({
        variables: {
          where: {
            id: interfaceDetails.id,
          },
          data: {
            deletedBy: {
              set: user?.kid,
            },
            deletedAt: {
              set: new Date(),
            },
          },
        },
      });
    }

    if (user?.kid && projectId && data.employeeId) {
      setPreviousInterface(true);
      const createId = uuidv4();
      await createProjectInterface({
        variables: {
          data: {
            id: createId,
            name: data.name,
            cause: data.cause,
            description: data.description,
            challenges: data.challenges,
            collaboration: data.collaboration,
            interfaceId:
              interfaceDetails.interfaceId !== ''
                ? interfaceDetails.interfaceId
                : uuidv4(),
            createdBy: user.kid,
            isAutomatic: data.isAutomatic,
            employee: {
              connect: {
                id: data.employeeId,
              },
            },
            interfaceReference: {
              connectOrCreate: {
                where: {
                  id: data.id,
                },
                create: {
                  id: uuidv4(),
                },
              },
            },
            project: {
              connect: {
                id: projectId,
              },
            },
            status: data.riskData.status,
          },
        },
      });

      if (data.riskData.status) {
        await updateProjectInterface({
          variables: {
            where: {
              id: createId,
            },
            data: {
              riskStatus: {
                connect: {
                  id: data.riskData.riskStatus,
                },
              },
              riskOccurance: {
                connect: {
                  id: data?.riskData?.riskOccurrence,
                },
              },
              riskDamagePotential: {
                connect: {
                  id: data?.riskData?.riskDamagePotential,
                },
              },
            },
          },
        });
      }

      if (!noInterfaceDetail && previousInterface) {
        await handleCreateInterfaceDetails(createId);
      }
      await onInput(createId);
    }
  };

  const [createInterfaceDetailMutation] = useCreateInterfaceDetailMutation();

  const createInterfaceDetail = async (
    data: ProjectInterfaceImportDetail | ProjectInterfaceExportDetail,
    id: string,
    description: string | null | undefined,
  ) => {
    if (user?.kid && id) {
      await createInterfaceDetailMutation({
        variables: {
          data: {
            createdBy: user.kid,
            isExport: data.isExport,
            interfaceFormat: {
              connect: {
                id: data.interfaceFormatId.value,
              },
            },
            interfaceType: {
              connect: {
                id: data.interfaceTypeId.value,
              },
            },
            projectInterface: {
              connect: {
                id: id,
              },
            },
            description: description,
          },
        },
      });
    }
  };

  const handleCreateInterfaceDetails = (id: string) => {
    if (onlyExport) {
      createInterfaceDetail(
        exportDescriptions,
        id,
        exportDescriptions.exportDescription,
      );
    } else if (onlyImport) {
      createInterfaceDetail(
        importDescriptions,
        id,
        importDescriptions.importDescription,
      );
    } else if (!onlyExport && !onlyImport) {
      createInterfaceDetail(
        exportDescriptions,
        id,
        exportDescriptions.exportDescription,
      );
      createInterfaceDetail(
        importDescriptions,
        id,
        importDescriptions.importDescription,
      );
    }
  };
  const changeInDescription =
    interfaceDescriptions.challenges === interfaceDetails.challenges &&
    interfaceDescriptions.collaboration === interfaceDetails.collaboration &&
    interfaceDescriptions.cause === interfaceDetails.cause &&
    interfaceDescriptions.description === interfaceDetails.description;

  return (
    <div>
      <div className=" px-4 pt-6">
        <Form
          onSubmit={() => handleSubmit(interfaceDescriptions)}
          mode="onChange"
        >
          <dl>
            {location.pathname.includes('description') && (
              <InterfaceDescription
                interfaceDetails={interfaceDetails}
                onInput={handleInterfaceInput}
              />
            )}
            {location.pathname.includes('edit') && (
              <InterfaceEdit
                interfaceDetails={interfaceDetails}
                onInput={handleInterfaceInput}
              />
            )}
            <div className="flex items-end justify-end pt-10">
              <Button
                disabled={
                  interfaceDescriptions.employeeId === '' ||
                  interfaceDescriptions.name === '' ||
                  (location.pathname.includes('description') &&
                    changeInDescription)
                }
                onClick={() => handleSubmit(interfaceDescriptions)}
              >{t`itProjects.dashboard.actions.submitButton`}</Button>
            </div>
          </dl>
        </Form>
      </div>
    </div>
  );
};
export default InterfacePage;
