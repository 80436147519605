import { useTranslation } from 'react-i18next';
import Table from 'components/NewTable';
import config from 'config/config';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/common/Button';
import { useReportingGetProjectInterfacesByIdLazyQuery } from 'graphql/codegen/graphql';
import { useEffect } from 'react';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export interface InterfacesPageProps {
  projectId: string;
}

const InterfacesTable: React.VFC<InterfacesPageProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const basePath = config.localRoutes.tdReporting.interfaces.replace(
    ':projectId',
    projectId,
  );

  const [fetchProjects, { data, loading }] =
    useReportingGetProjectInterfacesByIdLazyQuery({
      notifyOnNetworkStatusChange: true,

      variables: {
        id: projectId,
      },
    });

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const getColumns = () => {
    return [
      {
        label: t`tdReporting.interfaces.name`,
        render: data => (
          <button
            className="font-medium text-primary"
            onClick={() =>
              history.push({
                pathname: `${config.localRoutes.tdReporting.editInterface.replace(
                  ':projectId',
                  projectId,
                )}/edit`,
                search: `interfaceId=${data.id}`,
              })
            }
          >
            {data.projectId === projectId ? data.name : []}
          </button>
        ),
      },
      {
        label: t`tdReporting.interfaces.supervisor`,
        render: data => (
          <p>
            {data.projectId === projectId
              ? `${data.employee.lastName}, ${data.employee.firstName}`
              : []}
          </p>
        ),
      },
      {
        label: t`tdReporting.interfaces.status`,
        render: data => (
          <p>{data.projectId === projectId ? data.status : []}</p>
        ),
      },
    ];
  };

  return (
    <div className="w-full pt-10 space-y-10">
      <Breadcrumb label={t('tdReporting.pages.interfaces')} route={basePath} />
      <Table
        data={
          data?.masters[0].projectInterface?.filter(
            entry => entry.deletedAt === null,
          ) ?? []
        }
        loading={loading}
        columns={getColumns()}
      />
      <div className="w-full flex items-end justify-end">
        <Button
          theme="primary"
          onClick={() =>
            history.push({
              pathname: `${config.localRoutes.tdReporting.editInterface.replace(
                ':projectId',
                projectId,
              )}/edit`,
            })
          }
        >
          {t`tdReporting.interfaces.addInterface`}
        </Button>
      </div>
    </div>
  );
};
export default InterfacesTable;
