import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useBreadcrumbsContext } from './useBreadcrumbsContext';

export const Breadcrumbs = () => {
  const { items } = useBreadcrumbsContext();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li className="flex">
          <Link
            to="/landing"
            className="flex items-center text-primary hover:text-blue-500"
          >
            <span className="text-md">Start</span>
          </Link>
        </li>
        {items?.map((item, index) => {
          const isLast = items.length - 1 === index;
          return (
            <li key={item.id} className="flex">
              <div className="flex items-center">
                <ChevronRightIcon
                  className="flex-shrink-0 w-4 h-4 text-gray-400"
                  aria-hidden="true"
                />
                {isLast ? (
                  <span className="ml-4 text-gray-400 text-md">
                    {item.label}
                  </span>
                ) : (
                  <Link
                    to={item.route}
                    className="ml-4 text-md text-primary hover:text-blue-500"
                    /**aria-current={page.current ? 'page' : undefined}**/
                  >
                    {item.label}
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );

  return (
    <nav aria-label="Breadcrumb" className="flex px-8 pb-10 md:px-16">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/landing" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {items.map(item => (
          <li key={item.id}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              <Link
                to={item.route}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                /**aria-current={page.current ? 'page' : undefined}**/
              >
                {item.label}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );

  return (
    <ul className="w-full px-8 md:px-16">
      {items.map(item => {
        return (
          <li className="font-primary text-primary last:text-black">
            <Link to={item.route}>{item.label}</Link>
          </li>
        );
      })}
    </ul>
  );
};
