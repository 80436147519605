import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import TabBar from 'components/TabBar';
import config from 'config/config';
import InterfacesTable from './table';
import EditInterfacesPage from './edit/editInterfaces';
import EditCharacteristicPage from './characteristic/editCharacteristic';
import { useHistory } from 'react-router-dom';
import { useReportingGetProjectByIdLazyQuery } from 'graphql/codegen/graphql';
import React, { useEffect, useState } from 'react';
import TDReportingAuth from '../../../../components/TDReporting/TDReportingAuth';
import { Alert } from 'react-bootstrap';

export interface InterfaceSelectionPageProps {
  projectId: string;
}

const InterfaceSelectionPage: React.VFC<InterfaceSelectionPageProps> = ({
  projectId,
}) => {
  const { t } = useTranslation();
  const { location } = useHistory();
  const params = new URLSearchParams(location.search);

  const interfaceId = params.get('interfaceId');
  const [authenticated, setAuthenticated] = useState(false);
  const basePath = config.localRoutes.tdReporting.editInterface.replace(
    ':projectId',
    projectId,
  );

  const [reportingGetProjectById, { data, loading }] =
    useReportingGetProjectByIdLazyQuery({
      variables: {
        id: projectId,
      },
    });

  useEffect(() => {
    if (authenticated) {
      reportingGetProjectById();
    }
  }, [authenticated]);

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div className="w-full">
      {interfaceId ? (
        <TabBar
          isProjectSelected
          data={[
            {
              id: 1,
              text: t`tdReporting.pages.interfaces`,
              route: `${basePath}/edit?interfaceId=${interfaceId}`,
            },
            {
              id: 2,
              text: t`tdReporting.interfaces.description`,
              route: `${basePath}/description?interfaceId=${interfaceId}`,
            },
            {
              id: 3,
              text: t`tdReporting.interfaces.characteristic`,
              route: `${basePath}/characteristic?interfaceId=${interfaceId}`,
            },
          ]}
        />
      ) : (
        <TabBar
          isProjectSelected
          data={[
            {
              id: 1,
              text: t`tdReporting.pages.interfaces`,
              route: `${basePath}`,
              pageId: 'interfaces',
            },
          ]}
        />
      )}

      <Switch>
        <Route
          path={config.localRoutes.tdReporting.interfaces}
          exact
          component={() => <InterfacesTable projectId={projectId} />}
        />
        <Route
          path={`${basePath}`}
          exact
          component={() => <InterfacesTable projectId={projectId} />}
        />
        <Route
          path={`${basePath}/edit`}
          exact
          component={() => <EditInterfacesPage projectId={projectId} />}
        />
        <Route
          path={`${basePath}/description`}
          exact
          component={() => <EditInterfacesPage projectId={projectId} />}
        />
        <Route
          path={`${basePath}/characteristic`}
          exact
          component={() => (
            <EditCharacteristicPage
              projectId={projectId}
              interfaceId={interfaceId || ''}
            />
          )}
        />
      </Switch>
    </div>
  );
};
export default InterfaceSelectionPage;
