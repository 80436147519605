import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  theme?: 'primary' | 'secondary' | 'grey' | 'grey-secondary' | 'danger';
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({ theme = 'primary', ...props }: ButtonProps) => {
  return (
    <button
      type={props.type || 'button'}
      className={classNames(
        'inline-block cursor-pointer rounded-[100px] border-2 px-6 py-2 font-primary text-sm font-medium',
        {
          'border-primary bg-primary text-white hover:border-opacity-90 hover:bg-opacity-90 active:border-opacity-80 active:bg-opacity-80':
            theme === 'primary',
          'border-primary bg-white text-primary hover:bg-blue-50 active:bg-blue-100':
            theme === 'secondary',
          'opacity-40': props.disabled,
          'bg-darkGrey text-white': theme === 'grey',
          'border-darkGrey text-darkGrey': theme === 'grey-secondary',
          'bg-red-700 text-red-100 focus:shadow-outline hover:bg-red-800':
            theme === 'danger',
        },
      )}
      {...props}
    />
  );
};
