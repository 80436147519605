import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import deleteIcon from '../../../assets/images/deleteIcon_grey.png';
import { TextInput } from 'stories/TextInput';

export interface IDocument {
  id: string;
  name?: string;
  description?: string | null;
  location?: string | null;
  file?: File;
}

interface DocumentRowProps {
  index: number;
  doc: IDocument;
  markError: boolean;
  onReupload: (todo: string) => void;
  onDelete: (doc: IDocument) => void;
  handleCriteriaStatus: (status: boolean) => void;
  handleDescChange: (text: string, index: number) => void;
}

export const DocumentRow = ({
  index,
  doc,
  markError,
  onReupload,
  onDelete,
  handleCriteriaStatus,
  handleDescChange,
}: DocumentRowProps) => {
  const { t } = useTranslation();
  const [criteriasFulfilled, setCriteriasFulfilled] = useState(false);

  const handleReupload = () => {
    console.log('handle reuplaod');
  };

  const handleDelete = () => {
    onDelete(doc);
  };

  const onCriteriaStatus = (status: boolean) => {
    setCriteriasFulfilled(status);
    handleCriteriaStatus(status);
  };

  const onDescChange = (text: string) => {
    handleDescChange(text, index);
  };

  return (
    <tr className="border-t border-tableDivider">
      <td>
        <TextInput
          id={index.toString()}
          value={doc.description!}
          minChar={1}
          markError={!criteriasFulfilled && markError}
          errorText={t('myItIdea.documents.mandatoryField')}
          criteriasChecked={onCriteriaStatus}
          onChange={onDescChange}
          hover={true}
        />
      </td>
      <td>
        {doc.file?.name && doc.file.name !== 'File' ? doc.file.name : doc.name}
      </td>
      <td className="text-right align-sub">
        {/* <button className="mr-4" onClick={handleReupload}>
          <img className="h-4" src={uploadIcon} alt="upload icon" />
        </button> */}
        <button onClick={handleDelete}>
          <img className="h-4" src={deleteIcon} alt="delete icon" />
        </button>
      </td>
    </tr>
  );
};
