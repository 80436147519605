import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import config from 'config/config';
import configuration from '../../Config';
import { PaperClipIcon } from '@heroicons/react/solid';
import { RouteComponentProps } from 'react-router-dom';
import { addMonths, format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import IdeaDeclineDialog from 'components/dashboard/IdeaDeclineDialog';
import IdeaReleaseDialog from 'components/dashboard/IdeaReleaseDialog';
import ProjectActivationReleaseDialog from '../../components/dashboard/ProjectActivationReleaseDialog';
import SkeletonLoader from 'components/SkeletonLoader';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { ProjectRolesEnum, ProjectStatusEnum } from 'graphql/types';
import AuthenticationMessage from '../../AuthenticationMessage';
import { isUserInGroup } from 'helpers/util';
import { useAppContext } from '../../AppContext';
import {
  useCreateProjectDocumentMutation,
  useCreateProjectRoleMutation,
  useDashboardGetProjectByIdQuery,
  useGetEmployeeByIdLazyQuery,
  useRemoveProjectDocumentMutation,
  useUpdateProjectCommentsMutation,
  useUpdateProjectRoleMutation,
} from 'graphql/codegen/graphql';
import uploadIcon from '../../assets/images/uploadIcon_blue.png';
import { v4 as uuidv4 } from 'uuid';
import UploadButton from 'components/UploadButton';
import Form from 'components/common/Form/components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import deleteIcon from '../../assets/images/deleteIcon_grey.png';
import { TDocument } from 'types/TDocument';
import { debounce } from 'lodash';
import { User } from 'microsoft-graph';
import { findUser } from '../../GraphService';
import { useMsal } from '@azure/msal-react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

const GROUPS_WITH_RELEASE_PERMISSION =
  process.env.NODE_ENV === 'development'
    ? []
    : [
        'c8c651ba-4085-46bb-98f5-7c9e758c5b92',
        'eae512de-c9d8-4b32-8524-c5aa33b6b94c',
      ];

const SHORT_KID_LENGTH = 5;
const LONG_KID_LENGTH = 6;

const DetailPage: React.VFC<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dialog, setDialog] = useState<
    'release_idea' | 'release_project_activation' | 'decline_idea' | null
  >(null);
  const { user } = useAppContext();
  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );
  const showReleaseButtons = isUserInGroup(
    GROUPS_WITH_RELEASE_PERMISSION,
    user,
  );
  const { data, loading } = useDashboardGetProjectByIdQuery({
    variables: {
      id,
    },
  });

  const [createProjectRoleMutation] = useCreateProjectRoleMutation();
  const [updateProjectRoleMutation] = useUpdateProjectRoleMutation();

  const [getEmployeeById, { loading: approverLoading, data: approverData }] =
    useGetEmployeeByIdLazyQuery();
  const [removeProjectDocumentMutation] = useRemoveProjectDocumentMutation();

  const [createProjectDocument] = useCreateProjectDocumentMutation({
    refetchQueries: ['dashboardGetProjectById'],
  });

  const [updateProjectDescription] = useUpdateProjectCommentsMutation({
    refetchQueries: ['dashboardGetProjectById'],
  });

  const project = data?.masters[0];
  const projectStatus = project?.projectStatus?.find(p => p.deletedBy === null);
  const isApproved = project?.projectApproval?.some(
    a => a.approvalBoardId === 'con',
  );

  const projectLead = project?.projectRole?.find(
    projectRole => projectRole.role.id === ProjectRolesEnum.PROJEKTLEITUNG,
  )?.employee;
  const latestStatus = project?.projectDescriptions?.filter(
    s => s.deletedBy === null,
  );

  const projectContact = project?.projectRole?.find(
    projectRole =>
      projectRole.role.id === ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER &&
      projectRole.deletedBy === null,
  )?.employee;

  const projectContactId = project?.projectRole?.find(
    projectRole =>
      projectRole.role.id === ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER &&
      projectRole.deletedBy === null,
  )?.id;

  const projectApproverInfo = project?.projectStatus?.find(
    s => s.sequenceStatusId === ProjectStatusEnum.IDEA_ACCEPTED,
  );

  useEffect(() => {
    getEmployeeById({
      variables: {
        id: projectApproverInfo?.createdBy || '',
      },
    });
  }, [projectApproverInfo?.createdBy, getEmployeeById]);

  useEffect(() => {
    if (project?.projectDocuments) {
      setDocs(project?.projectDocuments);
    }
  }, [project?.projectDocuments]);

  const getReleaseButtonBySequence = (sequence: String | undefined) => {
    switch (sequence) {
      case ProjectStatusEnum.IDEA:
        return (
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={() => setDialog('release_idea')}
          >
            {t`itProjects.detail.actions.approve`}
          </button>
        );
      case ProjectStatusEnum.BOARD_ACCEPTED:
        return (
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={() => setDialog('release_project_activation')}
          >
            {t`itProjects.detail.actions.approve`}
          </button>
        );
      case ProjectStatusEnum.DECLINED:
        return (
          <button
            type="button"
            className="mr-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={() => setDialog('decline_idea')}
          >
            {t`itProjects.detail.actions.decline`}
          </button>
        );
      default:
        return null;
    }
  };
  const showEditButton = () => {
    if (showReleaseButtons) {
      if (editMode) {
        return (
          <button
            type="button"
            className="mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={() => onSubmit()}
          >
            {t`itProjects.detail.actions.saveButton`}
          </button>
        );
      } else {
        return (
          <button
            type="button"
            className="mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={() => setEditMode(!editMode)}
          >
            {t`itProjects.detail.actions.editButton`}
          </button>
        );
      }
    }
  };

  const mapRemarks = () => {
    let arr: ReactElement[] = [];
    project?.remarks?.forEach((r, index) => {
      if (r.deletedBy === null) {
        arr.push(
          <div className="flex space-x-2" key={index}>
            <p className="flex font-medium">{r.segment.segment + ': '}</p>
            {r.remark === '' ? <p>{'Keine Bemerkungen'}</p> : <p>{r.remark}</p>}
          </div>,
        );
      }
    });
    return arr;
  };

  const [remarks, setRemarks] = useState<ReactElement[]>();
  const [docs, setDocs] = useState<(TDocument & { uri?: string })[]>(
    [] || ([] as any),
  );

  const [editMode, setEditMode] = useState(false);
  const [commentsDescription, setCommentsDescription] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectCategory, setProjectCategory] = useState('');
  const [contactKID, setContactKID] = useState(
    projectContact?.id ? projectContact?.id : '',
  );
  const [kidInput, setKidInput] = useState<string | undefined>('');
  const [employee, setEmployee] = useState<User>();
  const handleCommentsChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentsDescription(e.target.value);
  };

  const handleProjectNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handleCategoryChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    setProjectCategory(e.target.value);
  };

  const requestEmployee = async (value: string) => {
    setEmployee(undefined);
    if (value) {
      const user = await findUser(authProvider, value);
      if (user) {
        setEmployee(user);
      }
    }
  };
  const debouncedRequestEmployee = debounce(requestEmployee, 1000);
  const handleContactChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setKidInput(e.target.value);
    // setContactKID(e.target.value.replace(/^[A-Z][0-9]{4,6}$/, ''));
    setEmployee(undefined);
    if (
      e.target.value.length === SHORT_KID_LENGTH ||
      e.target.value.length === LONG_KID_LENGTH
    )
      debouncedRequestEmployee(e.target.value);
  };

  useEffect(() => {
    setRemarks(mapRemarks);
  }, [project?.remarks]);

  useEffect(() => {
    debouncedRequestEmployee(projectContact?.id ? projectContact?.id : '');
    setKidInput(projectContact?.id);
  }, [projectContact]);

  const showButton = (sequence: string | undefined) => {
    return showReleaseButtons
      ? projectStatus?.sequenceStatusId !== ProjectStatusEnum.DECLINED &&
        project !== undefined
        ? getReleaseButtonBySequence(sequence)
        : ''
      : '';
  };

  const onSubmit = async () => {
    await updateProjectDescription({
      variables: {
        data: {
          name: {
            set: projectName
              ? projectName
              : latestStatus?.[0].name
              ? latestStatus?.[0].name
              : '',
          },
          comments: {
            set: commentsDescription
              ? commentsDescription
              : latestStatus?.[0].comments
              ? latestStatus?.[0].comments
              : '',
          },
          category: {
            set: projectCategory
              ? projectCategory
              : latestStatus?.[0].category
              ? latestStatus?.[0].category
              : '',
          },
        },
        where: {
          id: latestStatus?.[0].id,
        },
      },
    });
    if (
      id &&
      user?.kid &&
      employee &&
      employee.givenName &&
      employee.surname &&
      employee.userPrincipalName &&
      kidInput !== projectContact?.id
    ) {
      if (projectContactId) {
        await updateProjectRoleMutation({
          variables: {
            data: {
              deletedBy: {
                set: user?.kid ? user!.kid : '',
              },
              deletedAt: {
                set: new Date(),
              },
            },
            where: {
              id: projectContactId,
            },
          },
        });
      }
      await createProjectRoleMutation({
        variables: {
          createdBy: user.kid,
          employeeId: employee!.userPrincipalName
            ? employee!.userPrincipalName.split('@')[0]
            : '',
          projectId: id,
          roleId: ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER,
          firstName: employee.givenName,
          lastName: employee.surname,
          email: employee.userPrincipalName,
        },
      });
    }
    setEditMode(false);
  };

  const handleDocumentSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    e.target.files &&
      (await createProjectDocument({
        variables: {
          data: {
            id: uuidv4(),
            name: e.target.files[0].name,
            description: '',
            location: 'it-projects',
            uri: '',
            createdBy: user!.kid!,
            project: {
              connect: {
                id: project?.id,
              },
            },
          },
          file: e.target.files[0],
        },
      }));
  };

  const handleDelete = async docID => {
    setDocs(docs.filter(f => f.id !== docID));

    await removeProjectDocumentMutation({
      variables: {
        where: {
          id: docID,
        },
      },
    });
  };

  const handleUpload = (files: FileList | null) => {
    const newDocs = [...docs];

    if (files?.length) {
      Array.from(files).map(f => {
        const doesExist = newDocs.some(n => n.name === f.name);
        if (!doesExist) {
          newDocs.push({
            id: uuidv4(),
            description: f.name,
            file: f,
          });
        }
      });

      newDocs.map(async (doc, index) => {
        if (doc.file) {
          await createProjectDocument({
            variables: {
              data: {
                id: doc.id,
                name: doc.file!.name,
                description: doc.description,
                location: 'ds',
                uri: 'https://www.dummy-url.com',
                createdBy: user!.kid!,
                project: {
                  connect: {
                    id: id,
                  },
                },
              },
              file: doc.file,
            },
          }).then(res => {
            const allDocs = [...docs];
            allDocs.push({
              id: doc.id,
              description: doc.description,
              file: doc.file,
              uri: res.data?.createProjectDocument.uri,
            });
            setDocs(allDocs);
          });
        }
      });
    }
  };

  if (AuthenticationMessage('it-projects')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`itProjects.detail.actions.alert`}</p>
      </Alert>
    );
  }

  return (
    <>
      <Breadcrumb
        label={latestStatus?.[0].name ?? ''}
        route={`/it-projects/detail/${id}`}
      />
      <div className="mb-5 flex items-center justify-between">
        {showReleaseButtons && (
          <span>
            {editMode && (
              <span>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="rounded-md border border-gray-200 w-full"
                  defaultValue={latestStatus?.[0].name!}
                  onChange={handleProjectNameChange}
                />
              </span>
            )}
            {!editMode && (
              <h3 className="h-6 text-lg font-medium leading-6 text-gray-900">
                <SkeletonLoader loading={loading} className="h-6">
                  {latestStatus?.[0].name}
                </SkeletonLoader>
              </h3>
            )}
          </span>
        )}
        <div className="ml-4 flex">
          {showEditButton()}
          {showButton(ProjectStatusEnum.DECLINED)}
          {showButton(projectStatus?.sequenceStatusId)}
        </div>
      </div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <Form onSubmit={() => {}}>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.projectLead`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-64" loading={loading}>
                    {projectLead
                      ? `${projectLead?.firstName} ${projectLead?.lastName}`
                      : t`itProjects.detail.actions.noProjectLeadFound`}
                  </SkeletonLoader>
                </dd>
              </div>
              <div className="sm:col-span-1">
                {showReleaseButtons && (
                  <span>
                    <dt className="text-sm font-medium text-gray-500">
                      {editMode
                        ? t`itProjects.detail.labels.editProjectContact`
                        : t`itProjects.detail.labels.projectContact`}
                    </dt>
                    {editMode && (
                      <span>
                        <input
                          id="contact.kid"
                          name="contact.kid"
                          type="text"
                          className="rounded-md border border-gray-200 w-full"
                          defaultValue={contactKID}
                          onChange={handleContactChange}
                        />
                      </span>
                    )}
                  </span>
                )}
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-64" loading={loading}>
                    {employee
                      ? `${employee?.givenName} ${employee?.surname}`
                      : 'noch zu benennen'}
                  </SkeletonLoader>
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.category`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-64" loading={loading}>
                    {latestStatus?.[0].projectType.projectType}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.start`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-48" loading={loading}>
                    {project?.projectDescriptions?.[0].projectStart
                      ? format(
                          parseISO(
                            latestStatus?.[0].projectStart as unknown as string,
                          ),
                          'dd.MM.yyyy',
                        )
                      : ''}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.financial-data`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {isApproved && (
                      <p>{t`itProjects.detail.actions.budgetApproved`}</p>
                    )}
                    <a
                      href={'/financial-data/' + id}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {t`itProjects.detail.actions.open`}
                    </a>
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.end`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-48" loading={loading}>
                    {project?.projectDescriptions?.[0].projectStart
                      ? t('itProjects.detail.projectEnd', {
                          date: format(
                            addMonths(
                              parseISO(
                                latestStatus?.[0]
                                  .projectStart as unknown as string,
                              ),
                              latestStatus?.[0].projectDuration!,
                            ),
                            'dd.MM.yyyy',
                          ),
                          months: latestStatus?.[0].projectDuration,
                        })
                      : ''}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.description`}</dt>
                <dd className="mt-1 text-sm text-gray-900 break-words">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {latestStatus?.[0].description}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.remark`}</dt>
                <dd className="mt-1 text-sm text-gray-900 break-words">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {remarks?.length ? remarks : 'Keine Bemerkungen'}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.status`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {projectStatus?.sequenceStatus.descriptionLong}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.background`}</dt>
                <dd className="mt-1 text-sm text-gray-900 break-words">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {latestStatus?.[0].background}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.approver`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader
                    className="h-10 w-full"
                    loading={approverLoading}
                  >
                    {projectApproverInfo !== undefined
                      ? approverData?.employee.firstName +
                        ' ' +
                        approverData?.employee.lastName
                      : t`itProjects.detail.actions.notReleased`}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.approvalDate`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader
                    className="h-10 w-full"
                    loading={approverLoading}
                  >
                    {projectApproverInfo !== undefined
                      ? projectApproverInfo.createdAt
                        ? format(
                            parseISO(
                              projectApproverInfo.createdAt as unknown as string,
                            ),
                            'dd.MM.yyyy',
                          )
                        : ''
                      : t`itProjects.detail.actions.notReleased`}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                {showReleaseButtons && (
                  <span>
                    <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.category`}</dt>
                    {editMode && (
                      <span>
                        <select
                          id="category"
                          name="category"
                          className="rounded-md border border-gray-200 w-full"
                          defaultValue={latestStatus?.[0].category!}
                          onChange={handleCategoryChange}
                        >
                          <option value=""></option>
                          <option value="ASS">ASS</option>
                          <option value="Data/iPEN">Data/iPEN</option>
                          <option value="GIS">GIS</option>
                          <option value="iConnect">iConnect</option>
                          <option value="Infrastruktur">Infrastruktur</option>
                          <option value="PowerBi">PowerBi</option>
                          <option value="PowerApp">PowerApp</option>
                          <option value="SAP">SAP</option>
                          <option value="SHNG Cloud">SHNG Cloud</option>
                          <option value="Sonstige">Sonstige</option>
                          <option value="RPA">RPA</option>
                        </select>
                      </span>
                    )}
                    {!editMode && (
                      <span>
                        <dd className="mt-1 text-sm text-gray-900 break-words">
                          <SkeletonLoader
                            className="h-10 w-full"
                            loading={loading}
                          >
                            {latestStatus?.[0].category}
                          </SkeletonLoader>
                        </dd>
                      </span>
                    )}
                  </span>
                )}
              </div>

              <div className="sm:col-span-1">
                {showReleaseButtons && (
                  <span>
                    <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.comments`}</dt>
                    {editMode && (
                      <span>
                        <textarea
                          id="comments"
                          name="comments"
                          rows={5}
                          className="rounded-md border border-gray-200 w-full"
                          defaultValue={latestStatus?.[0].comments!}
                          onChange={handleCommentsChange}
                        />
                      </span>
                    )}
                    {!editMode && (
                      <span>
                        <dd className="mt-1 text-sm text-gray-900 break-words">
                          <SkeletonLoader
                            className="h-10 w-full"
                            loading={loading}
                          >
                            {latestStatus?.[0].comments}
                          </SkeletonLoader>
                        </dd>
                      </span>
                    )}
                  </span>
                )}
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1" />
            </dl>
          </Form>

          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-4">
              <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.documents`}</dt>
              {project?.projectRole?.some(r => {
                return (
                  r.deletedBy === null &&
                  r.role.id === ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER &&
                  r.employee.id === user?.kid
                );
              }) && (
                <div className="flex w-fit py-2">
                  <label
                    className="h-10 storybook-button--label storybook-button--secondary"
                    htmlFor="select-document"
                  >
                    <div className="flex mx-6">
                      <img
                        className="h-4 mr-2"
                        src={uploadIcon}
                        alt="upload icon"
                      />
                      {t('myItIdea.documents.upload')}
                    </div>
                  </label>
                  <div>
                    <input
                      className="hidden"
                      onChange={handleDocumentSelect}
                      type="file"
                      id="select-document"
                      multiple
                    />
                  </div>
                </div>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                <SkeletonLoader loading={loading} className="h-24 w-full">
                  <Form onSubmit={() => {}}>
                    {docs.length > 0 ? (
                      <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                        {docs.map(projectDocument => (
                          <li
                            key={projectDocument.uri}
                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                          >
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 w-0 flex-1 truncate">
                                {projectDocument.name}
                              </span>
                            </div>
                            <div className="ml-4 flex flex-shrink-0 justify-center">
                              <a
                                href={
                                  process.env.REACT_APP_BACKEND_BASE_URL +
                                  projectDocument.uri!
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                              >
                                {t`itProjects.detail.actions.download`}
                              </a>
                              {user?.kid === projectContact?.id && (
                                <button
                                  className="mx-2"
                                  onClick={() =>
                                    handleDelete(projectDocument.id)
                                  }
                                >
                                  <img
                                    className="h-4"
                                    src={deleteIcon}
                                    alt="delete icon"
                                  />
                                </button>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span className="text-sm text-gray-900">
                        {t`itProjects.detail.noDocuments`}
                      </span>
                    )}
                    <div className="mt-2">
                      {user?.kid === projectContact?.id && (
                        <UploadButton
                          onUpload={handleUpload}
                          multiple
                          theme="secondary"
                        >
                          <div className="space-x-2">
                            <FontAwesomeIcon icon={faUpload} />
                            <span>
                              {t(
                                'myItProject.generalWorksCouncil.upload_button',
                              )}
                            </span>
                          </div>
                        </UploadButton>
                      )}
                    </div>
                  </Form>
                </SkeletonLoader>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <IdeaReleaseDialog
        projectStatusId={Number(projectStatus?.id)}
        open={dialog === 'release_idea'}
        onClose={() => {
          setDialog(null);
          history.push(`${config.localRoutes.dashboard.home}`);
        }}
        projectId={project?.id}
        projectDescriptionId={latestStatus?.[0].id}
      />
      <ProjectActivationReleaseDialog
        projectStatusId={Number(projectStatus?.id)}
        open={dialog === 'release_project_activation'}
        onClose={() => setDialog(null)}
        projectId={project?.id}
        projectDescriptionId={latestStatus?.[0].id}
      />
      <IdeaDeclineDialog
        projectStatusId={Number(projectStatus?.id)}
        open={dialog === 'decline_idea'}
        onClose={() => setDialog(null)}
        projectId={project?.id}
        projectDescriptionId={latestStatus?.[0].id}
      />
    </>
  );
};
export default DetailPage;
