import {
  SortOrder,
  useResourceplanningGetProjectByIdLazyQuery,
  useUpdateProjectResourceCaptureMutation,
} from '../../../graphql/codegen/graphql';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/common/Button';
import { useTranslation } from 'react-i18next';
import TabBar from '../../../components/TabBar';
import config from '../../../config/config';
import ResourcePlanningDialog from '../../../components/TDReporting/Dialog/ResourcePlanningDialog';
import Dialog from '../../../components/common/Dialog';
import useTypedForm from '../../../components/common/Form/components';
import { useAppContext } from '../../../AppContext';
import ProjectResourceTable from '../../../components/TDReporting/ProjectResourceTable';
import TDReportingAuth from '../../../components/TDReporting/TDReportingAuth';
import { Alert } from 'react-bootstrap';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export interface ResourcePlaningPageProps {
  projectId: string;
}

export interface ExtendedDropdownProps {
  value: string;
  label: string;
  department?: string | null;
}

const DeleteDialog = ({ deleteDialog, onClose, data }) => {
  const { t } = useTranslation();
  const { Form, SubmitButton } = useTypedForm();
  const { user } = useAppContext();

  const [updateResource] = useUpdateProjectResourceCaptureMutation({
    refetchQueries: ['resourceplanningGetProjectById'],
  });

  const handleSubmit = () => {
    updateResource({
      variables: {
        data: {
          deletedBy: {
            set: user?.kid,
          },
          deletedAt: {
            set: new Date(),
          },
        },
        where: {
          id: deleteDialog,
        },
      },
    });
    onClose();
  };

  const value = data.employee.lastName + ', ' + data.employee.firstName;

  return (
    <Dialog
      open={!!deleteDialog}
      onClose={onClose}
      title={t`tdReporting.projectResourcePage.deleteDialogTitle`}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="label-notifications">
              <div className="text-base font-normal text-gray-900 text-center sm:text-lg sm:text-gray-700">
                {t(`tdReporting.projectResourcePage.deleteDialogMessage`, {
                  value,
                })}
              </div>
              <div className="flex pt-10 flex items-center justify-center">
                <SubmitButton theme="danger">{t`tdReporting.projectResourcePage.deleteResource`}</SubmitButton>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

const ResourcePlaningPage = ({ projectId }: ResourcePlaningPageProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'create' | 'edit' | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<string | null>(null);
  const [editProjectResourcePlanning, setEditProjectResourcePlanning] =
    useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const basePath = config.localRoutes.tdReporting.rescourceplaning.replace(
    ':projectId',
    projectId,
  );
  const [fetchResourceplanningGetProjectById, { data, loading }] =
    useResourceplanningGetProjectByIdLazyQuery({
      variables: {
        id: projectId,
        orderBy: [
          {
            projectResourceCapture: {
              _count: SortOrder.Desc,
            },
          },
        ],
      },
    });

  const onSearchChange = value => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (authenticated) {
      fetchResourceplanningGetProjectById();
    }
  }, [authenticated]);

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  const compareOnboardingDate = (a, b) => {
    if (a.onboarding > b.onboarding) {
      return 1;
    }
    if (a.onboarding < b.onboarding) {
      return -1;
    }
    return 0;
  };

  const comparePhase = (a, b) => {
    if (a.projectPhaseId > b.projectPhaseId) {
      return 1;
    }
    if (a.projectPhaseId < b.projectPhaseId) {
      return -1;
    }
    return 0;
  };

  const compareRoles = (a, b) => {
    if (a.roleId > b.roleId) {
      return 1;
    }
    if (a.roleId < b.roleId) {
      return -1;
    }
    return 0;
  };

  let res = data?.masters[0].projectResourceCapture?.map(c => {
    return c;
  });

  return (
    <div className="w-full w-min-40 bg-white overflow-x-auto h-full">
      <TabBar
        isProjectSelected={!!projectId}
        data={[
          {
            id: 1,
            text: t`tdReporting.pages.resourceplaning`,
            route: basePath,
          },
        ]}
      />
      <div className="flex w-full">
        <Breadcrumb
          label={t('tdReporting.pages.resourceplaning')}
          route={basePath}
        />
        <div className="w-full py-10">
          <input
            className="w-1/3 rounded-md border border-gray-300 px-4 py-2 text-sm placeholder:text-gray-400"
            name="search"
            onChange={event => onSearchChange(event.currentTarget.value)}
            placeholder={t`tdReporting.projectResourcePage.searchBar`}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="h-full justify-left items-left ">
        <ProjectResourceTable
          searchName={searchValue}
          data={
            res
              ?.sort(comparePhase)
              .sort(compareRoles)
              .sort(compareOnboardingDate)
              .filter(r => r.deletedBy === null) ?? []
          }
          loading={loading}
          setEditProjectResourcePlanning={setEditProjectResourcePlanning}
          setMode={setMode}
          setDeleteDialog={setDeleteDialog}
        />
      </div>
      <div className="flex pt-10 flex items-end justify-end">
        <Button
          onClick={() => {
            setMode('create');
          }}
        >
          {t`tdReporting.projectResourcePage.createButton`}
        </Button>
      </div>
      {mode !== null ? (
        <ResourcePlanningDialog
          mode={mode}
          setMode={setMode}
          editProjectResourcePlanning={editProjectResourcePlanning}
          setEditProjectResourcePlanning={setEditProjectResourcePlanning}
          projectId={projectId}
          data={data}
        />
      ) : null}
      {deleteDialog !== null ? (
        <DeleteDialog
          deleteDialog={deleteDialog}
          onClose={() => setDeleteDialog(null)}
          data={data?.masters[0]?.projectResourceCapture?.find(
            r => r.id === deleteDialog,
          )}
        />
      ) : null}
    </div>
  );
};
export default ResourcePlaningPage;
