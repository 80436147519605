import ContentLoader from 'react-content-loader';
import { GroupBase } from 'react-select';
import ReactCreatable, {
  CreatableProps as ReactSelectCreatableProps,
} from 'react-select/creatable';

export type CreatableProps<TOption> = {
  variant: 'creatable';
  loading?: boolean;
} & ReactSelectCreatableProps<TOption, false, GroupBase<TOption>>;
function Creatable<TOption>({
  loading,
  variant,
  ...selectProps
}: CreatableProps<TOption>) {
  return loading ? (
    <ContentLoader className="w-full h-10">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="38" />
    </ContentLoader>
  ) : (
    <ReactCreatable {...selectProps} />
  );
}
export default Creatable;
