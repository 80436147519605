import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

import TDReportingTable, {
  ProjectFilter,
} from 'components/TDReporting/TDReportingTable';

import { t } from 'i18next';
import { ProjectStatusEnum } from '../../../graphql/types';
import TDReportingAuth from '../../../components/TDReporting/TDReportingAuth';
import config from 'config/config';
import Dialog from 'components/common/Dialog';

export type ProjectsTableProps = {
  projectId: string;
};
const ProjectSelectionTable: React.VFC<ProjectsTableProps> = ({
  projectId,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const basePath = config.localRoutes.tdReporting.home.replace(
    ':projectId',
    projectId,
  );

  const onSearchChange = value => {
    setSearchValue(value);
  };

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div className="w-full">
      <Breadcrumb
        label={t('tdReporting.pages.projectselection')}
        route={basePath}
      />
      <p className="text-sm  py-10">
        {t('tdReporting.userGuidance.projectSelection')}
      </p>
      <div className="w-1/2 pb-10">
        <input
          className="w-full rounded-md border border-gray-300 px-4 py-2 text-sm placeholder:text-gray-400"
          name="search"
          onChange={event => onSearchChange(event.currentTarget.value)}
          placeholder="Suche nach Projektname"
          autoComplete="off"
        />
      </div>
      <TDReportingTable searchName={searchValue} />
    </div>
  );
};

export default ProjectSelectionTable;

export const getProjectStatusByFilterType = (
  filter: ProjectFilter,
): ProjectStatusEnum[] => {
  switch (filter) {
    case 'ideas':
      return [ProjectStatusEnum.IDEA];
    case 'projects':
      return [ProjectStatusEnum.IDEA_ACCEPTED];
    case 'inventory':
      return [ProjectStatusEnum.INVENTORY];
    case 'activated':
      return [ProjectStatusEnum.ACTIVATED];
    default:
      return [
        ProjectStatusEnum.ACTIVATED,
        ProjectStatusEnum.IDEA,
        ProjectStatusEnum.IDEA_ACCEPTED,
        ProjectStatusEnum.INVENTORY,
      ];
  }
};
