import ConfirmIcon from 'components/ConfirmIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import config, { getFrontendUrl } from 'config/config';

import {
  GetMasterWithApprovalsDocument,
  useCreateProjectDocumentMutation,
  useGetProjectDocumentsLazyQuery,
  useRemoveProjectDocumentMutation,
  useUpdateApprovalStatusMutation,
  useGetMailInfoByProjectLazyQuery,
  useGetProjectDescriptionsLazyQuery,
} from 'graphql/codegen/graphql';
import FileUploadList from '../../../components/FileUploadList';
import MailPopUp from '../../../components/MailPopUp';
import UploadButton from '../../../components/UploadButton';
import useTypedForm from 'components/common/Form/components';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { IDocument } from '../../../components/myItIdea/components/DocumentRow';
import { useAppContext } from 'AppContext';

export interface ApprovalDataSecurityProps {
  approved: boolean;
  confirmed: boolean;
  projectId: string;
}

type Mail = {
  cc?: string;
  subject: string;
  body: string;
};

const ApprovalDataSecurity = ({
  approved,
  confirmed,
  projectId,
}: ApprovalDataSecurityProps) => {
  const { Form, IconSubmitButton } = useTypedForm();
  const { user } = useAppContext();

  const [docs, setDocs] = useState<(IDocument & { uri?: string })[]>(
    [] || ([] as any),
  );

  const [getProjectDocuments, { data: projectDocumentsData }] =
    useGetProjectDocumentsLazyQuery();
  const [projectDescriptionId, setProjectDescriptionId] = useState<string>('');
  const [updateApprovalStatusMutation] = useUpdateApprovalStatusMutation();
  const [createProjectDocumentMutation] = useCreateProjectDocumentMutation();
  const [removeProjectDocumentMutation] = useRemoveProjectDocumentMutation();
  const [getMailInfo, { data: mailInfoData }] =
    useGetMailInfoByProjectLazyQuery();

  const loadProjectDocuments = async () => {
    return await getProjectDocuments({
      variables: {
        where: {
          projectId: {
            equals: projectId,
          },
          location: {
            equals: 'ds',
          },
        },
      },
    });
  };

  const [getProjectDescription] = useGetProjectDescriptionsLazyQuery({
    variables: {
      where: {
        projectId: {
          equals: projectId,
        },
      },
    },
  });

  const [mail, setMail] = useState<Mail>({
    cc: '',
    subject: 'initial',
    body: '',
  });

  useEffect(() => {
    loadContent();
    loadMailInfo();
  }, [mailInfoData, docs]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadProjectDocuments();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const loadMailInfo = () => {
    if (
      !mailInfoData?.employeeMasters[0] ||
      !mailInfoData.master.projectDescriptions![0]
    ) {
      const body: string = '';
      setMail({
        subject: '[Datenschutz] - IT-Projekt: <hier Projektname einfügen>',
        body,
      });

      return;
    }

    const cc: string = mailInfoData?.employeeMasters[0].employee.email || '';
    const subject: string = `[Datenschutz] - IT-Projekt: ${
      mailInfoData?.master.projectDescriptions![0].name
    }`;

    const projectLead: string =
      (mailInfoData?.employeeMasters[0].employee.firstName || '') +
      ' ' +
      (mailInfoData?.employeeMasters[0].employee.lastName || '');
    let body: string = t('myItProject.dataSecurity.mailText', {
      projectName: mailInfoData?.master.projectDescriptions![0].name,
      projectLead: projectLead,
      givenName: user?.givenName,
      surname: user?.surname,
    });

    setMail({
      cc,
      subject,
      body,
    });
  };

  const loadContent = () => {
    getMailInfo({
      variables: {
        where: {
          id: projectId,
        },
        where2: {
          projectId: {
            equals: projectId,
          },
        },
      },
    });

    if (confirmed) {
      getProjectDescription().then(response => {
        const description = response && response.data!.projectDescriptions[0]!;
        setProjectDescriptionId(description.id);
      });
    }
    loadProjectDocuments().then(response => {
      const documents = response && response.data!.projectDocuments!;
      if (documents.length !== 0) {
        setDocs(documents);
      }
    });
  };

  useEffect(() => {
    setDocs(projectDocumentsData?.projectDocuments ?? []);
  }, [projectDocumentsData]);

  const handleUpload = (files: FileList | null) => {
    const newDocs = [...docs];

    if (files?.length) {
      Array.from(files).map(f => {
        const doesExist = newDocs.some(n => n.name === f.name);
        if (!doesExist) {
          newDocs.push({
            id: uuidv4(),
            description: f.name,
            file: f,
          });
        }
      });

      newDocs.map(async (doc, index) => {
        if (doc.file) {
          await createProjectDocumentMutation({
            variables: {
              data: {
                id: doc.id,
                name: doc.file!.name,
                description: doc.description,
                location: 'ds',
                uri: 'https://www.dummy-url.com',
                createdBy: user!.kid!,
                project: {
                  connect: {
                    id: projectId,
                  },
                },
              },
              file: doc.file,
            },
          }).then(res => {
            const allDocs = [...docs];
            allDocs.push({
              id: doc.id,
              description: doc.description,
              file: doc.file,
              uri: res.data?.createProjectDocument.uri,
            });
            setDocs(allDocs);
          });
        }
      });
    }
  };

  const handleDelete = async doc => {
    await removeProjectDocumentMutation({
      variables: {
        where: {
          id: doc.id,
        },
      },
    });
    setDocs(docs.filter(f => f.id !== doc.id));
  };

  const handleSubmit = () => {
    updateApprovalStatusMutation({
      variables: {
        data: {
          projectApproval: {
            create: [
              {
                createdBy: user!.kid!,
                ApprovalBoard: { connect: { id: 'ds' } },
                approvalStatus:
                  process.env.NODE_ENV === 'development'
                    ? { connect: { id: 'angenommen' } }
                    : undefined,
              },
            ],
          },
        },
        projectId,
      },
      refetchQueries: ['getProjectDocuments', GetMasterWithApprovalsDocument],
    }).then(() => {
      loadContent();
    });
  };
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb
        label={t`myItProject.steps.ds`}
        route={`/meine-it-initiative/${projectId}/ds`}
      />
      <Form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <p className="text-xl font-medium">
            <Trans i18nKey={'myItProject.dataSecurity.description'} />
          </p>
          <MailPopUp
            cc={mail.cc}
            subject={mail.subject}
            body={mail.body}
            projectId={projectId}
            approverId="ds"
            isDisabled={confirmed}
          ></MailPopUp>
          <br />
          <p className="text-xl font-medium">
            <Trans
              i18nKey={'myItProject.dataSecurity.coordinatorsLink'}
              components={{
                href: (
                  <a
                    href="https://connect.eon.com/pages/haw-datenschutz/apps/content/datenschutzkoordinatoren"
                    className="underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </p>
          <div className="flex flex-wrap">
            <div className="w-1/2">
              <UploadButton
                onUpload={handleUpload}
                multiple={false}
                disabled={docs.length === 1 || confirmed}
                theme="secondary"
              >
                <div className="space-x-2">
                  <FontAwesomeIcon icon={faUpload} />
                  <span>
                    {t('myItProject.generalWorksCouncil.upload_button')}
                  </span>
                </div>
              </UploadButton>
            </div>
            <div className="w-1/2">
              <FileUploadList
                files={docs}
                onDelete={handleDelete}
                disabled={confirmed}
              >
                <span className="font-semibold">
                  {t('myItProject.generalWorksCouncil.upload_button')}
                </span>
              </FileUploadList>
            </div>
          </div>
          <div className="text-xl font-medium">
            <p>{t('myItProject.dataSecurity.description3')}</p>
          </div>
          <div className="flex items-center space-x-5">
            <IconSubmitButton
              active
              approved={confirmed}
            >{t`myItProject.dataSecurity.confirmedLabel`}</IconSubmitButton>
          </div>
          <p className="text-xl font-medium">{t`myItProject.dataSecurity.description2`}</p>
          <ConfirmIcon
            enabled
            active={approved}
          >{t`myItProject.dataSecurity.approvedLabel`}</ConfirmIcon>
        </div>
      </Form>
    </>
  );
};
export default ApprovalDataSecurity;
