import { GroupBase } from 'react-select';
import ReactSelectAsyncCreatable, {
  AsyncCreatableProps as ReactSelectAsyncCreatableProps,
} from 'react-select/async-creatable';

export type AsyncCreatableProps<TOption> = {
  variant: 'async-creatable';
} & ReactSelectAsyncCreatableProps<TOption, false, GroupBase<TOption>>;
function AsyncCreatable<TOption>({
  variant,
  ...selectProps
}: AsyncCreatableProps<TOption>) {
  return (
    <ReactSelectAsyncCreatable
      {...selectProps}
      onChange={(value, actionMeta) => {
        selectProps.onChange?.(value, actionMeta);
      }}
    />
  );
}
export default AsyncCreatable;
