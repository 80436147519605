import { Button } from 'components/common/Button';
import { Trans, useTranslation } from 'react-i18next';
import { StepProps } from '.';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import React from 'react';
import SuccessTable from '../../components/myItIdea/successTable';

const Success = ({ values }: StepProps<{ name: string }>) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className="w-full flex flex-col">
      <Breadcrumb label="Erfolg" route="neue-it-initiative/success" />

      <div className="w-full flex flex-1 flex-col space-y-10">
        <p className="text-base">
          <Trans
            i18nKey="myItIdea.success.description"
            values={{ name: values?.name }}
          />
          <div className="my-5">
            <Trans i18nKey="myItIdea.success.desc2" />
          </div>
          <SuccessTable />
        </p>
      </div>
      <div className="flex flex-grow-0 justify-end pt-5 space-x-7">
        <Button onClick={() => push('/landing')}>
          {t('myItIdea.actions.finish')}
        </Button>
      </div>
    </div>
  );
};
export default Success;
