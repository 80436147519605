import {
  components,
  GroupBase,
  ValueContainerProps as ReactSelectValueContainerProps,
} from 'react-select';
import './ValueContainer.css';

export type ValueContainerProps<TOption> = ReactSelectValueContainerProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function ValueContainer<TOption>({
  children,
  ...rest
}: ValueContainerProps<TOption>) {
  return (
    <components.ValueContainer {...rest} className="dropdown-value-container">
      {children}
    </components.ValueContainer>
  );
}
export default ValueContainer;
