import ContentLoader from 'react-content-loader';

export interface SkeletonLoaderProps {
  className?: string;
  loading?: boolean;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  className,
  children,
  loading,
}) => {
  return loading ? (
    <ContentLoader className={className}>
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
    </ContentLoader>
  ) : (
    <>{children}</>
  );
};
export default SkeletonLoader;
