import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import TDReportingDetail from './Detail';

export type TDReportingProps = RouteComponentProps;

const TDReportingPage = ({ match }: TDReportingProps) => {
  return (
    <div>
      <Switch>
        <Route path={match.url} exact component={TDReportingDetail} />
        <Route path={`${match.url}/:projectId`} component={TDReportingDetail} />
      </Switch>
    </div>
  );
};
export default TDReportingPage;
