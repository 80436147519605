import Label from 'components/common/Label';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import './DatePicker.css';
import classNames from 'classnames';
import de from 'date-fns/locale/de';
import {
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';

registerLocale('de', de);

export type FormDatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
> = {
  className?: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholderText?: string;
  onChange?: (event: Date | null) => void;
  id?: string;
} & UseControllerProps<TFieldValues>;

function FormDatePicker<TFieldValues extends FieldValues = FieldValues>({
  className,
  label,
  minDate,
  maxDate,
  placeholderText,
  onChange,
  id,
  ...controllerProps
}: FormDatePickerProps<TFieldValues>) {
  const { field } = useController<TFieldValues>(controllerProps);
  const value = useWatch({
    name: controllerProps.name,
  });
  return (
    <div className="relative">
      {label && <Label>{label}</Label>}
      <ReactDatePicker
        id={id}
        minDate={minDate}
        maxDate={maxDate}
        locale="de"
        className="w-full"
        dateFormat="dd.MM.yyyy"
        onBlur={field.onBlur}
        onChange={event => {
          onChange?.(event);
          field.onChange(event);
        }}
        selected={value || null}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ]}
        showPopperArrow={false}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        placeholderText={placeholderText}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2 bg-white font-primary">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={classNames(
                'inline-flex p-1 rounded-md text-gray-600 bg-white border-white border hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary',
                {
                  'cursor-not-allowed opacity-50': prevMonthButtonDisabled,
                },
              )}
            >
              <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
            </button>
            <span className="text-base text-gray-700">
              {format(date, 'MMMM yyyy', { locale: de })}
            </span>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={classNames(
                'inline-flex p-1 rounded-md text-gray-600 bg-white border-white border hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary',
                {
                  'cursor-not-allowed opacity-50': nextMonthButtonDisabled,
                },
              )}
            >
              <ChevronRightIcon className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        )}
      />
    </div>
  );
}
export default FormDatePicker;
