import { Button } from 'components/common/Button';
import { FormDropdownOption } from 'components/common/Form/components/Dropdown';
import InterfaceCreateDialog from 'components/common/Dialog/InterfaceCreationDialog';
import ToolTip from 'components/common/ToolTip';
import Label from 'components/common/Label';
import { nanoid } from 'nanoid';
import {
  GetProjectTypesQuery,
  InterfaceFormat,
  InterfaceType,
  LeanixMapping,
  useGetProjectTypesQuery,
} from 'graphql/codegen/graphql';
import useTypedForm from 'components/common/Form/components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PlusIcon } from '@heroicons/react/solid';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ApplicationDropdown from 'components/inventory/Form/ApplicationDropdown';
import { get } from 'lodash';
import { mapProjectTypes } from 'helpers/map';

type DropdownOption = {
  value: string;
  label: string;
  __isNew__?: boolean;
};

type Application = Pick<
  LeanixMapping,
  '__typename' | 'id' | 'description' | 'name' | 'type'
>;

export interface FormData {
  test: boolean;
  application: Partial<Application>;
  projectType: string;
  description: string;
  additionalComments: Record<string, string>;
  child: {
    enabled: boolean;
    app: DropdownOption;
  };
  parent: {
    enabled: boolean;
    app: DropdownOption;
  };
  replacement?: {
    enabled: boolean;
    date: Date;
    app: DropdownOption;
  };
  interfaces: {
    enabled: boolean;
    interfaces: Record<
      string,
      {
        id: string;
        name: string;
        isAutomatic: boolean;
        application: Application;
        description: {
          import: string;
          export: string;
        };
      }
    >;
  };
  businessProcesses: {
    enabled: boolean;
    comment: string;
    items: Record<
      string,
      {
        name: string;
        description: string;
      }
    >;
  };
  contact: {
    name: string;
    id: string;
  };
  technicalContact: {
    name: string;
    id: string;
  };
}

export type InventoryDetailPageProps = RouteComponentProps<{
  projectId: string;
}>;
const InventoryDetailPage = (props: InventoryDetailPageProps) => {
  // eslint-disable-next-line
  const projectId = props.match.params.projectId;
  const { t } = useTranslation();
  const projectTypes = useGetProjectTypesQuery();
  const {
    DatePicker,
    Dropdown,
    Form,
    TextArea,
    TextInput,
    Switch,
    Value,
    SubmitButton,
  } = useTypedForm<FormData>();

  const [comments, setComments] = useState([nanoid(4)]);
  const [showInterfaceDialog, setShowInterfaceDialog] = useState<string | null>(
    null,
  );

  return (
    <div className="w-full min-h-screen font-primary">
      <Form
        className="w-full space-y-10"
        mode="onChange"
        onSubmit={data => console.log('Will submit data', data)}
        submitOnChange
      >
        <div className="w-full bg-gray-100 border border-gray-300">
          <div className="p-4 lg:px-12 xl:px-24 text-primary">{t`myItInventory.headline`}</div>
        </div>
        <div className="px-4 lg:px-12 xl:px-24 space-y-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <ApplicationDropdown<FormData>
              name="application"
              label={t`myItInventory.fields.application`}
              formatCreateLabel={value =>
                t('myItInventory.createDisclaimer', { value })
              }
            />
            <Dropdown
              variant="default"
              name="projectType"
              label={t`myItInventory.fields.projectType`}
              loading={projectTypes.loading}
              options={mapProjectTypes(projectTypes.data?.projectTypes)}
            />
          </div>
          <div>
            <Value name="application">
              {application => (
                <TextArea
                  name="application.description"
                  label={t`myItInventory.fields.description`}
                  disabled={!!application?.id}
                />
              )}
            </Value>
          </div>
          <div className="space-y-5">
            {comments.map(id => (
              <div className="relative" key={id}>
                <TextArea
                  name={`additionalComments[${id}]` as any}
                  label={t`myItInventory.fields.comment`}
                />
                {comments.length > 1 && (
                  <button
                    className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 rounded-full hover:bg-gray-100"
                    onClick={() => {
                      const index = comments.indexOf(id);
                      setComments(prev => {
                        const next = prev.slice(0);
                        next.splice(index, 1);
                        return next;
                      });
                    }}
                  >
                    <TrashIcon className="w-4 h-4 text-gray-500" />
                  </button>
                )}
              </div>
            ))}
            <div className="relative h-4">
              <button
                className="absolute top-0 left-0 inline-flex items-center justify-center -mt-3 text-sm hover:text-gray-600"
                onClick={() => {
                  setComments(comments => [...comments, nanoid(4)]);
                }}
              >
                <PlusIcon className="w-4 h-4 mr-2" /> Bemerkungsfeld hinzufügen
              </button>
            </div>
          </div>
          <div className="py-5 -mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <Label>{t`myItInventory.fields.contact.label`}</Label>
              <div className="grid w-full grid-cols-2 gap-5">
                <TextInput
                  name="contact.name"
                  placeholder={t`myItInventory.fields.contact.placeholder.name`}
                />
                <TextInput
                  name="contact.id"
                  placeholder={t`myItInventory.fields.contact.placeholder.kid`}
                />
              </div>
            </div>
            <div>
              <Label>{t`myItInventory.fields.technicalContact.label`}</Label>
              <div className="grid w-full grid-cols-2 gap-5">
                <TextInput
                  name="technicalContact.name"
                  placeholder={t`myItInventory.fields.technicalContact.placeholder.name`}
                  rules={{
                    minLength: 3,
                  }}
                />
                <TextInput
                  name="technicalContact.id"
                  placeholder={t`myItInventory.fields.technicalContact.placeholder.kid`}
                />
              </div>
            </div>
          </div>
          <div className="py-5 -mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="grid items-start w-full grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex">
              <Switch name="replacement.enabled">
                <span className="text-primary">{t`myItInventory.fields.replacement.label`}</span>
              </Switch>
              <ToolTip mode="click">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </ToolTip>
            </div>
            <Value name="replacement.enabled">
              {value =>
                value ? (
                  <div className="space-y-5">
                    <DatePicker
                      name="replacement.date"
                      className="w-64"
                      label={t`myItInventory.fields.replacement.date`}
                    />
                    <ApplicationDropdown<FormData>
                      label={t`myItInventory.fields.replacement.app`}
                      name="replacement.app"
                      formatCreateLabel={value =>
                        t('myItInventory.createDisclaimer', { value })
                      }
                    />
                  </div>
                ) : null
              }
            </Value>
          </div>
          <div className="py-5 -mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="grid items-start w-full grid-cols-1 md:grid-cols-2 gap-5">
            <Switch name="parent.enabled">
              <span className="text-primary">{t`myItInventory.fields.parent.label`}</span>
            </Switch>
            <Value name="parent.enabled">
              {value =>
                value ? (
                  <div className="space-y-5">
                    <ApplicationDropdown<FormData>
                      label={t`myItInventory.fields.parent.app`}
                      name="parent.app"
                      formatCreateLabel={value =>
                        t('myItInventory.createDisclaimer', { value })
                      }
                    />
                  </div>
                ) : null
              }
            </Value>
          </div>
          <div className="grid items-start w-full grid-cols-1 md:grid-cols-2 gap-5 pt-5">
            <Switch name="child.enabled">
              <span className="text-primary">{t`myItInventory.fields.child.label`}</span>
            </Switch>
            <Value name="child.enabled">
              {value =>
                value ? (
                  <div className="space-y-5">
                    <ApplicationDropdown<FormData>
                      label={t`myItInventory.fields.child.app`}
                      name="child.app"
                      formatCreateLabel={value => {
                        return t('myItInventory.createDisclaimer', { value });
                      }}
                    />
                  </div>
                ) : null
              }
            </Value>
          </div>
          <div className="py-5 -mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="grid items-start w-full grid-cols-1 md:grid-cols-2 gap-5">
            <Switch name="interfaces.enabled">
              <span className="text-primary">{t`myItInventory.fields.interfaces.label`}</span>
            </Switch>
            <Value name="interfaces.enabled">
              {enabled =>
                enabled ? (
                  <div>
                    <Value name="interfaces.interfaces">
                      {(interfaces, onChange) => (
                        <>
                          <div
                            className={classNames(
                              'flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-t-md',
                              {
                                'rounded-b-md':
                                  Object.keys(interfaces || {}).length === 0,
                              },
                            )}
                          >
                            <span>{t`myItInventory.fields.interfaces.table.headline`}</span>
                            <button
                              className="p-1 text-sm text-gray-700 rounded-full hover:bg-gray-50"
                              title={t`myItInventory.fields.interfaces.table.add`}
                              onClick={() => setShowInterfaceDialog(nanoid(4))}
                            >
                              <PlusIcon className="w-4 h-4 fill-current" />
                            </button>
                          </div>
                          {(Object.keys(interfaces || {}) || []).map(
                            (key, index) => (
                              <div
                                key={key}
                                className={classNames(
                                  'w-full px-4 py-2 text-sm text-gray-700 flex justify-between items-center last:rounded-b-md',
                                  { 'bg-gray-100': index % 2 === 0 },
                                  { 'bg-gray-50': index % 2 !== 0 },
                                )}
                              >
                                <span>{interfaces[key].name}</span>
                                <div className="space-x-2">
                                  <button
                                    className="p-1 rounded-full hover:bg-gray-200"
                                    onClick={() => setShowInterfaceDialog(key)}
                                  >
                                    <PencilIcon className="w-4 h-4 stroke-current" />
                                  </button>
                                  <button
                                    className="p-1 rounded-full hover:bg-gray-200"
                                    onClick={() => {
                                      const next = {
                                        ...interfaces,
                                      };
                                      delete next[key];
                                      onChange(next);
                                    }}
                                  >
                                    <TrashIcon className="w-4 h-4 stroke-current" />
                                  </button>
                                </div>
                              </div>
                            ),
                          )}
                        </>
                      )}
                    </Value>
                  </div>
                ) : null
              }
            </Value>
          </div>
          <div className="py-5 -mx-24">
            <hr className="border-gray-300" />
          </div>
          <div>
            <div className="grid items-start w-full grid-cols-1 md:grid-cols-2 gap-5">
              <Switch name="businessProcesses.enabled">
                <span className="text-primary">{t`myItInventory.fields.businessProcesses.label`}</span>
              </Switch>
              <Value name="businessProcesses.enabled">
                {enabled =>
                  enabled ? (
                    <>
                      <Value name="businessProcesses.items">
                        {(items, onChange) => (
                          <div className="space-y-5">
                            {Object.keys(items || {}).map((key, index) => (
                              <>
                                <div key={key} className="relative">
                                  <div className="mb-5">
                                    <TextInput
                                      name={
                                        `businessProcesses.items[${key}].name` as any
                                      }
                                      label={t`myItInventory.fields.businessProcesses.name`}
                                    />
                                  </div>
                                  <TextArea
                                    name={
                                      `businessProcesses.items[${key}].description` as any
                                    }
                                    label={t`myItInventory.fields.businessProcesses.description`}
                                  />
                                  <button
                                    className="absolute top-0 right-0 p-1 mt-0 rounded-full hover:bg-gray-200"
                                    onClick={() => {
                                      const next = {
                                        ...items,
                                      };
                                      delete next[key];
                                      onChange(next);
                                    }}
                                  >
                                    <TrashIcon className="w-4 h-4 stroke-current" />
                                  </button>
                                </div>
                                {Object.keys(items).length - 1 > index && (
                                  <div className="py-5">
                                    <hr className="border-gray-300" />
                                  </div>
                                )}
                              </>
                            ))}
                            <button
                              className="flex items-center justify-center p-1 text-sm text-gray-700 rounded-full hover:bg-gray-50"
                              title={t`myItInventory.fields.businessProcesses.add`}
                              onClick={() =>
                                onChange({
                                  ...items,
                                  [nanoid(4)]: {
                                    name: '',
                                    description: '',
                                  },
                                })
                              }
                            >
                              <span className="inline-block mr-2">
                                <PlusIcon className="w-4 h-4 fill-current" />
                              </span>
                              {t`myItInventory.fields.businessProcesses.add`}
                            </button>
                          </div>
                        )}
                      </Value>
                    </>
                  ) : null
                }
              </Value>
            </div>
          </div>
          <div className="py-5 -mx-24">
            <hr className="border-gray-300" />
          </div>
          <div className="flex items-center justify-end w-full space-x-5">
            <Button theme="secondary">{t`myItInventory.actions.delete`}</Button>
            <Button theme="secondary">{t`myItInventory.actions.cancel`}</Button>
            <Button theme="secondary">{t`myItInventory.actions.save`}</Button>
            <SubmitButton>{t`myItInventory.actions.confirm`}</SubmitButton>
          </div>
        </div>
        <Value name="interfaces.interfaces">
          {(interfaces, onChange) => {
            return (
              <InterfaceCreateDialog
                open={typeof showInterfaceDialog === 'string'}
                values={
                  showInterfaceDialog
                    ? get(interfaces, showInterfaceDialog, {
                        id: showInterfaceDialog,
                      })
                    : {}
                }
                onClose={() => setShowInterfaceDialog(null)}
                onSubmit={data => {
                  onChange({
                    ...interfaces,
                    [data.id]: data,
                  });
                  setShowInterfaceDialog(null);
                }}
              />
            );
          }}
        </Value>
      </Form>
    </div>
  );
};
export default InventoryDetailPage;
