import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

import './i18n.ts';
import './index.css';
import App from './App';
import config from './Config';
import reportWebVitals from './reportWebVitals';

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.appId!,
    redirectUri: config.redirectUri,
    authority: config.authority,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authResult = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(authResult.account);
  }
});

const Loading = () => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div className="loading-icon" />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App pca={msalInstance} />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
