import { useEffect } from 'react';
import { useBreadcrumbsContext } from './useBreadcrumbsContext';

export const Breadcrumb: React.VFC<{ label: string; route: string }> = ({
  route,
  label,
}) => {
  const { register } = useBreadcrumbsContext();
  useEffect(() => {
    const unregister = register({ label, route });
    return () => unregister();
  }, [label, register, route]);
  return null;
};
