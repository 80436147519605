import React from 'react';

import arrow from '../../../assets/images/arrow4x.png';
import { GeneralCostTableRow } from './GeneralCostSumTable';
import TableInput from './TableInput';

interface GeneralCostRowProps {
  data: GeneralCostTableRow;
  lastSavedData: GeneralCostTableRow;
  firstValueIndex: number;
  darker: boolean;
  isDisabled?: boolean;
  handleExtend: (id: string) => void;
  handleInput: (value: number, id: string) => void;
}

const GeneralCostElementRow = (props: GeneralCostRowProps) => {
  const {
    data,
    lastSavedData,
    firstValueIndex,
    darker,
    handleExtend,
    handleInput,
  } = props;

  const extend = () => {
    handleExtend(data.costTypeId!);
  };

  const openRow = () => {
    handleExtend(data.costTypeId!);
  };

  const input = (value: number, id: string) => {
    handleInput(value, id);
  };

  const color = darker ? 'tableLightGrey' : 'white';

  const rows: any[] = [];
  const max = 4; //Math.min(4, data.values!.length);
  for (let i = 0; i < max; i++) {
    let isHighlighted = false;
    if (
      data &&
      data.values[i + firstValueIndex] &&
      lastSavedData &&
      lastSavedData.values[i + firstValueIndex]
    ) {
      isHighlighted =
        !data.isSummary &&
        +data.values[i + firstValueIndex].sum! !==
          +lastSavedData.values[i + firstValueIndex].sum!;
    }
    rows.push(<td key={i}></td>);
    rows.push(
      <td key={i + max} onClick={data.isSummary ? openRow : undefined}>
        <TableInput
          data={data.values![i + firstValueIndex]}
          color={color}
          isBold={data.isSummary && data.isExtended}
          isHighlighted={isHighlighted}
          handleInput={input}
          isDisabled={data.isSummary}
        />
      </td>,
    );
  }

  const checkRowName = (data: string) => {
    if (data === 'Sammler EKN Aktivierung') {
      return 'Sammler EKS Aktivierung';
    } else {
      return data;
    }
  };

  return (
    <tr className={`text-right bg-${color}`}>
      <td className="text-left">
        {data.isSummary ? (
          <div className="flex items-center">
            <button onClick={extend} className="flex-none h-6">
              <img src={arrow} alt="arrow down" className="h-2 px-3 mr-1" />
            </button>
            {checkRowName(data.costTypeId)}
          </div>
        ) : (
          <div className="flex items-center ml-16">{data.costSubtypeId}</div>
        )}
      </td>
      {rows}
      <td />
      <td>
        <TableInput
          data={data.estimatedCost!}
          isBold
          color={color}
          isDisabled={true}
        />
      </td>
      <td className="w-1/18" />
    </tr>
  );
};

export default GeneralCostElementRow;
