import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FinancialElementRow from './FinancialElementRow';
import FinancialHeadRow from './FinancialHeadRow';
import FinancialSumRow from './FinancialSumRow';
import FinancialTabs from './FinancialTabs';
import ToolTip from '../common/ToolTip';
import { GroupProjectResourceCapturesQuery } from 'graphql/codegen/graphql';
const DEFAULT_MULT_FACTOR = 1;

export type FinancialValue = {
  delta?: number;
  id: string;
  value?: number;
  year?: number;
  _sum?: {
    value?: number;
  };
};

export type FinancialTableRow = {
  assetId: string;
  isProjectCosts: boolean;
  isSummary: boolean;
  isExtended: boolean;
  companyId?: string;
  values: FinancialValue[];
  estimatedCost?: FinancialValue;
};

export type Allocation = {
  id: string;
  isSelected: boolean;
};

type FinancialSumTableProps = {
  projectId?: string;
  financialData: any;
  resourceData?: any;
  companiesData: any;
  assetsData: any;
  tData?: FinancialTableRow[];
  isRequired?: boolean;
  projectCostChange: (isProjectCosts: boolean) => void;
};

const FinancialSumTable = (props: FinancialSumTableProps) => {
  const {
    financialData,
    resourceData,
    companiesData,
    assetsData,
    tData,
    projectCostChange,
  } = props;
  const { t } = useTranslation();
  const [firstValueIndex, setFirstValueIndex] = useState<number>(0);

  const getExtendedAssets = (tData: FinancialTableRow[]) => {
    return tData.filter(d => d.isSummary && d.isExtended).map(d => d.assetId);
  };
  const [extendedAssets, setExtendedAssets] = useState<string[]>(
    tData ? getExtendedAssets(tData) : [],
  );

  const createEmptyTable = useCallback(() => {
    const emptyTable: FinancialTableRow[] = [];
    assetsData.forEach(a => {
      const newAssetRow: FinancialTableRow = {
        assetId: a.id,
        isProjectCosts: a.isProjectCosts,
        isSummary: true,
        isExtended: extendedAssets.includes(a.id),
        values: [],
      };
      emptyTable.push(newAssetRow);
      companiesData.forEach(c => {
        const newCompanyRow: FinancialTableRow = {
          assetId: a.id,
          isProjectCosts: a.isProjectCosts,
          isSummary: false,
          isExtended: false,
          companyId: c.id,
          values: [],
        };
        emptyTable.push(newCompanyRow);
      });
    });
    return emptyTable;
  }, [assetsData, companiesData, extendedAssets]);

  const addMissingZeros = (data: FinancialTableRow[], years: number[]) => {
    data.forEach(d => {
      years.forEach(y => {
        if (!d.values?.some(v => v.year === y)) {
          const newZero: FinancialValue = {
            id: 'key-' + d.assetId + '-' + d.companyId + '-' + y,
            value: 0,
            year: y,
          };
          d.values?.push(newZero);
        }
      });
      d.values?.sort((a, b) => a.year! - b.year!);
    });
  };

  const calcSummaryValues = (data: FinancialTableRow[]) => {
    data
      .filter(s => s.isSummary)
      .forEach(s => {
        const values: FinancialValue[] = [];
        data
          .filter(d => d.assetId === s.assetId && !d.isSummary)
          .forEach(d => {
            d.values?.forEach(companyData => {
              const exisitingValue = values.find(
                v => v.year === companyData.year,
              );
              if (!exisitingValue) {
                values.push({
                  id: 'key-summary' + d.assetId + '-' + companyData.year,
                  value: companyData.value ? +companyData.value : 0,
                  year: companyData.year,
                  delta: companyData.delta ? +companyData.delta : 0,
                });
              } else {
                exisitingValue.value! += companyData.value
                  ? +companyData.value
                  : 0;
                exisitingValue.delta! += companyData.delta
                  ? +companyData.delta
                  : 0;
              }
            });
          });
        values.sort((a, b) => a.year! - b.year!);
        s.values = values;
      });
  };

  const calcEstimatedCost = (data: FinancialTableRow[]) => {
    data.forEach(d => {
      let estimatedCost: number = 0;
      d.values?.forEach(v => {
        estimatedCost += v.value ? +v.value : 0;
      });
      d.estimatedCost = {
        id: 'key-estimated-' + d.assetId + '-' + d.companyId,
        value: +estimatedCost,
      };
    });
  };

  const addFutureYears = (y: number[]) => {
    const currentYear = y[0]; // new Date().getFullYear();
    for (let i = Math.min(...y); i < currentYear + 4; i++) {
      if (!y.includes(i)) {
        y.push(i);
      }
    }
  };
  const [years, setYears] = useState<number[]>([new Date().getFullYear()]);

  const getYears = useCallback(
    (tData?: FinancialTableRow[]) => {
      const y: number[] = years;
      let resetIndex: boolean = false;
      if (tData) {
        tData[0].values.forEach(v => {
          if (!y.includes(v.year!)) {
            y.push(v.year!);
            resetIndex = true;
          }
        });
      } else {
        financialData.forEach(d => {
          if (!y.includes(d?.year)) {
            y.push(d?.year);
            resetIndex = true;
          }
        });
        if (financialData.length === 0) resetIndex = true;
        addFutureYears(y);
      }
      y.sort();
      if (resetIndex) {
        const now = new Date().getFullYear();
        setFirstValueIndex(y.length > 4 ? y.findIndex(y => y === now) : 0);
      }
      return y;
    },
    [financialData, years],
  );

  const createTableState = useCallback(() => {
    const y = getYears();
    setYears(y);

    const tableInitial: FinancialTableRow[] = createEmptyTable();

    financialData.forEach(d => {
      const rowExists = tableInitial.find(
        t => t?.assetId === d?.assetId && t?.companyId === d?.companyId,
      );
      if (rowExists && DEFAULT_MULT_FACTOR) {
        const newValue: FinancialValue = {
          id: d.id,
          value:
            d.value || d.value === 0
              ? d.value * DEFAULT_MULT_FACTOR
              : d._sum.value * DEFAULT_MULT_FACTOR,
          delta: d.delta,
          year: d.year,
        };
        rowExists.values?.push(newValue);
      }
    });
    addMissingZeros(tableInitial, y);
    calcSummaryValues(tableInitial);
    calcEstimatedCost(tableInitial);
    return tableInitial;
  }, [createEmptyTable, financialData, getYears]);

  const deepCopy = useCallback(obj => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = deepCopy(item);
        return arr;
      }, []);
    }

    if (obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = deepCopy(obj[key]);
        return newObj;
      }, {});
    }
  }, []);

  const createTableDataCopy = useCallback(
    (tData: FinancialTableRow[], localExtended?: boolean) => {
      if (years.length !== 4) setYears(getYears(tData));
      const newData: FinancialTableRow[] = [];
      tData.forEach(d => {
        const newObject: FinancialTableRow = deepCopy(d);
        newObject.values = deepCopy(d.values);
        if (localExtended) {
          newObject.isExtended = extendedAssets.includes(d.assetId);
        }
        newData.push(newObject);
      });
      return newData;
    },
    [deepCopy, extendedAssets, getYears, years.length],
  );

  const [tableData, setTableData] = useState<FinancialTableRow[]>(
    tData ? createTableDataCopy(tData) : () => createTableState(),
  );
  const [initialTable, setInitialTable] = useState<FinancialTableRow[]>(
    tData ? createTableDataCopy(tData) : () => createTableState(),
  );

  const getSum = useCallback(
    (data: FinancialTableRow[], isProject: boolean) => {
      const sumValues: FinancialValue[] = [];
      data
        .filter(
          d =>
            (isProject ? d.isProjectCosts : !d.isProjectCosts) && d.isSummary,
        )
        .filter(l => {
          return !(
            l.assetId === 'Anpassungen Schnittstellen' ||
            l.assetId === 'Anpassungen Umsysteme' ||
            l.assetId === 'Changemanagement' ||
            l.assetId === 'Migration' ||
            l.assetId === 'Support Driver (inkl. Erweiterungen)'
          );
        })
        .forEach(d => {
          d.values?.forEach((data: FinancialValue) => {
            const value = sumValues.find(v => v.year === data.year);
            if (!value) {
              const newValue: FinancialValue = {
                id:
                  'key-' + isProject ? 'project-' : 'operational-' + data.year,
                value: data.value ? +data.value : 0,
                year: data.year,
                delta: data.delta ? +(data.value! - data.delta!) : +data.value!,
              };
              sumValues.push(newValue);
            } else {
              value.value! += data.value ? +data.value : 0;
              value.delta! += data.delta
                ? +(data.value! - data.delta!)
                : +data.value!;
            }
          });
        });
      const row: FinancialTableRow = {
        assetId: isProject
          ? t('pages.financial.sumP')
          : t('pages.financial.sum0'),
        isProjectCosts: false,
        isSummary: false,
        isExtended: false,
        values: sumValues,
      };
      calcEstimatedCost([row]);
      return row;
    },
    [],
  );

  const getOwnContribution = useCallback(
    (
      resources: GroupProjectResourceCapturesQuery['groupProjectResourceCaptures'][number][] = [],
    ) => {
      const contributionCosts: FinancialValue[] = [];

      const data = tableData?.filter(d => d.isProjectCosts && d.isSummary)[0];
      data?.values.forEach(d => {
        const newValue: FinancialValue = {
          id: 'key-project' + d.year,
          value: 0,
          year: d.year,
        };
        contributionCosts.push(newValue);
      });

      resources?.forEach(c => {
        const value = contributionCosts.find(v => v.year === c.year);
        if (!value) {
          if (c._sum?.rate) {
            const newValue: FinancialValue = {
              id: 'key-project-' + c.year,
              value: c._sum ? (c._sum.rate / 100) * 100000 : 0,
              year: c.year,
            };
            contributionCosts.push(newValue);
          }
        } else {
          if (c._sum?.rate) {
            value.value! = c._sum ? (c._sum.rate / 100) * 100000 : 0;
          }
        }
      });

      const row: FinancialTableRow = {
        assetId: 'pages.financial.ownWorkP',
        isProjectCosts: true,
        isSummary: false,
        isExtended: false,
        values: contributionCosts,
      };
      calcEstimatedCost([row]);
      return row;
    },
    [tableData],
  );

  const [sumProjectcosts, setSumProjectcosts] = useState<FinancialTableRow>(
    getSum(tableData, true),
  );

  const [ownContributionProject, setOwnContributionProject] =
    useState<FinancialTableRow>(getOwnContribution(resourceData));

  const [sumOperationalcosts, setSumOperationalcosts] =
    useState<FinancialTableRow>(getSum(tableData, false));

  const getIsTableEmpty = useCallback(() => {
    let isEmpty = true;
    tableData.forEach(row => {
      row.values.forEach(v => {
        if (v.value !== 0) {
          isEmpty = false;
        }
      });
    });
    return isEmpty;
  }, [tableData]);

  const [isTableEmpty, setIsTableEmpty] = useState(getIsTableEmpty());

  useEffect(() => {
    setSumProjectcosts(getSum(tableData, true));
    setSumOperationalcosts(getSum(tableData, false));
    setIsTableEmpty(getIsTableEmpty());
  }, [tableData, getIsTableEmpty, getSum]);

  useEffect(() => {
    setOwnContributionProject(getOwnContribution(resourceData));
  }, [resourceData, tableData, getOwnContribution]);

  const maxFirstValueIndex = years.length - 4;

  const [showProjectData, setShowProjectData] = useState(true);

  const handleBack = () => {
    if (firstValueIndex > 0) {
      setFirstValueIndex(firstValueIndex - 1);
    }
  };

  const handleForward = () => {
    if (firstValueIndex < maxFirstValueIndex) {
      setFirstValueIndex(firstValueIndex + 1);
    }
  };

  const handleExtend = (id: string) => {
    setTableData(
      [...tableData].map(object => {
        if (object.assetId === id) {
          return {
            ...object,
            isExtended: !object.isExtended,
          };
        } else {
          return object;
        }
      }),
    );
    setInitialTable(
      [...initialTable].map(object => {
        if (object.assetId === id) {
          return {
            ...object,
            isExtended: !object.isExtended,
          };
        } else {
          return object;
        }
      }),
    );
    setExtendedAssets(
      extendedAssets.includes(id)
        ? extendedAssets.filter(a => a !== id)
        : [...extendedAssets, id],
    );
  };

  const handleInput = (value: number, id: string) => {
    const newTableData = [...tableData].map(object => {
      const index: number = object.values!.findIndex(v => v.id === id);
      const newValues: FinancialValue[] = object.values!;
      if (index >= 0) {
        newValues[index].value = value;
        return {
          ...object,
          values: newValues,
        };
      } else {
        return object;
      }
    });
    calcSummaryValues(newTableData);
    calcEstimatedCost(newTableData);
    setTableData(newTableData);
  };

  const handleTabChange = () => {
    projectCostChange(!showProjectData);
    setShowProjectData(!showProjectData);
  };

  const displayedRows = (data: FinancialTableRow[]) => {
    const returnData: FinancialTableRow[] = [];
    let extendedAsset: string = '';
    data
      .filter(d => (showProjectData ? d.isProjectCosts : !d.isProjectCosts))
      .forEach((element: FinancialTableRow) => {
        if (element.isSummary) {
          extendedAsset = element.isExtended ? element.assetId : '';
          returnData.push(element);
        } else {
          if (element.assetId === extendedAsset) {
            returnData.push(element);
          }
        }
      });
    return returnData;
  };

  useEffect(() => {
    tData && setExtendedAssets(getExtendedAssets(tData));
    setTableData(tData ? createTableDataCopy(tData) : () => createTableState());
    setInitialTable(
      tData ? createTableDataCopy(tData) : () => createTableState(),
    );
  }, [financialData, createTableState, createTableDataCopy, tData]);

  return (
    <div>
      <div className="mb-2">
        <div className="flex">
          <FinancialTabs
            showProjectData={showProjectData}
            onChange={handleTabChange}
          />

          <ToolTip mode="hover" color="text-customBlue">
            {t('myItIdea.financial-data.tooltipHeadRow')}
          </ToolTip>
        </div>
      </div>
      <table className="table-fixed">
        <thead>
          <FinancialHeadRow
            years={years}
            distributedTitle={t(
              `pages.financial.distribution${showProjectData ? 'P' : 'O'}`,
            )}
            plannedTitle={t(
              `pages.financial.planned${showProjectData ? 'P' : 'O'}`,
            )}
            firstValueIndex={firstValueIndex}
            showForward={firstValueIndex !== maxFirstValueIndex}
            handleBack={handleBack}
            handleForward={handleForward}
          />
        </thead>
        <tbody>
          {displayedRows(tableData)
            .filter(l => {
              return !(
                l.assetId === 'Anpassungen Schnittstellen' ||
                l.assetId === 'Anpassungen Umsysteme' ||
                l.assetId === 'Changemanagement' ||
                l.assetId === 'Migration' ||
                l.assetId === 'Support Driver (inkl. Erweiterungen)'
              );
            })
            .map((data, index) => {
              return (
                <FinancialElementRow
                  data={data}
                  lastSavedData={displayedRows(initialTable)[index]}
                  firstValueIndex={firstValueIndex}
                  darker={index % 2 === 1}
                  handleExtend={handleExtend}
                  handleInput={handleInput}
                  key={index}
                  isDisabled={true}
                />
              );
            })}
          <tr>
            <td />
          </tr>
          <FinancialSumRow
            data={showProjectData ? sumProjectcosts : sumOperationalcosts}
            firstValueIndex={firstValueIndex}
          />
          {showProjectData && (
            <tr>
              <td />
            </tr>
          )}
          {showProjectData && (
            <FinancialSumRow
              data={ownContributionProject}
              firstValueIndex={firstValueIndex}
              tooltip={t('myItIdea.financial-data.tooltipSumRow')}
              disableDelta={true}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FinancialSumTable;
