import ContentLoader from 'react-content-loader';
import ToolTip from '../common/ToolTip';
import React from 'react';

export interface TableColumn<TData = any> {
  label: string | JSX.Element;
  render: (data: TData) => string | number | JSX.Element;
  tooltip?: string;
}

export interface TableProps<TData = any> {
  loading?: boolean;
  data: TData[];
  columns: TableColumn<TData>[];
  rowClicked?: (data: TData) => void;
}
export function Table({ columns, data, loading, rowClicked }: TableProps) {
  return (
    <div className="flex flex-col font-primary">
      <div className="min-w-full py-2 align-middle">
        <div className="border-b border-tableDarkGrey shadow sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-tableLightGrey">
              <tr>
                {columns.map((column, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-900 uppercase align-middle"
                  >
                    <div className="flex">
                      <div className="place-self-center">{column.label}</div>
                      {column.tooltip ? (
                        <div className="pl-0.5 font-normal normal-case">
                          <ToolTip mode="hover" color="text-customBlue">
                            {column.tooltip}
                          </ToolTip>
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr key="loading" className="bg-white">
                  {columns.map((column, index) => (
                    <td key={index} className="px-6 py-4">
                      <ContentLoader className="w-full h-5">
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="20"
                        />
                      </ContentLoader>
                    </td>
                  ))}
                </tr>
              ) : (
                data.map((row, index) => (
                  <tr
                    onClick={() => (rowClicked ? rowClicked(index) : undefined)}
                    key={index}
                    className={
                      index % 2 === 0
                        ? 'bg-white hover:bg-tableDarkGrey'
                        : 'bg-tableLightGrey hover:bg-tableDarkGrey'
                    }
                  >
                    {columns.map((column, index) => (
                      <td
                        key={index}
                        className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                      >
                        {column.render(row)}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default Table;
