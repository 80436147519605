import * as React from 'react';
import classNames from 'classnames';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import Header from './components/Header';
import config from './config/config';
import routes from './config/routes';
import {
  useCreateEmployeeMutation,
  useGetEmployeeByIdLazyQuery,
} from './graphql/codegen/graphql';
import { useAppContext } from './AppContext';

export interface MainLayoutProps {
  removePadding?: boolean;
}

const MainLayout = ({ removePadding = false }: MainLayoutProps) => {
  const app = useAppContext();
  const user = app.user || { displayName: '', email: '', kid: '' };
  const kid = user.kid || 'no-kid';
  const [getEmployee, { data: employeeData }] = useGetEmployeeByIdLazyQuery();
  const [createEmployee] = useCreateEmployeeMutation();

  useEffect(() => {
    if (kid !== 'no-kid') {
      const checkEmployee = async () => {
        return await getEmployee({
          variables: {
            id: kid,
          },
        });
      };

      const saveEmployee = async () => {
        await createEmployee({
          variables: {
            data: {
              id: kid,
              firstName: user.givenName,
              lastName: user.surname,
              email: user.email,
              department: user.department,
              createdBy: kid,
            },
          },
        });
      };

      checkEmployee().then(response => {
        const data = response && response.data!;
        if (!data) {
          saveEmployee().catch(console.error);
        }
      });
    }
  }, [kid, employeeData]);

  return (
    <BrowserRouter>
      <div className="w-full h-full space-y-1 flex flex-col">
        <Header />
        <div
          className={classNames('relative flex flex-1 w-full flex-col', {
            'px-8 md:px-16 pb-10': !removePadding,
          })}
        >
          <Switch>
            {routes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Route>
              <Redirect to={config.localRoutes.landing} />
            </Route>
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default MainLayout;
