import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ApproverPage from './Approver';
import DashboardPage from './Dashboard';
import DetailPage from './Detail';
import DocumentsPage from './Documents';
import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Breadcrumbs } from 'components/Breadcrumbs';
import ApprovalProcessOverview from './ApprovalProcessOverview';
import ApproverRecipientPage from './ApproverRecipient';
import LandingApplications from './LandingApplications';
import React from 'react';

const TDDashboardPage = () => {
  const basePath = config.localRoutes.dashboard.home;

  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col w-full min-h-full font-primary">
      <Breadcrumbs />
      <Breadcrumb
        label={t`itProjects.dashboard.headline`}
        route="/it-projects"
      />
      <Switch>
        <Route
          path={[basePath, 'documents'].join('/')}
          exact
          component={DocumentsPage}
        />
        <Route
          path={[basePath, 'approver'].join('/')}
          exact
          component={ApproverPage}
        />
        <Route
          path={[basePath, 'recipient-approver'].join('/')}
          exact
          component={ApproverRecipientPage}
        />
        <Route
          path={[basePath, 'detail', ':id'].join('/')}
          component={DetailPage}
        />
        <Route
          path={[basePath, 'process'].join('/')}
          component={ApprovalProcessOverview}
        />
        <Route
          path={[basePath, 'applications'].join('/')}
          component={LandingApplications}
        />
        <Route path={basePath} component={DashboardPage} />
      </Switch>
    </div>
  );
};
export default TDDashboardPage;
