import { useRef } from 'react';
import { ButtonProps } from 'stories/Button';
import { Button } from 'stories/Button';

interface UploadButtonProps extends ButtonProps {
  multiple?: boolean;
  disabled?: boolean;
  onUpload: (files: FileList | null) => void;
}

const UploadButton = ({
  onUpload,
  multiple = false,
  disabled = false,
  ...buttonProps
}: UploadButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        type="file"
        hidden
        ref={fileInputRef}
        multiple={multiple}
        onChange={e => onUpload(e.target.files)}
      />
      <Button {...buttonProps} onClick={() => fileInputRef.current!.click()} disabled={disabled} />
    </>
  );
};

export default UploadButton;
