import {
  components,
  GroupBase,
  InputProps as ReactSelectInputProps,
} from 'react-select';
import './Input.css';

export type InputProps<TOption> = ReactSelectInputProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function Input<TOption>({ children, ...rest }: InputProps<TOption>) {
  return (
    <components.Input {...rest} className="dropdown-input">
      {children}
    </components.Input>
  );
}
export default Input;
