import Dialog from 'components/common/Dialog';
import useTypedForm from 'components/common/Form/components';
import {
  DashboardGetProjectByIdDocument,
  useCreateProjectRoleMutation,
  useCreateProjectStatusMutation,
  useCreateRemarkMutation,
  useGetSegmentsQuery,
  useUpdateProjectStatusMutation,
} from 'graphql/codegen/graphql';
import { ProjectStatusEnum, Segments } from 'graphql/types';
import { useAppContext } from 'AppContext';
import { useMsal } from '@azure/msal-react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import configuration from '../../../Config';
import React, { useState } from 'react';
// import { debounce } from 'lodash';

// import { findUser } from '../../../GraphService';
import { useTranslation } from 'react-i18next';

export type NullableOption<T> = T | null;

interface FormValues {
  // kid: string;
  // kidName: string;
  remark: string;
  activateProject: boolean;
}

export type Employee = {
  givenName?: NullableOption<string>;
  surname?: NullableOption<string>;
  userPrincipalName?: NullableOption<string>;
  mail?: NullableOption<string>;
  department?: NullableOption<string>;
  displayName?: NullableOption<string>;
};

// const SHORT_KID_LENGTH = 5;
// const LONG_KID_LENGTH = 6;

const IdeaReleaseDialog: React.VFC<{
  open: boolean;
  onClose: () => void;
  projectStatusId: number;
  projectId?: string;
  projectDescriptionId?: string;
}> = ({ open, onClose, projectStatusId, projectId, projectDescriptionId }) => {
  const { TextInput, TextArea, Form, SubmitButton, Checkbox } =
    useTypedForm<FormValues>();
  const [createProjectRoleMutation] = useCreateProjectRoleMutation();
  const { user } = useAppContext();
  const { t } = useTranslation();
  const [sequenceStatus, setSequenceStatus] = useState(
    ProjectStatusEnum.IDEA_ACCEPTED,
  );

  // const [employee, setEmployee] = useState<Employee>();
  const [createProjectStatus] = useCreateProjectStatusMutation({
    variables: {
      data: {
        createdBy: user?.kid ? user!.kid : '',
        project: {
          connect: {
            id: projectId,
          },
        },
        sequenceStatus: {
          connect: {
            id: sequenceStatus,
          },
        },
      },
    },
  });
  const [updateProjectStatus] = useUpdateProjectStatusMutation({
    variables: {
      data: {
        deletedBy: {
          set: user?.kid ? user!.kid : '',
        },
        deletedAt: {
          set: new Date(),
        },
      },
      where: {
        id: projectStatusId,
      },
    },
  });
  const { data: segmentsData } = useGetSegmentsQuery({});
  const [createRemarkMutation] = useCreateRemarkMutation({});

  const findSegmentId = (segmentName: string) =>
    segmentsData
      ? segmentsData.segments.find(s => s.segment === segmentName)?.id
      : undefined;

  const createRemark = (data: { remark: string; segment: string }) => {
    createRemarkMutation({
      variables: {
        data: {
          remark: data.remark,
          createdBy: user?.kid ? user!.kid : '',
          segment: {
            connect: {
              id: findSegmentId(data.segment),
            },
          },
          project: {
            connect: {
              id: projectId,
            },
          },
        },
      },
    });
  };

  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  const [inputText, setInputText] = useState('');

  // const handleSubmit = async (employee: Employee) => {
  const handleSubmit = async (data: FormValues) => {
    if (data.activateProject) {
      setSequenceStatus(ProjectStatusEnum.ACTIVATED);
    }

    if (
      projectStatusId &&
      projectId &&
      projectDescriptionId &&
      user?.kid
      // user?.kid &&
      // employee.givenName &&
      // employee.surname &&
      // employee.userPrincipalName
    ) {
      if (inputText !== undefined) {
        await createRemark({
          remark: inputText,
          segment: Segments.SUBMIT_PROJECT,
        });
      }
      await updateProjectStatus();
      await createProjectStatus({
        refetchQueries: [DashboardGetProjectByIdDocument],
      });

      // await createProjectRoleMutation({
      //   variables: {
      //     createdBy: user.kid,
      //     employeeId: employee.userPrincipalName
      //       ? employee.userPrincipalName.split('@')[0]
      //       : '',
      //     projectId: projectId,
      //     roleId: ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER,
      //     firstName: employee.givenName,
      //     lastName: employee.surname,
      //     email: employee.userPrincipalName,
      //   },
      // });
      onClose();
    }
  };

  // const requestEmployee = async (value: string) => {
  //   const user = await findUser(authProvider, value);
  //   if (user) {
  //     setEmployee(user);
  //   } else {
  //     setEmployee(undefined);
  //   }
  // };
  // const debouncedRequestEmployee = debounce(requestEmployee, 1000);
  //
  // const handleKIDFieldChange = (value: string) => {
  //   setEmployee(undefined);
  //   if (value.length === SHORT_KID_LENGTH || value.length === LONG_KID_LENGTH)
  //     debouncedRequestEmployee(value);
  // };

  return (
    <Dialog
      title={t`itProjects.dashboard.actions.releaseInitiative`}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={(data: FormValues) => {
          // if (employee) {
          handleSubmit(data);
          // handleSubmit(employee);
          // }
        }}
        mode="onChange"
      >
        {/*<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">*/}
        {/*  <label*/}
        {/*    htmlFor="first-name"*/}
        {/*    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:h-12"*/}
        {/*  >*/}
        {/*    {t('itProjects.detail.labels.projectContact')}*/}
        {/*  </label>*/}
        {/*  <div className="mt-1 sm:col-span-2 sm:mt-0">*/}
        {/*    <TextInput*/}
        {/*      name="kid"*/}
        {/*      defaultValue={''}*/}
        {/*      maxLength={LONG_KID_LENGTH}*/}
        {/*      onChange={e => {*/}
        {/*        handleKIDFieldChange(e.target.value);*/}
        {/*      }}*/}
        {/*      rules={{ required: true }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="mt-3 sm:mt-1 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">*/}
        {/*  <label*/}
        {/*    htmlFor="kid-name"*/}
        {/*    className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:h-12"*/}
        {/*  >*/}
        {/*    {t('itProjects.detail.labels.contactName')}*/}
        {/*  </label>*/}
        {/*  <div className="mt-1 sm:col-span-2 sm:mt-0">*/}
        {/*    <TextInput*/}
        {/*      name="kidName"*/}
        {/*      value={*/}
        {/*        employee ? employee?.surname + ', ' + employee?.givenName : ''*/}
        {/*      }*/}
        {/*      disabled={true}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="mt-3 sm:mt-1 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
          <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:h-12">
            {t`itProjects.detail.labels.remark`}
          </div>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <TextArea
              name="remark"
              className="p-2 w-full border border-gray-300 rounded-md hover:border-primary focus:border-primary"
              onChange={e => setInputText(e.target.value)}
              placeholder={t`itProjects.detail.description.remarkDescription`}
              rows={1}
              autoHeight={true}
            />
          </div>

          <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:h-12">
            {t`itProjects.detail.labels.activateProject`}
          </div>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <Checkbox name="activateProject" />
          </div>
        </div>
        <div className="pt-6 sm:pt-5">
          <div role="group" aria-labelledby="label-notifications">
            <div className="flex items-center justify-center">
              <SubmitButton
              // disabled={!employee}
              >{t`itProjects.dashboard.actions.submitButton`}</SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </Dialog>
  );
};
export default IdeaReleaseDialog;
