import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { BreadcrumbsProvider } from 'components/Breadcrumbs/Provider';

import MainLayout from './MainLayout';
import ProvideAppContext from './AppContext';
import { msalInstance } from 'index';

type AppProps = {
  pca: IPublicClientApplication;
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_BASE_URL + '/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  const accounts = msalInstance.getAllAccounts();
  const getToken = await msalInstance.acquireTokenSilent({
    scopes: ['User.Read'],
    account: accounts[0],
  });

  return {
    headers: {
      ...headers,
      authorization: getToken ? `Bearer ${getToken.idToken}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Allocation: {
        fields: {
          projects: {
            merge(
              existing: any[],
              incoming: any[],
              { readField, mergeObjects },
            ) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

const App = ({ pca }: AppProps) => {
  const removePaddingFromLayout =
    window.location.pathname.split('/')[1] === 'powerapp';
  return (
    <MsalProvider instance={pca}>
      <ProvideAppContext>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <ApolloProvider client={client}>
            <BreadcrumbsProvider>
              <MainLayout removePadding={removePaddingFromLayout} />
            </BreadcrumbsProvider>
          </ApolloProvider>
        </MsalAuthenticationTemplate>
      </ProvideAppContext>
    </MsalProvider>
  );
};

export default App;
