import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GeneralCostElementRow from './GeneralCostElementRow';
import GeneralCostSumRow from './GeneralCostSumRow';
import FinancialHeadRow from '../../financialData/FinancialHeadRow';
import { Button } from '../../../stories/Button';

export type GeneralCostValue = {
  id: string;
  sum?: number;
  year?: number;
};

export type GeneralCostTableRow = {
  costTypeId: string;
  isSummary: boolean;
  isExtended: boolean;
  costSubtypeId?: string;
  values: GeneralCostValue[];
  estimatedCost?: GeneralCostValue;
};

type GeneralCostSumTableProps = {
  generalCostData: any;
  costTypeData: any;
  tData?: GeneralCostTableRow[];
  isUpdating?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  handleSaveTableData?: (
    tableData: GeneralCostTableRow[],
    initialTable: GeneralCostTableRow[],
  ) => void;
  handleSubmit?: (data: { tableData: GeneralCostTableRow[] }) => void;
};

const GeneralCostSumTable = (props: GeneralCostSumTableProps) => {
  const {
    generalCostData,
    costTypeData,
    tData,
    isUpdating,
    isRequired,
    isDisabled,
    handleSaveTableData,
    handleSubmit,
  } = props;
  const { t } = useTranslation();

  const [firstValueIndex, setFirstValueIndex] = useState<number>(0);

  useEffect(() => {
    tData && setExtendedTypes(getExtendedTypes(tData));
    setTableData(tData ? createTableDataCopy(tData) : () => createTableState());
    setInitialTable(
      tData ? createTableDataCopy(tData) : () => createTableState(),
    );
  }, [generalCostData]);

  const createEmptyTable = () => {
    const emptyTable: GeneralCostTableRow[] = [];
    costTypeData.forEach(a => {
      const newTypeRow: GeneralCostTableRow = {
        costTypeId: a.id,
        isSummary: true,
        isExtended: extendedTypes.includes(a.id),
        values: [],
      };
      emptyTable.push(newTypeRow);

      const subTypes = costTypeData.find(x => x.id === a.id);
      const mergedArray = Array.from(
        new Set(subTypes.GerneralItCosts.map(s => s.costSubtypeId)),
      ).map(costSubtypeId => {
        return {
          costSubtypeId: costSubtypeId,
        };
      });

      mergedArray.forEach(c => {
        const newSubTypeRow: GeneralCostTableRow = {
          costTypeId: a.id,
          isSummary: false,
          isExtended: false,
          costSubtypeId: String(c.costSubtypeId),
          values: [],
        };
        emptyTable.push(newSubTypeRow);
      });
    });
    return emptyTable;
  };

  const addMissingZeros = (data: GeneralCostTableRow[], years: number[]) => {
    data.forEach(d => {
      years.forEach(y => {
        if (!d.values?.some(v => v.year === y)) {
          const newZero: GeneralCostValue = {
            id: 'key-' + d.costTypeId + '-' + d.costSubtypeId + '-' + y,
            sum: 0,
            year: y,
          };
          d.values?.push(newZero);
        }
      });
      d.values?.sort((a, b) => a.year! - b.year!);
    });
  };

  const calcSummaryValues = (data: GeneralCostTableRow[]) => {
    data
      .filter(s => s.isSummary)
      .forEach(s => {
        const values: GeneralCostValue[] = [];
        data
          .filter(d => d.costTypeId === s.costTypeId && !d.isSummary)
          .forEach(d => {
            d.values?.forEach(costSubTypeData => {
              const exisitingValue = values.find(
                v => v.year === costSubTypeData.year,
              );
              if (!exisitingValue) {
                values.push({
                  id: 'key-summary' + d.costTypeId + '-' + costSubTypeData.year,
                  sum: costSubTypeData.sum ? +costSubTypeData.sum : 0,
                  year: costSubTypeData.year,
                });
              } else {
                exisitingValue.sum! += costSubTypeData.sum
                  ? +costSubTypeData.sum
                  : 0;
              }
            });
          });
        values.sort((a, b) => a.year! - b.year!);
        s.values = values;
      });
  };

  const calcEstimatedCost = (data: GeneralCostTableRow[]) => {
    data.forEach(d => {
      let estimatedCost: number = 0;
      d.values?.forEach(v => {
        estimatedCost += v.sum ? +v.sum : 0;
      });
      d.estimatedCost = {
        id: 'key-estimated-' + d.costTypeId + '-' + d.costSubtypeId,
        sum: +estimatedCost,
      };
    });
  };

  const addFutureYears = (y: number[]) => {
    const currentYear = y[0];
    for (let i = Math.min(...y); i < currentYear + 4; i++) {
      if (!y.includes(i)) {
        y.push(i);
      }
    }
  };

  const getYears = (tData?: GeneralCostTableRow[]) => {
    const y: number[] = years;
    let resetIndex: boolean = false;
    if (tData) {
      tData[0].values.forEach(v => {
        if (!y.includes(v.year!)) {
          y.push(v.year!);
          resetIndex = true;
        }
      });
    } else {
      generalCostData.forEach(d => {
        if (!y.includes(d.year)) {
          y.push(d.year);
          resetIndex = true;
        }
      });
      if (generalCostData.length === 0) resetIndex = true;
      addFutureYears(y);
    }
    y.sort();
    if (resetIndex) {
      const now = new Date().getFullYear();
      setFirstValueIndex(y.length > 4 ? y.findIndex(y => y === now) - 1 : 0);
    }
    return y;
  };

  const [years, setYears] = useState<number[]>([new Date().getFullYear()]);

  const createTableState = () => {
    const y = getYears();
    setYears(y);

    const tableInitial: GeneralCostTableRow[] = createEmptyTable();

    generalCostData
      .filter(
        s => s.deletedBy === null /*entry => {
      return (
        entry.projectStatus?.filter(s => s.deletedBy === null) ?? []
      )
    }*/,
      )
      .forEach(d => {
        const rowExists = tableInitial.find(
          t =>
            t.costTypeId === d.costTypeId &&
            t.costSubtypeId === d.costSubtypeId,
        );
        if (rowExists) {
          const newValue: GeneralCostValue = {
            id: d.id,
            sum: d.sum,
            year: d.year,
          };
          rowExists.values?.push(newValue);
        }
      });
    addMissingZeros(tableInitial, y);
    calcSummaryValues(tableInitial);
    calcEstimatedCost(tableInitial);
    return tableInitial;
  };

  const getExtendedTypes = (tData: GeneralCostTableRow[]) => {
    const types: string[] = [];
    tData.forEach(d => {
      if (d.isSummary && d.isExtended) types.push(d.costTypeId);
    });
    return types;
  };

  const createTableDataCopy = (
    tData: GeneralCostTableRow[],
    localExtended?: boolean,
  ) => {
    if (years.length !== 4) setYears(getYears(tData));
    const newData: GeneralCostTableRow[] = [];
    tData.forEach(d => {
      const newObject: GeneralCostTableRow = deepCopy(d);
      newObject.values = deepCopy(d.values);
      if (localExtended) {
        newObject.isExtended = extendedTypes.includes(d.costTypeId);
      }
      newData.push(newObject);
    });
    return newData;
  };

  const deepCopy = obj => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = deepCopy(item);
        return arr;
      }, []);
    }

    if (obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = deepCopy(obj[key]);
        return newObj;
      }, {});
    }
  };

  const [extendedTypes, setExtendedTypes] = useState<string[]>(
    tData ? getExtendedTypes(tData) : [],
  );
  const [tableData, setTableData] = useState<GeneralCostTableRow[]>(
    tData ? createTableDataCopy(tData) : () => createTableState(),
  );
  const [initialTable, setInitialTable] = useState<GeneralCostTableRow[]>(
    tData ? createTableDataCopy(tData) : () => createTableState(),
  );

  const getSum = (data: GeneralCostTableRow[]) => {
    const sumValues: GeneralCostValue[] = [];
    data
      .filter(q => q.isSummary)
      .forEach(d => {
        d.values?.forEach((data: GeneralCostValue) => {
          const value = sumValues.find(v => v.year === data.year);
          if (!value) {
            const newValue: GeneralCostValue = {
              id: 'key-general-' + data.year,
              sum: data.sum ? +data.sum : 0,
              year: data.year,
            };
            sumValues.push(newValue);
          } else {
            value.sum! += data.sum ? +data.sum : 0;
          }
        });
      });
    const row: GeneralCostTableRow = {
      costTypeId: 'pages.financial.sumG',
      isSummary: false,
      isExtended: false,
      values: sumValues,
    };
    calcEstimatedCost([row]);
    return row;
  };

  const [sumProjectcosts, setSumProjectcosts] = useState<GeneralCostTableRow>(
    getSum(tableData),
  );

  const getIsTableEmpty = () => {
    let isEmpty = true;
    tableData.forEach(row => {
      row.values.forEach(v => {
        if (v.sum !== 0) {
          isEmpty = false;
        }
      });
    });
    return isEmpty;
  };

  const [isTableEmpty, setIsTableEmpty] = useState(getIsTableEmpty());

  useEffect(() => {
    setSumProjectcosts(getSum(tableData));
    setIsTableEmpty(getIsTableEmpty());
  }, [tableData]);

  const maxFirstValueIndex = years.length - 4;

  const handleBack = () => {
    if (firstValueIndex > 0) {
      setFirstValueIndex(firstValueIndex - 1);
    }
  };

  const handleForward = () => {
    if (firstValueIndex < maxFirstValueIndex) {
      setFirstValueIndex(firstValueIndex + 1);
    }
  };

  const handleExtend = (id: string) => {
    setTableData(
      [...tableData].map(object => {
        if (object.costTypeId === id) {
          return {
            ...object,
            isExtended: !object.isExtended,
          };
        } else {
          return object;
        }
      }),
    );
    setInitialTable(
      [...initialTable].map(object => {
        if (object.costTypeId === id) {
          return {
            ...object,
            isExtended: !object.isExtended,
          };
        } else {
          return object;
        }
      }),
    );
    setExtendedTypes(
      extendedTypes.includes(id)
        ? extendedTypes.filter(a => a !== id)
        : [...extendedTypes, id],
    );
  };

  const handleInput = (value: number, id: string) => {
    const newTableData = [...tableData].map(object => {
      const index: number = object.values!.findIndex(v => v.id === id);
      const newValues: GeneralCostValue[] = object.values!;
      if (index >= 0) {
        newValues[index].sum = value;
        return {
          ...object,
          values: newValues,
        };
      } else {
        return object;
      }
    });
    calcSummaryValues(newTableData);
    calcEstimatedCost(newTableData);
    setTableData(newTableData);
  };

  const handleSaveButton = () => {
    handleSaveTableData && handleSaveTableData(tableData, initialTable);
  };

  // showed, when creating a new idea to submit changes
  const handleSubmitButton = () => {
    handleSubmit && handleSubmit({ tableData });
  };

  const displayedRows = (data: GeneralCostTableRow[]) => {
    const returnData: GeneralCostTableRow[] = [];
    let extendedType: string = '';
    data.forEach((element: GeneralCostTableRow) => {
      if (element.isSummary) {
        extendedType = element.isExtended ? element.costTypeId : '';
        returnData.push(element);
      } else {
        if (element.costTypeId === extendedType) {
          returnData.push(element);
        }
      }
    });
    return returnData;
  };

  return (
    <div>
      <table className="table-fixed">
        <thead>
          <FinancialHeadRow
            years={years}
            distributedTitle={t(`pages.financial.distributionG`)}
            plannedTitle={t(`pages.financial.plannedG`)}
            firstValueIndex={firstValueIndex}
            showForward={firstValueIndex !== maxFirstValueIndex}
            handleBack={handleBack}
            handleForward={handleForward}
          />
        </thead>
        <tbody>
          {displayedRows(tableData).map((data, index) => {
            return (
              <GeneralCostElementRow
                data={data}
                lastSavedData={displayedRows(initialTable)[index]}
                firstValueIndex={firstValueIndex}
                darker={index % 2 === 1}
                handleExtend={handleExtend}
                handleInput={handleInput}
                key={index}
                isDisabled={true}
              />
            );
          })}
          <tr>
            <td />
          </tr>
          <GeneralCostSumRow
            data={sumProjectcosts}
            firstValueIndex={firstValueIndex}
          />
        </tbody>
      </table>
      <div className="flex justify-end gap-8 py-8">
        {handleSaveTableData && !isDisabled && (
          <Button
            disabled={isUpdating}
            theme="primary"
            onClick={handleSaveButton}
          >
            {t('pages.financial.save')}
          </Button>
        )}
        {handleSubmit && !isDisabled && (
          <Button
            disabled={isRequired && isTableEmpty}
            theme="primary"
            onClick={handleSubmitButton}
          >
            {t('myItIdea.financial-data.submit')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GeneralCostSumTable;
