import { Trans, useTranslation } from 'react-i18next';
import { useRemoveApproverRecipientMutation } from '../../../graphql/codegen/graphql';
import Dialog from '../../common/Dialog';
import { Button } from '../../common/Button';
import React from 'react';
import { EditDialogProps } from '../../../pages/Dashboard/ApproverRecipient';

const DeleteDialog = ({
  editApproverRecipient,
  onClose,
}: Omit<EditDialogProps, 'mode'>) => {
  const { t } = useTranslation();
  const [removeApproverRecipient] = useRemoveApproverRecipientMutation({
    refetchQueries: ['ApproverRecipients'],
  });

  const handleConfirm = () => {
    if (editApproverRecipient?.id)
      removeApproverRecipient({ variables: { id: editApproverRecipient?.id } });
  };

  return (
    <Dialog
      open={!!editApproverRecipient}
      onClose={onClose}
      title={t`itProjects.dashboard.recipientApproverPage.delete`}
    >
      <div className="space-y-4">
        <Trans
          t={t}
          i18nKey="itProjects.dashboard.recipientApproverPage.deleteMessage"
        >
          t<b>{{ value: editApproverRecipient?.kid }}</b>
        </Trans>
        <div>
          <Button theme={'danger'} onClick={handleConfirm}>
            {t`itProjects.dashboard.recipientApproverPage.deleteConfirm`}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
