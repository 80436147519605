import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApproverRecipientsQuery,
  useApproverRecipientsQuery,
} from 'graphql/codegen/graphql';

import { DialogProps } from 'components/common/Dialog';
import Table, { TableColumn } from 'components/NewTable';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Button } from 'components/common/Button';
import CreateEditDialog from '../../components/dashboard/ApproverRecipient/CreateEditDialog';
import DeleteDialog from '../../components/dashboard/ApproverRecipient/DeleteDialog';

export interface EditDialogProps {
  editApproverRecipient:
    | null
    | ApproverRecipientsQuery['approverRecipients'][number];
  onClose: DialogProps['onClose'];
  mode: 'create' | 'edit';
}

const ApproverRecipientPage = () => {
  const { t } = useTranslation();

  const [editApproverRecipient, setEditApproverRecipient] = useState<
    string | null
  >(null);
  const [deleteApproverRecipient, setDeleteApproverRecipient] = useState<
    string | null
  >(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { data: approverRecipientsData } = useApproverRecipientsQuery();

  const columns: TableColumn<
    ApproverRecipientsQuery['approverRecipients'][number]
  >[] = [
    {
      label: t`itProjects.dashboard.recipientApproverPage.kid`,
      render: data => <div>{data.kid}</div>,
    },
    {
      label: t`itProjects.dashboard.recipientApproverPage.givenName`,
      render: data => <div>{data.firstName}</div>,
    },
    {
      label: t`itProjects.dashboard.recipientApproverPage.surname`,
      render: data => <div>{data.lastName}</div>,
    },
    {
      label: t`itProjects.dashboard.recipientApproverPage.process`,
      render: data => <div>{data.approverProcess.description}</div>,
    },
    {
      label: '',
      render: data => (
        <div className="flex items-center justify-evenly -m-4">
          <div
            onClick={() =>
              setEditApproverRecipient(
                editApproverRecipient === data.id ? null : data.id,
              )
            }
            className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200"
          >
            <FontAwesomeIcon icon={faPen} />
          </div>
          <div
            onClick={() =>
              setDeleteApproverRecipient(
                deleteApproverRecipient === data.id ? null : data.id,
              )
            }
            className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200"
          >
            <FontAwesomeIcon className="text-red-600" icon={faTrash} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb
        label={t`itProjects.dashboard.actions.recipient-approver`}
        route="/approver"
      />
      <div className="flex items-center justify-end mb-2">
        <Button
          onClick={() => setOpenCreateDialog(true)}
        >{t`itProjects.dashboard.recipientApproverPage.create`}</Button>
      </div>
      <Table
        columns={columns}
        data={approverRecipientsData?.approverRecipients || []}
      />
      {editApproverRecipient ? (
        <CreateEditDialog
          editApproverRecipient={
            approverRecipientsData?.approverRecipients.find(
              entry => entry.id === editApproverRecipient,
            ) || null
          }
          onClose={() => setEditApproverRecipient(null)}
          mode="edit"
        />
      ) : null}
      {deleteApproverRecipient ? (
        <DeleteDialog
          editApproverRecipient={
            approverRecipientsData?.approverRecipients.find(
              entry => entry.id === deleteApproverRecipient,
            ) || null
          }
          onClose={() => setDeleteApproverRecipient(null)}
        />
      ) : null}
      <CreateEditDialog
        editApproverRecipient={
          openCreateDialog
            ? {
                id: '',
                firstName: '',
                approverProcess: { description: '', id: '' },
                kid: '',
                lastName: '',
              }
            : null
        }
        onClose={() => setOpenCreateDialog(false)}
        mode="create"
      />
    </div>
  );
};
export default ApproverRecipientPage;
