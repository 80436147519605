import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, ButtonProps } from 'components/common/Button';

export type FormSubmitButtonProps = PropsWithChildren<{
  disabled?: boolean;
  theme?: ButtonProps['theme'];
}>;
function FormSubmitButton({
  children,
  disabled,
  theme,
}: FormSubmitButtonProps | ButtonProps) {
  const { formState } = useFormContext();

  const isDisabled = !formState.isValid || formState.isValidating;
  return (
    <Button
      theme={theme ?? 'primary'}
      disabled={disabled || isDisabled}
      type="submit"
    >
      {children}
    </Button>
  );
}
export default FormSubmitButton;
