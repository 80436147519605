import {
  components,
  GroupBase,
  SingleValueProps as ReactSelectSingleValueProps,
} from 'react-select';
import './SingleValue.css';

export type SingleValueProps<TOption> = ReactSelectSingleValueProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function SingleValue<TOption>({
  children,
  ...rest
}: SingleValueProps<TOption>) {
  return (
    <components.SingleValue {...rest} className="dropdown-single-value">
      {children}
    </components.SingleValue>
  );
}
export default SingleValue;
