import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import Table, { TableColumn } from 'components/NewTable';
import {
  LandingApplicationClustersQuery,
  LandingApplicationsQuery,
  useLandingApplicationClustersLazyQuery,
  useLandingApplicationsLazyQuery,
} from 'graphql/codegen/graphql';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../components/common/Button';

import { useTranslation } from 'react-i18next';
import CreateEditDialog from '../../components/dashboard/LandingPageEdit/CreateEditDialog';
import DeleteDialog from '../../components/dashboard/LandingPageEdit/DeleteDialog';
import AuthenticationMessage from '../../AuthenticationMessage';
import { Alert } from 'react-bootstrap';

export type IIcon = {
  id: string;
  name?: string;
  altText?: string;
  location?: string;
  file?: File;
};

function LandingApplicationsEdit() {
  const { t } = useTranslation();
  const [mode, setMode] = useState<{
    action:
      | 'None'
      | 'Create'
      | 'Update'
      | 'Delete'
      | 'CreateCluster'
      | 'UpdateCluster'
      | 'DeleteCluster';
    applicationId: string;
  }>({ action: 'None', applicationId: '' });

  const [fetchLandingApplications, { data: landingAppsData }] =
    useLandingApplicationsLazyQuery({});
  const [fetchLandingApplicationCluster, { data: landingAppsClusterData }] =
    useLandingApplicationClustersLazyQuery({});

  const [switchTable, setSwitchTable] = useState(true);

  const landingApplications =
    landingAppsData?.landingApplications.filter(a => a.isActive === true) ?? [];
  const landingApplicationClusters =
    landingAppsClusterData?.landingApplicationClusters.filter(
      a => a.isActive === true,
    ) ?? [];

  const columns: TableColumn<
    LandingApplicationsQuery['landingApplications'][number]
  >[] = [
    {
      label: t`itProjects.landingApplication.name`,
      render: data => <div>{data.name}</div>,
    },
    {
      label: t`itProjects.landingApplication.description`,
      render: data => <div>{data.label}</div>,
    },
    {
      label: '',
      render: data => (
        <div className="flex items-center justify-evenly -m-4">
          <div className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200">
            <button
              onClick={() => {
                setMode({
                  action: 'Update',
                  applicationId: data.id,
                });
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
          </div>
          <div className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200">
            <button
              onClick={() => {
                setMode({ action: 'Delete', applicationId: data.id });
              }}
            >
              <FontAwesomeIcon className="text-red-600" icon={faTrash} />
            </button>
          </div>
        </div>
      ),
    },
  ];

  const columnsCluster: TableColumn<
    LandingApplicationClustersQuery['landingApplicationClusters'][number]
  >[] = [
    {
      label: t`itProjects.landingApplication.name`,
      render: data => <div>{data.clusterName}</div>,
    },
    {
      label: t`itProjects.landingApplication.description`,
      render: data => <div>{data.label}</div>,
    },
    {
      label: '',
      render: data => (
        <div className="flex items-center justify-evenly -m-4">
          {data.id !== 'startseite' ? (
            <div className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200">
              <button
                onClick={() => {
                  setMode({
                    action: 'UpdateCluster',
                    applicationId: data.id,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
          ) : null}
          {data.id !== 'startseite' ? (
            <div className="cursor-pointer  flex items-center justify-center w-10 h-10 rounded-full hover:bg-slate-200">
              <button
                onClick={() => {
                  setMode({ action: 'DeleteCluster', applicationId: data.id });
                }}
              >
                <FontAwesomeIcon className="text-red-600" icon={faTrash} />
              </button>
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (authenticated) {
      fetchLandingApplications();
      fetchLandingApplicationCluster();
    }
  }, [authenticated]);

  if (AuthenticationMessage('it-projects')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div>
      <Breadcrumb
        label={t`itProjects.dashboard.actions.landingApplications`}
        route="/applications"
      />
      <div className={'grid grid-cols-2 py-10'}>
        <div className="justify-self-start">
          <Button
            theme={'secondary'}
            name={'switchLandingApplications'}
            onClick={() => setSwitchTable(!switchTable)}
          >
            {switchTable
              ? t`itProjects.applications.showLandingApplicationCluster`
              : t`itProjects.applications.showLandingApplications`}
          </Button>
        </div>
        <div className="justify-self-end">
          {switchTable ? (
            <Button
              theme={'primary'}
              name={'addLandingApplication'}
              onClick={() => setMode({ action: 'Create', applicationId: '' })}
            >
              {t`itProjects.applications.newLandingApplication`}
            </Button>
          ) : (
            <Button
              theme={'primary'}
              name={'addLandingApplicationCluster'}
              onClick={() =>
                setMode({ action: 'CreateCluster', applicationId: '' })
              }
            >
              {t`itProjects.applications.newCluster`}
            </Button>
          )}
        </div>
      </div>
      {switchTable ? (
        <div>
          <Table data={landingApplications || []} columns={columns} />
        </div>
      ) : (
        <div>
          <Table
            data={
              landingApplicationClusters?.filter(a => a.isActive === true) || []
            }
            columns={columnsCluster}
          />
        </div>
      )}
      {mode.action === 'Update' ? (
        <CreateEditDialog
          editApplication={
            landingApplications.find(
              entry => entry.id === mode.applicationId,
            ) || null
          }
          onClose={() => setMode({ action: 'None', applicationId: '' })}
          mode="edit"
          landingApplications={landingApplications}
        />
      ) : null}
      {mode.action === 'Create' ? (
        <CreateEditDialog
          editApplication={{
            id: '',
            name: '',
            url: 'https://',
            icon: '',
            tooltip: '',
            order: 0,
            label: '',
          }}
          onClose={() => setMode({ action: 'None', applicationId: '' })}
          mode="create"
          landingApplications={landingApplications}
        />
      ) : null}
      {mode.action === 'Delete' ? (
        <DeleteDialog
          editApplication={
            landingApplications.find(
              entry => entry.id === mode.applicationId,
            ) || null
          }
          mode={mode.action}
          onClose={() => setMode({ action: 'None', applicationId: '' })}
        />
      ) : null}
      {mode.action === 'CreateCluster' ? (
        <CreateEditDialog
          editApplication={{
            id: '',
            name: '',
            url: '',
            icon: '',
            tooltip: '',
            order: 0,
            label: '',
          }}
          onClose={() => setMode({ action: 'None', applicationId: '' })}
          mode="createCluster"
          landingApplications={landingApplicationClusters}
        />
      ) : null}
      {mode.action === 'UpdateCluster' ? (
        <CreateEditDialog
          editApplication={
            landingApplicationClusters.find(
              entry => entry.id === mode.applicationId,
            ) || null
          }
          onClose={() => setMode({ action: 'None', applicationId: '' })}
          mode="editCluster"
          landingApplications={landingApplicationClusters}
        />
      ) : null}
      {mode.action === 'DeleteCluster' ? (
        <DeleteDialog
          editApplication={
            landingApplicationClusters.find(
              entry => entry.id === mode.applicationId,
            ) || null
          }
          mode={mode.action}
          onClose={() => setMode({ action: 'None', applicationId: '' })}
        />
      ) : null}
    </div>
  );
}

export default LandingApplicationsEdit;
