import { useTranslation } from 'react-i18next';
import { Route, Switch, RouteComponentProps } from 'react-router';
import TabBar from 'components/TabBar';
import config from 'config/config';
import GeneralCostPage from './GeneralCostController';
import ProjectCostsPage from './ProjectCosts';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export type ControllerPageProps = RouteComponentProps & {};
const ControllerPage = ({ match }: ControllerPageProps) => {
  const { t } = useTranslation();
  const basePath = config.localRoutes.dashboardController.home;
  return (
    <div className="w-full">
      <Breadcrumbs />
      <Breadcrumb
        label={t`dashboardController.headline`}
        route="/dashboard-controller"
      />
      <div className="mb-5 border-b border-gray-300 pb-5 items-center justify-between">
        <p className="mt-2 max-w-4xl text-sm text-gray-600">
          {t`dashboardController.description`}
        </p>
      </div>

      <TabBar
        isProjectSelected={true}
        data={[
          {
            id: 1,
            text: t`dashboardController.fields.projectCosts`,
            route: `${
              match.url === config.localRoutes.dashboardController.home
                ? match.url
                : config.localRoutes.dashboardController
                    .projectCostsNotSelected + '/project-costs'
            }`,
          },
          {
            id: 2,
            text: t`dashboardController.fields.generalCosts`,
            route: `${config.localRoutes.dashboardController.generalCosts}`,
          },
        ]}
      />

      <Switch>
        <Route path={`${basePath}`} exact component={ProjectCostsPage} />
        <Route
          path={`${config.localRoutes.dashboardController.generalCosts}`}
          exact
          component={GeneralCostPage}
        />
        <Route
          path={`${config.localRoutes.dashboardController.projectCostsNotSelected}`}
          exact
          component={ProjectCostsPage}
        />
        <Route
          path={`${config.localRoutes.dashboardController.projectCosts}`}
          exact
          component={ProjectCostsPage}
        />
      </Switch>
    </div>
  );
};
export default ControllerPage;
