const FRONTEND_BASE_URL = process.env.REACT_APP_BASE_URL;
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const config = {
  defaults: {
    namespace: 'Application',
  },
  localRoutes: {
    landing: '/landing',
    myItIdea: {
      home: '/neue-it-initiative',
      greeting: '/neue-it-initiative/greeting',
      categorize: '/neue-it-initiative/categorize',
      describe1: '/neue-it-initiative/describe1',
      describe2: '/neue-it-initiative/describe2',
      describe3: '/neue-it-initiative/describe3',
      finalize1: '/neue-it-initiative/finalize1',
      finalize2: '/neue-it-initiative/finalize2',
      goodbye: '/neue-it-initiative/goodbye',
    },
    financialData: '/financial-data/:id',
    myItProject: {
      home: '/meine-it-initiative',
      dashboard: '/meine-it-initiative/:projectId',
      view: '/meine-it-initiative/view/:projectId',
      files: '/meine-it-initiative/:projectId/files',
      dt: '/meine-it-initiative/:projectId/dt',
      ism: '/meine-it-initiative/:projectId/ism',
      ds: '/meine-it-initiative/:projectId/ds',
      gbr: '/meine-it-initiative/:projectId/gbr',
      iuk: '/meine-it-initiative/:projectId/iuk',
      auf: '/meine-it-initiative/:projectId/auf',
      completion: '/meine-it-initiative/:projectId/completion',
    },
    dashboard: {
      home: '/it-projects',
      detail: '/it-projects/detail/:projectId',
    },
    tdReporting: {
      home: '/td-reporting',
      projectselection: '/td-reporting/:projectId/projectselection',
      information: '/td-reporting/:projectId/information',
      projectoverview: '/td-reporting/:projectId/overview',
      general: '/td-reporting/:projectId/general',
      rescourceplaning: '/td-reporting/:projectId/resourceplaning',
      financialdata: '/td-reporting/:projectId/financialdata',
      projectcosts: '/td-reporting/:projectId/financialdata/projectcosts',
      operatingcosts: '/td-reporting/:projectId/financialdata/operatingcosts',
      budget: '/td-reporting/:projectId/budget',
      interfaces: '/td-reporting/:projectId/interfaces',
      editInterface: '/td-reporting/:projectId/interfaces',
      risks: '/td-reporting/:projectId/risks',
      report: '/td-reporting/:projectId/report',
    },
    dashboardController: {
      home: '/dashboard-controller',
      generalCosts: '/dashboard-controller/general-costs',
      projectCosts: '/dashboard-controller/:projectId/project-costs',
      projectCostsNotSelected: '/dashboard-controller/project-costs',
    },
    dashboardProject: {
      home: '/dashboard-projects',
    },
    myItInventory: {
      home: '/my-it-inventory',
    },
    apus: '/apus',
    itLandingPage: '/it-landing-page',
  },
  endpoints: {
    LANDING: FRONTEND_BASE_URL + '/landing',
    MY_IT_IDEA: FRONTEND_BASE_URL + '/neue-it-initiative',
    FINANCIAL_DATA: FRONTEND_BASE_URL + '/financial-data',
    POWERAPP: FRONTEND_BASE_URL + '/powerapp',
    APUS: FRONTEND_BASE_URL + '/apus',
  },
};

export default config;

export const getBackendUrl = (path?: string) =>
  new URL(path || '', BACKEND_BASE_URL);

export const getFrontendUrl = (path?: string) =>
  new URL(path || '', FRONTEND_BASE_URL);
