import useTypedForm from '../../common/Form/components';
import { useTranslation } from 'react-i18next';
import {
  ProjectPhasesQuery,
  ReportingGetProjectByIdQuery,
  useProjectPhasesLazyQuery,
  useUpdateProjectPhaseCaptureMutation,
} from '../../../graphql/codegen/graphql';
import { parseISO } from 'date-fns';
import Dialog from '../../common/Dialog';
import Dropdown from '../../common/Form/components/Dropdown/variants/Default';
import React, { useEffect, useState } from 'react';

export interface DropdownOptions {
  label: string | undefined;
  value: string | undefined;
}

interface editProjectPlanningDialogProps {
  editProjectPlanning: string;
  onClose: () => void;
  data: ReportingGetProjectByIdQuery | undefined;
}

interface FormValues {
  projectComment: string;
  projectDescription: string | undefined;
  projectStartDate: Date;
  projectEndDate: Date;
}

const MAX_LENGTH = 200;
const EditProjectPlanningDialog = ({
  editProjectPlanning,
  onClose,
  data,
}: editProjectPlanningDialogProps) => {
  const { Form, TextArea, DatePicker, SubmitButton } =
    useTypedForm<FormValues>();
  const { t } = useTranslation();

  const [projectPhaseValue, setProjectPhaseValue] = useState<DropdownOptions[]>(
    [],
  );

  const [updateProjectPhaseCapture] = useUpdateProjectPhaseCaptureMutation({
    refetchQueries: ['reportingGetProjectById'],
  });

  const [
    fetchProjectPhases,
    { data: projectPhasesData, loading: projectPhasesLoading },
  ] = useProjectPhasesLazyQuery({
    variables: {
      where: {
        phaseOnType: {
          some: {
            projectTypeId: {
              equals: data?.masters[0]?.projectDescriptions?.[0].projectType.id,
            },
          },
        },
      },
    },
  });

  const handleUpdateProjectPhaseCapture = (phaseCaptureData: FormValues) => {
    updateProjectPhaseCapture({
      variables: {
        data: {
          projectPhase: {
            connect: {
              id: phaseCaptureData.projectDescription,
            },
          },
          comment: {
            set: phaseCaptureData.projectComment,
          },
          startDate: {
            set: phaseCaptureData.projectStartDate,
          },
          endDate: {
            set: phaseCaptureData.projectEndDate,
          },
        },
        where: {
          id: editProjectPlanning,
        },
      },
    });
  };

  const parseDate = (data: Date | undefined) => {
    if (data ?? '') {
      return parseISO(data?.toString()!);
    }
  };

  const textFilterPattern = /^[\p{L}\p{P}0-9_\- ]{1,200}$/u;

  const [startDate, setStartDate] = useState<{
    projectStartDate: Date | undefined;
  }>({ projectStartDate: new Date() });
  const [endDate, setEndDate] = useState<{ projectEndDate: Date | undefined }>({
    projectEndDate: new Date(),
  });

  useEffect(() => {
    setStartDate({
      projectStartDate: parseDate(
        data?.masters[0]?.projectPhaseCapture?.find(
          p => p.id === editProjectPlanning,
        )?.startDate,
      ),
    });
    setEndDate({
      projectEndDate: parseDate(
        data?.masters[0]?.projectPhaseCapture?.find(
          p => p.id === editProjectPlanning,
        )?.endDate,
      ),
    });
  }, [data, editProjectPlanning]);

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 2);

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 2);

  const mapProjectPhases = (projectPhases: ProjectPhasesQuery | undefined) => {
    let occupied: string[] = [];
    data?.masters[0].projectPhaseCapture?.forEach(c =>
      occupied.push(c.projectPhaseId),
    );
    if (projectPhases !== undefined) {
      return projectPhases?.projectPhases
        .filter(p => !occupied.includes(p.id))
        .map(p => {
          return { label: p.id, value: p.id };
        });
    }
  };

  const handleSelect = (projectPhase: DropdownOptions | null) => {
    if (projectPhase !== null) setProjectPhaseValue([projectPhase]);
  };

  const handleSubmitPlanning = (data: FormValues) => {
    data.projectDescription = projectPhaseValue[0].value;
    handleUpdateProjectPhaseCapture(data);
    onClose();
  };

  const [updateCounter, setUpdateCounter] = useState<number>(0);

  const countUpdateInput = e => {
    setUpdateCounter(e.target.value.length);
  };

  useEffect(() => {
    fetchProjectPhases();
    setProjectPhaseValue([
      {
        label: data?.masters[0]?.projectPhaseCapture?.find(
          p => p.id === editProjectPlanning,
        )?.projectPhaseId,
        value: data?.masters[0]?.projectPhaseCapture?.find(
          p => p.id === editProjectPlanning,
        )?.projectPhaseId,
      },
    ]);
  }, [editProjectPlanning]);

  return (
    <Dialog
      open={!!editProjectPlanning}
      onClose={onClose}
      title={'Projektplanung bearbeiten'}
    >
      <div>
        <Form
          onSubmit={handleSubmitPlanning}
          defaultValues={{
            ...startDate,
            ...endDate,
            projectDescription:
              data?.masters[0]?.projectPhaseCapture?.find(
                p => p.id === editProjectPlanning,
              )?.projectPhaseId ?? '',
            projectComment:
              data?.masters[0]?.projectPhaseCapture?.find(
                p => p.id === editProjectPlanning,
              )?.comment ?? '',
          }}
          mode="onChange"
        >
          <div className="space-y-4">
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-2/6">
                {t`tdReporting.projectoverviewPage.projectDescription` + ':'}
              </div>
              <div className="w-full">
                <Dropdown
                  variant="default"
                  className="text-sm"
                  loading={projectPhasesLoading}
                  value={projectPhaseValue}
                  options={mapProjectPhases(projectPhasesData)}
                  onChange={handleSelect}
                />
              </div>
            </div>

            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-2/6">
                {t`tdReporting.projectoverviewPage.comment` + ':'}
              </div>
              <div className="w-full">
                <TextArea
                  name={'projectComment'}
                  rows={2}
                  autoHeight={true}
                  onChange={countUpdateInput}
                  rules={{
                    required: true,
                    pattern: {
                      value: textFilterPattern,
                      message: t`tdReporting.projectResourcePage.errorMessageUpdate`,
                    },
                  }}
                />
                <p className="text-gray-500 text-sm justify-right text-right">
                  {`${updateCounter}/${MAX_LENGTH}`}
                </p>
              </div>
            </div>

            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-2/6">
                {t`tdReporting.projectoverviewPage.projectStartDate` + ':'}
              </div>
              <div className="w-full">
                <DatePicker
                  name="projectStartDate"
                  onChange={value =>
                    value && setStartDate({ projectStartDate: value })
                  }
                  rules={{
                    required: true,
                    validate: () => {
                      return startDate.projectStartDate &&
                        endDate.projectEndDate
                        ? startDate.projectStartDate < endDate.projectEndDate
                        : false;
                    },
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            </div>

            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-2/6">
                {t`tdReporting.projectoverviewPage.projectEndDate` + ':'}
              </div>
              <div className="w-full">
                <DatePicker
                  name="projectEndDate"
                  onChange={value =>
                    value && setEndDate({ projectEndDate: value })
                  }
                  rules={{
                    required: true,
                    validate: () => {
                      return startDate.projectStartDate &&
                        endDate.projectEndDate
                        ? startDate.projectStartDate < endDate.projectEndDate
                        : false;
                    },
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                {(startDate.projectStartDate && endDate.projectEndDate
                  ? startDate.projectStartDate > endDate.projectEndDate
                  : false) && (
                  <p className="text-red-600">{t`tdReporting.projectResourcePage.endDateNotice`}</p>
                )}
              </div>
            </div>
            <div className="pt-5 flex items-end justify-end">
              <SubmitButton>{t`tdReporting.projectoverviewPage.saveButton`}</SubmitButton>
            </div>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};
export default EditProjectPlanningDialog;
