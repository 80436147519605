import { Link } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/common/Select/Select';
import Table, { TableColumn } from 'components/NewTable';
import {
  DashboardGetFilteredProjectsQuery,
  useDashboardGetFilteredProjectsLazyQuery,
} from 'graphql/codegen/graphql';
import {
  ArrayElement,
  ProjectRolesEnum,
  ProjectStatusEnum,
} from 'graphql/types';

export type ProjectFilter =
  | 'all'
  | 'ideas'
  | 'projects'
  | 'inventory'
  | 'activated'
  | 'declined'
  | 'returned'
  | 'deleted'
  | 'accepted_by_committee'
  | 'accepted_by_committee_and_budget_release'
  | 'budget_release';

export interface ProjectsTableProps {
  filter: ProjectFilter;
  searchName?: string;
  onChange: (filter: ProjectFilter) => void;
}

const ProjectsTable: React.VFC<ProjectsTableProps> = ({
  filter,
  onChange,
  searchName,
}) => {
  const { t } = useTranslation();
  const [fetchProjects, { data, loading }] =
    useDashboardGetFilteredProjectsLazyQuery();

  const nameFilter = useCallback(() => {
    return data?.masters.filter(item => {
      return item.projectDescriptions?.[0].name
        .toLowerCase()
        .includes((searchName || '').toLowerCase());
    });
  }, [data, searchName]);

  const projectStatusChangedFilter = () => {
    let arr = [] as any;
    nameFilter()?.forEach(p => {
      if (
        p.projectStatus?.every(s => {
          if (
            getProjectStatusByFilterType(filter)[0] !==
            'Eingereicht durch IT-Idee'
          ) {
            return true;
          } else {
            return s.deletedBy === null;
          }
        })
      ) {
        arr.push(p);
      }
    });
    return arr;
  };

  useEffect(() => {
    fetchProjects({
      variables: {
        projectStatus: getProjectStatusByFilterType(filter),
      },
    });
  }, [fetchProjects, filter]);

  return (
    <div>
      <div className="mb-5 flex w-full items-center justify-end">
        <Select
          className="w-full md:w-72"
          selected={filter}
          onChange={(value: string) => onChange(value as ProjectFilter)}
          options={[
            {
              value: 'all',
              label: t`itProjects.dashboard.filter.all`,
            },
            {
              value: 'ideas',
              label: t`itProjects.dashboard.filter.ideas`,
            },
            {
              value: 'projects',
              label: t`itProjects.dashboard.filter.projects`,
            },
            // {
            //   value: 'inventory',
            //   label: t`itProjects.dashboard.filter.inventory`,
            // },
            {
              value: 'activated',
              label: t`itProjects.dashboard.filter.activated`,
            },
            {
              value: 'declined',
              label: t`itProjects.dashboard.filter.declined`,
            },
            {
              value: 'returned',
              label: t`itProjects.dashboard.filter.returned`,
            },
            // {
            //   value: 'deleted',
            //   label: t`itProjects.dashboard.filter.deleted`,
            // },
            // {
            //   value: 'accepted_by_committee_and_budget_release',
            //   label: t`itProjects.dashboard.filter.acceptedByCommitteeAndBudgetRelease`,
            // },
          ]}
        />
      </div>
      <Table
        data={projectStatusChangedFilter() ?? []}
        loading={loading}
        columns={getColumnsByFilterType(filter)}
      />
    </div>
  );
};
export default ProjectsTable;

const getEmployeeByRole = (
  data: ArrayElement<DashboardGetFilteredProjectsQuery['masters']>,
  role: ProjectRolesEnum,
) => data?.projectRole?.find(projectRole => projectRole.role.id === role);

const getColumnsByFilterType = (
  filter: ProjectFilter,
): TableColumn<
  ArrayElement<DashboardGetFilteredProjectsQuery['masters']>
>[] => {
  switch (filter) {
    case 'projects':
    case 'ideas':
    case 'activated':
    case 'inventory':
    case 'declined':
    case 'returned':
    case 'deleted':
    case 'accepted_by_committee':
    case 'accepted_by_committee_and_budget_release':
    case 'budget_release':
    case 'all':
      return [
        {
          label: 'Projektname',
          render: data => (
            <Link
              className="font-medium text-primary hover:underline hover:opacity-80"
              to={`/it-projects/detail/${data.id}`}
            >
              {data.projectDescriptions
                ? data.projectDescriptions![0].name
                : null}
            </Link>
          ),
        },
        {
          render: data =>
            getEmployeeByRole(data, ProjectRolesEnum.PROJEKTLEITUNG)?.employee
              .lastName || 'zu benennen',
          label: (
            <span>
              Projektleiter:in
              <br />
              Nachname
            </span>
          ),
        },
        {
          render: data =>
            getEmployeeByRole(data, ProjectRolesEnum.PROJEKTLEITUNG)?.employee
              .firstName || 'zu benennen',
          label: (
            <span>
              Projektleiter:in
              <br />
              Vorname
            </span>
          ),
        },
        {
          render: data =>
            getEmployeeByRole(
              data,
              ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER,
            )?.employee.lastName || 'zu benennen',
          label: (
            <span>
              Technischer Ansprechpartner:in
              <br />
              Nachname
            </span>
          ),
        },
        {
          render: data =>
            getEmployeeByRole(
              data,
              ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER,
            )?.employee.firstName || 'zu benennen',
          label: (
            <span>
              Technischer Ansprechpartner:in
              <br />
              Vorname
            </span>
          ),
        },
        {
          render: data => new Date(data.createdAt).toLocaleDateString(),
          label: `Einreichung`,
        },
      ];
    default:
      return [];
  }
};

const getProjectStatusByFilterType = (
  filter: ProjectFilter,
): ProjectStatusEnum[] => {
  switch (filter) {
    case 'ideas':
      return [ProjectStatusEnum.IDEA];
    case 'projects':
      return [ProjectStatusEnum.IDEA_ACCEPTED];
    case 'inventory':
      return [ProjectStatusEnum.INVENTORY];
    case 'activated':
      return [ProjectStatusEnum.ACTIVATED];
    case 'returned':
      return [ProjectStatusEnum.RETURNED];
    case 'declined':
      return [ProjectStatusEnum.DECLINED];
    case 'deleted':
      return [ProjectStatusEnum.DELETED];
    case 'accepted_by_committee':
      return [ProjectStatusEnum.BOARD_ACCEPTED];
    case 'accepted_by_committee_and_budget_release':
      return [ProjectStatusEnum.BOARD_AND_BUDGET_ACCEPTED];
    case 'budget_release':
      return [ProjectStatusEnum.BUDGET_RELEASE];
    default:
      return [
        ProjectStatusEnum.ACTIVATED,
        ProjectStatusEnum.IDEA,
        ProjectStatusEnum.IDEA_ACCEPTED,
        ProjectStatusEnum.INVENTORY,
        ProjectStatusEnum.BOARD_ACCEPTED,
        ProjectStatusEnum.BOARD_AND_BUDGET_ACCEPTED,
        ProjectStatusEnum.BUDGET_RELEASE,
      ];
  }
};
