import { InputHTMLAttributes } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import UploadButton from 'components/UploadButton';

export type FormUploadButtonProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<UseControllerProps<TFieldValues>, 'control' | 'shouldUnregister'> &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    multiple?: boolean;
    onUpload: (files: FileList | null) => void;
  };
function FormUploadButton<TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  rules,
  multiple = false,
  onUpload,
  children,
  disabled,
}: FormUploadButtonProps<TFieldValues>) {
  const { field } = useController({
    name,
    defaultValue,
    rules,
  });

  const handleUpload = (files: FileList | null) => {
    onUpload(files);
    if (files)
      field.onChange([...Array.from(field.value || []), ...Array.from(files)]);
  };

  return (
    <UploadButton multiple={multiple} onUpload={handleUpload} disabled={disabled}>
      {children}
    </UploadButton>
  );
}
export default FormUploadButton;
