import { Route, Switch } from 'react-router';
import config from 'config/config';
import { useState, useEffect, useCallback } from 'react';

import Background from 'pages/Idea/Background';
import Summary from 'pages/Idea/Summary';
import Flow from 'pages/Idea/Flow';

import { useAppContext } from 'AppContext';

import {
  useDashboardGetProjectByIdLazyQuery,
  useUpdateProjectDescriptionMutation,
  useCreateProjectShortHistoryMutation,
} from 'graphql/codegen/graphql';
import { t } from 'i18next';

export type FormData = {
  id: string;
  projectTypeId: string;
  name: string;
  background?: string;
  procedure?: string;
  description?: string;
  projectStart?: Date;
  projectDuration?: number;
};

export type DescriptionPageProps = {
  projectId: string;
};

const DescriptionPage = ({ projectId }: DescriptionPageProps) => {
  const basePath = config.localRoutes.tdReporting.general.replace(
    ':projectId',
    projectId,
  );
  const [descriptionData, setDescriptionData] = useState<Partial<FormData>>({});
  const [updateProjectDescription] = useUpdateProjectDescriptionMutation();
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();
  const { user } = useAppContext();

  const [getDescriptionData, { data: description }] =
    useDashboardGetProjectByIdLazyQuery();

  const fetchDescriptionData = useCallback(
    (projectId: string) => {
      getDescriptionData({
        variables: {
          id: projectId,
        },
      });
      if (description) {
        if (
          description.masters.length &&
          description.masters[0].projectDescriptions
        ) {
          description.masters[0].projectDescriptions
            .filter(s => s.deletedBy === null)
            .map(descriptions => {
              const initalData = {
                id: descriptions.id,
                projectTypeId: descriptions.projectType.id,
                name: descriptions.name,
                background: descriptions.background ?? undefined,
                procedure: descriptions.procedure ?? undefined,
                description: descriptions.description ?? undefined,
                projectStart: descriptions.projectStart ?? undefined,
                projectDuration: descriptions.projectDuration ?? undefined,
              };
              return setDescriptionData(initalData);
            });
        }
      }
    },
    [getDescriptionData, description],
  );

  useEffect(() => {
    fetchDescriptionData(projectId);
  }, [fetchDescriptionData, projectId]);

  const handleUpdate = (data: string, id: string) => {
    if (data) {
      const actionName = () => {
        switch (id) {
          case 'background':
            return t`tdReporting.subpages.background`;
          case 'summary':
            return t`tdReporting.subpages.summary`;
          case 'flow':
            return t`tdReporting.subpages.flow`;
        }
      };

      createProjectShortHistory({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            action: actionName()!,
            createdBy: user?.kid,
          },
        },
      });

      switch (id) {
        case 'background':
          return updateProjectDescription({
            variables: {
              data: {
                background: {
                  set: data,
                },
              },
              where: {
                id: descriptionData.id,
              },
            },
          });
        case 'summary':
          return updateProjectDescription({
            variables: {
              data: {
                description: {
                  set: data,
                },
              },
              where: {
                id: descriptionData.id,
              },
            },
          });
        case 'flow':
          return updateProjectDescription({
            variables: {
              data: {
                procedure: {
                  set: data,
                },
              },
              where: {
                id: descriptionData.id,
              },
            },
          });
      }
    }
  };

  return (
    <div className="w-full pt-5">
      <Switch>
        <Route
          path={[basePath, 'background'].join('/')}
          exact
          component={() => (
            <Background
              onSubmit={data => {
                setDescriptionData({
                  background: data.background,
                });
                handleUpdate(data.background, 'background');
              }}
              values={{
                background: descriptionData.background ?? undefined,
              }}
            />
          )}
        />
        <Route
          path={[basePath, 'summary'].join('/')}
          exact
          component={() => (
            <Summary
              onSubmit={data => {
                setDescriptionData({
                  description: data.summary,
                });
                handleUpdate(data.summary, 'summary');
              }}
              values={{
                summary: descriptionData.description ?? undefined,
              }}
            />
          )}
        />
        <Route
          path={[basePath, 'flow'].join('/')}
          exact
          component={() => (
            <Flow
              onSubmit={data => {
                setDescriptionData({
                  procedure: data.flow,
                });
                handleUpdate(data.flow, 'flow');
              }}
              values={{
                flow: descriptionData.procedure ?? undefined,
              }}
            />
          )}
        />
      </Switch>
    </div>
  );
};
export default DescriptionPage;
