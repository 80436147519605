import useTypedForm from 'components/common/Form/components';
import DebouncedLookup from 'components/debouncedKidLookup';
import { useEffect, useState } from 'react';
import { ReportPopupTable } from './popupTable';
import { User } from 'microsoft-graph';
import { useGetAddresseeGroupLazyQuery } from 'graphql/codegen/graphql';
import { useAppContext } from 'AppContext';
import { useTranslation } from 'react-i18next';
import { Button } from 'stories/Button';

export type ReportPopupData = {
  title: string;
  reportId: string;
  tooltip?: boolean | undefined;
  onSubmit: (addresseeGroup: AddresseeGroupType[]) => void;
};
export type AddresseeGroupType = {
  createdBy: string;
  employeeId: string;
};

const ReportPopup = ({ title, tooltip, reportId, onSubmit }) => {
  const { t } = useTranslation();

  const { Form } = useTypedForm();
  const [employeeKid, setEmployeeKid] = useState<string>('');
  const { user } = useAppContext();
  const reportIdNumber = Number(reportId);
  const [employee, setEmployee] = useState<User[]>([]);
  const [fetchAddresseeGroups, { data: addresseeGroupData }] =
    useGetAddresseeGroupLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const [addresseeGroup, setAddresseeGroup] = useState<AddresseeGroupType[]>(
    [],
  );

  useEffect(() => {
    fetchAddresseeGroups();
  }, [fetchAddresseeGroups, addresseeGroupData, employee, reportIdNumber]);

  const handleUserInput = (empl: User) => {
    const addresseeKid = empl.userPrincipalName!?.split('@')[0];
    const lastEmployee = employeeKid.includes(addresseeKid);
    const employeeAlreadyListed = employee
      .map(entry => entry.userPrincipalName!.split('@')[0])
      .includes(addresseeKid);

    if (employee.length > 0) {
      if (!employeeAlreadyListed && !lastEmployee) {
        setEmployee([...employee, empl]);
        setAddresseeGroup(prev => [
          ...prev.filter(entries => entries.employeeId !== addresseeKid),
          { employeeId: addresseeKid, createdBy: user!.kid! },
        ]);
      }
    } else {
      setEmployee([...employee, empl]);
      setAddresseeGroup(prev => [
        ...prev.filter(entries => entries.employeeId !== addresseeKid),
        { employeeId: addresseeKid, createdBy: user!.kid! },
      ]);
    }
  };

  const checkEmployeeInList = (kid: string): boolean => {
    return employee
      .map(entry => entry.userPrincipalName!.split('@')[0])
      .includes(kid);
  };

  const hanldeDelete = async (user: User) => {
    fetchAddresseeGroups();
    const addresseeKid = user.userPrincipalName!?.split('@')[0];
    setEmployee(employee.filter(entry => entry.id !== user.id));
    if (addresseeGroupData && reportId) {
      addresseeGroupData.addresseeGroups.filter(
        entry =>
          entry.reportId === reportIdNumber &&
          checkEmployeeInList(entry.employeeId),
      );
      setAddresseeGroup(
        addresseeGroup.filter(entry => entry.employeeId !== addresseeKid),
      );
    }
  };

  return (
    <Form onSubmit={() => console.log('')} mode="onChange">
      <p className="text-sm  pb-8">{t('tdReporting.userGuidance.report')}</p>

      {tooltip ? (
        <ReportPopupTable addressee={employee} />
      ) : (
        <div>
          <DebouncedLookup
            name={title}
            employeeName={employeeKid}
            handleUserInput={user => {
              handleUserInput(user);
            }}
            onInput={value => {
              setEmployeeKid(value);
            }}
          />

          <ReportPopupTable handleDelete={hanldeDelete} addressee={employee} />
        </div>
      )}
      <div className="pt-5 w-full flex items-end justify-end">
        <Button
          onClick={() => onSubmit(addresseeGroup)}
        >{t`tdReporting.report.send`}</Button>
      </div>
    </Form>
  );
};
export default ReportPopup;
