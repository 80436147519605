import { Trans, useTranslation } from 'react-i18next';

import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Button } from 'components/common/Button';
import { StepProps } from '.';
import { useAppContext } from '../../AppContext';

const Greeting = ({ onSubmit }: StepProps) => {
  const { user } = useAppContext();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <Breadcrumb label="Begrüßung" route={'neue-it-initiative'} />

      <div className="flex-1 w-full">
        <p className="text-base">
          <Trans
            i18nKey={'myItIdea.greeting.description'}
            components={{
              mail: (
                <a href="mailto:meine-it@hansewerk.com" className="text-primary" />
              ),
            }}
            values={{ firstName: user?.givenName }}
          />
        </p>
      </div>
      <div className="flex justify-end flex-grow-0 pt-5 space-x-7">
        <Button onClick={() => onSubmit({})}>
          {t('myItIdea.actions.next')}
        </Button>
      </div>
    </div>
  );
};
export default Greeting;
