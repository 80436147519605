import classNames from 'classnames';
import useTypedForm from 'components/common/Form/components';
import ConfirmIcon from 'components/ConfirmIcon';
import MailButton from 'components/MailButton';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  GetMasterWithApprovalsDocument,
  useGetMailInfoByProjectLazyQuery,
  useUpdateApprovalStatusMutation,
} from 'graphql/codegen/graphql';
import { useAppContext } from 'AppContext';
import Checkbox from '../../../components/common/Checkbox';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export interface ApprovalEducationTrainingCommitteeProps {
  approved: boolean;
  approvalStatus: string;
  confirmed: boolean;
  projectId: string;
}

type Mail = {
  cc?: string;
  subject: string;
  body: string;
};

const ApprovalEducationTrainingCommittee = ({
  approved,
  approvalStatus,
  confirmed,
  projectId,
}: ApprovalEducationTrainingCommitteeProps) => {
  const [noConsultationNeeded, setConsultationNeeded] = useState(
    approvalStatus === 'uebersprungen',
  );
  const [consultationConfirmed, setConsultationConfirmed] = useState(
    approvalStatus === 'warten' || approvalStatus === 'angenommen',
  );

  const { Form } = useTypedForm();

  const [updateApprovalStatusMutation] = useUpdateApprovalStatusMutation();
  const [getMailInfo, { data: mailInfoData }] =
    useGetMailInfoByProjectLazyQuery();

  const { user } = useAppContext();

  useEffect(() => {
    getMailInfo({
      variables: {
        where: {
          id: projectId,
        },
        where2: {
          projectId: {
            equals: projectId,
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [noConsultationNeeded, consultationConfirmed]);

  const handleSubmit = () => {};

  const { t } = useTranslation();

  const toggleAgreement = () => {
    if (noConsultationNeeded) return;
    setConsultationNeeded(true);
    updateApprovalStatusMutation({
      variables: {
        data: {
          projectApproval: {
            create: [
              {
                createdBy: user!.kid!,
                ApprovalBoard: { connect: { id: 'auf' } },
                approvalStatus:
                  process.env.NODE_ENV === 'development'
                    ? { connect: { id: 'angenommen' } }
                    : { connect: { id: 'uebersprungen' } },
              },
            ],
          },
        },
        projectId,
      },
      refetchQueries: [GetMasterWithApprovalsDocument],
    });
  };

  const toggleConfirmed = () => {
    if (consultationConfirmed) return;
    setConsultationConfirmed(true);
    updateApprovalStatusMutation({
      variables: {
        data: {
          projectApproval: {
            create: [
              {
                createdBy: user!.kid!,
                ApprovalBoard: { connect: { id: 'auf' } },
                approvalStatus:
                  process.env.NODE_ENV === 'development'
                    ? { connect: { id: 'angenommen' } }
                    : { connect: { id: 'warten' } },
              },
            ],
          },
        },
        projectId,
      },
      refetchQueries: [GetMasterWithApprovalsDocument],
    });
  };

  const [mail, setMail] = useState<Mail>({
    cc: '',
    subject: '',
    body: '',
  });

  useEffect(() => {
    if (
      !mailInfoData?.employeeMasters[0] ||
      !mailInfoData.master.projectDescriptions![0]
    ) {
      const body: string =
        'Lieber Aus- und Fortbildungsausschuss,\n\nbitte schlagen Sie mir Termine für eine Absprache bezüglich der Aus- und Fortbildung für folgendes Projekt vor:\nProjektname: ' +
        '<hier Projektname einfügen>' +
        '\nProjektleiter: <hier Projektleiter einfügen>' +
        '\n\nViele Grüße\n ' +
        user?.givenName +
        ' ' +
        user?.surname;
      setMail({
        subject:
          '[Aus- und Fortbildungsauschuss] - IT-Projekt: <hier Projektname einfügen>',
        body,
      });
      return;
    }

    const cc: string = mailInfoData?.employeeMasters[0].employee.email || '';
    const subject: string = `[Aus- und Fortbildungsauschuss] - IT-Projekt: ${
      mailInfoData?.master.projectDescriptions![0].name
    }`;

    let body: string =
      'Lieber Aus- und Fortbildungsausschuss,\n\nbitte schlagen Sie mir Termine für eine Absprache bezüglich der Aus- und Fortbildung für folgendes Projekt vor:\nProjektname: ' +
      mailInfoData?.master.projectDescriptions![0].name +
      '\nProjektleiter: ';
    const projectLead: string =
      (mailInfoData?.employeeMasters[0].employee.firstName || '') +
      ' ' +
      (mailInfoData?.employeeMasters[0].employee.lastName || '');
    body = body + projectLead;
    body = body + '\n\nViele Grüße\n' + user?.givenName + ' ' + user?.surname;

    setMail({
      cc,
      subject,
      body,
    });
  }, [mailInfoData]);

  return (
    <>
      <Breadcrumb
        label={t`myItProject.steps.auf`}
        route={`/meine-it-initiative/${projectId}/auf`}
      />
      <Form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <p className="text-xl font-medium">
            <Trans
              i18nKey={'myItProject.educationTrainingCommittee.description'}
            />
            <br />
            {t`myItProject.educationTrainingCommittee.description4`}
          </p>
          <div
            className={classNames({
              'opacity-25': consultationConfirmed,
            })}
          >
            <div className="flex items-center justify-start space-x-5">
              <Checkbox
                disabled={consultationConfirmed || noConsultationNeeded}
                defaultChecked={noConsultationNeeded}
                onChange={toggleAgreement}
              />
              <span className="text-xl">
                {t`myItProject.educationTrainingCommittee.consultLabel`}
              </span>
            </div>
          </div>
          <div
            className={classNames('space-y-12', {
              'opacity-25': noConsultationNeeded, // TODO: Change when meta data are available to distinguish between consultationNeeded and noConsultationNeeded
            })}
          >
            <p className="text-xl font-medium">
              {t`myItProject.educationTrainingCommittee.description2`}
              <br />
              {t`myItProject.educationTrainingCommittee.description5`}
            </p>
            {/*             <MailButton
              email="torsten.sowa@eon.com"
              cc={mail.cc}
              subject={mail.subject}
              body={mail.body}
              theme={noConsultationNeeded ? 'grey-secondary' : 'secondary'}
              disabled={noConsultationNeeded}
            >
              <span>
                {t('myItProject.educationTrainingCommittee.mail_button')}
              </span>
            </MailButton> */}
            <div className="flex items-center space-x-5">
              <div className="flex items-center justify-start space-x-5">
                <Checkbox
                  disabled={noConsultationNeeded || consultationConfirmed}
                  defaultChecked={consultationConfirmed}
                  onChange={toggleConfirmed}
                />
                <span className="text-xl">
                  {t`myItProject.educationTrainingCommittee.confirmedLabel`}
                </span>
              </div>

              {/*<IconSubmitButton*/}
              {/*  active*/}
              {/*  disabled={noConsultationNeeded}*/}
              {/*  approved={confirmed}*/}
              {/*>{t`myItProject.educationTrainingCommittee.confirmedLabel`}</IconSubmitButton>*/}
            </div>
          </div>
          <p className="text-xl font-medium">
            {t`myItProject.educationTrainingCommittee.description3`}
          </p>
          <ConfirmIcon enabled={true} active={approved}>
            {approvalStatus === 'uebersprungen'
              ? t`myItProject.educationTrainingCommittee.approvedLabel2`
              : t`myItProject.educationTrainingCommittee.approvedLabel`}
          </ConfirmIcon>
        </div>
      </Form>
    </>
  );
};
export default ApprovalEducationTrainingCommittee;
