import { PropsWithChildren } from 'react';

import FileUploadListItem from 'components/FileUploadList/FileUploadListItem';

interface FileUploadListProps {
  files?: any[];
  change?: any;
  onDelete: (file: any) => void;
  disabled?: boolean;
}

const FileUploadList = ({
  files,
  children,
  change,
  onDelete,
  disabled,
}: PropsWithChildren<FileUploadListProps>) => {
  const handleDelete = (file: any) => {
    onDelete(file);
  };

  const renderFileUploadListItem = (
    <div>
      <div className="pb-2 border-b border-tableDarkGrey">{children}</div>
      {files &&
        files.map(doc => (
          <FileUploadListItem
            key={doc.id}
            name={doc.file ? doc.file.name : doc.name}
            onDelete={() => handleDelete(doc)}
            disabled={disabled}
          />
        ))}
    </div>
  );

  const renderNoDocuments = <div>Keine Dokumente vorhanden.</div>;

  return files && files.length > 0
    ? renderFileUploadListItem
    : renderNoDocuments;
};

export default FileUploadList;
