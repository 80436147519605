import { useTranslation } from 'react-i18next';
import useTypedForm from 'components/common/Form/components';
import {
  GetMasterWithApprovalsDocument,
  SortOrder,
  useCreateProjectQuestionnaireMutation,
  useGetProjectQuestionnaireStatusesQuery,
  useGetProjectQuestionnairesLazyQuery,
  useUpdateApprovalStatusMutation,
} from 'graphql/codegen/graphql';
import ApproverTable from '../../../components/dashboardController/ApproverTable';
import Dropdown from '../../../components/common/Form/components/Dropdown/variants/Default';
import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { Button } from '../../../stories/Button';
import { useAppContext } from 'AppContext';

export interface ApprovalActivationQuestionnaireProps {
  id: number;
  approved: boolean;
  projectId: string;
  confirmed: boolean;
}
const ApprovalActivationQuestionnaire = ({
  id,
  approved,
  projectId,
  confirmed,
}: ApprovalActivationQuestionnaireProps) => {
  const { Form } = useTypedForm();
  const [getProjectQuestionnaires, { data: questionnnaireData }] =
    useGetProjectQuestionnairesLazyQuery();
  const [createProjectQuestionnaire] = useCreateProjectQuestionnaireMutation();
  const [updateApprovalStatusMutation] = useUpdateApprovalStatusMutation();

  const {
    data: ProjectQuestionnaireStatusesData,
    loading: ProjectQuestionnaireStatusesLoading,
  } = useGetProjectQuestionnaireStatusesQuery({
    variables: {
      where: {
        question: {
          not: null,
        },
      },
      orderBy: [
        {
          order: SortOrder.Asc,
        },
      ],
    },
  });

  const { user } = useAppContext();

  const options = [
    { value: 'y', label: 'Ja' },
    { value: 'n', label: 'Nein' },
  ];

  const empty = {
    value: '',
    label: '',
  };

  const [question1, setQuestion1] =
    useState<{ value: string; label: string }>(empty);

  const [question2, setQuestion2] =
    useState<{ value: string; label: string }>(empty);

  const [question3, setQuestion3] =
    useState<{ value: string; label: string }>(empty);

  const [question4, setQuestion4] =
    useState<{ value: string; label: string }>(empty);

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = () => {
    getProjectQuestionnaires({
      variables: {
        where: {
          projectId: {
            equals: projectId,
          },
        },
      },
    }).then(response => {
      if (response.data?.projectQuestionnaires) {
        const questionnaires = response.data.projectQuestionnaires;
        if (questionnaires.length > 0) {
          if (questionnaires[0]) {
            setQuestion1(questionnaires[0].value ? options[0] : options[1]);
          }
          if (questionnaires[1]) {
            setQuestion2(questionnaires[1].value ? options[0] : options[1]);
          }
          if (questionnaires[2]) {
            setQuestion3(questionnaires[2].value ? options[0] : options[1]);
          }
          if (questionnaires[3]) {
            setQuestion4(questionnaires[3].value ? options[0] : options[1]);
          }
        }
      }
    });
  };

  const handleQuestion1 = async question => {
    setQuestion1(question);
    setQuestion2(empty);
    setQuestion3(empty);
    setQuestion4(empty);
  };

  const handleQuestion2 = async question => {
    setQuestion2(question);
    setQuestion3(empty);
    setQuestion4(empty);
  };

  const handleQuestion3 = async question => {
    setQuestion3(question);
    setQuestion4(empty);
  };

  const handleQuestion4 = async question => {
    setQuestion4(question);
  };

  const handleNothing = async () => {};

  const saveQuestionnaire = async () => {
    if (question1.value) {
      await createProjectQuestionnaire({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            projectQuestionnaireStatus: {
              connect: {
                id: 'dauerhaft',
              },
            },
            createdBy: user!.kid!,
            value: question1.value === 'y',
          },
        },
      });
    }

    if (question2.value) {
      await createProjectQuestionnaire({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            projectQuestionnaireStatus: {
              connect: {
                id: 'eigentum',
              },
            },
            createdBy: user!.kid!,
            value: question2.value === 'y',
          },
        },
      });
    }

    if (question3.value) {
      await createProjectQuestionnaire({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            projectQuestionnaireStatus: {
              connect: {
                id: 'einzeln',
              },
            },
            createdBy: user!.kid!,
            value: question3.value === 'y',
          },
        },
      });
    }

    if (question4.value) {
      await createProjectQuestionnaire({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            projectQuestionnaireStatus: {
              connect: {
                id: 'individual',
              },
            },
            createdBy: user!.kid!,
            value: question4.value === 'y',
          },
        },
      });
    }

    await updateApprovalStatusMutation({
      variables: {
        data: {
          projectApproval: {
            create: [
              {
                createdBy: user!.kid!,
                ApprovalBoard: { connect: { id: 'af' } },
                approvalStatus:
                  process.env.NODE_ENV === 'development'
                    ? { connect: { id: 'angenommen' } }
                    : { connect: { id: 'angenommen' } },
              },
            ],
          },
        },
        projectId,
      },
      refetchQueries: [
        'getProjectQuestionnaires',
        GetMasterWithApprovalsDocument,
      ],
    });

    await loadContent();
  };
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb
        label={t`myItProject.steps.af`}
        route={`/meine-it-initiative/${projectId}/af`}
      />
      <Form onSubmit={handleNothing}>
        <div className="space-y-12">
          <div className="w-full">
            <h3 className="text-xl font-medium mb-2">
              {
                ProjectQuestionnaireStatusesData
                  ?.projectQuestionnaireStatuses[0].question
              }
            </h3>
            <Dropdown
              variant="default"
              name="question1"
              options={options}
              onChange={handleQuestion1}
              value={question1}
              defaultValue={question1}
              isDisabled={confirmed}
            />
          </div>
          {question1.value === 'y' && (
            <div className="w-full">
              <h3 className="text-xl font-medium mb-2">
                {
                  ProjectQuestionnaireStatusesData
                    ?.projectQuestionnaireStatuses[1].question
                }
              </h3>
              <Dropdown
                variant="default"
                name="question2"
                options={options}
                onChange={handleQuestion2}
                value={question2}
                defaultValue={question2}
                isDisabled={confirmed}
              />
            </div>
          )}
          {question1.value === 'y' && question2.value === 'y' && (
            <div className="w-full">
              <h3 className="text-xl font-medium mb-2">
                {
                  ProjectQuestionnaireStatusesData
                    ?.projectQuestionnaireStatuses[2].question
                }
              </h3>
              <Dropdown
                variant="default"
                name="question3"
                options={options}
                onChange={handleQuestion3}
                value={question3}
                defaultValue={question3}
                isDisabled={confirmed}
              />
            </div>
          )}
          {question1.value === 'y' &&
            question2.value === 'y' &&
            question3.value === 'y' && (
              <div className="w-full">
                <h3 className="text-xl font-medium mb-2">
                  {
                    ProjectQuestionnaireStatusesData
                      ?.projectQuestionnaireStatuses[3].question
                  }
                </h3>
                <Dropdown
                  variant="default"
                  name="question4"
                  options={options}
                  onChange={handleQuestion4}
                  value={question4}
                  defaultValue={question4}
                  isDisabled={confirmed}
                />
              </div>
            )}
          <div className="flex space-y-12 justify-end">
            <Button
              theme="primary"
              disabled={
                confirmed ||
                !question1.value ||
                (question1.value === 'y' && !question2.value) ||
                (question2.value === 'y' && !question3.value) ||
                (question3.value === 'y' && !question4.value)
              }
              onClick={saveQuestionnaire}
            >
              {t('myItProject.activationQuestionnaire.submitLabel')}
            </Button>
          </div>
          <div className="space-y-12">
            <ApproverTable projectId={projectId} />
          </div>
        </div>
      </Form>
    </>
  );
};
export default ApprovalActivationQuestionnaire;
