import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'components/common/Button';

export type FormCancelButtonProps = PropsWithChildren<{
  disabled?: boolean;
  theme?: 'primary' | 'secondary' | 'grey' | 'grey-secondary' | 'danger';
  onClick?: () => void;
}>;
function FormCancelButton({
  children,
  disabled,
  theme,
  onClick,
}: FormCancelButtonProps) {
  const { formState } = useFormContext();

  const isDisabled = !formState.isValid || formState.isValidating;

  return (
    <Button
      theme={theme ? theme : 'primary'}
      disabled={disabled || isDisabled}
      onClick={() => !disabled && onClick?.()}
      type="reset"
    >
      {children}
    </Button>
  );
}
export default FormCancelButton;
