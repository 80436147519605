import { useTranslation } from 'react-i18next';
import useTypedForm from '../../common/Form/components';
import React, { useEffect, useState } from 'react';
import {
  LandingApplicationClusterGroup,
  LandingApplicationClusterMapping,
  LandingApplicationClustersQuery,
  LandingApplicationsQuery,
  SecurityGroup,
  useCreateLandingApplicationClusterMutation,
  useCreateLandingApplicationMutation,
  useGetLandingApplicationClustersQuery,
  useGetSecurityGroupsQuery,
  useUpdateLandingApplicationClusterMutation,
  useUpdateLandingApplicationMutation,
} from '../../../graphql/codegen/graphql';
import { toNumber } from 'lodash';
import Dialog, { DialogProps } from '../../common/Dialog';
import uploadIcon from '../../../assets/images/uploadIcon_blue.png';
import { IIcon } from '../../../pages/Dashboard/LandingApplications';
import { v4 as uuidv4 } from 'uuid';
import {
  handleCreate,
  handleCreateCluster,
  handleCreateClusterGroup,
  handleCreateLandingApplicationClusterMapping,
  handleEdit,
  handleEditCluster,
  handleUpdateClusterGroup,
  handleUpdateClusterMapping,
} from './CreateEditDialog.api';
import { useAppContext } from '../../../AppContext';
import ToolTip from '../../common/ToolTip';

export interface EditDialogProps {
  editApplication:
    | null
    | LandingApplicationsQuery['landingApplications'][number]
    | LandingApplicationClustersQuery['landingApplicationClusters'][number];
  onClose: DialogProps['onClose'];
  mode:
    | 'create'
    | 'edit'
    | 'createCluster'
    | 'editCluster'
    | 'Delete'
    | 'DeleteCluster';
  landingApplications?:
    | LandingApplicationsQuery['landingApplications']
    | LandingApplicationClustersQuery['landingApplicationClusters'];
}

type DropdownOptions = {
  label: string;
  value: string;
  mappingId?: string;
};

export interface FormValues {
  name: string;
  url: string;
  tooltip: string;
  label: string;
  icon: string;
  order: number;
  cluster: any;
  securityGroup: { groupId: string; createdBy: string }[];
}

const CreateEditDialog = ({
  editApplication,
  onClose,
  mode,
  landingApplications,
}: EditDialogProps) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { Form, TextInput, SubmitButton, Dropdown } =
    useTypedForm<FormValues>();
  const [icon, setIcon] = useState<IIcon | null>();
  const [orderValue, setOrderValue] = useState<number>();
  const [textValue, setTextValue] = useState([] as string[]);
  const [selectedItems, setSelectedItems] = useState<DropdownOptions[]>([]);
  const [updateOrders, setUpdateOrders] = useState<any>(null);

  const [createLandingApplication] = useCreateLandingApplicationMutation({
    refetchQueries: ['LandingApplications'],
  });

  const [createLandingApplicationCluster] =
    useCreateLandingApplicationClusterMutation({
      refetchQueries: [
        'LandingApplicationClusters',
        'LandingApplications',
        'getLandingApplicationClusters',
      ],
    });

  const [updateLandingApplication] = useUpdateLandingApplicationMutation({
    refetchQueries: ['LandingApplications'],
  });

  const [updateLandingApplicationCluster] =
    useUpdateLandingApplicationClusterMutation({
      refetchQueries: [
        'LandingApplicationClusters',
        'LandingApplications',
        'getLandingApplicationClusters',
      ],
    });

  const { data: clustersData, loading: clustersLoading } =
    useGetLandingApplicationClustersQuery({});

  const { data: securityGroupData, loading: securityGroupsLoading } =
    useGetSecurityGroupsQuery();

  const handleSubmit = (data: FormValues) => {
    switch (mode) {
      case 'edit':
        {
          let createClusterMapping = selectedItems
            .filter(c => c.mappingId === '')
            .map(c =>
              handleCreateLandingApplicationClusterMapping({
                clusterId: c.value,
                user: user,
              }),
            );

          let updateClusterMapping =
            editApplication?.landingApplicationClusterMapping
              ?.filter(e => !selectedItems.some(s => s.mappingId === e.id))
              .map(c =>
                handleUpdateClusterMapping({ clusterId: c.id, user: user }),
              );

          updateLandingApplication(
            handleEdit({
              data: data,
              editApplicationId: editApplication?.id,
              icon: icon,
              createClusterMapping: createClusterMapping,
              updateClusterMapping: updateClusterMapping,
            }),
          );
          updateOrders &&
            updateOrders.forEach(
              async o => await updateLandingApplication({ ...o }),
            );
        }
        break;
      case 'create':
        {
          data.cluster = selectedItems.map(c => {
            return {
              cluster: { connect: { id: c.value } },
              createdBy: user!.kid ? user!.kid : '',
            };
          });
          createLandingApplication(
            handleCreate({ data: data, iconFile: icon }),
          );
          updateOrders &&
            updateOrders.forEach(
              async o => await updateLandingApplication({ ...o }),
            );
        }
        break;
      case 'createCluster':
        {
          data.securityGroup = selectedItems.map(i => {
            return {
              groupId: i.value,
              createdBy: user?.kid!,
            };
          });
          createLandingApplicationCluster(
            handleCreateCluster({ data: data, user: user, iconFile: icon }),
          );
          updateOrders &&
            updateOrders.forEach(
              async o => await updateLandingApplicationCluster({ ...o }),
            );
        }
        break;
      case 'editCluster': {
        let createClusterGroup = selectedItems
          .filter(
            i =>
              !editApplication?.['landingApplicationClusterGroup'].some(e => {
                return e.groupId === i.value && e.deletedBy === null;
              }),
          )
          .map(c =>
            handleCreateClusterGroup({
              groupId: c.value,
              user: user,
            }),
          );

        let updateClusterGroup = editApplication?.[
          'landingApplicationClusterGroup'
        ]
          ?.filter(c => !selectedItems.some(s => s.value === c.groupId))
          .map(c => handleUpdateClusterGroup({ id: c.id, user: user }));

        updateLandingApplicationCluster(
          handleEditCluster({
            data: data,
            editApplicationId: editApplication?.id,
            icon: icon,
            createClusterGroup: createClusterGroup,
            updateClusterGroup: updateClusterGroup,
          }),
        );
        updateOrders &&
          updateOrders.forEach(
            async o => await updateLandingApplicationCluster({ ...o }),
          );
      }
    }
    onClose();
  };

  const handleIconSelect = (fileList: FileList | null) => {
    if (fileList?.length && fileList[0].type.substring(0, 6) === 'image/') {
      let img: HTMLImageElement;
      img = document.createElement('img');

      img.onload = () => {
        if (img.height * 5.5 <= img.width && img.width <= img.height * 6.5) {
          setIcon(icon);
        } else {
          setIcon(null);
        }
      };
      img.src = URL.createObjectURL(fileList[0]);
      const icon: IIcon = {
        id: uuidv4(),
        altText: '',
        file: fileList[0],
      };
    } else {
      setIcon(null);
    }
  };

  const mapClusters = (
    data:
      | LandingApplicationClustersQuery['landingApplicationClusters']
      | undefined,
  ): DropdownOptions[] => {
    if (data !== undefined) {
      return data.map(c => ({
        label: c.clusterName,
        value: c.id,
        mappingId: '',
      }));
    } else {
      return [{ label: '', value: '', mappingId: '' }];
    }
  };

  const mapItemLabels = (data: DropdownOptions[]) => {
    let arr = [] as string[];
    data.forEach(c => {
      arr.push(c.label);
    });
    return arr;
  };

  const handleChange = (data: DropdownOptions | null) => {
    if (data) {
      if (!selectedItems.some(e => e.label === data.label)) {
        selectedItems.push(data);

        setSelectedItems(selectedItems);
        setTextValue(mapItemLabels(selectedItems));
      } else {
        let arr = [] as DropdownOptions[];
        selectedItems.forEach(c => {
          if (c.label !== data.label) {
            arr.push(c);
          }
        });

        setSelectedItems(arr);
        setTextValue(mapItemLabels(arr));
      }
    }
  };

  const mapSecurityGroups = (data: SecurityGroup[] | undefined) => {
    if (data !== undefined) {
      return data.map(s => ({
        label: s.name,
        value: s.id,
      }));
    } else {
      return [{ label: '', value: '' }];
    }
  };

  const mapClusters2 = (
    data: LandingApplicationClusterMapping[],
  ): DropdownOptions[] => {
    return data.map(c => ({
      label: c.cluster.clusterName,
      value: c.cluster.id,
      mappingId: c.id,
    }));
  };

  useEffect(() => {
    if (
      orderValue &&
      orderValue !== editApplication?.order &&
      landingApplications?.some(l => l.order === orderValue)
    ) {
      if (landingApplications?.[0].__typename === 'LandingApplicationCluster') {
        // @ts-ignore
        const updateVariable = clustersData?.landingApplicationClusters
          .filter(f => f.order >= orderValue && f.id !== editApplication?.id)
          .map(f => {
            return {
              variables: {
                data: {
                  order: {
                    increment: 1,
                  },
                },
                where: {
                  id: f.id,
                },
              },
            };
          });
        setUpdateOrders(updateVariable);
      } else if (landingApplications?.[0].__typename === 'LandingApplication') {
        // @ts-ignore
        //prettier-ignore
        const updateVariable = landingApplications?.filter(f => f.order >= orderValue && f.id !== editApplication.id)
          .map(f => {
            return {
              variables: {
                data: {
                  order: {
                    increment: 1,
                  },
                },
                id: f.id,
              },
            };
          });
        setUpdateOrders(updateVariable);
      }
    } else {
      setUpdateOrders(null);
    }
  }, [orderValue]);

  useEffect(() => {
    if (
      editApplication?.landingApplicationClusterMapping?.length &&
      mode !== 'editCluster'
    ) {
      let arr: LandingApplicationClusterMapping[] =
        [] as LandingApplicationClusterMapping[];
      editApplication?.landingApplicationClusterMapping.forEach(c => {
        if (c.deletedBy === null) {
          arr.push(c as LandingApplicationClusterMapping);
        }
      });
      mapClusters2(arr).forEach(c => {
        handleChange(c);
      });
    }
  }, []);

  useEffect(() => {
    if (editApplication?.['landingApplicationClusterGroup']?.length) {
      let arr: LandingApplicationClusterGroup[] =
        [] as LandingApplicationClusterGroup[];
      let arr2: SecurityGroup[] = [] as SecurityGroup[];
      editApplication?.['landingApplicationClusterGroup'].forEach(c => {
        if (c.deletedBy === null) {
          arr.push(c);
        }
      });
      arr.forEach(e => {
        let element = securityGroupData?.securityGroups.find(s => {
          return s.id === e.groupId;
        });
        if (!securityGroupsLoading && element) {
          arr2.push(element);
        }
      });
      mapSecurityGroups(arr2).forEach(c => {
        handleChange(c);
      });
    }
  }, [securityGroupData]);

  return (
    <Dialog
      open={!!editApplication}
      onClose={onClose}
      title={
        mode === 'edit'
          ? t`itProjects.landingApplication.edit`
          : t`itProjects.landingApplication.create`
      }
    >
      <div>
        <Form onSubmit={handleSubmit} mode="onChange">
          <div className="space-y-4">
            <div className="flex flex-grow items-center">
              <div className="text-primary w-4/12	">{t`itProjects.landingApplication.name2`}</div>
              <div className="w-full">
                <TextInput
                  name="name"
                  defaultValue={
                    editApplication?.['name'] ||
                    editApplication?.['clusterName'] ||
                    ''
                  }
                  rules={{
                    required: {
                      value: true,
                      message: t`itProjects.landingApplication.errorMessageEmpty`,
                    },
                  }}
                />
              </div>
            </div>
            {mode !== 'createCluster' && mode !== 'editCluster' ? (
              <div className="flex flex-grow items-center">
                <div className="text-primary w-4/12	">{t`itProjects.landingApplication.url`}</div>
                <div className="w-full">
                  <TextInput
                    name="url"
                    defaultValue={editApplication?.['url']}
                    rules={{
                      required: {
                        value: true,
                        message: t`itProjects.landingApplication.errorMessageEmpty`,
                      },
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="flex flex-grow items-center ">
              <div className="text-primary w-4/12">{t`itProjects.landingApplication.tooltip`}</div>
              <div className="w-full">
                <TextInput
                  name="tooltip"
                  defaultValue={editApplication?.tooltip ?? ''}
                  rules={{
                    required: {
                      value: true,
                      message: t`itProjects.landingApplication.errorMessageEmpty`,
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-grow items-center">
              <div className="text-primary w-4/12">{t`itProjects.landingApplication.label`}</div>
              <div className="w-full">
                <TextInput
                  name="label"
                  defaultValue={editApplication?.label ?? ''}
                  rules={{
                    required: {
                      value: true,
                      message: t`itProjects.landingApplication.errorMessageEmpty`,
                    },
                    pattern: {
                      value: /^[\p{L}\p{P}0-9_\- ]{1,30}$/u,
                      message: t(
                        'itProjects.landingApplication.errorMessageLabel',
                      ),
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-grow items-center">
              <div className="flex text-primary w-4/12	">
                {t`itProjects.landingApplication.icon`}
                <ToolTip
                  mode={'hover'}
                >{t`itProjects.landingApplication.iconTooltip`}</ToolTip>
              </div>
              <div className="w-full flex">
                <div className="grow">
                  <TextInput
                    name="icon"
                    defaultValue={editApplication?.icon!}
                    value={
                      icon === null
                        ? ''
                        : icon?.file?.name ?? editApplication?.icon!
                    }
                    disabled={true}
                  />
                  {icon === null && (
                    <p
                      className={'text-red-600'}
                    >{t`itProjects.landingApplication.iconErrorMessage`}</p>
                  )}
                </div>
                <div className="flex w-fit ml-4">
                  <label
                    className="h-10 storybook-button--label storybook-button--secondary"
                    htmlFor="select-icon"
                  >
                    <div className="flex mx-6">
                      <img
                        className="h-4 mr-2"
                        src={uploadIcon}
                        alt={t('itProjects.landingApplication.altIconUpload')}
                      />
                      {t('itProjects.landingApplication.upload')}
                    </div>
                  </label>
                  <div>
                    <input
                      className="hidden"
                      type="file"
                      accept="image/*"
                      id="select-icon"
                      onChange={event =>
                        handleIconSelect(event.currentTarget.files)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {mode === 'create' || mode === 'edit' ? (
              <div className="flex flex-grow items-center">
                <div className="text-primary w-4/12	">
                  {t('itProjects.landingApplication.cluster')}
                </div>
                <div className="w-full space-y-4">
                  <p>
                    {t('itProjects.landingApplication.selectedClusters')}
                    {textValue.length ? textValue.join(', ') : 'keine'}
                  </p>
                  <Dropdown
                    variant="default"
                    name="cluster"
                    loading={clustersLoading}
                    value={selectedItems}
                    options={mapClusters(
                      clustersData?.landingApplicationClusters.filter(
                        c => c.isActive === true,
                      ),
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : null}
            {/*TODO: Think about the possibility to select Security Groups also when creating new LandingApps */}
            {mode === 'createCluster' || mode === 'editCluster' ? (
              <div className="flex flex-grow items-center">
                <div className="text-primary w-4/12	">
                  {t('itProjects.landingApplication.securityGroups')}
                </div>
                <div className="w-full space-y-4">
                  <p>
                    {t('itProjects.landingApplication.selectedGroups')}
                    {textValue.length ? textValue.join(', ') : 'keine'}
                  </p>
                  <Dropdown
                    variant="default"
                    name="securityGroup"
                    loading={securityGroupsLoading}
                    value={selectedItems}
                    options={mapSecurityGroups(
                      securityGroupData?.securityGroups,
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : null}
            <div className="flex flex-grow items-center">
              <div className="text-primary w-4/12	">
                {t('itProjects.landingApplication.order2')}
              </div>
              <div className="w-full">
                <TextInput
                  name="order"
                  defaultValue={
                    editApplication?.order === 0
                      ? ''
                      : editApplication?.order ?? ''
                  }
                  onChange={event =>
                    setOrderValue(toNumber(event.target.value))
                  }
                  rules={{
                    required: {
                      value: true,
                      message: t`itProjects.landingApplication.errorMessageEmpty`,
                    },
                    pattern: {
                      value: /^[0-9]*$/u,
                      message: t(
                        'itProjects.landingApplication.errorMessageOrder',
                      ),
                    },
                    validate: () => {
                      return !!(orderValue !== undefined
                        ? orderValue
                        : editApplication?.order);
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex items-end justify-end pt-5">
              <SubmitButton
                disabled={
                  (icon === null
                    ? true
                    : icon
                    ? false
                    : !editApplication?.icon) || !textValue.length
                }
              >
                {t('itProjects.landingApplication.saveButton')}
              </SubmitButton>
            </div>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

export default CreateEditDialog;
