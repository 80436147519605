import { Link, useLocation } from 'react-router-dom';

export interface SideBarProps {
  data: {
    id: number;
    text: string;
    route: string;
    pageId: string;
  }[];

  isProjectSelected: boolean;
}

const SideBar: React.VFC<SideBarProps> = ({ data, isProjectSelected }) => {
  const location = useLocation();

  return (
    <div className="pb-3 pt-6">
      <ul className="w-min-40 px-4 bg-white outline-none origin-top-left">
        {data.map(item => {
          return (
            <div className={'w-40 mb-2'} key={item.id}>
              <li
                className={`
          flex items-center justify-center py-1 w-full text-left font-medium text-sm hover:text-primary
          ${
            !isProjectSelected && item.id !== 1
              ? 'text-black opacity-40'
              : 'text-black'
          }
          ${
            (isProjectSelected && location.pathname.includes(item.pageId)) ||
            location.pathname.includes(item.route)
              ? 'border rounded border-darkGrey text-primary bg-tableLightGrey'
              : ''
          }
          `}
              >
                <Link
                  to={isProjectSelected ? item.route : ''}
                  style={!isProjectSelected ? { pointerEvents: 'none' } : {}}
                >
                  {item.text}
                </Link>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default SideBar;
