import { Link, useLocation } from 'react-router-dom';

export interface TabBarProps {
  isProjectSelected?: boolean;
  data: {
    id: number;
    text: string;
    route: string;
    pageId?: string;
  }[];
}

const TabBar: React.VFC<TabBarProps> = ({ data, isProjectSelected }) => {
  const location = useLocation();
  return (
    <div>
      <ul className="relative flex w-full items-end justify-end space-x-10">
        {data.map(item => {
          return (
            <div className={'w-full min-h-full font-primary'} key={item.id}>
              <Link
                to={isProjectSelected ? item.route : ''}
                style={!isProjectSelected ? { pointerEvents: 'none' } : {}}
                className={`
                  flex text-sm font-medium hover:text-primary
                  box-border px-10 py-1 border-b border-grey min-h-80 justify-center text-center
                  ${
                    item.route.includes('?')
                      ? item.route.slice(0, item.route.indexOf('?')) ===
                        location.pathname
                        ? 'border-primary text-primary'
                        : ''
                      : item.route === location.pathname ||
                        (item.pageId &&
                          location.pathname.includes(item.pageId!))
                      ? 'border-primary text-primary'
                      : ''
                  }
                  `}
              >
                {item.text}
              </Link>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default TabBar;
