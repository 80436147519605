import NumericInput from 'react-numeric-input2';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import FinancialElementRow from './FinancialElementRow';
import config from 'config/config';
import FinancialHeadRow from './FinancialHeadRow';
import FinancialSumRow from './FinancialSumRow';
import FinancialTabs from './FinancialTabs';
import useTypedForm from '../common/Form/components';
import { Button } from 'stories/Button';
import {
  useGetProjectDescriptionsQuery,
  useUpdateProjectDescriptionMutation,
} from '../../graphql/codegen/graphql';
import { toInteger } from 'lodash';
import { useAppContext } from '../../AppContext';

export type FinancialValue = {
  id: string;
  value?: number;
  year?: number;
  delta?: number;
  isEstimatedCost?: boolean;
};

export type FinancialTableRow = {
  assetId: string;
  isProjectCosts: boolean;
  isSummary: boolean;
  isExtended: boolean;
  companyId?: string;
  values: FinancialValue[];
  estimatedCost?: FinancialValue;
};

export type Allocation = {
  id: string;
  percentage: number;
  allocationId: string;
};

type FinancialTableProps = {
  projectId?: string;
  allocationsData: any;
  allocs?: Allocation[];
  financialData: any;
  resourceData?: any;
  tData?: FinancialTableRow[];
  isUpdating?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isNumericInputDisabled?: boolean;
  handleSaveTableData?: (
    tableData: FinancialTableRow[],
    initialTable: FinancialTableRow[],
    allocationChanged: boolean,
  ) => void;
  // handleCreateFinanceData?: (data: {assetId: string, companyId: string, valueData: FinancialValue}) => void;
  // handleUpdateFinanceData?: (data: {value: number, id: string}) => void;
  handleConnectAllocation?: (
    id: string,
    allocationId: string,
    percentage: number,
  ) => void;
  handlePrev?: (data: {
    allocations: Allocation[];
    tableData: FinancialTableRow[];
  }) => void;
  // handleSave?: () => void;
  handleSubmit?: (data: {
    allocations: Allocation[];
    tableData: FinancialTableRow[];
  }) => void;
};

const FinancialTable = (props: FinancialTableProps) => {
  const {
    projectId,
    allocationsData,
    allocs,
    financialData,
    resourceData,
    tData,
    isUpdating,
    isRequired,
    isDisabled,
    isNumericInputDisabled = false,
    handleSaveTableData,
    handleConnectAllocation,
    handlePrev,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const { user } = useAppContext();
  const location = useLocation();
  const [firstValueIndex, setFirstValueIndex] = useState<number>(0);
  const [afaState, setAfaState] = useState(null);
  const history = useHistory();
  const { Form, TextInput, SubmitButton } = useTypedForm();
  /**
   * setting up allocations
   */

  const createAllocationsState = () => {
    if (allocs?.length) return allocs;
    const as: Allocation[] = [];
    allocationsData?.allocations.map(a => {
      const newAlloc: Allocation = {
        id: a.id,
        allocationId: a.id,
        percentage: 0.0,
      };
      as.push(newAlloc);
    });
    return as;
  };

  useEffect(() => {
    setAllocations(allocs?.length ? allocs : () => createAllocationsState());
    setInitialAllocations(
      allocs?.length ? allocs : () => createAllocationsState(),
    );
  }, [allocationsData, allocs]);

  const [allocations, setAllocations] = useState<Allocation[]>(
    allocs ? allocs : () => createAllocationsState(),
  );
  const [initialAllocations, setInitialAllocations] = useState<Allocation[]>(
    allocs ? allocs : () => createAllocationsState(),
  );

  let allocationChanged = false;
  const submitAllocations = (allocations: Allocation[]) => {
    allocationChanged = false;
    if (allocations !== initialAllocations) {
      allocationChanged = true;
      allocations.forEach((a: Allocation, index) => {
        handleConnectAllocation &&
          handleConnectAllocation(a.id, a.allocationId, a.percentage);
      });
    }
  };

  /**
   * setting up Table data
   */
  useEffect(() => {
    tData && setExtendedAssets(getExtendedAssets(tData));
    setTableData(tData ? createTableDataCopy(tData) : () => createTableState());
    setInitialTable(
      tData ? createTableDataCopy(tData) : () => createTableState(),
    );
  }, [financialData]);

  const createEmptyTable = () => {
    const emptyTable: FinancialTableRow[] = [];
    financialData?.assets.forEach(a => {
      const newAssetRow: FinancialTableRow = {
        assetId: a.id,
        isProjectCosts: a.isProjectCosts,
        isSummary: true,
        isExtended: extendedAssets.includes(a.id),
        values: [],
      };
      emptyTable.push(newAssetRow);
      financialData?.companies.forEach(c => {
        const newCompanyRow: FinancialTableRow = {
          assetId: a.id,
          isProjectCosts: a.isProjectCosts,
          isSummary: false,
          isExtended: false,
          companyId: c.id,
          values: [],
        };
        emptyTable.push(newCompanyRow);
      });
    });
    return emptyTable;
  };

  const addMissingZeros = (data: FinancialTableRow[], years: number[]) => {
    data.forEach(d => {
      years.forEach(y => {
        if (!d.values?.some(v => v.year === y)) {
          const newZero: FinancialValue = {
            id: 'key-' + d.assetId + '-' + d.companyId + '-' + y,
            value: 0,
            year: y,
          };
          d.values?.push(newZero);
        }
      });
      d.values?.sort((a, b) => a.year! - b.year!);
    });
  };

  const calcSummaryValues = (data: FinancialTableRow[]) => {
    data
      .filter(s => s.isSummary)
      .forEach(s => {
        const values: FinancialValue[] = [];
        data
          .filter(d => d.assetId === s.assetId && !d.isSummary)
          .forEach(d => {
            d.values?.forEach(companyData => {
              const exisitingValue = values.find(
                v => v.year === companyData.year,
              );
              if (!exisitingValue) {
                values.push({
                  id: 'key-summary' + d.assetId + '-' + companyData.year,
                  value: companyData.value ? +companyData.value : 0,
                  year: companyData.year,
                });
              } else {
                exisitingValue.value! += companyData.value
                  ? +companyData.value
                  : 0;
              }
            });
          });
        values.sort((a, b) => a.year! - b.year!);
        s.values = values;
      });
  };

  const calcEstimatedCost = (data: FinancialTableRow[]) => {
    data.forEach(d => {
      let estimatedCost: number = 0;
      d.values?.forEach(v => {
        estimatedCost += v.value ? +v.value : 0;
      });
      d.estimatedCost = {
        id: 'key-estimated-' + d.assetId + '-' + d.companyId,
        value: +estimatedCost,
      };
    });
  };

  const addFutureYears = (y: number[]) => {
    const currentYear = y[0]; // new Date().getFullYear();
    for (let i = Math.min(...y); i < currentYear + 4; i++) {
      if (!y.includes(i)) {
        y.push(i);
      }
    }
  };

  const getYears = (tData?: FinancialTableRow[]) => {
    const y: number[] = years;
    let resetIndex: boolean = false;
    if (tData) {
      tData[0].values.forEach(v => {
        if (!y.includes(v.year!)) {
          y.push(v.year!);
          resetIndex = true;
        }
      });
    } else {
      financialData?.finances.forEach(d => {
        if (!y.includes(d.year)) {
          y.push(d.year);
          resetIndex = true;
        }
      });
      if (financialData?.finances.length === 0) resetIndex = true;
      addFutureYears(y);
    }
    y.sort();
    if (resetIndex) {
      const now = new Date().getFullYear();
      setFirstValueIndex(y.length > 4 ? y.findIndex(y => y === now) - 1 : 0);
    }
    return y;
  };

  const [years, setYears] = useState<number[]>([new Date().getFullYear()]);

  const createTableState = () => {
    const y = getYears();
    setYears(y);

    const tableInitial: FinancialTableRow[] = createEmptyTable();

    financialData?.finances
      .filter(s => s.deletedBy === null)
      .forEach(d => {
        const rowExists = tableInitial.find(
          t => t.assetId === d.asset.id && t.companyId === d.company.id,
        );
        if (rowExists) {
          const newValue: FinancialValue = {
            id: d.id,
            value: d.value,
            year: d.year,
          };
          rowExists.values?.push(newValue);
        }
      });
    addMissingZeros(tableInitial, y);
    calcSummaryValues(tableInitial);
    calcEstimatedCost(tableInitial);
    return tableInitial;
  };

  const getExtendedAssets = (tData: FinancialTableRow[]) => {
    const assets: string[] = [];
    tData.forEach(d => {
      if (d.isSummary && d.isExtended) assets.push(d.assetId);
    });
    return assets;
  };

  const createTableDataCopy = (
    tData: FinancialTableRow[],
    localExtended?: boolean,
  ) => {
    if (years.length !== 4) setYears(getYears(tData));
    const newData: FinancialTableRow[] = [];
    tData.forEach(d => {
      const newObject: FinancialTableRow = deepCopy(d);
      newObject.values = deepCopy(d.values); // JSON.parse(JSON.stringify(d));
      if (localExtended) {
        newObject.isExtended = extendedAssets.includes(d.assetId);
      }
      newData.push(newObject);
    });
    return newData;
  };

  // from: https://stackoverflow.com/questions/38416020/deep-copy-in-es6-using-the-spread-syntax
  const deepCopy = obj => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = deepCopy(item);
        return arr;
      }, []);
    }

    if (obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = deepCopy(obj[key]);
        return newObj;
      }, {});
    }
  };

  const [extendedAssets, setExtendedAssets] = useState<string[]>(
    tData ? getExtendedAssets(tData) : [],
  );
  const [tableData, setTableData] = useState<FinancialTableRow[]>(
    tData ? createTableDataCopy(tData) : () => createTableState(),
  );
  const [initialTable, setInitialTable] = useState<FinancialTableRow[]>(
    tData ? createTableDataCopy(tData) : () => createTableState(),
  );

  const getSum = useCallback(
    (data: FinancialTableRow[], isProject: boolean) => {
      const sumValues: FinancialValue[] = [];
      data
        .filter(
          d =>
            (isProject ? d.isProjectCosts : !d.isProjectCosts) && d.isSummary,
        )
        .filter(l => {
          return !(
            l.assetId === 'Anpassungen Schnittstellen' ||
            l.assetId === 'Anpassungen Umsysteme' ||
            l.assetId === 'Changemanagement' ||
            l.assetId === 'Migration' ||
            l.assetId === 'Support Driver (inkl. Erweiterungen)'
          );
        })
        .forEach(d => {
          d.values?.forEach((data: FinancialValue) => {
            const value = sumValues.find(v => v.year === data.year);
            if (!value) {
              const newValue: FinancialValue = {
                id:
                  'key-' + isProject ? 'project-' : 'operational-' + data.year,
                value: data.value ? +data.value : 0,
                year: data.year,
              };
              sumValues.push(newValue);
            } else {
              value.value! += data.value ? +data.value : 0;
            }
          });
        });
      const row: FinancialTableRow = {
        assetId: isProject ? 'pages.financial.sumP' : 'pages.financial.sumO',
        isProjectCosts: false,
        isSummary: false,
        isExtended: false,
        values: sumValues,
      };
      calcEstimatedCost([row]);
      return row;
    },
    [],
  );

  const getOwnContribution = useCallback(
    (resources: any) => {
      const contributionCosts: FinancialValue[] = [];

      const data = tableData?.filter(d => d.isProjectCosts && d.isSummary)[0];
      data?.values.forEach(d => {
        const newValue: FinancialValue = {
          id: 'key-project' + d.year,
          value: 0,
          year: d.year,
        };
        contributionCosts.push(newValue);
      });

      if (resources) {
        resources.forEach(c => {
          const value = contributionCosts.find(v => v.year === c.year);
          if (!value) {
            const newValue: FinancialValue = {
              id: 'key-project-' + c.year,
              value: c._sum ? (c._sum.rate / 100) * 100000 : 0,
              year: c.year,
            };
            contributionCosts.push(newValue);
          } else {
            value.value! = c._sum ? (c._sum.rate / 100) * 100000 : 0;
          }
        });
      }
      const row: FinancialTableRow = {
        assetId: 'pages.financial.ownWorkP',
        isProjectCosts: true,
        isSummary: false,
        isExtended: false,
        values: contributionCosts,
      };
      calcEstimatedCost([row]);
      return row;
    },
    [tableData],
  );

  const [sumProjectcosts, setSumProjectcosts] = useState<FinancialTableRow>(
    getSum(tableData, true),
  );
  const [ownContributionProject, setOwnContributionProject] =
    useState<FinancialTableRow>(
      getOwnContribution(resourceData?.groupProjectResourceCaptures),
    );
  const [sumOperationalcosts, setSumOperationalcosts] =
    useState<FinancialTableRow>(getSum(tableData, false));

  const [isOperationalCostsEmpty, setIsOperationalCostsEmpty] =
    useState<Boolean>(true);
  const [isProjCostsEmpty, setIsProjCostsEmpty] = useState<Boolean>(true);

  const getIsTableEmpty = useCallback(() => {
    let isProjectCostsEmpty = true;
    let isCompanyCostEmpty = true;
    tableData.forEach(row => {
      if (row.isProjectCosts) {
        row.values.forEach(v => {
          if (v.value !== 0) {
            isProjectCostsEmpty = false;
          }
        });
      }
      if (!row.isProjectCosts) {
        row.values.forEach(v => {
          if (v.value !== 0) {
            isCompanyCostEmpty = false;
          }
        });
      }
    });
    return isProjectCostsEmpty || isCompanyCostEmpty;
  }, [tableData]);

  const getIsAllocationEmpty = useCallback(() => {
    return false;
    /*allocations?.reduce((accumulator, allocation) => {
        return accumulator + allocation.percentage;
      }, 0) !== 100*/
  }, [allocations]);

  const [isTableEmpty, setIsTableEmpty] = useState(getIsTableEmpty());
  const [isAllocationEmpty, seAllocationEmpty] = useState(
    getIsAllocationEmpty(),
  );

  useEffect(() => {
    setSumProjectcosts(getSum(tableData, true));
    setSumOperationalcosts(getSum(tableData, false));
    setIsTableEmpty(getIsTableEmpty());
    seAllocationEmpty(getIsAllocationEmpty());
  }, [tableData, allocations, getIsAllocationEmpty, getIsTableEmpty, getSum]);

  useEffect(() => {
    setOwnContributionProject(
      getOwnContribution(resourceData?.groupProjectResourceCaptures),
    );
  }, [resourceData, tableData, getOwnContribution]);

  const maxFirstValueIndex = years.length - 4;

  const [hideProjectData, setHideProjectData] = useState(
    location.pathname.includes('financialdata/operatingcosts'),
  );

  const handleAllocations = (valueAsNumber, valueAsString, input) => {
    setAllocations(
      [...allocations].map(a => {
        if (a.allocationId === input.name) {
          return {
            ...a,
            percentage: valueAsNumber,
          };
        } else {
          return a;
        }
      }),
    );
  };

  const handleBack = () => {
    if (firstValueIndex > 0) {
      setFirstValueIndex(firstValueIndex - 1);
    }
  };

  const handleForward = () => {
    if (firstValueIndex < maxFirstValueIndex) {
      setFirstValueIndex(firstValueIndex + 1);
    }
  };

  const handleExtend = (id: string) => {
    setTableData(
      [...tableData].map(object => {
        if (object.assetId === id) {
          return {
            ...object,
            isExtended: !object.isExtended,
          };
        } else {
          return object;
        }
      }),
    );
    setInitialTable(
      [...initialTable].map(object => {
        if (object.assetId === id) {
          return {
            ...object,
            isExtended: !object.isExtended,
          };
        } else {
          return object;
        }
      }),
    );
    setExtendedAssets(
      extendedAssets.includes(id)
        ? extendedAssets.filter(a => a !== id)
        : [...extendedAssets, id],
    );
  };

  const handleInput = (value: number, id: string) => {
    const newTableData = [...tableData].map(object => {
      const index: number = object.values!.findIndex(v => v.id === id);
      const newValues: FinancialValue[] = object.values!;
      if (index >= 0) {
        newValues[index].value = value;
        return {
          ...object,
          values: newValues,
        };
      } else {
        return object;
      }
    });
    calcSummaryValues(newTableData);
    calcEstimatedCost(newTableData);
    setTableData(newTableData);
  };

  const handleTabChange = () => {
    setHideProjectData(!hideProjectData);
  };

  const dashboardControllerLocation = location.state === 'dashboard-controller';
  const handlePrevButton = () => {
    if (projectId) {
      if (dashboardControllerLocation) {
        history.push({
          pathname: `${config.localRoutes.dashboardController.projectCosts.replace(
            ':projectId',
            projectId,
          )}`,
          search: `projectId=${projectId}`,
        });
      } else {
        history.push(
          config.localRoutes.dashboard.detail.replace(':projectId', projectId),
        );
      }

      handlePrev && handlePrev({ allocations, tableData });
    }
  };

  const handleRevertButton = () => {
    setAllocations(allocs ? allocs : createAllocationsState());
    setTableData(tData ? createTableDataCopy(tData, true) : createTableState());
    setInitialTable(
      tData ? createTableDataCopy(tData, true) : createTableState(),
    );
    // TODO: add confirmation?
  };

  // showed on FinancialDataPage to save changes
  const handleSaveButton = () => {
    submitAllocations(allocations);
    setInitialAllocations(allocations);
    handleSaveTableData &&
      handleSaveTableData(tableData, initialTable, allocationChanged);
  };

  // showed, when creating a new idea to submit changes
  const handleSubmitButton = () => {
    handleSubmit && handleSubmit({ allocations, tableData });
  };

  const displayedRows = (data: FinancialTableRow[]) => {
    const returnData: FinancialTableRow[] = [];
    let extendedAsset: string = '';
    data
      .filter(d => (hideProjectData ? !d.isProjectCosts : d.isProjectCosts))
      .forEach((element: FinancialTableRow) => {
        if (element.isSummary) {
          extendedAsset = element.isExtended ? element.assetId : '';
          returnData.push(element);
        } else {
          if (element.assetId === extendedAsset) {
            returnData.push(element);
          }
        }
      });
    return returnData;
  };

  const { data: projectDescriptionId } = useGetProjectDescriptionsQuery({
    variables: {
      where: {
        projectId: {
          equals: projectId,
        },
      },
    },
  });

  const [updateAfa] = useUpdateProjectDescriptionMutation({
    variables: {
      where: {
        id: projectDescriptionId?.projectDescriptions[0].id,
      },
      data: {
        afa: {
          set: afaState,
        },
      },
    },
  });

  const onSubmit = data => {
    setAfaState(data.afa);
    updateAfa({
      variables: {
        where: {
          id: projectDescriptionId?.projectDescriptions[0].id,
        },
        data: {
          afa: {
            set: toInteger(data.afa),
          },
        },
      },
    });
  };

  const totalCosts =
    (sumProjectcosts &&
      sumProjectcosts.estimatedCost?.value &&
      sumOperationalcosts.estimatedCost?.value &&
      sumProjectcosts.estimatedCost?.value +
        sumOperationalcosts.estimatedCost?.value) ||
    0;

  const projectCosts =
    (sumProjectcosts &&
      sumProjectcosts.estimatedCost?.value &&
      sumProjectcosts.estimatedCost?.value) ||
    0;

  const operationalCosts =
    (sumOperationalcosts &&
      sumOperationalcosts.estimatedCost?.value &&
      sumOperationalcosts.estimatedCost?.value) ||
    0;

  return (
    <div className="w-full flex flex-1 flex-col">
      {/*<div className="flex pt-5 place-content-center gap-8 ">
        <p>{t(`myItIdea.financial-data.totalCosts`, { totalCosts })}</p>
        <p>{t('myItIdea.financial-data.projectCosts', { projectCosts })}</p>
        <p>
          {t('myItIdea.financial-data.operationalCosts', { operationalCosts })}
        </p>
      </div>
      <div className="flex flex-wrap w-full	pt-10 pb-5 space-x-4 justify-center items-center">
        {allocations &&
          allocations.map((data: Allocation) => {
            return (
              <div
                className="justify-center text-center my-2 space-x-4 space-y-4"
                key={data.id}
              >
                <div className="space-x-2">
                  {data.allocationId ? data.allocationId : data.id}
                </div>
                <div>
                  <NumericInput
                    name={data.allocationId}
                    value={data.percentage}
                    min={0}
                    max={100}
                    step={0.01}
                    precision={2}
                    size={6}
                    maxLength={6}
                    required
                    mobile
                    inputMode="numeric"
                    onChange={handleAllocations}
                    disabled={isNumericInputDisabled}
                  />
                </div>
              </div>
            );
          })}
      </div>*/}
      <div className="flex-col justify-end gap-8 py-5">
        <div className="mb-2">
          {!location.pathname.includes('financialdata') && (
            <FinancialTabs
              showProjectData={!hideProjectData}
              onChange={handleTabChange}
            />
          )}
        </div>
        <table className="table-fixed">
          <thead>
            <FinancialHeadRow
              years={years}
              distributedTitle={t(
                `pages.financial.distribution${hideProjectData ? 'O' : 'P'}`,
              )}
              plannedTitle={t(
                `pages.financial.planned${hideProjectData ? 'O' : 'P'}`,
              )}
              firstValueIndex={firstValueIndex}
              showForward={firstValueIndex !== maxFirstValueIndex}
              handleBack={handleBack}
              handleForward={handleForward}
            />
          </thead>
          <tbody className=" border-b border-tableDarkGrey shadow sm:rounded-lg">
            {displayedRows(tableData)
              .filter(l => {
                return !(
                  l.assetId === 'Anpassungen Schnittstellen' ||
                  l.assetId === 'Anpassungen Umsysteme' ||
                  l.assetId === 'Changemanagement' ||
                  l.assetId === 'Migration' ||
                  l.assetId === 'Support Driver (inkl. Erweiterungen)'
                );
              })
              .map((data, index) => {
                return (
                  <FinancialElementRow
                    data={data}
                    lastSavedData={displayedRows(initialTable)[index]}
                    firstValueIndex={firstValueIndex}
                    darker={index % 2 === 1}
                    handleExtend={handleExtend}
                    handleInput={handleInput}
                    key={index}
                    isDisabled={isDisabled}
                  />
                );
              })}
            <tr>
              <td />
            </tr>
            <FinancialSumRow
              data={hideProjectData ? sumOperationalcosts : sumProjectcosts}
              firstValueIndex={firstValueIndex}
            />
            {!hideProjectData && (
              <tr>
                <td />
              </tr>
            )}
            {!hideProjectData && (
              <FinancialSumRow
                data={ownContributionProject}
                firstValueIndex={firstValueIndex}
                tooltip={t('itProjects.detail.actions.wrongFormatWarning')}
              />
            )}
          </tbody>
        </table>
        {/*{user?.groups?.includes('92744778-209c-4914-bd92-20fcfee48ee0')! ? (
          <div className={'w-1/2 mt-6'}>
            <Form
              onSubmit={onSubmit}
              mode={'onChange'}
              defaultValue={0}
              className="h-full flex flex-col"
            >
              <p>Startjahr - AfA</p>
              <div className={'flex mt-1.5'}>
                <div className={'flex-none w-40'}>
                  <TextInput
                    name="afa"
                    rules={{
                      pattern: {
                        value:
                          process.env.NODE_ENV !== 'development'
                            ? /./
                            : /^[0-9]{4}$/,
                        message: t(
                          'itProjects.detail.actions.wrongFormatWarning',
                        ),
                      },
                    }}
                  />
                </div>
                <div className={'ml-2.5'}>
                  <SubmitButton>AfA speichern</SubmitButton>
                </div>
              </div>
            </Form>
          </div>
        ) : null}*/}
        <div className="flex justify-end gap-8 py-5 pt-10">
          {!location.pathname.includes('td-reporting') && (
            <Button theme="secondary" onClick={handlePrevButton}>
              {t('pages.financial.back')}
            </Button>
          )}
          {!isDisabled && (
            <Button theme="secondary" onClick={handleRevertButton}>
              {t('pages.financial.revert')}
            </Button>
          )}
          {(handleSaveTableData || handleConnectAllocation) && !isDisabled && (
            <Button
              disabled={isUpdating || getIsTableEmpty() || isAllocationEmpty}
              theme="primary"
              onClick={handleSaveButton}
            >
              {t('pages.financial.save')}
            </Button>
          )}
          {handleSubmit && !isDisabled && (
            <Button
              disabled={isRequired && (isTableEmpty || isAllocationEmpty)}
              theme="primary"
              onClick={handleSubmitButton}
            >
              {t('myItIdea.financial-data.submit')}
            </Button>
          )}
        </div>
        {!isDisabled && (
          <div className="space-y-4">
            {isAllocationEmpty && (
              <p className="text-red-600">
                {t('myItIdea.financial-data.allocationsEmpty')}
              </p>
            )}
            {projectCosts <= 0 && (
              <p className="text-red-600">
                {t('myItIdea.financial-data.projectCostsEmpty')}
              </p>
            )}
            {operationalCosts <= 0 && (
              <p className="text-red-600">
                {t('myItIdea.financial-data.operatingCostsEmpty')}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FinancialTable;
