import React from 'react';

import './table.css';
import TableInput from './TableInput';
import arrow from '../../assets/images/arrow4x.png';
import { FinancialTableRow } from './FinancialTable';

interface FinancialRowProps {
  data: FinancialTableRow;
  lastSavedData: FinancialTableRow;
  firstValueIndex: number;
  darker: boolean;
  isDisabled?: boolean;
  handleExtend: (id: string) => void;
  handleInput: (value: number, id: string) => void;
}

const FinancialElementRow = (props: FinancialRowProps) => {
  const {
    data,
    lastSavedData,
    firstValueIndex,
    darker,
    isDisabled,
    handleExtend,
    handleInput,
  } = props;

  const extend = () => {
    handleExtend(data.assetId);
  };

  const openRow = () => {
    handleExtend(data.assetId);
  };

  const input = (value: number, id: string) => {
    handleInput(value, id);
  };

  const color = darker ? 'tableLightGrey' : 'white';

  const rows: any[] = [];
  const max = 4; //Math.min(4, data.values!.length);
  for (let i = 0; i < max; i++) {
    let isHighlighted = false;
    if (
      data &&
      data.values[i + firstValueIndex] &&
      lastSavedData &&
      lastSavedData.values[i + firstValueIndex]
    ) {
      // console.log(data.values[i + firstValueIndex])
      isHighlighted =
        !data.isSummary &&
        +data.values[i + firstValueIndex].value! !==
          +lastSavedData.values[i + firstValueIndex].value!;
    }
    rows.push(<td key={i}></td>);
    rows.push(
      <td key={i + max} onClick={data.isSummary ? openRow : undefined}>
        <TableInput
          data={data.values![i + firstValueIndex]}
          color={color}
          isBold={data.isSummary && data.isExtended}
          isHighlighted={isHighlighted}
          handleInput={input}
          isDisabled={data.isSummary || isDisabled}
        />
      </td>,
    );
  }

  let dataDelta = 0;
  let newData;
  data.values.forEach(v => {
    dataDelta += v.delta ? v.delta : 0;
  });
  if (data.estimatedCost) {
    newData = {
      estimatedCost: {
        id: data.estimatedCost.id,
        value: data.estimatedCost?.value,
        delta: dataDelta,
        isEstimatedCost: true,
      },
    };
  }

  return (
    <tr className={`text-right bg-${color} `}>
      <td className="text-left">
        {data.isSummary ? (
          <div className="flex items-center">
            <button onClick={extend} className="flex-none h-6">
              <img src={arrow} alt="arrow down" className="h-2 px-3 mr-1" />
            </button>
            {data.assetId}
          </div>
        ) : (
          <div className="flex items-center ml-16">
            {data.companyId === 'Planung EKN' ? 'Planung EGS' : data.companyId}
          </div>
        )}
      </td>
      {rows}
      <td />
      <td>
        <TableInput
          data={newData.estimatedCost!}
          isBold
          color={color}
          isDisabled={true}
        />
      </td>
      <td className="w-1/18" />
    </tr>
  );
};

export default FinancialElementRow;
