import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import config from 'config/config';
import { Button } from 'stories/Button';

function Completion() {
  const { t } = useTranslation();

  return (
    <div className="mt-32 text-xl">
      {t(`myItProject.completion.description`)}
      <div className="absolute space-x-4 -bottom-20 right-4">
        <Link to={config.localRoutes.landing}>
          <Button theme="secondary">{t('myItProject.completion.end')}</Button>
        </Link>
      </div>
    </div>
  );
}

export default Completion;
