import classNames from 'classnames';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from 'components/common/Checkbox';

export type FormIconSubmitButtonProps = PropsWithChildren<{
  disabled?: boolean;
  approved?: boolean;
  active?: boolean;
  onClick?: () => void;
}>;
function FormIconSubmitButton({
  children,
  disabled = false,
  approved = false,
  active,
  onClick,
}: FormIconSubmitButtonProps) {
  const { formState } = useFormContext();
  const submitRef = useRef<HTMLButtonElement | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

  useEffect(() => {
    // setIsDisabled(disabled ? disabled : !formState.isValid || formState.isValidating || formState.isSubmitSuccessful || approved)
    setIsDisabled(disabled ? disabled : formState.isValidating || formState.isSubmitSuccessful || approved)
  }, [formState, disabled]);


  const handleClick = () => {
    submitRef.current?.click();
    onClick?.();
  };
  return (
    <div className="flex items-center justify-start space-x-5">
      <Checkbox
        disabled={isDisabled}
        defaultChecked={formState.isSubmitSuccessful || approved}
        onChange={handleClick}
        className={classNames({ '!cursor-default': isDisabled })}
      />
      <button type="submit" className="hidden" ref={submitRef} />
      {/* <button
        type="submit"
        disabled={disabled || isDisabled || formState.isSubmitSuccessful}
        className={classNames(
          'flex items-center justify-center w-12 h-9 rounded-3xl space-x-5',
          {
            'bg-tableDarkGrey': disabled,
            'bg-grey': active && !disabled,
            'bg-primary': formState.isSubmitSuccessful || approved,
          },
        )}
        onClick={() => !active && onClick?.()}
      >
        {(formState.isSubmitSuccessful || approved) && (
          <FontAwesomeIcon icon={faCheck} className="text-xs text-white" />
        )}
      </button> */}
      <span
        className={classNames('text-xl', {
          'text-tableDarkGrey': !active && !disabled,
          'text-grey': !active && disabled,
        })}
      >
        {children}
      </span>
    </div>
  );
}
export default FormIconSubmitButton;
