import { Trans, useTranslation } from 'react-i18next';
import {
  useUpdateLandingApplicationClusterMutation,
  useUpdateLandingApplicationMutation,
} from '../../../graphql/codegen/graphql';
import Dialog from '../../common/Dialog';
import { Button } from '../../common/Button';
import React from 'react';
import { EditDialogProps } from './CreateEditDialog';

const DeleteDialog = ({ editApplication, onClose, mode }: EditDialogProps) => {
  const { t } = useTranslation();

  const [updateLandingApplication] = useUpdateLandingApplicationMutation({
    variables: {
      data: {
        isActive: {
          set: false,
        },
        order: {
          set: null,
        },
      },
      id: editApplication?.id!,
    },
    refetchQueries: ['LandingApplications'],
  });

  const [updateLandingApplicationCluster] =
    useUpdateLandingApplicationClusterMutation({
      variables: {
        data: {
          isActive: {
            set: false,
          },
          order: {
            set: -1,
          },
        },
        where: {
          id: editApplication?.id,
        },
      },
      refetchQueries: ['LandingApplicationClusters'],
    });

  const handleConfirm = async () => {
    if (editApplication?.id && mode === 'Delete') {
      await updateLandingApplication();
    } else if (editApplication?.id && mode === 'DeleteCluster') {
      await updateLandingApplicationCluster();
    }
    onClose();
  };

  return (
    <Dialog
      open={!!editApplication}
      onClose={onClose}
      title={t('itProjects.landingApplication.deleteDialog.delete')}
    >
      <div className="space-y-4">
        <Trans t={t} i18nKey="itProjects.landingApplication.deleteMessage">
          t
          <b>
            {{
              value:
                editApplication?.['name'] ?? editApplication?.['clusterName'],
            }}
          </b>
        </Trans>
        <div>
          <Button theme={'danger'} onClick={handleConfirm}>
            {t`itProjects.landingApplication.deleteButton`}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
