import { PropsWithChildren } from 'react';
import ProgressListItem from './ProgressListItem';

export interface ProgressListProps {}
const ProgressList = ({ children }: PropsWithChildren<ProgressListProps>) => {
  return <ol className="items-center list-decimal">{children}</ol>;
};

ProgressList.displayName = 'ProgressList';
ProgressList.Item = ProgressListItem;

export default ProgressList;
