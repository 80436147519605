import { useTranslation } from 'react-i18next';
import { ReportingGetProjectByIdQuery } from '../../../../graphql/codegen/graphql';

import Table from 'components/NewTable';

import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import useTypedForm from 'components/common/Form/components';

import ToolTip from 'components/common/ToolTip';

export interface OverviewRolesProps {
  projectId: string;
  data: ReportingGetProjectByIdQuery | undefined;
  loading: boolean;
}

const OverviewRoles = ({ projectId, data, loading }: OverviewRolesProps) => {
  const { t } = useTranslation();
  const basePath = config.localRoutes.tdReporting.projectoverview.replace(
    ':projectId',
    projectId,
  );
  const { Form } = useTypedForm();

  let sortArrayShortHistory: any = [];
  data?.masters[0].projectShortHistory?.forEach(h =>
    sortArrayShortHistory.push(h),
  );

  return (
    <Form onSubmit={() => console.log('')}>
      <div className="w-full">
        <Breadcrumb
          label={t`tdReporting.projectoverviewPage.tooltip.role`}
          route={basePath}
        />
        <div className="w-min-40 px-4 bg-white">
          <dl>
            <div className="sm:col-span-1 space-y-5">
              <div className="w-full space-y-5">
                <dt className="pt-10 text-primary flex">
                  {t`tdReporting.projectoverviewPage.roles`}

                  <ToolTip mode="hover" color="text-customBlue">
                    {t`tdReporting.projectoverviewPage.tooltip.role`}
                  </ToolTip>
                </dt>

                <dd className="mt-1 text-sm text-gray-900">
                  {
                    <Table
                      data={data?.masters[0]?.projectRole ?? []}
                      loading={loading}
                      columns={[
                        {
                          label: t`tdReporting.projectoverviewPage.role`,
                          render: data => <div>{data?.role.id}</div>,
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.person`,
                          render: data => (
                            <div>
                              {data?.employee.lastName +
                                ', ' +
                                data?.employee.firstName}
                            </div>
                          ),
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.department`,
                          render: data => (
                            <div>{data?.employee.department}</div>
                          ),
                        },
                      ]}
                    />
                  }
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </Form>
  );
};
export default OverviewRoles;
