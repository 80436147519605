import { useAppContext } from 'AppContext';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { useLocation } from 'react-router-dom';
import FinancialTable, {
  Allocation,
  FinancialTableRow,
} from 'components/financialData/FinancialTable';
import {
  useGetAllocationsLazyQuery,
  useGetFinancialByProjectLazyQuery,
  SortOrder,
} from 'graphql/codegen/graphql';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'stories/Button';

export type FinancialDataProps = {
  values: {
    addBudget: boolean;
    name?: string;
    allocations?: Allocation[];
    tableData?: FinancialTableRow[];
    isRequired?: boolean;
    editedProjectId?: string;
  };
  // onSaveTable: (data: {allocations: Allocation[], tableData: FinancialTableRow[]});
  onSubmit: (data?: {
    allocations: Allocation[];
    tableData: FinancialTableRow[];
  }) => void;
  onPrev: (data?: {
    allocations: Allocation[];
    tableData: FinancialTableRow[];
  }) => void;
  handleBudgetChange: (value: boolean) => void;
};

const Financial = ({
  values,
  onSubmit,
  onPrev,
  handleBudgetChange,
}: FinancialDataProps) => {
  const { t } = useTranslation();
  const app = useAppContext();
  const [
    getAllocations,
    { loading: allocationsLoading, data: allocationsData },
  ] = useGetAllocationsLazyQuery();
  const [getFinancialData, { loading: financialLoading, data: financialData }] =
    useGetFinancialByProjectLazyQuery();

  useEffect(() => {
    getAllocations();
    fetchFinancialData();
  }, []);

  const fetchFinancialData = () => {
    getFinancialData({
      variables: {
        where: {
          projectId: {
            equals: values.editedProjectId ? values.editedProjectId : '-',
          },
        },
        assetsOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
        companiesOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
      },
    });
  };

  const handlePrevWithoutBudget = () => {
    onPrev();
  };

  const handleSubmitWithoutBudget = () => {
    onSubmit();
  };

  return (
    <div className="w-full ">
      <Breadcrumb label="Finanzen" route="neue-it-initiative/financial-data" />
      <div>{t('myItIdea.financial-data.budgetQuestion')}</div>
      <div className="space-x-2">
        <p>
          <input
            className="mr-2"
            type="radio"
            name="addBudget"
            id="yes"
            value="yes"
            checked={values.addBudget}
            onChange={() => handleBudgetChange(true)}
          />
          <label htmlFor="yes" className="mr-6">
            {t('myItIdea.financial-data.addBudgetTrue')}
          </label>
          <input
            className="mr-2"
            type="radio"
            name="addBudget"
            id="no"
            value="no"
            checked={!values.addBudget}
            onChange={() => handleBudgetChange(false)}
          />
          <label htmlFor="no">
            {t('myItIdea.financial-data.addBudgetFalse')}
          </label>
        </p>
      </div>
      {values.addBudget ? (
        <>
          <div className='pb-2'>{t('myItIdea.financial-data.description')}</div>
          <FinancialTable
            allocationsData={allocationsData}
            allocs={values?.allocations}
            financialData={financialData}
            tData={values?.tableData}
            isRequired={values?.isRequired}
            handlePrev={onPrev}
            handleSubmit={onSubmit}
          />
        </>
      ) : (
        <div className=" absolute right-0 bottom-0 flex justify-end gap-8 py-8">
          <Button theme="secondary" onClick={handlePrevWithoutBudget}>
            {t('myItIdea.actions.prev')}
          </Button>
          <Button theme="primary" onClick={handleSubmitWithoutBudget}>
            {t('myItIdea.financial-data.submit')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Financial;
