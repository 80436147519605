import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Dropdown,
  Navbar,
  Nav,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';

import { AppUser, useAppContext } from '../AppContext';
import { getInitials } from '../helpers/util';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

interface UserAvatarProps {
  user: AppUser;
}

function UserAvatar(props: UserAvatarProps) {
  return (
    <img src={props.user.photo || '/images/no-profile-photo.png'} alt="user" />
  );
}

const UserMenu = () => {
  const { t } = useTranslation();
  const app = useAppContext();
  const user = app.user || { displayName: '', email: '' };

  return (

    <Container>
      <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav navbar>
            <AuthenticatedTemplate>
             <NavDropdown title={getInitials(user.displayName!)} id="user-dropdown" align="end" className="flex items-center justify-center w-9 h-9 text-white bg-primary rounded-full">   
                <div className="absolute right-0 mt-2 w-min-40 p-2 px-4 bg-white border border-gray-200 rounded-md outline-none shadow-lg origin-top-right">
                  <h5 className="flex items-center pt-2 w-full text-left text-black text-sm">{user.displayName}</h5>
                  <p className="flex items-center py-4 w-full text-left text-black text-sm">{user.email}</p>
                    <Dropdown.Divider className="w-min-40" />
                  <Dropdown.Item onClick={app.signOut!} className="flex items-center pt-3 pb-2 w-full text-left text-black text-sm">Sign Out</Dropdown.Item>
                </div>
              </NavDropdown>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <NavItem>
                <Nav.Link onClick={app.signIn!}>Sign In</Nav.Link>
              </NavItem>
            </UnauthenticatedTemplate>
          </Nav>
        </Navbar.Collapse>
    </Container>

  );
};

export default UserMenu;
