import { useTranslation } from 'react-i18next';
import { ReportingGetProjectByIdQuery } from '../../../../graphql/codegen/graphql';
import { useState } from 'react';

import Table from 'components/NewTable';
import { format, parseISO } from 'date-fns';
import EditProjectPlanningDialog from '../../../../components/TDReporting/Dialog/EditProjectPlanningDialog';

import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import useTypedForm from 'components/common/Form/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

export interface OverviewPlanningProps {
  projectId: string;
  data: ReportingGetProjectByIdQuery | undefined;
  loading: boolean;
}

const OverviewPlanning = ({
  projectId,
  data,
  loading,
}: OverviewPlanningProps) => {
  const { t } = useTranslation();
  const [editProjectPlanning, setEditProjectPlanning] = useState<string>('');
  const basePath = config.localRoutes.tdReporting.projectoverview.replace(
    ':projectId',
    projectId,
  );

  const { Form } = useTypedForm();

  let sortArrayShortHistory: any = [];
  data?.masters[0].projectShortHistory?.forEach(h =>
    sortArrayShortHistory.push(h),
  );

  return (
    <Form onSubmit={() => console.log('')}>
      <div className="w-full">
        <Breadcrumb
          label={t`tdReporting.projectoverviewPage.projectPlanning`}
          route={basePath}
        />
        <div className="w-min-40 px-4 bg-white">
          <dl>
            <div className="sm:col-span-1 space-y-5">
              <div className="w-full space-y-5">
                <dt className="pt-10 text-primary">{t`tdReporting.projectoverviewPage.projectPlanning`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {
                    <Table
                      data={data?.masters[0]?.projectPhaseCapture ?? []}
                      loading={loading}
                      columns={[
                        {
                          label: t`tdReporting.projectoverviewPage.projectDescription`,
                          render: data => <div>{data?.projectPhaseId}</div>,
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.comment`,
                          tooltip: t`tdReporting.projectoverviewPage.tooltip.comment`,
                          render: data => (
                            <div className={'w-40 truncate'}>
                              {data?.comment}
                            </div>
                          ),
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.start`,
                          render: data => (
                            <div>
                              {format(
                                parseISO(data?.startDate as unknown as string),
                                'dd.MM.yyyy',
                              )}
                            </div>
                          ),
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.end`,
                          render: data => (
                            <div>
                              {format(
                                parseISO(data?.endDate as unknown as string),
                                'dd.MM.yyyy',
                              )}
                            </div>
                          ),
                        },
                        {
                          label: '',
                          render: data => (
                            <div className="cursor-pointer flex items-center justify-center rounded-full hover:bg-slate-200">
                              <button
                                onClick={() => {
                                  setEditProjectPlanning(data.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </button>
                            </div>
                          ),
                        },
                      ]}
                    />
                  }
                </dd>
              </div>
            </div>
          </dl>
          <EditProjectPlanningDialog
            editProjectPlanning={editProjectPlanning}
            onClose={() => setEditProjectPlanning('')}
            data={data}
          />
        </div>
      </div>
    </Form>
  );
};
export default OverviewPlanning;
