import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import 'components/financialData/table.css';
import { useAppContext } from 'AppContext';
import { useTranslation } from 'react-i18next';
import {
  ProjectResourceCaptureScalarFieldEnum,
  SortOrder,
  useCreateFinanceMutation,
  useCreateProjectAllocationInputMutation,
  useCreateProjectShortHistoryMutation,
  useDashboardGetProjectByIdLazyQuery,
  useGetAllocationsLazyQuery,
  useGetFinancialByProjectLazyQuery,
  useGetProjectAllocationsLazyQuery,
  useGroupProjectResourceCapturesLazyQuery,
  useUpdateFinanceMutation,
  useUpdateProjectAllocationMutation,
} from 'graphql/codegen/graphql';

import FinancialTable, {
  FinancialTableRow,
  FinancialValue,
} from 'components/financialData/FinancialTable';
import TDReportingAuth from '../../../../components/TDReporting/TDReportingAuth';
import { Alert } from 'react-bootstrap';
import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { isUserInGroup } from 'helpers/util';

export type ProjectCostsPageProps = {
  projectId: string;
};

const ProjectCostsPage = ({ projectId }: ProjectCostsPageProps) => {
  const app = useAppContext();
  const basePath = config.localRoutes.tdReporting.projectcosts.replace(
    ':projectId',
    projectId,
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [project, setProject] = useState<any>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const { t } = useTranslation();
  const [getAllocations, { data: allocationsData }] =
    useGetAllocationsLazyQuery();
  const [getProjectAllocations, { data: projectAllocationsData }] =
    useGetProjectAllocationsLazyQuery();
  const [getFinancialData, { data: financialData }] =
    useGetFinancialByProjectLazyQuery();
  const [getProjectResourceCaptures, { data: projectResourceCapturesData }] =
    useGroupProjectResourceCapturesLazyQuery();
  const [createProjectAllocation] = useCreateProjectAllocationInputMutation();
  const [updateProjectAllocation] = useUpdateProjectAllocationMutation();
  const [updateFinance] = useUpdateFinanceMutation();
  const [createFinance] = useCreateFinanceMutation();

  const [getProjectById] = useDashboardGetProjectByIdLazyQuery({
    onCompleted: data => {
      setProject(data.masters[0]);
    },
  });

  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();

  const securityGroups = [
    'c8c651ba-4085-46bb-98f5-7c9e758c5b92',
    'ebf948fe-739c-46bf-9a0a-9f04f0b01b6b',
  ];

  const isUserInSecurityGroup = isUserInGroup(securityGroups, app.user);

  useEffect(() => {
    if (projectId && authenticated) {
      getProjectById({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId, getProjectById, authenticated]);

  useEffect(() => {
    if (project) {
      const projectAccepted = project.projectStatus.find(
        s =>
          s.deletedBy === null && s.sequenceStatusId === 'IT-Idee akzeptiert',
      );
      const approvalConId = project.projectApproval.some(
        a => a.approvalBoardId === 'con',
      );
      const projectIdea = project.projectStatus.find(
        s =>
          s.deletedBy === null &&
          s.sequenceStatusId === 'Eingereicht durch IT-Idee',
      );
      const projectActive = project.projectStatus.find(
        s =>
          s.deletedBy === null && s.sequenceStatusId === 'Projektaktivierung',
      );
      setDisabled(
        (!projectAccepted &&
          !approvalConId &&
          !projectIdea &&
          !projectActive) ||
          !isUserInSecurityGroup,
      );
    }
  }, [project]);

  const fetchFinancialData = useCallback(() => {
    if (authenticated) {
      getFinancialData({
        variables: {
          where: {
            projectId: {
              equals: projectId,
            },
          },
          assetsOrderBy: [
            {
              description: SortOrder.Asc,
            },
          ],
          companiesOrderBy: [
            {
              description: SortOrder.Asc,
            },
          ],
        },
      });
      getProjectResourceCaptures({
        variables: {
          by: [ProjectResourceCaptureScalarFieldEnum.Year],
          sum: {
            rate: true,
          },
          where: {
            projectId: {
              equals: projectId,
            },
            deletedBy: {
              equals: null,
            },
          },
        },
      });
    }
  }, [getFinancialData, getProjectResourceCaptures, projectId, authenticated]);

  useEffect(() => {
    if (authenticated) {
      getAllocations();
      getProjectAllocations({
        variables: {
          projectAllocationsWhere: {
            projectId: {
              equals: projectId,
            },
          },
        },
      });
      fetchFinancialData();
    }
  }, [
    fetchFinancialData,
    getAllocations,
    getProjectAllocations,
    projectId,
    authenticated,
  ]);

  const createProjectAllocationData = (
    allocationId: string,
    percentage: number,
  ) => {
    createProjectAllocation({
      variables: {
        data: {
          id: uuidv4,
          allocation: {
            connect: {
              id: allocationId,
            },
          },
          percentage: percentage,
          project: {
            connect: {
              id: projectId,
            },
          },
        },
      },
    });
  };

  const connectAllocation = (id: string, percentage: number) => {
    updateProjectAllocation({
      variables: {
        data: {
          projectAllocations: {
            update: [
              {
                data: {
                  percentage: {
                    set: parseFloat(percentage.toString()),
                  },
                },
                where: {
                  id: id,
                },
              },
            ],
          },
        },
        where: {
          id: projectId,
        },
      },
    });
  };

  const testAllocationExistence = (
    id: string,
    allocationId: string,
    percentage: number,
  ) => {
    if (projectAllocationsData?.projectAllocations.length) {
      connectAllocation(allocationId, percentage);
    } else {
      createProjectAllocationData(id, percentage);
    }
  };

  const updateFinanceData = async (data: { value: number; id: string }) => {
    await updateFinance({
      variables: {
        where: {
          id: data.id,
        },
        data: {
          deletedBy: {
            set: app.user!.kid!,
          },
          deletedAt: {
            set: new Date(),
          },
        },
      },
    });
  };

  const createFinanceData = async (data: {
    assetId: string;
    companyId: string;
    valueData: FinancialValue;
  }) => {
    await createFinance({
      variables: {
        data: {
          asset: {
            connect: {
              id: data.assetId,
            },
          },
          company: {
            connect: {
              id: data.companyId,
            },
          },
          project: {
            connect: {
              id: projectId,
            },
          },
          value: +data.valueData.value!,
          year: +data.valueData.year!,
          createdBy: app.user!.kid!,
        },
      },
    });
  };

  const createProjectShortHistoryData = () => {
    createProjectShortHistory({
      variables: {
        data: {
          action: t('itProjects.detail.labels.financial-data'),
          createdBy: app.user!.kid!,
          project: {
            connect: {
              id: projectId,
            },
          },
        },
      },
    });
  };

  const saveTableData = async (
    tableData: FinancialTableRow[],
    initialTable: FinancialTableRow[],
    allocationChanged: boolean,
  ) => {
    setIsUpdating(true);
    enum Operation {
      create,
      update,
      remove,
    }
    let array: { operation: Operation; data: any }[] = [];
    tableData.forEach((rowData: FinancialTableRow, rowIndex: number) => {
      rowData.values?.forEach((vData: FinancialValue, vIndex: number) => {
        if (rowData.isSummary) return;
        if (vData.value !== initialTable[rowIndex].values![vIndex].value) {
          if (vData.id.substring(0, 4) === 'key-') {
            if (!vData.value) return;
            array.push({
              operation: Operation.create,
              data: {
                assetId: rowData.assetId,
                companyId: rowData.companyId || '',
                valueData: vData,
              },
            });
          } else {
            if (vData.value === 0 || !vData.value) {
              array.push({
                operation: Operation.update,
                data: {
                  value: vData.value,
                  id: vData.id,
                  assetId: rowData.assetId,
                  companyId: rowData.companyId || '',
                  valueData: vData,
                },
              });
            } else {
              array.push({
                operation: Operation.update,
                data: {
                  value: vData.value,
                  id: vData.id,
                  assetId: rowData.assetId,
                  companyId: rowData.companyId || '',
                  valueData: vData,
                },
              });
            }
          }
        }
      });
    });
    const promiseArray = array.map(item => {
      switch (item.operation) {
        case Operation.create: {
          return createFinanceData({
            assetId: item.data.assetId,
            companyId: item.data.companyId,
            valueData: item.data.valueData,
          });
        }
        case Operation.update: {
          return updateFinanceData({
            value: item.data.value,
            id: item.data.id,
          });
        }
        case Operation.remove: {
          return updateFinanceData({
            value: item.data.assetId,
            id: item.data.id,
          });
        }
        default: {
          return;
        }
      }
    });
    Promise.all(promiseArray).then(() => {
      setIsUpdating(false);
      fetchFinancialData();
    });
    if (array.length === 0) {
      if (!allocationChanged) {
        return;
      }
      createProjectShortHistoryData();
    } else {
      createProjectShortHistoryData();
    }
  };
  const projectName =
    project?.projectDescriptions[0].name && project?.projectDescriptions
      ? project?.projectDescriptions[0].name
      : t('myItIdea.financial-data.noProjectName');
  const createdAt =
    project?.createdAt && project
      ? new Date(project.createdAt).toLocaleDateString()
      : t('myItIdea.financial-data.noDate');

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div className="w-full flex flex-1 flex-col">
      <Breadcrumb
        label={t('tdReporting.pages.projectCosts')}
        route={basePath}
      />
      <div className="flex pt-8 place-content-center gap-8 pb-5">
        <p>
          {t('myItIdea.financial-data.projectCreated', {
            projectName,
            createdAt,
          })}
        </p>
      </div>
      <FinancialTable
        projectId={projectId}
        allocationsData={allocationsData}
        allocs={projectAllocationsData?.projectAllocations}
        financialData={financialData}
        resourceData={projectResourceCapturesData}
        isUpdating={isUpdating}
        isDisabled={isDisabled}
        isNumericInputDisabled={!isUserInSecurityGroup}
        handleSaveTableData={saveTableData}
        handleConnectAllocation={testAllocationExistence}
      />
    </div>
  );
};
export default ProjectCostsPage;
