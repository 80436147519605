import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import useTypedForm from 'components/common/Form/components';
import Dropdown from 'components/common/Form/components/Dropdown/variants/Default';

import {
  useGetInterfaceTypesQuery,
  useGetInterfaceFormatsQuery,
} from 'graphql/codegen/graphql';

import { debounce } from 'lodash';
import { ProjectInterfaceExportDetail, FormData } from '../../interfaceTypes';

export interface CharacteristicProps {
  exportDetails: ProjectInterfaceExportDetail;
  interfaceId: string;
  onInput: (exportDetails: ProjectInterfaceExportDetail) => void;
}
const ExportDetail: React.VFC<CharacteristicProps> = ({
  exportDetails,
  onInput,
}) => {
  const { t } = useTranslation();
  const { TextArea } = useTypedForm<FormData>();

  const { data: typesData, loading: typesLoading } =
    useGetInterfaceTypesQuery();
  const { data: formatData, loading: formatLoading } =
    useGetInterfaceFormatsQuery();
  const [exportDescriptions, setExportDescriptions] =
    useState<ProjectInterfaceExportDetail>({ ...exportDetails });

  useEffect(() => {
    setExportDescriptions({ ...exportDetails });
  }, [exportDetails]);

  const handleInput = (data: ProjectInterfaceExportDetail) => {
    onInput(data);
  };
  const debouncedhandleInput = useCallback(
    debounce(value => handleInput(value), 1000),
    [],
  );

  return (
    <div className="w-full">
      <div className="w-min-40 px-4 bg-white">
        <div>
          <div className="pt-4 text-primary">
            <dt className="pb-4">
              {t('tdReporting.interfaces.exportDescription')}
            </dt>
            <dd>
              <TextArea
                name="exportDescription"
                rows={5}
                value={exportDescriptions.exportDescription || ''}
                onChange={e => {
                  setExportDescriptions({
                    ...exportDescriptions,
                    exportDescription: e.target.value,
                  });
                  debouncedhandleInput({
                    ...exportDescriptions,
                    exportDescription: e.target.value,
                  });
                }}
              />
            </dd>
          </div>
          <div className="pt-4 text-primary">
            <dt className="pb-4">
              {t('tdReporting.interfaces.exportInterface')}
            </dt>
            <dd>
              <Dropdown
                variant="default"
                name="exportInterface"
                loading={typesLoading}
                options={typesData?.interfaceTypes.map(type => ({
                  label: type.id,
                  value: type.id,
                }))}
                value={exportDescriptions.interfaceTypeId}
                onChange={e => {
                  setExportDescriptions({
                    ...exportDescriptions,
                    interfaceTypeId: {
                      label: e?.label!,
                      value: e?.value!,
                    },
                  });
                  debouncedhandleInput({
                    ...exportDescriptions,
                    interfaceTypeId: {
                      label: e?.label!,
                      value: e?.value!,
                    },
                  });
                }}
              />
            </dd>
          </div>
          <div className="pt-4 text-primary">
            <dt className="pb-4">{t('tdReporting.interfaces.exportFormat')}</dt>
            <dd>
              <Dropdown
                variant="default"
                name="interfaces"
                loading={formatLoading}
                options={formatData?.interfaceFormats.map(format => ({
                  label: format.id,
                  value: format.id,
                }))}
                value={exportDescriptions.interfaceFormatId}
                onChange={e => {
                  setExportDescriptions({
                    ...exportDescriptions,
                    interfaceFormatId: {
                      label: e?.label!,
                      value: e?.value!,
                    },
                  });
                  debouncedhandleInput({
                    ...exportDescriptions,

                    interfaceFormatId: {
                      label: e?.label!,
                      value: e?.value!,
                    },
                  });
                }}
              />
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExportDetail;
