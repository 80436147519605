import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface FinancialTabsProps {
  showProjectData: boolean;
  onChange: (value: boolean) => void;
}

const FinancialTabs: React.FC<FinancialTabsProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { showProjectData, onChange } = props;

  const handleOnProject = () => {
    onChange(true);
  };

  const handleOnOperational = () => {
    onChange(false);
  };

  return (
    <div className="flex text-primary">
      <button
        type="button"
        className={`box-border px-6 py-1 border-b-2 focus:outline-none ${
          showProjectData ? 'border-primary' : 'border-white text-black'
        }`}
        onClick={handleOnProject}
      >
        {t('pages.financial.projectCost')}
      </button>
      <button
        type="button"
        className={`box-border px-6 py-1 ml-4 border-b-2 focus:outline-none ${
          showProjectData ? 'border-white text-black' : 'border-primary'
        }`}
        onClick={handleOnOperational}
      >
        {t('pages.financial.operationalCost')}
      </button>
    </div>
  );
};

export default FinancialTabs;
