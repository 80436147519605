import { useTranslation } from 'react-i18next';
import Table from 'components/NewTable';
import { Button } from 'components/common/Button';
import { AddresseeGroupType, ReportTableData } from './reportData';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from 'components/common/Dialog';
import { useState } from 'react';
import ReportPopup from './ReportPopup/reportPopup';
import { ArrayElement } from 'graphql/types';
import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';

export interface TableColumn<TData = JSX.Element> {
  label: string | JSX.Element;
  render: (data: TData) => string | number | JSX.Element;
  tooltip?: string;
}

export interface ReportTableProps {
  loading: boolean;
  report: ReportTableData[];
  projectName: string;
  onSubmit: (addresseeGroup: AddresseeGroupType[]) => void;
}

const ReportTable: React.VFC<ReportTableProps> = ({
  projectName,
  loading,
  report,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [reportId, setReportId] = useState<string>();
  const basePath = config.localRoutes.tdReporting.report;

  const getColumns = (): TableColumn<ArrayElement<ReportTableData[]>>[] => {
    return [
      {
        label: t`tdReporting.report.name`,
        render: data => <p>{data.reportTitle}</p>,
      },
      {
        label: t`tdReporting.report.content`,
        render: () => <p> {projectName}</p>,
      },
      {
        label: t`tdReporting.report.send`,
        render: data => (
          <div className="flex space-x-5">
            <button
              onClick={() => {
                setOpenReportPopup(true);
                setReportId(data.id);
              }}
            >
              <FontAwesomeIcon color="#0080C8" icon={faEnvelope} />
            </button>
          </div>
        ),
      },
    ];
  };

  const [openReportPopup, setOpenReportPopup] = useState<boolean>(false);

  const handleClose = () => {
    setOpenReportPopup(false);
  };
  return (
    <div className="w-full pt-18 space-y-10">
      <Breadcrumb label={t('tdReporting.pages.report')} route={basePath} />
      <Table
        data={report ? report : []}
        loading={loading}
        columns={getColumns()}
      />

      <Dialog
        open={openReportPopup}
        onClose={handleClose}
        title={t`tdReporting.report.addresseeName`}
      >
        <div className="space-y-5">
          <ReportPopup
            reportId={reportId}
            title={t`tdReporting.report.addresseeName`}
            tooltip={false}
            onSubmit={addresseeGroup => {
              handleClose();
              onSubmit(addresseeGroup);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};
export default ReportTable;
