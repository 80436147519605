import { useTranslation } from 'react-i18next';
import React from 'react';
import TableInput from './TableInput';
import { FinancialTableRow } from './FinancialTable';
import ToolTip from '../common/ToolTip';

interface FinancialRowProps {
  data: FinancialTableRow;
  firstValueIndex: number;
  tooltip?: string;
  disableDelta?: boolean;
}

const FinancialSumRow = (props: FinancialRowProps) => {
  const { t } = useTranslation();
  const { data, firstValueIndex, tooltip, disableDelta } = props;

  var rows: any[] = [];
  const max = 4;
  for (var i = 0; i < max; i++) {
    rows.push(<td key={i}></td>);
    rows.push(
      <td key={i + max}>
        <TableInput
          data={data.values![i + firstValueIndex]}
          color="tablelightGrey"
          isDisabled
          isBold
          disableDelta={disableDelta}
        />
      </td>,
    );
  }
  let delta = 0;
  if (data.values.some(v => v.delta)) {
    data.values.forEach(v => {
      delta += v.delta!;
    });
  }
  let newData = {
    estimatedCost: {
      id: data.estimatedCost?.id!,
      value: data.estimatedCost?.value!,
      delta: delta,
    },
  };

  return (
    <tr className="text-right bg-tableLightGrey">
      <td className="font-semibold text-left firstCol">
        <div className="flex">
          {t(data.assetId)}
          {tooltip ? (
            <div className="pl-1">
              <ToolTip mode="hover" color="text-customBlue">
                {tooltip}
              </ToolTip>
            </div>
          ) : null}
        </div>
      </td>
      {rows}
      <td />
      <td>
        <TableInput
          data={newData.estimatedCost!}
          color="tableLightGrey"
          isDisabled
          isBold
        />
      </td>
      <td className="w-1/18" />
    </tr>
  );
};

export default FinancialSumRow;
