import FormDatePicker, { FormDatePickerProps } from './DatePicker';
import FormDropdown, { FormDropdownProps } from './Dropdown';
import Form, { FormProps } from './Form';
import FormSubmitButton, { FormSubmitButtonProps } from './SubmitButton';
import FormCancelButton, { FormCancelButtonProps } from './CancelButton';
import FormIconSubmitButton, {
  FormIconSubmitButtonProps,
} from './IconSubmitButton';
import FormSwitch, { FormSwitchProps } from './Switch';
import FormUploadButton, { FormUploadButtonProps } from './UploadButton';
import FormTextArea, { FormTextAreaProps } from './TextArea';
import FormTextInput, { FormTextInputProps } from './TextInput';
import FormValue, { FormValueProps } from './Value';
import { useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import FormHidden, { FormHiddenProps } from './Hidden/Hidden';
import FormCheckbox, { FormCheckboxProps } from './Checkbox';

function useTypedForm<TFieldValues extends FieldValues = FieldValues>() {
  return useMemo(
    () => ({
      Checkbox(props: FormCheckboxProps<TFieldValues>) {
        return <FormCheckbox {...props} />;
      },
      DatePicker(props: FormDatePickerProps<TFieldValues>) {
        return <FormDatePicker {...props} />;
      },
      Form(props: FormProps<TFieldValues>) {
        return <Form {...props} />;
      },
      Hidden(props: FormHiddenProps<TFieldValues>) {
        return <FormHidden {...props} />;
      },
      TextArea(props: FormTextAreaProps<TFieldValues>) {
        return <FormTextArea {...props} />;
      },
      TextInput(props: FormTextInputProps<TFieldValues>) {
        return <FormTextInput {...props} />;
      },
      Switch(props: FormSwitchProps<TFieldValues>) {
        return <FormSwitch {...props} />;
      },
      Dropdown<TOption>(props: FormDropdownProps<TFieldValues, TOption>) {
        return <FormDropdown {...props} />;
      },
      UploadButton(props: FormUploadButtonProps<TFieldValues>) {
        return <FormUploadButton {...props} />;
      },
      Value<
        TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
      >(props: FormValueProps<TFieldValues, TFieldName>) {
        return <FormValue {...props} />;
      },
      SubmitButton(props: FormSubmitButtonProps) {
        return <FormSubmitButton {...props} />;
      },
      CancelButton(props: FormCancelButtonProps) {
        return <FormCancelButton {...props} />;
      },
      IconSubmitButton(props: FormIconSubmitButtonProps) {
        return <FormIconSubmitButton {...props} />;
      },
    }),
    [],
  );
}
export default useTypedForm;
