import React from 'react';
import { Alert } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthenticationMessage from '../../AuthenticationMessage';
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumb';
import { Breadcrumbs } from '../../components/Breadcrumbs';

const DashboardProjectPage: React.VFC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation();

  if (AuthenticationMessage('dashboard-projects')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  }

  return (
    <div>
      <div className="relative flex flex-col w-full min-h-full font-primary">
        <Breadcrumbs />
        <Breadcrumb
          label={t`dashboardProject.headline`}
          route="/dashboard-projects"
        />
        <div className="mb-5 border-b border-gray-300 pb-5">
          <div className="flex items-center justify-between">
            <div className="sm:w-0 sm:flex-1">
              <h3 className="text-lg font-medium leading-9 text-gray-900">
                {t`dashboardController.headline`}
              </h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-600">
                {t`dashboardProject.description`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardProjectPage;
