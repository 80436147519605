import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserMenu from './UserMenu';
import logo from '../assets/images/haw-logo.svg';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname =
    location.pathname.split('/')[1] === 'powerapp' ||
    location.pathname.split('/').pop() === 'apus'
      ? location.pathname.split('/')[2]
      : location.pathname.split('/')[1];

  if (
    location.pathname.split('/').pop() === 'apus'
  ) {
    return null;
  }

  return (
    <div className="relative z-10 pt-7 w-full px-8 md:px-16">
      <div className="border-b-2 border-t-2">
        <nav className="nav nav-header relative flex items-center justify-between list-none mb-8">
          <li
            className="nav-item mt-8"
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://meine-it.hansewerk.com', '_blank')}
          >
            <img src={logo} alt="" width="129" />
          </li>

          <li className="invisible sm:visible nav-item flex pr-14 justify-center w-full mt-8">
            <NavLink to="/" className="text-primary text-2xl font-medium">
              {t(`header./${pathname}`)}
            </NavLink>
          </li>

          <li className="nav-item flex items-center justify-center mt-3">
            <UserMenu />
          </li>
        </nav>
        {/*<MainNavigation />*/}
      </div>
    </div>
  );
};

export default Header;
