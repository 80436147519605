import React, { useEffect, useState } from 'react';
import { useCheckProjectResourceCaptureLazyQuery } from '../../graphql/codegen/graphql';
import { getProjectStatusByFilterType } from '../../pages/Reporting/ProjectSelection/ProjectSelection';
import { useAppContext } from '../../AppContext';
import AuthenticationMessage from '../../AuthenticationMessage';

export default function TDReportingAuth(
  applicationId: string,
  projectId?: string,
) {
  const { user } = useAppContext();
  const [authenticated, setAuthenticated] = useState(false);

  const [checkProjektResourceCapture, { data: projectResourceCaptureData }] =
    useCheckProjectResourceCaptureLazyQuery({
      variables: {
        projectStatus: getProjectStatusByFilterType('activated'),
        projectEmployee: user ? user.kid : '',
        id: projectId,
      },
    });

  useEffect(() => {
    checkProjektResourceCapture();
  }, []);

  if (AuthenticationMessage(applicationId)) {
    if (projectResourceCaptureData?.masters.length) {
      if (!authenticated) {
        setAuthenticated(true);
      }
      return false;
    } else {
      return true;
    }
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
    return false;
  }
}
