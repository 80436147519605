import React from 'react';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import AuthenticationMessage from '../AuthenticationMessage';
import { useGetLandingApplicationQuery } from '../graphql/codegen/graphql';

const PowerAppPage = () => {
  const location = useLocation();
  const appId = location.pathname.split('/').pop();

  const { error, data } = useGetLandingApplicationQuery({
    variables: {
      where: {
        id: appId,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (error) return <p>Oh no... {error.message}</p>;

  if (AuthenticationMessage(appId!)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  }

  return (
    <div className="w-full h-full PowerApp">
      <iframe
        title="PowerApp"
        className="w-full h-full"
        src={data?.landingApplication.url!}
        allow="geolocation; microphone; camera"
      />
    </div>
  );
};

export default PowerAppPage;
