import classNames from 'classnames';
import Label from 'components/common/Label';
import React, { TextareaHTMLAttributes, useEffect, useState } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import autosizeTextArea from './autosize';

export type FormTextAreaProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<UseControllerProps<TFieldValues>, 'control' | 'shouldUnregister'> &
    TextareaHTMLAttributes<HTMLTextAreaElement> & {
      label?: string;
      autoHeight?: boolean;
    };
function FormTextArea<TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  rules,
  label,
  onChange,
  disabled = false,
  autoHeight,
  className,
  ...rest
}: FormTextAreaProps<TFieldValues>) {
  const [numberRows] = useState<number | undefined>(undefined);
  const [id] = useState(uuidv4());
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules,
  });
  const value = useWatch({
    name,
    defaultValue,
  });
  const handleScale = (e: { target: HTMLElement | null }) => {
    if (autoHeight) {
      autosizeTextArea(e);
    }
  };

  useEffect(() => {
    handleScale({ target: document.getElementById(id) });
  });

  return (
    <div>
      {label && <Label>{label}</Label>}
      <textarea
        id={id}
        className={classNames(
          className,
          'p-4 w-full text-sm border border-gray-300 rounded-md hover:border-primary focus:border-primary placeholder:text-gray-400',
          {
            'text-gray-400': disabled,
          },
        )}
        disabled={disabled}
        {...field}
        value={value || ''}
        onChange={event => {
          onChange?.(event);
          field.onChange(event);
        }}
        rows={numberRows}
        {...rest}
      />
      {fieldState.error ? (
        <div className="text-red-500">{fieldState.error.message}</div>
      ) : null}
    </div>
  );
}
export default FormTextArea;
