import { Link } from 'react-router-dom';
import { Button } from 'components/common/Button';

import {
  SortOrder,
  LandingApplicationClustersQuery,
  useGetLandingApplicationClustersQuery,
  useGetLandingApplicationsLazyQuery,
} from 'graphql/codegen/graphql';
import { useAppContext } from '../AppContext';
import ToolTip from 'components/common/ToolTip';
import { useEffect, useState } from 'react';
import TDReportingAuth from '../components/TDReporting/TDReportingAuth';

const LandingPage = () => {
  const { user } = useAppContext();
  localStorage.removeItem('DashboardProjectFilter');
  const [cluster, setCluster] = useState<
    LandingApplicationClustersQuery['landingApplicationClusters'] | null
  >(null);

  const [getLandingApplications, { data, loading, error }] =
    useGetLandingApplicationsLazyQuery({
      variables: {
        orderBy: {
          order: SortOrder.Asc,
        },
        where: {
          isActive: {
            equals: true,
          },
          landingApplicationClusterMapping: {
            some: {
              clusterId: {
                equals: cluster?.['id'],
              },
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    });

  const {
    loading: loadingCluster,
    error: errorCluster,
    data: dataCluster,
  } = useGetLandingApplicationClustersQuery({
    variables: {
      orderBy: {
        order: SortOrder.Asc,
      },
      where: {
        isActive: {
          equals: true,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleOnClick = landingApplicationCluster => {
    setCluster(landingApplicationCluster);
  };

  useEffect(() => {
    getLandingApplications();
  }, [cluster]);

  const showTDReportingApp = TDReportingAuth('td-reporting');

  const returnLandingApps = (landingApplication, index) => {
    if (
      landingApplication?.landingApplicationClusterMapping?.some(m => {
        return cluster?.['id']
          ? m.clusterId === cluster?.['id'] && m.deletedBy === null
          : m.clusterId === 'startseite' && m.deletedBy === null;
      })
    ) {
      if (
        landingApplication.landingApplicationGroup?.some(a =>
          user?.groups?.includes(a.groupId),
        ) ||
        landingApplication.id === 'td-reporting'
      ) {
        if (
          !landingApplication.isIframe! &&
          (landingApplication.url!.indexOf('https') === 0 ||
            landingApplication.url!.indexOf('http') === 0)
        ) {
          return (
            <a
              href={landingApplication.url!}
              target="_blank"
              key={landingApplication.id}
              rel="noreferrer"
              className="relative"
            >
              <div className="flex items-center justify-center p-5 space-x-4 text-center bg-white border border-gray-500 rounded min-h-48">
                <img
                  src={
                    landingApplication.iconLocation
                      ? process.env.REACT_APP_BACKEND_BASE_URL +
                        landingApplication.iconLocation
                      : 'images/landing/' + landingApplication.icon
                  }
                  alt={landingApplication.name}
                  className="object-scale-down max-h-36 py-5"
                />
                <div className="absolute top-0 right-0">
                  <ToolTip
                    mode="hover"
                    position={(index + 1) % 3 === 0 ? 'left' : 'right'}
                    color="text-customBlue opacity-50"
                  >
                    {landingApplication.tooltip}
                  </ToolTip>
                </div>
              </div>
            </a>
          );
        } else {
          return (
            <Link
              className="relative h-full"
              to={
                landingApplication.isIframe
                  ? landingApplication.id
                  : landingApplication.url!
              }
              key={landingApplication.id}
              rel={`${
                landingApplication.isIframe ? 'noopener noreferrer' : ''
              }`}
            >
              <div className="flex items-center justify-center p-5 space-x-4 text-center bg-white border border-gray-500 rounded min-h-48">
                <img
                  src={
                    landingApplication.iconLocation
                      ? process.env.REACT_APP_BACKEND_BASE_URL +
                        landingApplication.iconLocation
                      : 'images/landing/' + landingApplication.icon
                  }
                  alt={landingApplication.name}
                  className="object-scale-down max-h-36 py-5"
                />
                <div className="absolute top-0 right-0">
                  <ToolTip
                    mode="hover"
                    position={(index + 1) % 3 === 0 ? 'left' : 'right'}
                    color="text-customBlue opacity-50"
                  >
                    {landingApplication.tooltip}
                  </ToolTip>
                </div>
              </div>
            </Link>
          );
        }
      }
    }
  };

  if (loading || loadingCluster)
    return (
      <div className="w-full px-16 mt-10">
        <p>Loading...</p>
      </div>
    );
  if (error || errorCluster)
    return <p>Oh no... {error?.message || errorCluster?.message}</p>;

  if (!cluster) {
    return (
      <div className="w-full px-16 mt-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 xl:gap-4">
          {data?.landingApplications
            .filter(l => {
              return l.landingApplicationClusterMapping?.some(c => {
                return c.clusterId === 'startseite';
              });
            })
            .map((landingApplication, index) => {
              if (landingApplication.id === 'td-reporting') {
                if (!showTDReportingApp) {
                  return returnLandingApps(landingApplication, index);
                }
              } else {
                return returnLandingApps(landingApplication, index);
              }
            })}
          {dataCluster?.landingApplicationClusters
            .filter(c => {
              return c.id !== 'startseite';
            })
            .map((landingApplicationCluster, index) => {
              if (
                landingApplicationCluster.landingApplicationClusterGroup?.some(
                  a => user?.groups?.includes(a.groupId),
                )
              ) {
                return (
                  <div
                    className="relative"
                    key={landingApplicationCluster.id}
                    onClick={() => handleOnClick(landingApplicationCluster)}
                  >
                    <div className="flex items-center justify-center p-5 space-x-4 text-center bg-white border-2 border-gray-500 rounded min-h-48">
                      <img
                        src={
                          landingApplicationCluster.uri
                            ? process.env.REACT_APP_BACKEND_BASE_URL +
                              landingApplicationCluster.uri
                            : 'images/cluster/' + landingApplicationCluster.icon
                        }
                        alt={landingApplicationCluster.clusterName}
                        className="object-scale-down max-h-36 py-5"
                      />
                      <div className="absolute top-0 right-0">
                        <ToolTip
                          mode="hover"
                          position={(index + 1) % 3 === 0 ? 'left' : 'right'}
                          color="text-customBlue opacity-50"
                        >
                          {landingApplicationCluster.tooltip}
                        </ToolTip>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full px-16 mt-10">
        <div className="flex justify-between mb-4">
          <div>
            <Button onClick={() => setCluster(null)} theme="primary">
              {'<'}
            </Button>
          </div>
          <p className="text-lg font-medium leading-9 text-primary">
            {cluster['clusterName']}
          </p>
          <div className="w-16"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 xl:gap-4">
          {data?.landingApplications.map((landingApplication, index) => {
            return returnLandingApps(landingApplication, index);
          })}
        </div>
      </div>
    );
  }
};

export default LandingPage;
