import useTypedForm from 'components/common/Form/components';
import Dialog from '.';
import Label from 'components/common/Label';
import {
  useGetInterfaceTypesQuery,
  useGetInterfaceFormatsQuery,
  InterfaceType,
  InterfaceFormat,
} from 'graphql/codegen/graphql';
import { useTranslation } from 'react-i18next';
import ApplicationDropdown, {
  Application,
} from 'components/inventory/Form/ApplicationDropdown';

interface FormData {
  id: string;
  name: string;
  isAutomatic: boolean;
  application: Application;
  description: {
    import: string;
    export: string;
  };
}

export interface InterfaceCreationDialogProps {
  open: boolean;
  values?: Partial<FormData>;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

function InterfaceCreationDialog({
  open,
  onClose,
  values,
  onSubmit,
}: InterfaceCreationDialogProps) {
  const interfaceTypes = useGetInterfaceTypesQuery();
  const interfaceFormats = useGetInterfaceFormatsQuery();
  const { t } = useTranslation();

  const { Form, Dropdown, TextInput, Switch, SubmitButton, Hidden } =
    useTypedForm<FormData>();
  return (
    <Dialog open={open} title={t`myItInventory.dialog.title`} onClose={onClose}>
      <Form onSubmit={onSubmit} mode="onChange" defaultValues={values || {}}>
        <Hidden name="id" />
        <div className="flex flex-col pb-6 space-y-6">
          <div>
            <Label>{t`myItInventory.dialog.name`}</Label>
            <TextInput
              rules={{
                required: true,
              }}
              name="name"
            ></TextInput>
          </div>
          <ApplicationDropdown<FormData>
            name="application"
            label={t`myItInventory.dialog.interfaceSystem`}
          />
          <Switch name="isAutomatic" defaultValue={false}>
            <span className="text-primary">{t`myItInventory.dialog.automatedInterface`}</span>
          </Switch>

          <div>
            <Label>{t`myItInventory.dialog.exportDescription`}</Label>
            <TextInput
              rules={{
                required: true,
              }}
              name="description.export"
            ></TextInput>
          </div>

          <div>
            <Label>{t`myItInventory.dialog.importDescription`}</Label>
            <TextInput
              rules={{
                required: true,
              }}
              name="description.import"
            ></TextInput>
          </div>
          <div className="flex justify-end">
            <SubmitButton>{t`myItInventory.dialog.submit`}</SubmitButton>
          </div>
        </div>
      </Form>
    </Dialog>
  );
}

export default InterfaceCreationDialog;
