import Description from './Description';
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import { parseISO } from 'date-fns';
import { toNumber } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Categorize, { FormData as CategorizeFormData } from './Categorize';
import Details, { FormData as DetailsData } from './Details';
import Documents, { FormData as DocumentsData } from './Documents';
import Financial from './Financial';
import Greeting from './Greeting';
import Success from './Success';
import Summary, { FormData as SummaryData } from './Summary';
import config from 'config/config';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { FormData as BackgroundData } from './Background';
import { ProjectStatusEnum } from 'graphql/types';
import { useAppContext } from '../../AppContext';

import {
  useCreateFinanceMutation,
  useCreateMasterMutation,
  useCreateProjectDocumentMutation,
  useCreateProjectShortHistoryMutation,
  useDashboardGetProjectByIdQuery,
  useDeleteMasterMutation,
  useGetProjectAllocationsLazyQuery,
  useUpdateProjectAllocationMutation,
  useUpdateProjectDocumentMutation,
} from 'graphql/codegen/graphql';
import {
  Allocation,
  FinancialTableRow,
  FinancialValue,
} from 'components/financialData/FinancialTable';
import { IDocument } from 'components/myItIdea/components/DocumentRow';

export type FormData = CategorizeFormData &
  SummaryData &
  BackgroundData &
  DetailsData &
  DocumentsData;

export type IdeaPageProps = RouteComponentProps<{
  step?: string;
  projectId?: string;
}>;

export interface StepProps<TData = any> {
  values?: Partial<TData>;
  documents?: IDocument[];
  onSubmit: (data: TData) => void;
  onPrev?: () => void;
  projectData?: any;
  projectId?: string;
}

const IdeaPage = ({ match }: IdeaPageProps) => {
  const { t } = useTranslation();
  const { push, location } = useHistory();
  const { user } = useAppContext();

  const params = new URLSearchParams(location.search);
  const [data, setData] = useState<Partial<FormData>>({});
  const [initialData, setInitialData] = useState<Partial<FormData>>({});
  const [allocations, setAllocations] = useState<Allocation[]>();
  const [editedProjectId, setEditedProjectId] = useState(
    params.get('projectId'),
  );
  const [projectStatus, setProjectStatus] = useState<any>();
  const [projectData, setProjectData] = useState<any>();
  const [documentIds, setDocumentIds] = useState<any>();
  const [addBudget, setAddBudget] = useState<boolean>(false);

  const { data: project } = useDashboardGetProjectByIdQuery({
    variables: {
      id: editedProjectId !== null ? editedProjectId! : '-',
    },
    onCompleted: data => {
      if (data.masters.length) {
        let descriptionsData;
        let initialData;
        let customer = false;
        let digitalization = false;
        let excellence = false;
        let growth = false;
        let sustainability = false;

        const documents = [] as any;
        const documentIds = [] as any;
        data.masters[0].projectDescriptions?.map(a => {
          setProjectData(a);
          descriptionsData = a;
        });
        data.masters[0].goals?.forEach(a => {
          switch (a.goal.id) {
            case 'Exzellenz':
              excellence = true;
              break;
            case 'Digitalisierung':
              digitalization = true;
              break;
            case 'Kunde':
              customer = true;
              break;
            case 'Nachhaltigkeit':
              sustainability = true;
              break;
            case 'Wachstum':
              growth = true;
              break;
          }
        });
        data.masters[0].projectDocuments?.forEach(a => {
          let description = a.description;
          let id = a.id;
          let file = File;
          let name = a.name;
          let arr = { id, description, file, name };
          documents.push(arr);
          documentIds.push(a.id);
        });
        const projectType = 'ckwxitvei0041sdmndo33v2gc';
        setDocumentIds(documentIds);
        setProjectStatus(
          data.masters[0].projectStatus?.filter(s => s.deletedBy === null) ??
            [],
        );

        let summary = descriptionsData.description;
        let background = descriptionsData.background;
        let comments = descriptionsData.comments;

        const duration = descriptionsData.projectDuration;
        const name = descriptionsData.name;
        let parsedDate;
        if (descriptionsData.projectStart) {
          parsedDate = new Date(parseISO(descriptionsData.projectStart));
        }
        const startDate = parsedDate;

        let editProject = true;
        initialData = {
          projectType,
          summary,
          background,
          duration,
          name,
          startDate,
          comments,
          documents,
          editProject,
        };
        setData(initialData);
        setInitialData(initialData);

        let allocations = [] as Allocation[];
        setAllocations(allocations);
        data.masters[0].projectAllocations?.forEach(a => {
          let arr = {
            id: a.id,
            percentage: a.percentage,
            allocationId: a.allocationId,
          };
          allocations.push(arr);
        });
        setAllocations(allocations);
        setAddBudget(allocations.length !== 0);
      }
    },
  });

  const [createProject, { data: createMasterData }] = useCreateMasterMutation();
  const [deleteProject, { data: deleteMasterData }] = useDeleteMasterMutation();
  const [createProjectDocument, { data: createProjectDocumentData }] =
    useCreateProjectDocumentMutation();
  const [updateProjectDocument, { data: updateProjectDocumentData }] =
    useUpdateProjectDocumentMutation();
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();

  const basePath = config.localRoutes.myItIdea.home;

  const [tableData, setTableData] = useState<FinancialTableRow[]>();

  const [
    getProjectAllocations,
    { loading: projectAllocationsLoading, data: projectAllocationsData },
  ] = useGetProjectAllocationsLazyQuery();
  const [updateProjectAllocation] = useUpdateProjectAllocationMutation();
  const [createFinance] = useCreateFinanceMutation();

  const handleSaveTable = (data?: {
    allocations: Allocation[];
    tableData: FinancialTableRow[];
  }) => {
    setAllocations(data?.allocations);
    setTableData(data?.tableData);
  };

  const handleSubmit = async (fData?: {
    allocations: Allocation[];
    tableData: FinancialTableRow[];
  }) => {
    if (data.editProject) {
      const deletedProject = await deleteProject({
        variables: {
          where: {
            id: editedProjectId,
          },
          data: {
            deletedAt: {
              set: Date.now() as any as Date,
            },
            deletedBy: {
              set: user!.kid!,
            },
            projectStatus: {
              update: [
                {
                  data: {
                    deletedAt: {
                      set: Date.now() as any as Date,
                    },
                    deletedBy: {
                      set: user!.kid!,
                    },
                  },
                  where: {
                    id: toNumber(projectStatus[0].id),
                  },
                },
              ],
            },
          },
        },
      });
    }
    const createdProject = await createProject({
      variables: {
        data: {
          createdBy: user!.kid!,
          report: {
            create: [
              {
                reportTitle: 'TP-Digitale Baustelle',
                reportLink: '',
                createdBy: user?.kid!,
              },
            ],
          },
          employeeMaster: {
            create: [
              {
                employee: {
                  connectOrCreate: {
                    where: {
                      id: data.contact?.enabled
                        ? user!.kid!
                        : data.contact?.kid,
                    },
                    create: {
                      createdBy: user!.kid!,
                      id: data.contact?.enabled
                        ? user!.kid!
                        : data.contact?.kid!,
                      firstName: data.contact?.enabled
                        ? ''
                        : data.contact?.user.givenName,
                      lastName: data.contact?.enabled
                        ? ''
                        : data.contact?.user.surname,
                      email: data.contact?.enabled
                        ? ''
                        : data.contact?.user.mail ||
                          data.contact?.user.userPrincipalName ||
                          '',
                      department: data.contact?.enabled
                        ? ''
                        : data.contact?.user.department || '',
                    },
                  },
                },
                status: 0,
              },
            ],
          },
          projectRole: {
            create: [
              {
                createdBy: user!.kid!,
                role: {
                  connect: {
                    id: 'Fachlicher Ansprechpartner',
                  },
                },
                employee: {
                  connectOrCreate: {
                    where: {
                      id: data.contact?.enabled
                        ? user!.kid!
                        : data.contact?.kid,
                    },
                    create: {
                      createdBy: user!.kid!,
                      id: data.contact?.enabled
                        ? user!.kid!
                        : data.contact?.kid!,
                      firstName: data.contact?.enabled
                        ? ''
                        : data.contact?.user.givenName,
                      lastName: data.contact?.enabled
                        ? ''
                        : data.contact?.user.surname,
                      email: data.contact?.enabled
                        ? ''
                        : data.contact?.user.mail ||
                          data.contact?.user.userPrincipalName ||
                          '',
                      department: data.contact?.enabled
                        ? ''
                        : data.contact?.user.department || '',
                    },
                  },
                },
              },
              {
                createdBy: user!.kid!,
                role: {
                  connect: {
                    id: 'Projektleitung',
                  },
                },
                employee: {
                  connectOrCreate: {
                    where: {
                      id: data.contact?.enabled
                        ? user!.kid!
                        : data.contact?.kid,
                    },
                    create: {
                      createdBy: user!.kid!,
                      id: data.contact?.enabled
                        ? user!.kid!
                        : data.contact?.kid!,
                      firstName: data.contact?.enabled
                        ? ''
                        : data.contact?.user.givenName,
                      lastName: data.contact?.enabled
                        ? ''
                        : data.contact?.user.surname,
                      email: data.contact?.enabled
                        ? ''
                        : data.contact?.user.mail ||
                          data.contact?.user.userPrincipalName ||
                          '',
                      department: data.contact?.enabled
                        ? ''
                        : data.contact?.user.department || '',
                    },
                  },
                },
              },
            ],
          },
          projectDescriptions: {
            create: [
              {
                background: data.background,
                comments: data.comments,
                createdBy: user!.kid!,
                description: data.summary,
                procedure: 'Diese Beschreibung ist für dein Projekt nicht notwendig',
                name: data.name || 'no project name',
                projectType: {
                  connect: {
                    id: 'ckwxitvei0041sdmndo33v2gc',
                  },
                },
                projectDuration: parseInt(data.duration as any),
                projectStart: data.startDate as any as Date,
              },
            ],
          },
          projectStatus: {
            create: [
              {
                createdBy: user!.kid!,
                sequenceStatus: {
                  connect: {
                    id: ProjectStatusEnum.IDEA,
                  },
                },
              },
            ],
          },
        },
      },
    });

    fData?.tableData?.forEach(
      (rowData: FinancialTableRow, rowIndex: number) => {
        rowData.values?.forEach((vData: FinancialValue, vIndex: number) => {
          if (rowData.isSummary) return;
          if (vData.id.substring(0, 4) !== 'key-' && vData.value === 0) {
            createFinanceData({
              assetId: rowData.assetId,
              companyId: rowData.companyId || '',
              valueData: vData,
              projectId: createdProject.data?.createMaster.id || '',
            });
          }
          if (vData.value !== 0) {
            // id of values which are not in the database are generated in the frontend and start with "key-"
            if (vData.id.substring(0, 4) === 'key-' || data.editProject) {
              createFinanceData({
                assetId: rowData.assetId,
                companyId: rowData.companyId || '',
                valueData: vData,
                projectId: createdProject.data?.createMaster.id || '',
              });
            }
          }
        });
      },
    );

    fData?.allocations?.forEach((a: Allocation) => {
      connectAllocation(
        data.editProject ? a.allocationId : a.id,
        createdProject.data?.createMaster.id || '',
        a.percentage,
      );
    });

    data.documents?.map(async (doc, index) => {
      if (!documentIds?.includes(doc.id)) {
        await createProjectDocument({
          variables: {
            data: {
              id: uuidv4(),
              name: doc.file!.name,
              description: doc.description,
              location: 'neue-it-initiative',
              uri: '',
              createdBy: user!.kid!,
              project: {
                connect: {
                  id: createdProject.data?.createMaster.id,
                },
              },
            },
            file: doc.file,
          },
        });
      }
    });
    documentIds?.map(async d => {
      await updateProjectDocument({
        variables: {
          data: {
            project: {
              connect: {
                id: createdProject.data?.createMaster.id,
              },
            },
          },
          where: {
            id: d,
          },
        },
      });
    });
    if (editedProjectId) {
      createProjectShortHistory({
        variables: {
          data: {
            action: 'Stammdaten',
            createdBy: user?.kid || 'unknown',
            project: {
              connect: {
                id: editedProjectId,
              },
            },
          },
        },
      });
    }
  };

  const connectAllocation = (
    id: string,
    projectId: string,
    percentage: number,
  ) => {
    getProjectAllocations({
      variables: {
        projectAllocationsWhere: {
          projectId: {
            equals: projectId,
          },
          allocationId: {
            equals: id,
          },
        },
      },
    }).then(res => {
      if (!res.data?.projectAllocations[0] && data.editProject) {
        updateProjectAllocation({
          variables: {
            data: {
              projectAllocations: {
                create: [
                  {
                    allocation: {
                      connect: {
                        id: id,
                      },
                    },
                    percentage: parseFloat(percentage.toString()),
                  },
                ],
              },
            },
            where: {
              id: projectId,
            },
          },
        });
      } else if (res.data?.projectAllocations[0]) {
        updateProjectAllocation({
          variables: {
            data: {
              projectAllocations: {
                update: [
                  {
                    data: {
                      percentage: {
                        set: parseFloat(percentage.toString()),
                      },
                    },
                    where: {
                      id: res.data.projectAllocations[0].id,
                    },
                  },
                ],
              },
            },
            where: {
              id: projectId,
            },
          },
        });
      } else {
        updateProjectAllocation({
          variables: {
            data: {
              projectAllocations: {
                create: [
                  {
                    allocation: {
                      connect: {
                        id: id,
                      },
                    },
                    percentage: parseFloat(percentage.toString()),
                  },
                ],
              },
            },
            where: {
              id: projectId,
            },
          },
        });
      }
    });
  };

  const createFinanceData = (data: {
    assetId: string;
    companyId: string;
    valueData: FinancialValue;
    projectId: string;
  }) => {
    createFinance({
      variables: {
        data: {
          asset: {
            connect: {
              id: data.assetId,
            },
          },
          company: {
            connect: {
              id: data.companyId,
            },
          },
          project: {
            connect: {
              id: data.projectId,
            },
          },
          value: +data.valueData.value!,
          year: +data.valueData.year!,
          createdBy: user?.kid || 'unknown',
        },
      },
    });
  };

  return (
    <div className="relative flex flex-col w-full min-h-full font-primary lg:px-12 xl:px-24">
      <Breadcrumbs />
      <h1 className="flex-grow-0 pb-5 text-xl font-medium text-primary pt-5">
        {t(`myItIdea.${match.params.step ?? 'greeting'}.title`)}
      </h1>
      <div className="flex flex-1">
        <Switch>
          <Route
            path={basePath}
            exact
            component={() => (
              <Greeting
                onSubmit={() => push([basePath, 'background'].join('/'))}
              />
            )}
          />
          <Route
            path={[basePath, 'background'].join('/')}
            component={() => (
              <Description
                values={{
                  background:
                    data?.background !==
                    'Diese Beschreibung ist für dein Projekt nicht notwendig'
                      ? data.background ?? undefined
                      : undefined,
                  summary: data.summary ?? undefined,
                }}
                onPrev={() => push(basePath)}
                onSubmit={data => {
                  setData(prev => ({
                    ...prev,
                    ...data,
                  }));
                  push([basePath, 'details'].join('/'));
                }}
              />
            )}
          />
          <Route
            path={[basePath, 'details'].join('/')}
            component={() => (
              <Details
                values={{
                  contact: data.contact ?? undefined,
                  duration: data.duration ?? 1,
                  name: data.name ?? '',
                  startDate: data.startDate ?? undefined,
                  comments: data.comments ?? undefined,
                }}
                onPrev={() => push([basePath, 'background'].join('/'))}
                onSubmit={data => {
                  setData(prev => ({
                    ...prev,
                    ...data,
                  }));
                  push([basePath, 'documents'].join('/'));
                }}
              />
            )}
          />
          <Route
            path={[basePath, 'documents'].join('/')}
            component={() => (
              <Documents
                values={{
                  documents: data.documents ?? undefined,
                }}
                onPrev={() => push([basePath, 'details'].join('/'))}
                onSubmit={data => {
                  setData(prev => ({
                    ...prev,
                    ...data,
                  }));
                  // await handleSubmit({
                  //   ...data,
                  //   ...nextData,
                  // } as any);
                  push([basePath, 'financial-data'].join('/'));
                }}
              />
            )}
          />
          <Route
            path={[basePath, 'financial-data'].join('/')}
            component={() => (
              <Financial
                values={{
                  addBudget: addBudget,
                  name: data.name,
                  allocations: allocations,
                  tableData: tableData,
                  isRequired: data.projectType !== 'Pilotprojekt',
                  editedProjectId: editedProjectId
                    ? editedProjectId
                    : undefined,
                }}
                onPrev={async data => {
                  handleSaveTable(data);
                  push([basePath, 'documents'].join('/'));
                }}
                onSubmit={async data => {
                  handleSubmit(data);
                  push([basePath, 'success'].join('/'));
                }}
                handleBudgetChange={(value: boolean) => {
                  setAddBudget(value);
                }}
              />
            )}
          />
          <Route
            path={[basePath, 'success'].join('/')}
            component={() => (
              <Success
                values={{
                  name:
                    createMasterData?.createMaster.projectDescriptions![0]
                      .name || data.name,
                }}
                onSubmit={data => {
                  push([basePath, 'success'].join('/'));
                }}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};
export default IdeaPage;
