import classNames from 'classnames';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import Select from 'components/common/Select/Select';
import Table, { TableColumn } from 'components/NewTable';
import {
  ProjectApprovalsQuery,
  useApprovalStatusesQuery,
  useProjectApprovalsLazyQuery,
} from 'graphql/codegen/graphql';
import { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';

type ApprovalProcessEntry = ProjectApprovalsQuery['projectApprovals'][number];

function getNumberOfDays(start: Date, end: Date) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = endDate.getTime() - startDate.getTime();
  return Math.round(diffInTime / oneDay);
}

function getColorOfRow(row: ApprovalProcessEntry) {
  const daysBetween = getNumberOfDays(row.createdAt, new Date());
  switch (true) {
    case daysBetween <= 10:
      return ['bg-green-300', 'Genehmigt vor 10 Tagen'];
    case daysBetween <= 20:
      return ['bg-yellow-300', 'Genehmigt zwischen 10 - 20 Tagen'];
    case daysBetween <= 30:
      return ['bg-orange-300', 'Genehmigt zwischen 20 - 30 Tagen'];
    case daysBetween <= 99:
      return ['bg-red-400', 'Genehmigt zwischen 30 - 99 Tagen'];
    default:
      return ['', ''];
  }
}

function ApprovalProcessOverview() {
  const [filterApprovalStatus, setFilterApprovalStatus] = useState('');
  const [getProjectApprovals, { data }] = useProjectApprovalsLazyQuery();
  const { data: approvalStatus } = useApprovalStatusesQuery();

  useEffect(() => {
    getProjectApprovals({
      variables: { approvalStatus: filterApprovalStatus },
    });
  }, [filterApprovalStatus, getProjectApprovals]);

  const columns: TableColumn<ApprovalProcessEntry>[] = [
    {
      label: <div className="flex items-center justify-center">Status</div>,
      render: data => (
        <div className="flex items-center justify-center">
          <OverlayTrigger
            placement="right"
            overlay={
              <div className="bg-gray-200 ml-2 p-2 rounded w-20 text-xs">
                {getColorOfRow(data)[1]}
              </div>
            }
          >
            <div
              className={classNames(
                'w-8 h-8 rounded-full group relative inline-block',
                getColorOfRow(data)[0],
              )}
            />
          </OverlayTrigger>
        </div>
      ),
    },
    {
      label: 'Projektname',
      render: data => (
        <div>{data?.project?.projectDescriptions?.[0]?.name}</div>
      ),
    },
    { label: 'Status', render: data => <div>{data.approvalStatus?.id}</div> },
    {
      label: 'Datum',
      render: data => <div>{new Date(data.createdAt).toLocaleString()}</div>,
    },
    {
      label: 'Gremium',
      render: data => <div>{data.ApprovalBoard.description}</div>,
    },
    {
      label: 'Kommentar',
      render: data => <div>{data.comment}</div>,
    },
  ];

  return (
    <div>
      <Breadcrumb label="Genehmigungsprozess" route="/it-projects/process" />
      <div className="my-4 flex justify-end">
        <Select
          className="w-36"
          onChange={value => setFilterApprovalStatus(value)}
          selected={filterApprovalStatus}
          options={[
            { label: 'Jeder Status', value: '' },
            ...(approvalStatus?.approvalStatuses.map(entry => ({
              label: entry.id,
              value: entry.id,
            })) || []),
          ]}
        />
      </div>
      <Table columns={columns} data={data?.projectApprovals || []} />
    </div>
  );
}

export default ApprovalProcessOverview;
