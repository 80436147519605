import useTypedForm from '../../../components/common/Form/components';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../components/common/Dialog';
import Dropdown from '../../../components/common/Form/components/Dropdown/variants/Default';
import { ExtendedDropdownProps } from '../../../pages/Reporting/Detail/ResourcePlaning';
import { debounce, toInteger } from 'lodash';
import {
  ResourceplanningGetProjectByIdQuery,
  useCreateProjectResourceCaptureMutation,
  useResourceplanningDropdownDataQuery,
  useUpdateProjectResourceCaptureMutation,
} from '../../../graphql/codegen/graphql';
import { useAppContext } from '../../../AppContext';
import React, { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { Employee } from '../../dashboard/IdeaReleaseDialog';
import { findUser } from '../../../GraphService';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import configuration from '../../../Config';
import { useMsal } from '@azure/msal-react';
import { User } from 'microsoft-graph';

interface resourcePlanningDialogProps {
  mode: 'create' | 'edit' | null;
  setMode: (mode: 'create' | 'edit' | null) => void;
  editProjectResourcePlanning: string;
  setEditProjectResourcePlanning: (p: string) => void;
  projectId: string;
  data: ResourceplanningGetProjectByIdQuery | undefined;
}
const MAX_LENGTH = 200;
interface resourceProps {
  department: string;
  kid: string;
  name: string;
  year: number;
  month: number;
  rate: number;
  communication: string;
  update: string;
  startDate: Date;
  endDate: Date;
  phase?: string;
  employee?: string;
  role?: string;
}

const SHORT_KID_LENGTH = 5;
const LONG_KID_LENGTH = 6;

const ResourcePlanningDialog = ({
  mode,
  setMode,
  editProjectResourcePlanning,
  setEditProjectResourcePlanning,
  projectId,
  data,
}: resourcePlanningDialogProps) => {
  const { Form, TextInput, TextArea, DatePicker, SubmitButton } =
    useTypedForm<resourceProps>();
  const { t } = useTranslation();
  const { user } = useAppContext();

  const [createProjectResourceCapture] =
    useCreateProjectResourceCaptureMutation({
      refetchQueries: ['resourceplanningGetProjectById'],
    });
  const [updateProjectResourceCapture] =
    useUpdateProjectResourceCaptureMutation({
      refetchQueries: ['resourceplanningGetProjectById'],
    });
  const { data: dropdownData, loading: dropdownLoading } =
    useResourceplanningDropdownDataQuery({
      variables: {
        projectPhasesWhere2: {
          phaseOnType: {
            some: {
              projectTypeId: {
                equals:
                  data?.masters[0]?.projectDescriptions?.[0].projectType.id,
              },
            },
          },
        },
      },
    });

  const [projectPhaseValue, setProjectPhaseValue] =
    useState<ExtendedDropdownProps | null>();
  const [employeeValue, setEmployeeValue] =
    useState<ExtendedDropdownProps | null>();
  const [roleValue, setRoleValue] = useState<ExtendedDropdownProps | null>();
  const [mappedProjectPhaseIds, setMappedProjectPhaseIds] =
    useState<ExtendedDropdownProps[]>();
  const [mappedRoleIds, setMappedRoleIds] = useState<ExtendedDropdownProps[]>();
  const [employee, setEmployee] = useState<Employee>();
  const [kidChanged, setKidChanged] = useState<boolean>(false);

  const projectResourceCapture = data?.masters[0]?.projectResourceCapture?.find(
    r => r.id === editProjectResourcePlanning,
  );

  const [startDate, setStartDate] = useState<Date | null>(
    projectResourceCapture?.onboarding
      ? parseISO(projectResourceCapture.onboarding.toString())
      : new Date(),
  );
  let calcEndDate = new Date(startDate!);
  calcEndDate?.setDate(calcEndDate.getDate() + 1);

  const [endDate, setEndDate] = useState<Date | null>(
    projectResourceCapture?.endDate
      ? parseISO(projectResourceCapture.endDate.toString())
      : calcEndDate,
  );

  useEffect(() => {
    projectResourceCapture?.onboarding &&
      setStartDate(parseISO(projectResourceCapture.onboarding.toString()));
    projectResourceCapture?.endDate &&
      setEndDate(parseISO(projectResourceCapture.endDate.toString()));
  }, [projectResourceCapture]);

  const textFilterPattern = /^[\p{L}\p{P}0-9_\- ]{1,200}$/u;
  const percentageNumberPattern = /^[1-9]?[0-9]$|^100$/u;

  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  const handleCreateProjectResourceCapture = async (data: resourceProps) => {
    let i = data.startDate.getFullYear();
    let years: number[] = [];
    while (i < data.endDate.getFullYear()) {
      i++;
      years.push(i);
    }

    await createProjectResourceCapture({
      variables: {
        data: {
          project: {
            connect: {
              id: projectId,
            },
          },
          projectPhase: {
            connect: {
              id: data.phase,
            },
          },
          employee: {
            connectOrCreate: {
              where: {
                id: employee?.userPrincipalName?.split('@')[0],
              },
              create: {
                id: employee?.userPrincipalName?.split('@')[0]!,
                firstName: employee?.givenName,
                lastName: employee?.surname,
                email: employee?.mail,
                createdBy: user?.kid!,
              },
            },
          },
          role: {
            connect: {
              id: data.role,
            },
          },
          department: employee?.department ?? '',
          year: data.startDate.getFullYear(),
          month: dateDiffInDays(
            data.startDate,
            years.length
              ? new Date(data.startDate.getFullYear(), 11, 31, 23, 59, 59, 999)
              : (data.endDate as any as Date),
          ),
          onboarding: data.startDate as any as Date,
          endDate: years.length
            ? new Date(data.startDate.getFullYear(), 11, 31, 23, 59, 59, 999)
            : (data.endDate as any as Date),
          rate: toInteger(data.rate),
          communication: data.communication,
          update: data.update,
          createdBy: user?.kid!,
        },
      },
    });

    for (const y of years) {
      const index = years.indexOf(y);
      await createProjectResourceCapture({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            projectPhase: {
              connect: {
                id: data.phase,
              },
            },
            employee: {
              connectOrCreate: {
                where: {
                  id: employee?.userPrincipalName?.split('@')[0],
                },
                create: {
                  id: employee?.userPrincipalName?.split('@')[0]!,
                  firstName: employee?.givenName,
                  lastName: employee?.surname,
                  email: employee?.mail,
                  createdBy: user?.kid!,
                },
              },
            },
            role: {
              connect: {
                id: data.role,
              },
            },
            department: employee?.department ?? '',
            year: y,
            month: dateDiffInDays(
              new Date(y, 0, 1),
              index + 1 === years.length
                ? (data.endDate as any as Date)
                : new Date(y, 11, 31, 23, 59, 59, 999),
            ),
            onboarding: new Date(y, 0, 1),
            endDate:
              index + 1 === years.length
                ? (data.endDate as any as Date)
                : new Date(y, 11, 31, 23, 59, 59, 999),
            rate: toInteger(data.rate),
            communication: data.communication,
            update: data.update,
            createdBy: user?.kid!,
          },
        },
      });
    }
  };

  const handleUpdateProjectResourceCapture = (data: resourceProps) => {
    data.month = dateDiffInDays(data.startDate, data.endDate);
    updateProjectResourceCapture({
      variables: {
        data: {
          projectPhase: {
            connect: {
              id: data.phase,
            },
          },
          department: {
            set: employee?.department ?? data.department,
          },
          employee: {
            connectOrCreate: {
              where: {
                id: employee?.userPrincipalName?.split('@')[0] ?? data.employee,
              },
              create: {
                id: employee?.userPrincipalName?.split('@')[0]! ?? '',
                firstName: employee?.givenName,
                lastName: employee?.surname,
                email: employee?.mail,
                createdBy: user?.kid!,
              },
            },
          },
          year: {
            set: data.startDate.getFullYear(),
          },
          month: {
            set: data.month,
          },
          role: {
            connect: {
              id: data.role,
            },
          },
          rate: {
            set: toInteger(data.rate),
          },
          communication: {
            set: data.communication,
          },
          update: {
            set: data.update,
          },
          onboarding: {
            set: data.startDate as any as Date,
          },
          endDate: {
            set: data.endDate as any as Date,
          },
        },
        where: {
          id: editProjectResourcePlanning,
        },
      },
    });
  };

  const mapIds = (data: ({ id: string } | undefined)[] | { id: string }[]) => {
    if (data) {
      return data.map(p => {
        return {
          label: p?.id ?? '',
          value: p?.id ?? '',
        };
      });
    }
  };

  const mapEmployee = (
    data:
      | (
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              department?: string | null | undefined;
            }
          | undefined
        )[]
      | undefined,
  ) => {
    if (data) {
      return data.map(e => {
        return {
          label: (e?.lastName ?? '') + ', ' + (e?.firstName ?? ''),
          value: e?.id ?? '',
          department: e?.department ?? '',
        };
      });
    }
  };

  const dateDiffInDays = (a: Date, b: Date) => {
    const _MS_PER_MONTH = 1000 * 60 * 60 * 24 * (365 / 12);
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return (utc2 - utc1) / _MS_PER_MONTH;
  };

  const handleSubmit = (data: resourceProps) => {
    data.startDate = new Date(
      data.startDate.getFullYear(),
      data.startDate.getMonth(),
      data.startDate.getDate(),
    );
    data.endDate = new Date(
      data.endDate.getFullYear(),
      data.endDate.getMonth(),
      data.endDate.getDate(),
    );
    data.phase = projectPhaseValue?.value;
    data.employee = employeeValue?.value;
    data.department = employeeValue?.department!;
    data.role = roleValue?.value;
    if (mode === 'create') {
      handleCreateProjectResourceCapture(data);
    } else if (mode === 'edit') {
      handleUpdateProjectResourceCapture(data);
    }
    setMode(null);
  };

  const handleSelectPhase = (data: ExtendedDropdownProps | null) => {
    setProjectPhaseValue(data);
  };
  const handleSelectRole = (data: ExtendedDropdownProps | null) => {
    setRoleValue(data);
  };

  const requestEmployee = async (value: string) => {
    let user: User | undefined = undefined;
    try {
      user = await findUser(authProvider, value);
    } finally {
      setEmployee(undefined);
    }

    if (user) {
      setEmployee(user);
    } else {
      setEmployee(undefined);
    }
  };
  const debouncedRequestEmployee = debounce(requestEmployee, 1000);
  const handleKIDFieldChange = (value: string) => {
    setEmployee(undefined);
    if (value.length === SHORT_KID_LENGTH || value.length === LONG_KID_LENGTH)
      debouncedRequestEmployee(value);
  };

  const [occupiedResourceCaptureProps, setOccupiedResourceCaptureProps] =
    useState<
      | {
          id: string;
          projectPhaseId: string;
          employeeId: string;
          roleId: string;
          startDate: Date;
          endDate: Date;
          deletedBy: string;
        }[]
      | undefined
    >();

  useEffect(() => {
    if (dropdownData) {
      setMappedProjectPhaseIds(
        !dropdownLoading ? mapIds(dropdownData.projectPhases) : undefined,
      );
      setMappedRoleIds(
        !dropdownLoading ? mapIds(dropdownData.roles) : undefined,
      );
    }
  }, [dropdownLoading]);

  useEffect(() => {
    setProjectPhaseValue(undefined);
    setEmployeeValue(undefined);
    setRoleValue(undefined);
    if (dropdownData && mode === 'edit' && projectResourceCapture) {
      setProjectPhaseValue(
        mapIds([
          dropdownData.projectPhases.find(
            p => p.id === projectResourceCapture.projectPhaseId,
          ),
        ])?.[0],
      );

      setEmployeeValue(
        mapEmployee([
          dropdownData.employees.find(
            e => e.id === projectResourceCapture.employee.id,
          ),
        ])?.[0],
      );

      setRoleValue(
        mapIds([
          dropdownData.roles.find(r => r.id === projectResourceCapture.roleId),
        ])?.[0],
      );
    }
    let resourceCaptureProps = data?.masters[0].projectResourceCapture?.map(
      c => {
        return {
          projectPhaseId: c.projectPhaseId,
          employeeId: c.employeeId,
          roleId: c.roleId,
          startDate: c.onboarding,
          endDate: c.endDate!,
          id: c.id,
          deletedBy: c.deletedBy!,
        };
      },
    );
    setOccupiedResourceCaptureProps(resourceCaptureProps);
  }, [dropdownData, mode, projectResourceCapture]);

  const [updateCounter, setUpdateCounter] = useState<number>(0);

  const countUpdateInput = e => {
    setUpdateCounter(e.target.value.length);
  };

  const [updateCommunication, setUpdateCommunication] = useState<number>(0);

  const countCommunicationInput = e => {
    setUpdateCommunication(e.target.value.length);
  };

  const actualOccupiedResourceCaptureProps =
    occupiedResourceCaptureProps?.filter(p => {
      return (
        p.projectPhaseId === projectPhaseValue?.value &&
        p.roleId === roleValue?.value &&
        p.employeeId ===
          (employee?.userPrincipalName?.split('@')[0] ??
            projectResourceCapture?.employeeId) &&
        p.id !== editProjectResourcePlanning &&
        p.deletedBy === null
      );
    }) ?? [];

  const allowedCombination = actualOccupiedResourceCaptureProps.some(p => {
    if (!(startDate && endDate)) {
      return false;
    }
    const pStartDate = new Date(p.startDate).getTime();
    const pEndDate = new Date(p.endDate).getTime();
    const newStartDate = new Date(startDate).getTime();
    const newEndDate = new Date(endDate).getTime();

    return (
      (pStartDate <= newStartDate && newStartDate <= pEndDate) ||
      (pStartDate <= newEndDate && newEndDate <= pEndDate) ||
      (pStartDate >= newStartDate && newEndDate >= pEndDate)
    );
  });

  return (
    <Dialog
      open={!!mode}
      onClose={() => {
        setMode(null);
        setEditProjectResourcePlanning('');
      }}
      title={
        mode === 'create'
          ? t`tdReporting.projectResourcePage.titleCreate`
          : mode === 'edit'
          ? t`tdReporting.projectResourcePage.titleEdit`
          : ''
      }
    >
      <div>
        <Form
          onSubmit={handleSubmit}
          defaultValues={{
            startDate: projectResourceCapture?.onboarding
              ? parseISO(projectResourceCapture.onboarding.toString())
              : new Date(),
            endDate: projectResourceCapture?.endDate
              ? parseISO(projectResourceCapture.endDate.toString())
              : calcEndDate!,
          }}
          mode="onChange"
        >
          <div className="space-y-4">
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.phase` + ':'}
              </div>
              <div className="w-full">
                <Dropdown
                  variant="default"
                  placeholder={t`dropdown.placeholder`}
                  loading={dropdownLoading}
                  value={projectPhaseValue}
                  options={mappedProjectPhaseIds}
                  onChange={handleSelectPhase}
                />
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.kid` + ':'}
              </div>
              <div className="w-full">
                <TextInput
                  name="kid"
                  defaultValue={
                    mode === 'edit'
                      ? projectResourceCapture?.employeeId ?? ''
                      : ''
                  }
                  onChange={e => {
                    setKidChanged(true);
                    handleKIDFieldChange(e.target.value);
                  }}
                  rules={{
                    required: true,
                  }}
                />
                {kidChanged && !employee ? (
                  <p className="text-red-600">{t`tdReporting.projectResourcePage.errorMessageKid`}</p>
                ) : null}
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.name` + ':'}
              </div>
              <div className="w-full">
                <TextInput
                  name="name"
                  disabled={true}
                  className="hover:border-gray-300 bg-zinc-200"
                  value={
                    employee?.displayName ??
                    (kidChanged ? '' : employeeValue?.label) ??
                    ''
                  }
                />
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.department` + ':'}
              </div>
              <div className="w-full">
                <TextInput
                  name="department"
                  disabled={true}
                  className="hover:border-gray-300 bg-zinc-200"
                  value={
                    employee?.department ??
                    (kidChanged ? '' : employeeValue?.department) ??
                    ''
                  }
                />
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.role` + ':'}
              </div>
              <div className="w-full">
                <Dropdown
                  variant="default"
                  placeholder={t`dropdown.placeholder`}
                  loading={dropdownLoading}
                  value={roleValue}
                  options={mappedRoleIds}
                  onChange={handleSelectRole}
                />
                {occupiedResourceCaptureProps?.some(
                  p =>
                    p.projectPhaseId === projectPhaseValue?.value &&
                    p.roleId === roleValue?.value &&
                    p.employeeId ===
                      (employee?.userPrincipalName?.split('@')[0] ??
                        projectResourceCapture?.employeeId),
                ) && (
                  <p className="text-red-600">{t`tdReporting.projectResourcePage.errorMessageRole`}</p>
                )}
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.rate` + ':'}
              </div>
              <div className="w-full">
                <TextInput
                  name="rate"
                  defaultValue={
                    mode === 'edit' ? projectResourceCapture?.rate ?? '' : ''
                  }
                  rules={{
                    required: true,
                    pattern: {
                      value: percentageNumberPattern,
                      message: t`tdReporting.projectResourcePage.errorMessageRate`,
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.communication` + ':'}
              </div>
              <div className="w-full">
                <TextArea
                  name={'communication'}
                  defaultValue={
                    mode === 'edit'
                      ? projectResourceCapture?.communication ?? ''
                      : ''
                  }
                  rows={2}
                  autoHeight={true}
                  onChange={countCommunicationInput}
                  rules={{
                    required: false,
                    pattern: {
                      value: textFilterPattern,
                      message: t`tdReporting.projectResourcePage.errorMessageCommunication`,
                    },
                  }}
                />
                <p className="text-gray-500 text-sm justify-right text-right">
                  {`${updateCommunication}/${MAX_LENGTH}`}
                </p>
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.update` + ':'}
              </div>
              <div className="w-full">
                <TextArea
                  name={'update'}
                  defaultValue={
                    mode === 'edit' ? projectResourceCapture?.update ?? '' : ''
                  }
                  rows={2}
                  autoHeight={true}
                  onChange={countUpdateInput}
                  rules={{
                    required: false,
                    pattern: {
                      value: textFilterPattern,
                      message: t`tdReporting.projectResourcePage.errorMessageUpdate`,
                    },
                  }}
                />
                <p className="text-gray-500 text-sm justify-right text-right">
                  {`${updateCounter}/${MAX_LENGTH}`}
                </p>
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.startDate` + ':'}
              </div>
              <div className="w-full">
                <DatePicker
                  name="startDate"
                  onChange={value => setStartDate(value)}
                  rules={{
                    required: true,
                    validate: () => {
                      return (
                        (startDate && endDate ? startDate < endDate : false) &&
                        (mode === 'edit'
                          ? startDate && endDate
                            ? startDate?.getFullYear() ===
                              endDate?.getFullYear()
                            : false
                          : true)
                      );
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-grow items-center space-x-4">
              <div className="text-primary w-1/6">
                {t`tdReporting.projectResourcePage.endDate` + ':'}
              </div>
              <div className="w-full">
                <DatePicker
                  name="endDate"
                  onChange={value => setEndDate(value)}
                  rules={{
                    required: true,
                    validate: () => {
                      return (
                        (startDate && endDate ? startDate < endDate : false) &&
                        (mode === 'edit'
                          ? startDate && endDate
                            ? startDate?.getFullYear() ===
                              endDate?.getFullYear()
                            : false
                          : true)
                      );
                    },
                  }}
                />
              </div>
            </div>
            <div className={'flex flex-grow items-center space-x-4'}>
              <div className={'w-1/6'} />
              <div className="w-full">
                {(startDate && endDate ? startDate >= endDate : false) && (
                  <p className="text-red-600">{t`tdReporting.projectResourcePage.endDateNotice`}</p>
                )}
                {startDate?.getFullYear() !== endDate?.getFullYear() ? (
                  mode === 'edit' ? (
                    <p className="text-red-600">{t`tdReporting.projectResourcePage.errorMessageYear`}</p>
                  ) : (
                    <p
                      className="w-full px-3 py-2 text-sm font-medium text-white rounded-lg shadow-xl
                    bottom-4 min-w-24 bg-lightGrey"
                    >{t`tdReporting.projectResourcePage.noticeMessageYear`}</p>
                  )
                ) : null}
                {allowedCombination && (
                  <p className="text-red-600">{t`tdReporting.projectResourcePage.errorMessageRole`}</p>
                )}
              </div>
            </div>
            <div className="flex pt-10 flex items-end justify-end">
              {' '}
              <SubmitButton
                disabled={
                  !(
                    projectPhaseValue &&
                    (kidChanged
                      ? employee
                      : !!projectResourceCapture?.employeeId) &&
                    roleValue
                  ) || allowedCombination
                }
              >{t`tdReporting.projectResourcePage.saveButton`}</SubmitButton>
            </div>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

export default ResourcePlanningDialog;
