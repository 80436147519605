import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from 'config/config';

import Dropdown from 'components/common/Form/components/Dropdown/variants/Default';
import FinancialSumTable from '../../financialData/FinancialSumTable';
import Radiobutton from '../../financialData/Radiobutton';
import Select from 'components/common/Select/Select';
import useTypedForm from 'components/common/Form/components';
import { ProjectStatusEnum } from 'graphql/types';
import {
  FinanceScalarFieldEnum,
  GetLastFinancesPerYearQuery,
  ProjectResourceCaptureScalarFieldEnum,
  SortOrder,
  useDashboardGetFilteredProjectsLazyQuery,
  useGetFinancialSumByProjectLazyQuery,
  useGetLastFinancesPerYearLazyQuery,
  useGetProjectAllocationsQuery,
  useGroupByFinancialLazyQuery,
  useGroupProjectResourceCapturesLazyQuery,
} from 'graphql/codegen/graphql';
import DatePicker from '../../common/Form/components/DatePicker';

export type ProjectFilter =
  | 'all'
  | 'ideas'
  | 'projects'
  | 'inventory'
  | 'activated';

export interface ProjectsTableProps {
  filter: ProjectFilter;
  projectId: string;
  onChange: (filter: ProjectFilter) => void;
  onSelect: (projectId: string) => void;
  allocations: any;
}

const ControllerTable: React.VFC<ProjectsTableProps> = ({
  filter,
  projectId,
  onChange,
  onSelect,
  allocations,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { Form } = useTypedForm();
  const [fetchProjects, { data: projectData }] =
    useDashboardGetFilteredProjectsLazyQuery();
  const [getFinancialData, { data: financialData }] =
    useGetFinancialSumByProjectLazyQuery({
      onCompleted: data => {
        setFinances(data);
      },
    });
  const [groupByFinancial, { data: groupFinancialData }] =
    useGroupByFinancialLazyQuery({
      onCompleted: data => {
        setGroupFinances(data.groupFinances);
      },
    });
  const [getLastFinancesPerYear, { data: getLastFinancesPerYearData }] =
    useGetLastFinancesPerYearLazyQuery({
      onCompleted: data => {
        setLastFinancesPerYear(data.getLastFinancesPerYear);
      },
    });
  const [getProjectResourceCaptures, { data: projectResourceCapturesData }] =
    useGroupProjectResourceCapturesLazyQuery({
      onCompleted: data => {
        setResources(data.groupProjectResourceCaptures);
      },
    });
  const [
    groupProjectResourceCaptures,
    { data: groupProjectResourceCapturesData },
  ] = useGroupProjectResourceCapturesLazyQuery({
    onCompleted: data => {
      setGroupResources(data.groupProjectResourceCaptures);
    },
  });
  const { location, replace } = useHistory();
  const params = new URLSearchParams(location.search);

  const [resources, setResources] = useState<any>(null);
  const [groupResources, setGroupResources] = useState<any>(null);
  const [finances, setFinances] = useState<any>(null);
  const [lastFinancesPerYear, setLastFinancesPerYear] =
    useState<GetLastFinancesPerYearQuery['getLastFinancesPerYear']>();
  const [groupFinances, setGroupFinances] = useState<any>(null);
  const [date, setDate] = useState<Date | null>();

  const [radioStatus, setRadioStatus] = useState('Alle');
  const [factor, setFactor] = useState(1);
  const [showRadio, setShowButton] = React.useState(false);

  const editFinances = finances?.finances?.map(f => {
    let delta = 0;
    if (lastFinancesPerYear && (date || projectId !== '')) {
      let sf = lastFinancesPerYear.filter(
        sf =>
          sf.assetId === f.assetId &&
          sf.companyId === f.companyId &&
          sf.year === f.year,
      );
      if (sf.length) {
        delta = sf[0].value;
      }
      return { ...f, delta: delta * factor, value: f.value * factor };
    } else {
      return { ...f, value: f.value * factor };
    }
  });

  const editFinances2 = groupFinances?.map(f => {
    if (lastFinancesPerYear && (date || projectId !== '')) {
      let sf = lastFinancesPerYear.filter(
        sf =>
          sf.assetId === f.assetId &&
          sf.companyId === f.companyId &&
          sf.year === f.year,
      );
      let delta = 0;
      if (sf.length) {
        sf.forEach(f => {
          delta = delta + f.value;
        });
      }
      return { ...f, delta: delta || null };
    } else {
      return { ...f };
    }
  });

  const [showProjectData, setShowProjectData] = useState(true);
  const [projectValue, setProjectValue] = useState<{
    value: string;
    label: string;
  }>({
    value: projectId ? projectId : '0',
    label: '',
  });

  const getProjectId = (projectId: string) => {
    return projectData?.masters.find(m => m.id === projectId)
      ?.projectDescriptions?.[0].name!;
  };

  useEffect(() => {
    setProjectValue({
      value: projectId ? projectId : '0',
      label: projectId ? getProjectId(projectId) : '',
    });
  }, [projectData]);

  useEffect(() => {
    fetchProjects({
      variables: {
        projectStatus: getProjectStatusByFilterType(filter),
      },
    });

    groupByFinancial({
      variables: {
        by: [
          FinanceScalarFieldEnum.Year,
          FinanceScalarFieldEnum.AssetId,
          FinanceScalarFieldEnum.CompanyId,
        ],
        sum: {
          value: true,
        },
        where: {
          project: {
            projectStatus: {
              some: {
                sequenceStatusId: {
                  in: getProjectStatusByFilterType(filter),
                },
                deletedBy: {
                  equals: null,
                },
              },
            },
          },
          asset: {
            isProjectCosts: {
              equals: showProjectData,
            },
          },
          deletedBy: {
            equals: null,
          },
        },
        assetsOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
        companiesOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
      },
    });

    if ((date ? date < new Date() : null) || projectId !== '') {
      let wholeSelectedDate: Date | null = null;
      date &&
        (wholeSelectedDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
        ));
      wholeSelectedDate &&
        wholeSelectedDate.setDate(wholeSelectedDate.getDate() + 1);
      getLastFinancesPerYear({
        variables: {
          olderThan: wholeSelectedDate,
          projectId: projectId !== '' ? projectId : undefined,
          where: {
            project: {
              projectStatus: {
                some: {
                  sequenceStatusId: {
                    in: getProjectStatusByFilterType(filter),
                  },
                  deletedBy: {
                    equals: null,
                  },
                },
              },
            },
          },
        },
      });
    }

    groupProjectResourceCaptures({
      variables: {
        by: [ProjectResourceCaptureScalarFieldEnum.Year],
        sum: {
          rate: true,
        },
        where: {
          project: {
            projectStatus: {
              some: {
                sequenceStatusId: {
                  in: getProjectStatusByFilterType(filter),
                },
                deletedBy: {
                  equals: null,
                },
              },
            },
          },
          deletedBy: {
            equals: null,
          },
        },
      },
    });

    getFinancialData({
      variables: {
        where: {
          projectId: {
            equals: projectId,
          },
          asset: {
            isProjectCosts: {
              equals: showProjectData,
            },
          },
          deletedBy: null,
        },
        assetsOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
        companiesOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
      },
    });

    getProjectResourceCaptures({
      variables: {
        by: [ProjectResourceCaptureScalarFieldEnum.Year],
        sum: {
          rate: true,
        },
        where: {
          projectId: {
            equals: projectId,
          },
          deletedBy: {
            equals: null,
          },
        },
      },
    });
  }, [filter, projectId, showProjectData, date, finances]);

  useEffect(() => {
    setShowButton(!!params.get('projectId'));
  }, [params.get('projectId')]);

  const handleSubmit = async data => {
    console.log({ data });
  };

  const projectCostChange = (isProjectCost: boolean) => {
    setShowProjectData(isProjectCost);
  };

  const handleOnChange = async value => {
    onSelect('');
    projectId = '';
    onChange(value as ProjectFilter);
    setProjectValue({
      value: '0',
      label: '',
    });
  };

  const handleSelect = async updatedProject => {
    onSelect(updatedProject.value);
    projectId = updatedProject.value;
    setProjectValue({
      value: updatedProject.value,
      label: updatedProject.label,
    });
    await history.push({
      pathname: `${config.localRoutes.dashboardController.projectCosts.replace(
        ':projectId',
        projectId,
      )}`,
      search: `projectId=${projectId}`,
    });
  };

  const handleAllocationFilter = status => {
    setRadioStatus(status);
  };
  const { data: projectAllocations } = useGetProjectAllocationsQuery({
    variables: {
      projectAllocationsWhere: {
        projectId: {
          equals: projectId,
        },
        allocationId: {
          equals: radioStatus,
        },
      },
    },
    onCompleted: a => {
      if (a.projectAllocations[0] && params.get('projectId')) {
        setFactor(a.projectAllocations[0].percentage / 100);
      } else {
        setFactor(1);
      }
    },
  });

  const handleDateChange = (data: Date | null) => {
    setDate(data);
  };

  return (
    <div>
      <div className="mb-5 flex w-full items-center">
        <dl className="grid grid-cols-1 gap-x-4 sm:gap-y-8 sm:grid-cols-2 w-full">
          <div className="sm:flex">
            <Select
              className="sm:w-72 w-full sm:pr-1.5"
              selected={filter}
              onChange={handleOnChange}
              options={[
                {
                  value: 'all',
                  label: t`itProjects.dashboard.filter.all`,
                },
                {
                  value: 'ideas',
                  label: t`itProjects.dashboard.filter.ideas`,
                },
                {
                  value: 'projects',
                  label: t`itProjects.dashboard.filter.projects`,
                },
                {
                  value: 'inventory',
                  label: t`itProjects.dashboard.filter.inventory`,
                },
                {
                  value: 'activated',
                  label: t`itProjects.dashboard.filter.activated`,
                },
              ]}
            />
            <Form
              onSubmit={handleSubmit}
              className="sm:w-72 w-full pt-1 sm:pl-1.5"
            >
              <Dropdown
                name="projectId"
                variant="default"
                onChange={handleSelect}
                defaultValue={projectValue}
                value={projectValue}
                options={projectData?.masters.map(master => {
                  return {
                    label: master.projectDescriptions![0].name,
                    value: master.id,
                  };
                })}
              ></Dropdown>
            </Form>
          </div>
          <div className="sm:max-w-sm w-full pt-1 justify-self-end justify-end	">
            <Form onSubmit={handleSubmit} className="flex">
              <p className="text-sm w-32">{t`itProjects.dashboard.deltaFinance`}</p>
              <div className="w-full">
                <DatePicker
                  id={'financeDatePicker'}
                  name={'financeDate'}
                  placeholderText={t`itProjects.dashboard.datepickerPlaceholder`}
                  onChange={handleDateChange}
                ></DatePicker>
              </div>
            </Form>
          </div>
        </dl>
      </div>

      {/*<div className="w-1/2 py-5">
        <dl className="flex gap-x-10">
          {showRadio ? (
            <div>
              <Radiobutton
                label={'Alle'}
                value={'Alle' === radioStatus}
                handleChange={handleAllocationFilter}
              ></Radiobutton>
            </div>
          ) : null}

          {showRadio
            ? allocations?.map(a => {
                return (
                  <div key={a.allocationId}>
                    <Radiobutton
                      label={a.allocationId}
                      value={a.allocationId === radioStatus}
                      handleChange={handleAllocationFilter}
                    ></Radiobutton>
                  </div>
                );
              })
            : null}
        </dl>
      </div>*/}
      {date && (
        <div className=" w-full flex items-end justify-end">
          <p className=" text-sm text-end text-red-600">
            {t`itProjects.dashboard.bracket`}
          </p>
        </div>
      )}
      <FinancialSumTable
        projectId={projectId}
        financialData={
          projectId
            ? editFinances
              ? editFinances
              : []
            : editFinances2
            ? editFinances2
            : []
        }
        companiesData={
          projectId
            ? finances
              ? finances.companies
              : []
            : groupFinancialData
            ? groupFinancialData.companies
            : []
        }
        assetsData={
          projectId
            ? finances
              ? finances.assets
              : []
            : groupFinancialData
            ? groupFinancialData.assets
            : []
        }
        resourceData={
          projectId
            ? resources
              ? resources
              : []
            : groupResources
            ? groupResources
            : []
        }
        projectCostChange={projectCostChange}
      />
    </div>
  );
};
export default ControllerTable;

const getProjectStatusByFilterType = (
  filter: ProjectFilter,
): ProjectStatusEnum[] => {
  switch (filter) {
    case 'ideas':
      return [ProjectStatusEnum.IDEA];
    case 'projects':
      return [ProjectStatusEnum.IDEA_ACCEPTED];
    case 'inventory':
      return [ProjectStatusEnum.INVENTORY];
    case 'activated':
      return [ProjectStatusEnum.ACTIVATED];
    default:
      return [
        ProjectStatusEnum.ACTIVATED,
        ProjectStatusEnum.IDEA,
        ProjectStatusEnum.IDEA_ACCEPTED,
        ProjectStatusEnum.INVENTORY,
      ];
  }
};
