import { Button } from 'components/common/Button';
import Table from 'components/Table';
import ApprovalListRow from 'components/Table/ApprovalListRow';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';

const InventoryList = () => {
  const history = useHistory();
  return (
    <div className="space-y-10 w-full relative">
      <div className="absolute right-0 top-0">
        <Button onClick={() => history.push(`/my-it-inventory/${nanoid(6)}`)}>
          Neues Formular
        </Button>
      </div>
      <Table title="Gespeicherte Formulare">
        <ApprovalListRow
          onClick={() => history.push('/my-it-inventory/max_mustermann')}
          left="19.01.2022"
          center="Formular 1"
          name="Max Mustermann"
          onShow={() => console.log('onShow')}
          onEdit={() => console.log('onEdit')}
        />
        <ApprovalListRow
          left="19.01.2022"
          center="Formular 2"
          name="Max Mustermann"
        />
        <ApprovalListRow
          left="19.01.2022"
          center="Formular 3"
          name="Max Mustermann"
        />
        <ApprovalListRow
          left="19.01.2022"
          center="Formular 4"
          name="Max Mustermann"
        />
      </Table>
      <Table title="Abgeschickte Formulare">
        <ApprovalListRow
          onClick={() => console.log('Hello World')}
          left="19.01.2022"
          center="Formular 5"
          name="Dana Lutgens"
        />
        <ApprovalListRow
          left="19.01.2022"
          center="Formular 6"
          to="/"
          name="Dana Lutgens"
          onShow={() => console.log('onShow')}
          onEdit={() => console.log('onEdit')}
        />
        <ApprovalListRow
          left="19.01.2022"
          center="Formular 7"
          name="Dana Lutgens"
        />
      </Table>
    </div>
  );
};
export default InventoryList;
