import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import '../components/financialData/table.css';
import { useAppContext } from 'AppContext';

import {
  ProjectResourceCaptureScalarFieldEnum,
  SortOrder,
  useCreateFinanceMutation,
  useCreateProjectAllocationInputMutation,
  useCreateProjectShortHistoryMutation,
  useDashboardGetProjectByIdLazyQuery,
  useGetAllocationsLazyQuery,
  useGetFinancialByProjectLazyQuery,
  useGetProjectAllocationsLazyQuery,
  useGroupProjectResourceCapturesLazyQuery,
  useRemoveFinanceMutation,
  useUpdateFinanceMutation,
  useUpdateProjectAllocationMutation,
} from 'graphql/codegen/graphql';

import FinancialTable, {
  FinancialTableRow,
  FinancialValue,
} from 'components/financialData/FinancialTable';
import { useTranslation } from 'react-i18next';

const FinancialDataPage = () => {
  const app = useAppContext();
  const location = useLocation();
  const projectId = location.pathname.substring(16);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDisabled, toggleDisabled] = useState(false);
  const [project, setProject] = useState<any>(null);
  const { t } = useTranslation();

  // backend connection
  const [
    getAllocations,
    { loading: allocationsLoading, data: allocationsData },
  ] = useGetAllocationsLazyQuery();
  const [
    getProjectAllocations,
    { loading: projectAllocationsLoading, data: projectAllocationsData },
  ] = useGetProjectAllocationsLazyQuery();
  const [getFinancialData, { loading: financialLoading, data: financialData }] =
    useGetFinancialByProjectLazyQuery({ fetchPolicy: 'network-only' });
  const [getProjectResourceCaptures, { data: projectResourceCapturesData }] =
    useGroupProjectResourceCapturesLazyQuery();
  const [createProjectAllocation] = useCreateProjectAllocationInputMutation();
  const [updateProjectAllocation] = useUpdateProjectAllocationMutation();
  const [updateFinance] = useUpdateFinanceMutation();
  const [createFinance] = useCreateFinanceMutation();
  const [removeFinance] = useRemoveFinanceMutation();

  const [getProjectById, { data: projectData }] =
    useDashboardGetProjectByIdLazyQuery({
      onCompleted: data => {
        setProject(data.masters[0]);
      },
    });

  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation({
    refetchQueries: ['dashboardGetProjectById'],
  });

  useEffect(() => {
    if (projectId) {
      getProjectById({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (project) {
      toggleDisabled(
        !project.projectStatus.find(
          s =>
            s.deletedBy === null && s.sequenceStatusId === 'IT-Idee akzeptiert',
        ) &&
          !project.projectApproval.some(a => a.approvalBoardId === 'con') &&
          !project.projectStatus.find(
            s =>
              s.deletedBy === null &&
              s.sequenceStatusId === 'Eingereicht durch IT-Idee',
          ) &&
          !project.projectStatus.find(
            s =>
              s.deletedBy === null &&
              s.sequenceStatusId === 'Projektaktivierung',
          ),
      );
    }
  }, [project]);

  useEffect(() => {
    getAllocations();
    getProjectAllocations({
      variables: {
        projectAllocationsWhere: {
          projectId: {
            equals: projectId,
          },
        },
      },
    });
    fetchFinancialData();
  }, []);

  const fetchFinancialData = () => {
    getFinancialData({
      variables: {
        where: {
          projectId: {
            equals: projectId,
          },
        },
        assetsOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
        companiesOrderBy: [
          {
            description: SortOrder.Asc,
          },
        ],
      },
    });
    getProjectResourceCaptures({
      variables: {
        by: [ProjectResourceCaptureScalarFieldEnum.Year],
        sum: {
          rate: true,
        },
        where: {
          projectId: {
            equals: projectId,
          },
          deletedBy: {
            equals: null,
          },
        },
      },
    });
  };

  const createProjectAllocationData = (
    id: string,
    allocationId: string,
    percentage: number,
  ) => {
    createProjectAllocation({
      variables: {
        data: {
          id: uuidv4,
          allocation: {
            connect: {
              id: allocationId,
            },
          },
          percentage: percentage,
          project: {
            connect: {
              id: projectId,
            },
          },
        },
      },
    });
  };

  const connectAllocation = (
    id: string,
    allocationId: string,
    percentage: number,
  ) => {
    updateProjectAllocation({
      variables: {
        data: {
          projectAllocations: {
            update: [
              {
                data: {
                  percentage: {
                    set: parseFloat(percentage.toString()),
                  },
                },
                where: {
                  id: id,
                },
              },
            ],
          },
        },
        where: {
          id: projectId,
        },
      },
    });
  };

  const testAllocationExistence = (
    id: string,
    allocationId: string,
    percentage: number,
  ) => {
    if (projectAllocationsData?.projectAllocations.length) {
      connectAllocation(id, allocationId, percentage);
    } else {
      createProjectAllocationData(id, allocationId, percentage);
    }
  };

  const updateFinanceData = async (data: { value: number; id: string }) => {
    await updateFinance({
      variables: {
        where: {
          id: data.id,
        },
        data: {
          deletedBy: {
            set: app.user!.kid!,
          },
          deletedAt: {
            set: new Date(),
          },
        },
      },
    });
  };

  const createFinanceData = async (data: {
    assetId: string;
    companyId: string;
    valueData: FinancialValue;
  }) => {
    await createFinance({
      variables: {
        data: {
          asset: {
            connect: {
              id: data.assetId,
            },
          },
          company: {
            connect: {
              id: data.companyId,
            },
          },
          project: {
            connect: {
              id: projectId,
            },
          },
          value: +data.valueData.value!,
          year: +data.valueData.year!,
          createdBy: app.user!.kid!,
        },
      },
    });
  };

  const removeFinanceData = async (id: string) => {
    await removeFinance({
      variables: {
        where: {
          id: id,
        },
      },
    });
  };

  const createProjectShortHistoryData = () => {
    createProjectShortHistory({
      variables: {
        data: {
          action: 'Finanzdaten',
          createdBy: app.user!.kid!,
          project: {
            connect: {
              id: projectId,
            },
          },
        },
      },
    });
  };

  const saveTableData = async (
    tableData: FinancialTableRow[],
    initialTable: FinancialTableRow[],
    allocationChanged,
  ) => {
    setIsUpdating(true);
    enum Operation {
      create,
      update,
      remove,
    }
    let array: { operation: Operation; data: any }[] = [];
    tableData.forEach((rowData: FinancialTableRow, rowIndex: number) => {
      rowData.values?.forEach((vData: FinancialValue, vIndex: number) => {
        if (rowData.isSummary) return;
        if (vData.value !== initialTable[rowIndex].values![vIndex].value) {
          // id of values which are not in the database are generated in the frontend and start with "key-"
          if (vData.id.substring(0, 4) === 'key-') {
            if (!vData.value) return;
            array.push({
              operation: Operation.create,
              data: {
                assetId: rowData.assetId,
                companyId: rowData.companyId || '',
                valueData: vData,
              },
            });
          } else {
            if (vData.value === 0 || !vData.value) {
              array.push({
                operation: Operation.update,
                data: {
                  value: vData.value,
                  id: vData.id,
                  assetId: rowData.assetId,
                  companyId: rowData.companyId || '',
                  valueData: vData,
                },
              });
            } else {
              array.push({
                operation: Operation.update,
                data: {
                  value: vData.value,
                  id: vData.id,
                  assetId: rowData.assetId,
                  companyId: rowData.companyId || '',
                  valueData: vData,
                },
              });
            }
          }
        }
      });
    });
    const promiseArray = array.map(item => {
      switch (item.operation) {
        case Operation.create: {
          return createFinanceData({
            assetId: item.data.assetId,
            companyId: item.data.companyId,
            valueData: item.data.valueData,
          });
        }
        case Operation.update: {
          updateFinanceData({
            value: item.data.value,
            id: item.data.id,
          });
          return createFinanceData({
            assetId: item.data.assetId,
            companyId: item.data.companyId,
            valueData: item.data.valueData,
          });
        }
        case Operation.remove: {
          return updateFinanceData({
            value: item.data.assetId,
            id: item.data.id,
          });
        }
        default: {
          console.log('something is weird');
          break;
        }
      }
    });
    Promise.all(promiseArray).then(() => {
      setIsUpdating(false);
      fetchFinancialData();
    });
    if (array.length === 0) {
      if (!allocationChanged) {
        return;
      }
      createProjectShortHistoryData();
    } else {
      createProjectShortHistoryData();
    }
  };
  const projectName =
    project?.projectDescriptions[0].name && project?.projectDescriptions
      ? project?.projectDescriptions[0].name
      : t('myItIdea.financial-data.noProjectName');
  const createdAt =
    project?.createdAt && project
      ? new Date(project.createdAt).toLocaleDateString()
      : t('myItIdea.financial-data.noDate');
  return (
    <div className="w-full">
      <div className="text-xl font-semibold text-center">
        {project?.projectDescriptions
          ? project?.projectDescriptions![0].name
          : t('itProjects.detail.noProjectName')}
      </div>
      {/*<div className="flex pt-5 place-content-center gap-8">
        <p>
          {t('myItIdea.financial-data.projectCreated', {
            projectName,
            createdAt,
          })}
        </p>
      </div>*/}
      <FinancialTable
        projectId={projectId}
        allocationsData={allocationsData}
        allocs={projectAllocationsData?.projectAllocations}
        financialData={financialData}
        resourceData={projectResourceCapturesData}
        isUpdating={isUpdating}
        isDisabled={isDisabled}
        handleSaveTableData={saveTableData}
        handleConnectAllocation={testAllocationExistence}
      />
    </div>
  );
};

export default FinancialDataPage;
