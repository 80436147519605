import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import TableRow, { TableRowProps } from 'components/Table/TableRow';
import { MouseEvent } from 'react';

export interface ApprovalListRowProps extends Omit<TableRowProps, 'right'> {
  name?: string;
  onShow?: () => void;
  onEdit?: () => void;
}

const ApprovalListRow = ({
  name,
  onShow,
  onEdit,
  ...tableRowProps
}: ApprovalListRowProps) => {
  const handleOnShow = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onShow && onShow();
  };
  const handleOnEdit = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit && onEdit();
  };

  const rightSide = (
    <div className="flex">
      <div className="w-1/2 text-left">{name}</div>
      <div className="w-1/2 space-x-4">
        <button
          className={classNames('rounded-full w-8 h-8', {
            'hover:bg-darkGrey': onShow,
          })}
          disabled={!onShow}
          onClick={handleOnShow}
        >
          <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
        </button>
        <button
          className={classNames('rounded-full w-8 h-8', {
            'hover:bg-darkGrey': onEdit,
          })}
          disabled={!onEdit}
          onClick={handleOnEdit}
        >
          <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
        </button>
      </div>
    </div>
  );

  return <TableRow {...tableRowProps} right={rightSide} />;
};
export default ApprovalListRow;
