import { Route, Switch } from 'react-router';
import OperatingCostsPage from './OperatingCosts';
import ProjectCostsPage from './ProjectCosts';
import TabBar from 'components/TabBar';
import config from 'config/config';
export interface FinancialDataPageProps {
  projectId: string;
}

const FinancialDataPage = ({ projectId }: FinancialDataPageProps) => {
  const basePath = config.localRoutes.tdReporting.financialdata.replace(
    ':projectId',
    projectId,
  );
  return (
    <div className="w-full">
      <TabBar
        isProjectSelected={projectId !== undefined}
        data={[
          {
            id: 1,
            text: 'Projektkosten',
            route: `${basePath}`,
          },
          {
            id: 2,
            text: 'Betriebskosten',
            route: `${basePath}/operatingcosts`,
          },
        ]}
      />

      <Switch>
        <Route
          path={`${basePath}`}
          exact
          component={() => <ProjectCostsPage projectId={projectId} />}
        />
        <Route
          path={`${basePath}/projectcosts`}
          exact
          component={() => <ProjectCostsPage projectId={projectId} />}
        />
        <Route
          path={`${basePath}/operatingcosts`}
          exact
          component={() => <OperatingCostsPage projectId={projectId} />}
        />
      </Switch>
    </div>
  );
};
export default FinancialDataPage;
