// Updates the height of a <textarea> when the value changes.

const autosizeTextArea = (e: { target: HTMLElement | null }) => {
  if (e.target) {
    e.target.style.height = 'inherit';
    return (e.target.style.height = `${Math.min(
      e.target.scrollHeight + 2,
      512,
    )}px`);
  }
};

export default autosizeTextArea;
