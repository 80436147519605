import { User } from 'microsoft-graph';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface ReportPopupTableProps {
  addressee: User[];
  handleDelete?: (addressee: User, index: number) => void;
}

export const ReportPopupTable = ({
  addressee,
  handleDelete,
}: ReportPopupTableProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-grow gap-12 mt-4">
      <table>
        <thead>
          <tr className="text-left">
            <th className="w-7/12">{t`tdReporting.report.addresseeName`}</th>
            <th className="w-7/12">{t`tdReporting.report.addresseeKid`}</th>

            <th />
          </tr>
        </thead>
        {addressee.length > 0 && (
          <tbody>
            {addressee.map((employee, index) => {
              return (
                <tr key={index} className="border-t border-tableDivider">
                  <td>{employee.displayName}</td>
                  <td>
                    {employee.userPrincipalName
                      ? employee.userPrincipalName.split('@')[0]
                      : ''}
                  </td>
                  {handleDelete && (
                    <td>
                      <button
                        onClick={() => {
                          handleDelete(employee, index);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};
