import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { addMonths, format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import SkeletonLoader from 'components/SkeletonLoader';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { ProjectRolesEnum, ProjectStatusEnum } from 'graphql/types';
import AuthenticationMessage from '../../AuthenticationMessage';
import { isUserInGroup } from 'helpers/util';
import { useAppContext } from '../../AppContext';
import {
  useCreateProjectDocumentMutation,
  useDashboardGetProjectByIdQuery,
  useGetEmployeeByIdLazyQuery,
  useRemoveProjectDocumentMutation,
  useUpdateProjectCommentsMutation,
} from 'graphql/codegen/graphql';
import { v4 as uuidv4 } from 'uuid';
import Form from 'components/common/Form/components/Form';
import { TDocument } from 'types/TDocument';
import { ApprovalDetailProps } from './Detail';

const ViewPage = ({ match }: ApprovalDetailProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const [dialog, setDialog] = useState<
    'release_idea' | 'release_project_activation' | 'decline_idea' | null
  >(null);
  const { user } = useAppContext();
  const { data, loading } = useDashboardGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  });

  const [getEmployeeById, { loading: approverLoading, data: approverData }] =
    useGetEmployeeByIdLazyQuery();
  const [removeProjectDocumentMutation] = useRemoveProjectDocumentMutation();

  const [createProjectDocument] = useCreateProjectDocumentMutation({
    refetchQueries: ['dashboardGetProjectById'],
  });

  const [updateProjectDescription] = useUpdateProjectCommentsMutation({
    refetchQueries: ['dashboardGetProjectById'],
  });

  const project = data?.masters[0];
  const projectStatus = project?.projectStatus?.find(p => p.deletedBy === null);
  const isApproved = project?.projectApproval?.some(
    a => a.approvalBoardId === 'con',
  );

  const projectLead = project?.projectRole?.find(
    projectRole => projectRole.role.id === ProjectRolesEnum.PROJEKTLEITUNG,
  )?.employee;
  const latestStatus = project?.projectDescriptions?.filter(
    s => s.deletedBy === null,
  );

  const projectContact = project?.projectRole?.find(
    projectRole =>
      projectRole.role.id === ProjectRolesEnum.TECHNISCHER_ANSPRECHPARTNER,
  )?.employee;

  const projectApproverInfo = project?.projectStatus?.find(
    s => s.sequenceStatusId === ProjectStatusEnum.IDEA_ACCEPTED,
  );

  useEffect(() => {
    getEmployeeById({
      variables: {
        id: projectApproverInfo?.createdBy || '',
      },
    });
  }, [projectApproverInfo?.createdBy, getEmployeeById]);

  const mapRemarks = () => {
    let arr: ReactElement[] = [];
    project?.remarks?.forEach((r, index) => {
      if (r.deletedBy === null) {
        arr.push(
          <div className="flex space-x-2" key={index}>
            <p className="flex font-medium">{r.segment.segment + ': '}</p>
            {r.remark === '' ? <p>{'Keine Bemerkungen'}</p> : <p>{r.remark}</p>}
          </div>,
        );
      }
    });
    return arr;
  };

  const [remarks, setRemarks] = useState<ReactElement[]>();

  useEffect(() => {
    setRemarks(mapRemarks);
  }, [project?.remarks]);

  if (AuthenticationMessage('it-projects')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`itProjects.detail.actions.alert`}</p>
      </Alert>
    );
  }

  return (
    <>
      <Breadcrumb
        label={`Dashboard - ${data?.masters?.[0]?.projectDescriptions?.[0]?.name}`}
        route={`/meine-it-initiative/${projectId}`}
      />
      <div className="mb-5 flex items-center justify-between">
        <h3 className="h-6 text-lg font-medium leading-6 text-gray-900">
          <SkeletonLoader loading={loading} className="h-6">
            {latestStatus?.[0].name}
          </SkeletonLoader>
        </h3>
        <div className="ml-4 flex"></div>
      </div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <Form onSubmit={() => {}}>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.projectLead`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-64" loading={loading}>
                    {projectLead
                      ? `${projectLead?.firstName} ${projectLead?.lastName}`
                      : t`itProjects.detail.actions.noProjectLeadFound`}
                  </SkeletonLoader>
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.projectContact`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-64" loading={loading}>
                    {projectContact
                      ? `${projectContact?.firstName} ${projectContact?.lastName}`
                      : 'noch zu benennen'}
                  </SkeletonLoader>
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.category`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-64" loading={loading}>
                    {latestStatus?.[0].projectType.projectType}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.start`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-48" loading={loading}>
                    {project?.projectDescriptions?.[0].projectStart
                      ? format(
                        parseISO(
                          latestStatus?.[0].projectStart as unknown as string,
                        ),
                        'dd.MM.yyyy',
                      )
                      : ''}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />


              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t`itProjects.detail.labels.end`}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="block h-5 w-48" loading={loading}>
                    {project?.projectDescriptions?.[0].projectStart
                      ? t('itProjects.detail.projectEnd', {
                        date: format(
                          addMonths(
                            parseISO(
                              latestStatus?.[0]
                                .projectStart as unknown as string,
                            ),
                            latestStatus?.[0].projectDuration!,
                          ),
                          'dd.MM.yyyy',
                        ),
                        months: latestStatus?.[0].projectDuration,
                      })
                      : ''}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.description`}</dt>
                <dd className="mt-1 text-sm text-gray-900 break-words">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {latestStatus?.[0].description}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.remark`}</dt>
                <dd className="mt-1 text-sm text-gray-900 break-words">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {remarks?.length ? remarks : 'Keine Bemerkungen'}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.status`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {projectStatus?.sequenceStatus.descriptionLong}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.background`}</dt>
                <dd className="mt-1 text-sm text-gray-900 break-words">
                  <SkeletonLoader className="h-10 w-full" loading={loading}>
                    {latestStatus?.[0].background}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.approver`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader
                    className="h-10 w-full"
                    loading={approverLoading}
                  >
                    {projectApproverInfo !== undefined
                      ? approverData?.employee.firstName +
                      ' ' +
                      approverData?.employee.lastName
                      : t`itProjects.detail.actions.notReleased`}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{t`itProjects.detail.labels.approvalDate`}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <SkeletonLoader
                    className="h-10 w-full"
                    loading={approverLoading}
                  >
                    {projectApproverInfo !== undefined
                      ? projectApproverInfo.createdAt
                        ? format(
                          parseISO(
                            projectApproverInfo.createdAt as unknown as string,
                          ),
                          'dd.MM.yyyy',
                        )
                        : ''
                      : t`itProjects.detail.actions.notReleased`}
                  </SkeletonLoader>
                </dd>
              </div>

              <div className="sm:col-span-1" />

              <div className="sm:col-span-1" />
            </dl>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ViewPage;
