import { useHistory } from 'react-router-dom';

import Dialog from 'components/common/Dialog';
import useTypedForm from 'components/common/Form/components';
import {
  DashboardGetProjectByIdDocument,
  useCreateProjectShortHistoryMutation,
  useCreateProjectStatusMutation,
  useUpdateProjectStatusMutation,
} from 'graphql/codegen/graphql';
import { ProjectStatusEnum } from 'graphql/types';
import { useAppContext } from 'AppContext';
import { useTranslation } from 'react-i18next';

interface FormValues {}

const ProjectActivationReleaseDialog: React.VFC<{
  open: boolean;
  onClose: () => void;
  projectStatusId: number;
  projectId?: string;
  projectDescriptionId?: string;
}> = ({ open, onClose, projectStatusId, projectId, projectDescriptionId }) => {
  const { t } = useTranslation();
  const [createProjectShortHistory] = useCreateProjectShortHistoryMutation();
  const { Form, SubmitButton, CancelButton } = useTypedForm<FormValues>();
  const { user } = useAppContext();
  const history = useHistory();

  const [createProjectStatus] = useCreateProjectStatusMutation({
    variables: {
      data: {
        createdBy: user?.kid ? user!.kid : '',
        project: {
          connect: {
            id: projectId,
          },
        },
        sequenceStatus: {
          connect: {
            id: ProjectStatusEnum.ACTIVATED,
          },
        },
      },
    },
  });
  const [updateProjectStatus] = useUpdateProjectStatusMutation({
    variables: {
      data: {
        deletedBy: {
          set: user?.kid ? user!.kid : '',
        },
        deletedAt: {
          set: new Date(),
        },
      },
      where: {
        id: projectStatusId,
      },
    },
  });

  const handleSubmit = async data => {
    if (projectStatusId && projectId && projectDescriptionId && user?.kid) {
      await createProjectStatus();
      await updateProjectStatus({
        refetchQueries: [DashboardGetProjectByIdDocument],
      });
      createProjectShortHistory({
        variables: {
          data: {
            project: {
              connect: {
                id: projectId,
              },
            },
            action: t`tdReporting.subpages.projectActivation`,
            createdBy: user?.kid,
          },
        },
      });
      await history.push('/it-projects');
    }
    onClose();
  };

  return (
    <Dialog title="Projektaktivierung" open={open} onClose={onClose}>
      <Form onSubmit={handleSubmit} mode="onChange">
        <div className="pt-6 sm:pt-5">
          <div role="group" aria-labelledby="label-notifications">
            <div className="text-base font-normal text-gray-900 text-center sm:text-lg sm:text-gray-700">
              Möchtest du die Initiative fachlich freigeben?
            </div>
            <div className="my-8 flex items-center justify-center space-x-5">
              <CancelButton theme="danger" onClick={onClose}>
                Nein, nicht freigeben
              </CancelButton>
              <SubmitButton>Ja, freigeben</SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </Dialog>
  );
};

export default ProjectActivationReleaseDialog;
