import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import useTypedForm from 'components/common/Form/components';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import { StepProps } from '.';
import { Button } from 'components/common/Button';

export type FormData = {
  background: string;
  summary: string;
  projectType?: string;
};

const Description = ({ values, onSubmit, onPrev }: StepProps<FormData>) => {
  const { t } = useTranslation();
  const { TextArea, Form, SubmitButton } = useTypedForm<FormData>();

  const [summaryDescription, setSummaryDescription] = useState(values?.summary);
  const [backgroundDescription, setBackgroundDescription] = useState(
    values?.background,
  );

  return (
    <div className="w-full flex flex-1 flex-col">
      {values!.projectType === 'ckwxitvsi0055sdmnilg4t9du' ? (
        <Breadcrumb label="Beschreibung" route="neue-it-initiative/summary" />
      ) : (
        <Breadcrumb
          label="Aufnahme deiner Initiative"
          route="neue-it-initiative/description"
        />
      )}

      <Form
        onSubmit={onSubmit}
        defaultValues={values}
        mode="onChange"
        className="flex flex-col"
      >
        {/* background */}
        <div className="w-full flex flex-1 flex-col space-y-5 pb-2 pt-2" >
          <p className="text-base">{t`myItIdea.background.description`}</p>
          <TextArea
            name="background"
            rules={{
              required: true,
            }}
            value={backgroundDescription}
            onChange={e => {
              setBackgroundDescription(e.target.value);
            }}
          />
        </div>

        {/* summary */}
        <div className="w-full flex flex-1 flex-col space-y-5 pb-1 pt-5">
          <p className="text-base">
            <Trans i18nKey="myItIdea.summary.description2" />
          </p>
          <TextArea
            name="summary"
            rules={{
              required: true,
            }}
            value={summaryDescription}
            onChange={e => {
              setSummaryDescription(e.target.value);
            }}
          />
        </div>

        {/* button */}
        <div className="flex flex-grow-0 justify-end pt-5 space-x-7">
          {onPrev && (
            <Button onClick={onPrev} theme="secondary">
              {t('myItIdea.actions.prev')}
            </Button>
          )}
          <SubmitButton>{t('myItIdea.actions.next')}</SubmitButton>
        </div>
      </Form>
    </div>
  );
};
export default Description;
