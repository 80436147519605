import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

export interface FileUploadListItemProps {
  name: string;
  onDelete?: () => void;
  disabled?: boolean;
}

const FileUploadListItem = ({
  name,
  onDelete,
  disabled,
}: FileUploadListItemProps) => {
  return (
    <div className={`flex justify-between ${disabled && "opacity-30"}`}>
      <div>{name}</div>
      <div className="space-x-4">
        <FontAwesomeIcon
          className={classNames({ 'cursor-pointer': !!onDelete })}
          icon={faTrashAlt}
          onClick={disabled ? () => {} : onDelete}
        />
      </div>
    </div>
  );
};

export default FileUploadListItem;
