import classNames from 'classnames';
import { PropsWithChildren } from 'react';

const Label = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <label
      className={classNames(
        'block mb-4 text-primary font-primary font-normal',
        className,
      )}
    >
      {children}
    </label>
  );
};
export default Label;
