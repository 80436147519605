import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';

import OverviewHistory from './history';
import OverviewPage from './overview';
import OverviewPlanning from './planning';
import OverviewRoles from './roles';
import TDReportingAuth from 'components/TDReporting/TDReportingAuth';
import TabBar from 'components/TabBar';
import config from 'config/config';

import { ReportingGetProjectByIdQuery } from 'graphql/codegen/graphql';

export interface ProjectOverviewPageProps {
  projectId: string;
  data: ReportingGetProjectByIdQuery | undefined;
  loading: boolean;
}

const ProjectOverviewPage: React.VFC<ProjectOverviewPageProps> = ({
  projectId,
  data,
  loading,
}) => {
  const { t } = useTranslation();

  const basePath = config.localRoutes.tdReporting.projectoverview.replace(
    ':projectId',
    projectId,
  );

  const [authenticated, setAuthenticated] = useState(false);

  if (TDReportingAuth('td-reporting', projectId)) {
    return (
      <Alert variant="danger">
        <p className="mb-3">{t`pages.common.errorAuthentication`}</p>
      </Alert>
    );
  } else {
    if (!authenticated) {
      setAuthenticated(true);
    }
  }

  return (
    <div className="w-full">
      <TabBar
        isProjectSelected={true}
        data={[
          {
            id: 1,
            text: t`tdReporting.pages.projectoverview`,
            route: `${basePath}`,
          },
          {
            id: 2,
            text: t`tdReporting.projectoverviewPage.projectPlanning`,
            route: `${basePath}/planning`,
          },
          {
            id: 3,
            text: t`tdReporting.projectoverviewPage.roles`,
            route: `${basePath}/roles`,
          },
          {
            id: 4,
            text: t`tdReporting.projectoverviewPage.history`,
            route: `${basePath}/history`,
          },
        ]}
      />
      <Switch>
        <Route
          path={`${basePath}`}
          exact
          component={() => (
            <OverviewPage projectId={projectId} data={data} loading={loading} />
          )}
        />
        <Route
          path={`${basePath}/planning`}
          exact
          component={() => (
            <OverviewPlanning
              projectId={projectId}
              data={data}
              loading={loading}
            />
          )}
        />
        <Route
          path={`${basePath}/roles`}
          exact
          component={() => (
            <OverviewRoles
              projectId={projectId}
              data={data}
              loading={loading}
            />
          )}
        />
        <Route
          path={`${basePath}/history`}
          exact
          component={() => (
            <OverviewHistory
              projectId={projectId}
              data={data}
              loading={loading}
            />
          )}
        />
      </Switch>
    </div>
  );
};
export default ProjectOverviewPage;
