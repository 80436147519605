import classNames from 'classnames';
import {
  components,
  GroupBase,
  OptionProps as ReactSelectOptionProps,
} from 'react-select';
import './Option.css';

export type OptionProps<TOption> = ReactSelectOptionProps<
  TOption,
  false,
  GroupBase<TOption>
>;
function Option<TOption>({ children, ...rest }: OptionProps<TOption>) {
  return (
    <components.Option
      {...rest}
      className={classNames('dropdown-option', {
        'dropdown-option-selected': rest.isSelected,
      })}
    >
      {children}
    </components.Option>
  );
}
export default Option;
