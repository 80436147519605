import useTypedForm from '../../common/Form/components';
import { useAppContext } from '../../../AppContext';
import { useTranslation } from 'react-i18next';
import {
  ApproverProcessesQuery,
  useApproverProcessesQuery,
  useCreateApproverRecipientMutation,
  useUpdateApproverRecipientMutation,
} from '../../../graphql/codegen/graphql';
import React, { useEffect, useState } from 'react';
import { Employee } from '../IdeaReleaseDialog';
import { useMsal } from '@azure/msal-react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import configuration from '../../../Config';
import { findUser } from '../../../GraphService';
import { debounce } from 'lodash';
import Dialog from '../../common/Dialog';
import { EditDialogProps } from '../../../pages/Dashboard/ApproverRecipient';
import { DropdownOptions } from '../../TDReporting/Dialog/EditProjectPlanningDialog';

type EditDialogFormValues = {
  kid: string;
  firstname: string;
  lastname: string;
  process: DropdownOptions;
};

const SHORT_KID_LENGTH = 5;
const LONG_KID_LENGTH = 6;

const CreateEditDialog = ({
  editApproverRecipient,
  onClose,
  mode,
}: EditDialogProps) => {
  const { Form, Dropdown, TextInput, SubmitButton } =
    useTypedForm<EditDialogFormValues>();
  const { user } = useAppContext();
  const { t } = useTranslation();

  const [createApproverRecipient] = useCreateApproverRecipientMutation({
    refetchQueries: ['ApproverRecipients'],
  });

  const [updateApproverRecipient] = useUpdateApproverRecipientMutation({
    refetchQueries: ['ApproverRecipients'],
  });

  const [employee, setEmployee] = useState<Employee>();
  const [mappedApproverProcess, setMappedApproverProcess] = useState<
    DropdownOptions[]
  >([]);

  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  useEffect(() => {
    requestEmployee(editApproverRecipient?.kid || '');
  }, []);

  const handleEdit = (data: EditDialogFormValues) => {
    if (editApproverRecipient?.id)
      updateApproverRecipient({
        variables: {
          data: {
            approverProcess: {
              connect: {
                id: data.process.value,
              },
            },
            firstName: { set: employee?.givenName! },
            lastName: { set: employee?.surname! },
            kid: { set: data.kid },
          },
          id: editApproverRecipient?.id,
        },
      });
  };
  const handleCreate = (data: EditDialogFormValues) => {
    createApproverRecipient({
      variables: {
        data: {
          approverProcess: {
            connect: {
              id: data.process.value,
            },
          },
          firstName: employee?.givenName!,
          lastName: employee?.surname!,
          kid: data.kid,
          createdBy: user!.kid!,
        },
      },
    });
  };

  const requestEmployee = async (value: string) => {
    const user = await findUser(authProvider, value);
    if (user) {
      setEmployee(user);
    } else {
      setEmployee(undefined);
    }
  };

  const debouncedRequestEmployee = debounce(requestEmployee, 1000);

  const handleKIDFieldChange = (value: string) => {
    setEmployee(undefined);
    if (value.length === SHORT_KID_LENGTH || value.length === LONG_KID_LENGTH)
      debouncedRequestEmployee(value);
  };

  const handleSubmit = (data: EditDialogFormValues) => {
    if (mode === 'edit') {
      handleEdit(data);
    } else if (mode === 'create') {
      handleCreate(data);
    }
    setEmployee(undefined);
    onClose();
  };

  const mapApproverProcess = (data: ApproverProcessesQuery) => {
    setMappedApproverProcess(
      data.approverProcesses.map(entry => ({
        label: entry.id + ' - ' + entry.description,
        value: entry.id,
      })),
    );
  };

  const { data: approverProcessesData } = useApproverProcessesQuery({
    onCompleted: mapApproverProcess,
  });

  return (
    <Dialog
      open={!!editApproverRecipient}
      onClose={onClose}
      title={
        mode === 'edit'
          ? t`itProjects.dashboard.recipientApproverPage.edit`
          : t`itProjects.dashboard.recipientApproverPage.create`
      }
    >
      <Form onSubmit={handleSubmit} mode="onChange">
        <div className="space-y-4">
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">
              {t`itProjects.dashboard.recipientApproverPage.kid`}:
            </div>
            <div className="w-full">
              <TextInput
                name="kid"
                defaultValue={editApproverRecipient?.kid}
                onChange={e => {
                  handleKIDFieldChange(e.target.value);
                }}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">
              {t`itProjects.dashboard.recipientApproverPage.givenName`}:
            </div>
            <div className="w-full">
              <TextInput
                name="firstname"
                defaultValue={employee?.givenName ?? ''}
                value={employee?.givenName ?? ''}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">
              {t`itProjects.dashboard.recipientApproverPage.surname`}:
            </div>
            <div className="w-full">
              <TextInput
                name="lastname"
                defaultValue={employee?.surname ?? ''}
                value={employee?.surname ?? ''}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex flex-grow items-center space-x-4">
            <div className="text-primary w-1/6">
              {t`itProjects.dashboard.recipientApproverPage.process`}:
            </div>
            <div className="w-full">
              <Dropdown
                name="process"
                rules={{ required: true }}
                variant="default"
                options={mappedApproverProcess}
                defaultValue={mappedApproverProcess.find(
                  entry =>
                    entry.value === editApproverRecipient?.approverProcess.id,
                )}
              />
            </div>
          </div>
          <SubmitButton>{t`itProjects.dashboard.recipientApproverPage.saveButton`}</SubmitButton>
        </div>
      </Form>
    </Dialog>
  );
};

export default CreateEditDialog;
