import React from 'react';
import arrow from '../../assets/images/arrow4x.png';

interface FinancialRowProps {
  years: number[];
  distributedTitle: string;
  plannedTitle: string;
  firstValueIndex: number;
  showForward: boolean;
  handleBack: () => void;
  handleForward: () => void;
}

const FinancialHeadRow = (props: FinancialRowProps) => {
  const {
    years,
    distributedTitle,
    plannedTitle,
    firstValueIndex,
    showForward,
    handleBack,
    handleForward,
  } = props;

  return (
    <tr className="font-semibold text-left bg-tableLightGrey border-b border-tableDarkGrey shadow sm:rounded-lg">
      <th className="font-semibold firstCol w-7/18">{distributedTitle}</th>
      <th className="w-1/18">
        {firstValueIndex !== 0 && (
          <button onClick={handleBack} className="h-6">
            <img
              src={arrow}
              alt="arrow down"
              className="w-3 h-2 mx-2 transform rotate-90"
            />
          </button>
        )}
      </th>
      <th className="font-semibold w-1/18">{years[firstValueIndex]} in €</th>
      <th className="w-1/18"></th>
      <th className="font-semibold w-1/18">
        {years[1 + firstValueIndex]} in €
      </th>
      <th className="w-1/18"></th>
      <th className="font-semibold w-1/18">
        {years[2 + firstValueIndex]} in €
      </th>
      <th className="w-1/18"></th>
      <th className="font-semibold w-1/18">
        {years[3 + firstValueIndex]} in €
      </th>
      <th className="items-start w-1/18">
        {showForward && (
          <button onClick={handleForward} className="h-6">
            <img
              src={arrow}
              alt="arrow down"
              className="w-3 h-2 mx-2 transform -rotate-90"
            />
          </button>
        )}
      </th>
      <th className="font-semibold w-2/18" colSpan={2}>
        {plannedTitle}
      </th>
    </tr>
  );
};

export default FinancialHeadRow;
