import { useHistory } from 'react-router-dom';

import Dialog from 'components/common/Dialog';
import useTypedForm from 'components/common/Form/components';
import {
  DashboardGetProjectByIdDocument,
  useCreateProjectStatusMutation,
  useCreateRemarkMutation,
  useGetSegmentsQuery,
  useUpdateProjectStatusMutation,
} from 'graphql/codegen/graphql';
import { ProjectStatusEnum, Segments } from 'graphql/types';
import { useAppContext } from 'AppContext';
import CancelButton from '../../common/Form/components/CancelButton';
import { SetStateAction, useState } from 'react';
import TextArea from '../../common/Form/components/TextArea';
import { useTranslation } from 'react-i18next';

interface FormValues {}

const IdeaDeclineDialog: React.VFC<{
  open: boolean;
  onClose: () => void;
  projectStatusId: number;
  projectId?: string;
  projectDescriptionId?: string;
}> = ({ open, onClose, projectStatusId, projectId, projectDescriptionId }) => {
  const { Form, SubmitButton } = useTypedForm<FormValues>();
  const { user } = useAppContext();
  const { t } = useTranslation();
  const history = useHistory();

  const [declineProjectStatus] = useCreateProjectStatusMutation({
    variables: {
      data: {
        createdBy: user?.kid ? user!.kid : '',
        project: {
          connect: {
            id: projectId,
          },
        },
        sequenceStatus: {
          connect: {
            id: ProjectStatusEnum.DECLINED,
          },
        },
      },
    },
  });
  const [returnProjectStatus] = useCreateProjectStatusMutation({
    variables: {
      data: {
        createdBy: user?.kid ? user!.kid : '',
        project: {
          connect: {
            id: projectId,
          },
        },
        sequenceStatus: {
          connect: {
            id: ProjectStatusEnum.RETURNED,
          },
        },
      },
    },
  });
  const [updateProjectStatus] = useUpdateProjectStatusMutation({
    variables: {
      data: {
        deletedBy: {
          set: user?.kid ? user!.kid : '',
        },
        deletedAt: {
          set: new Date(),
        },
      },
      where: {
        id: projectStatusId,
      },
    },
  });
  const { data: segmentsData } = useGetSegmentsQuery({});
  const [createRemarkMutation] = useCreateRemarkMutation({});

  const findSegmentId = (segmentName: string) =>
    segmentsData
      ? segmentsData.segments.find(s => s.segment === segmentName)?.id
      : undefined;

  const createRemark = (data: { segment: string; remark: string }) => {
    createRemarkMutation({
      variables: {
        data: {
          remark: data.remark,
          createdBy: user?.kid ? user!.kid : '',
          segment: {
            connect: {
              id: findSegmentId(data.segment),
            },
          },
          project: {
            connect: {
              id: projectId,
            },
          },
        },
      },
    });
  };

  const [inputText, setInputText] = useState('');
  const handleChange = (data: {
    target: { value: SetStateAction<string> };
  }) => {
    setInputText(data.target.value);
  };

  const handleDecline = async () => {
    if (projectStatusId && projectId && projectDescriptionId && user?.kid) {
      if (inputText !== '') {
        await createRemark({
          remark: inputText,
          segment: Segments.DECLINE_PROJECT,
        });
      }
      await updateProjectStatus();
      await declineProjectStatus({
        refetchQueries: [DashboardGetProjectByIdDocument],
      });
      await history.push('/it-projects');
    }
    onClose();
  };

  const handleSubmit = async () => {
    if (projectStatusId && projectId && projectDescriptionId && user?.kid) {
      if (inputText !== '') {
        await createRemark({
          remark: inputText,
          segment: Segments.RETURN_PROJECT,
        });
      }
      await updateProjectStatus();
      await returnProjectStatus({
        refetchQueries: [DashboardGetProjectByIdDocument],
      });
      await history.push('/it-projects');
    }
    onClose();
  };

  return (
    <Dialog title="Initiative ablehnen" open={open} onClose={onClose}>
      <Form onSubmit={handleSubmit} mode="onChange">
        <div className="pt-6 sm:pt-5">
          <div role="group" aria-labelledby="label-notifications">
            <div className="text-base font-normal text-gray-900 text-center sm:text-lg sm:text-gray-700">
              {t`dashboardProject.declineInitiative`}
            </div>
            <div className="mb-4 mt-6 flex items-center justify-center space-x-5">
              <div className={'w-112'}>
                <TextArea
                  name={'remark'}
                  className="p-2 w-112 border border-gray-300 rounded-md hover:border-primary focus:border-primary"
                  onChange={handleChange}
                  placeholder={t`dashboardProject.reasonForRejection`}
                  rows={1}
                  autoHeight={true}
                />
              </div>
            </div>
            <div className="mb-8 mt-4 flex items-center justify-center space-x-5">
              <CancelButton
                theme="danger"
                disabled={inputText === ''}
                onClick={handleDecline}
              >{t`itProjects.dashboard.actions.cancelButton`}</CancelButton>
              <SubmitButton
                disabled={inputText === ''}
              >{t`itProjects.dashboard.actions.requestReworkButton`}</SubmitButton>
            </div>
          </div>
        </div>
      </Form>
    </Dialog>
  );
};
export default IdeaDeclineDialog;
