import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { Client } from '@microsoft/microsoft-graph-client';
import { Group, User } from 'microsoft-graph';

let graphClient: Client | undefined = undefined;

function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
}

export async function getUser(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
): Promise<User> {
  ensureClient(authProvider);

  const user: User = await graphClient!
    .api('/me')
    .select(
      'id,displayName,mail,userPrincipalName,department,givenName,surname',
    )
    .get();

  return user;
}

export async function findUser(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
  kid: string,
): Promise<User> {
  ensureClient(authProvider);

  const user: User = await graphClient!
    .api('/users/' + kid + '@eon.com')
    .select(
      'id,displayName,mail,userPrincipalName,department,givenName,surname',
    )
    .get();

  return user;
}

export async function getMyPhoto(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
) {
  const accessToken = await authProvider.getAccessToken();
  return await getUserPhoto('me', accessToken)
    .then(function (response) {
      if (response.ok) {
        return response.blob();
      }
    })
    .then(function (photoBlob) {
      if (photoBlob) {
        return URL.createObjectURL(photoBlob);
      }
    });
}

export async function getUserPhoto(userId, accessToken) {
  // https://graph.microsoft.com/beta/users/UserIdOrPrincipalName/photo/$value
  // https://graph.microsoft.com/beta/me/photo/$value
  // https://graph.microsoft.com/v1.0/' + userId + '/photo/$value
  const url = 'https://graph.microsoft.com/v1.0/' + userId + '/photo/$value';
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  });
}

export async function getGroups(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
): Promise<[string]> {
  ensureClient(authProvider);

  const groupMembers = await graphClient!
    .api('/me/getMemberGroups')
    .post({ securityEnabledOnly: false });

  return groupMembers.value;
}

export async function getGroupMembers(
  authProvider: AuthCodeMSALBrowserAuthenticationProvider,
  groupId: string,
): Promise<[]> {
  ensureClient(authProvider);

  const group =
    process.env.NODE_ENV === 'development'
      ? 'eae512de-c9d8-4b32-8524-c5aa33b6b94c'
      : groupId;

  const members = await graphClient!
    .api('/groups/' + group + '/members/microsoft.graph.user')
    .header('ConsistencyLevel','eventual')
    .select('id,displayName,surname,givenName,mail,userPrincipalName')
    .orderby('givenName')
    .get();

  return members.value;
}
