import { useEffect, useState } from 'react';

import useTypedForm from 'components/common/Form/components';
import { findUser } from '../GraphService';

import { debounce } from 'lodash';

import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import configuration from '../Config';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { useMsal } from '@azure/msal-react';
import { User } from 'microsoft-graph';

const SHORT_KID_LENGTH = 5;
const LONG_KID_LENGTH = 6;
export interface DebouncedLookupProps {
  name: string;
  employeeName: string;
  onInput: (value: string) => void;
  handleUserInput?: (user: User) => void;
}
const DebouncedLookup: React.VFC<DebouncedLookupProps> = ({
  name,
  employeeName,
  onInput,
  handleUserInput,
}) => {
  const [nameInput, setNameInput] = useState(employeeName);

  useEffect(() => {
    setNameInput(employeeName);
  }, [employeeName]);

  const { TextInput } = useTypedForm();
  const msal = useMsal();

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance as PublicClientApplication,
    {
      account: msal.instance.getActiveAccount()!,
      scopes: configuration.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  const requestEmployee = async (value: string) => {
    const user = await findUser(authProvider, value);
    if (user) {
      if (user.displayName) {
        onInput(value);
        if (handleUserInput) {
          handleUserInput(user);
        }
      }
    } else {
      return;
    }
  };
  const debouncedRequestEmployee = debounce(requestEmployee, 1000);
  const handleKIDFieldChange = (value: string) => {
    setNameInput(value);

    if (value.length === SHORT_KID_LENGTH || value.length === LONG_KID_LENGTH)
      debouncedRequestEmployee(value);
  };

  return (
    <div>
      <TextInput
        name={name}
        value={nameInput}
        onChange={e => {
          handleKIDFieldChange(e.target.value);
        }}
      />
    </div>
  );
};
export default DebouncedLookup;
