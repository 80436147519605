import React, { useEffect, useState, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import config from 'config/config';
import { useTranslation } from 'react-i18next';
import AuthenticationMessage from '../../AuthenticationMessage';
import ControllerTable, {
  ProjectFilter,
} from '../../components/dashboardController/ControllerTable';
import IdeaBudgetAcceptDialog from '../../components/dashboardController/IdeaBudgetAcceptDialog';
import {
  DashboardGetProjectByIdQuery,
  useDashboardGetProjectByIdLazyQuery,
  useGetAllocationsQuery,
} from '../../graphql/codegen/graphql';
import useSearchParams from '../../hooks/useSearchParams';

function isProjectStatus(
  project: DashboardGetProjectByIdQuery['masters'][number] | undefined,
  state: string,
) {
  if (project && project.projectStatus) {
    return project.projectStatus.find(
      s => s.deletedBy === null && s.sequenceStatusId === state,
    );
  }
}

const ProjectCostsPage = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState<
    'accept_budget' | 'release_project_activation' | 'decline_idea' | null
  >(null);
  const { location, replace } = useHistory();
  const history = useHistory();

  const [getProjectById] = useDashboardGetProjectByIdLazyQuery({
    onCompleted: data => {
      setProject(data.masters[0]);
    },
  });

  const [searchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(searchParams.get('projectId'));
  const params = new URLSearchParams(location.search);
  const [filter, setFilter] = useState(params.get('filter'));
  const [showApproveButton, setShowApproveButton] = React.useState(false);
  const [showFinanceButton, setShowFinanceButton] = React.useState(false);
  const [project, setProject] = useState<
    DashboardGetProjectByIdQuery['masters'][number] | undefined
  >();

  const onFilterChange = useCallback(
    (filter: ProjectFilter) => {
      replace({
        pathname: location.pathname,
        search: filter,
      });
      setFilter(filter);
    },
    [location.pathname, replace],
  );

  const onSelectChange = (projectId: string) => {
    history.replace(
      config.localRoutes.dashboardController.projectCosts.replace(
        ':projectId',
        projectId,
      ),
    );
    setProjectId(projectId);
  };

  useEffect(() => {
    if (projectId) {
      getProjectById({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId, getProjectById]);

  useEffect(() => {
    if (
      project &&
      project.projectApproval &&
      project.finances &&
      project.projectStatus
    ) {
      setShowApproveButton(
        !!(
          projectId &&
          !project.projectApproval.some(a => a.approvalBoardId === 'con') &&
          project.finances?.some(f => f.asset.isProjectCosts) &&
          project.finances?.some(f => !f.asset.isProjectCosts) &&
          isProjectStatus(project, 'IT-Idee akzeptiert')
        ),
      );

      setShowFinanceButton(
        !!(
          (matchProjectSelect(projectId) &&
            isProjectStatus(project, 'IT-Idee akzeptiert') &&
            !project.projectApproval.some(a => a.approvalBoardId === 'con')) ||
          isProjectStatus(project, 'Eingereicht durch IT-Idee') ||
          isProjectStatus(project, 'Projektaktivierung')
        ),
      );
    }
  }, [project, projectId, onFilterChange]);

  const [allocations, setAllocations] = useState<{ allocationId: string }[]>(
    [],
  );
  useGetAllocationsQuery({
    onCompleted: data => {
      let allocationArray: { allocationId: string }[] = [];
      data.allocations.forEach(a => {
        allocationArray.push({ allocationId: a.id });
      });
      setAllocations(allocationArray);
    },
  });

  if (AuthenticationMessage('dashboard-controller')) {
    return (
      <Alert variant="danger">
        <p className="mb-3">Kein Zugang!</p>
      </Alert>
    );
  }

  return (
    <div>
      <div className="relative flex flex-col w-full min-h-full font-primary pt-10">
        <ControllerTable
          filter={matchProjectFilter(filter)}
          projectId={matchProjectSelect(projectId)}
          onChange={onFilterChange}
          onSelect={onSelectChange}
          allocations={allocations}
        />
        <div>
          <IdeaBudgetAcceptDialog
            projectApprovalId={Number(
              project?.projectApproval
                ?.filter(s => s.deletedBy === null)
                .map(approval => approval.id),
            )}
            open={dialog === 'accept_budget'}
            onClose={() => setDialog(null)}
            projectId={matchProjectSelect(projectId)}
          />
          {showApproveButton ? (
            <button
              type="button"
              className="my-6 mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              onClick={() => setDialog('accept_budget')}
            >
              {t`itProjects.detail.actions.approveBudget`}
            </button>
          ) : null}
          {showFinanceButton && projectId ? (
            <button
              type="button"
              className="my-6 mr-2 inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              onClick={() => {
                if (projectId) {
                  history.push({
                    pathname: config.localRoutes.financialData.replace(
                      ':id',
                      projectId,
                    ),
                    state: 'dashboard-controller',
                  });
                }
              }}
            >
              {t`itProjects.detail.actions.financialEdit`}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ProjectCostsPage;

const matchProjectFilter = (filter: any): ProjectFilter => {
  const allowedFilters: ProjectFilter[] = [
    'all',
    'ideas',
    'projects',
    'inventory',
    'activated',
  ];
  return allowedFilters.includes(filter) ? (filter as ProjectFilter) : 'all';
};

const matchProjectSelect = (projectId: any): string => {
  return projectId ? projectId : '';
};
