import { useTranslation } from 'react-i18next';
import { ReportingGetProjectByIdQuery } from '../../../../graphql/codegen/graphql';

import Table from 'components/NewTable';
import { format, parseISO } from 'date-fns';

import config from 'config/config';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumb';
import useTypedForm from 'components/common/Form/components';

export interface OverviewHistoryProps {
  projectId: string;
  data: ReportingGetProjectByIdQuery | undefined;
  loading: boolean;
}

const OverviewHistory = ({
  projectId,
  data,
  loading,
}: OverviewHistoryProps) => {
  const { t } = useTranslation();
  const basePath = config.localRoutes.tdReporting.projectoverview.replace(
    ':projectId',
    projectId,
  );

  const { Form } = useTypedForm();

  const compareNumbers = (a, b) => {
    if (a.rowNumber < b.rowNumber) {
      return 1;
    }
    if (a.rowNumber > b.rowNumber) {
      return -1;
    }
    return 0;
  };
  let sortArrayShortHistory: any = [];
  data?.masters[0].projectShortHistory?.forEach(h =>
    sortArrayShortHistory.push(h),
  );

  return (
    <Form onSubmit={() => console.log('')}>
      <div className="w-full">
        <Breadcrumb
          label={t`tdReporting.projectoverviewPage.history`}
          route={basePath}
        />
        <div className="w-min-40 px-4 bg-white">
          <dl>
            <div className="sm:col-span-1 space-y-5">
              <div className="w-full space-y-5">
                <dt className="pt-10 text-primary flex">
                  {t`tdReporting.projectoverviewPage.history`}
                </dt>

                <dd className="mt-1 text-sm text-gray-900">
                  {
                    <Table
                      data={sortArrayShortHistory.sort(compareNumbers)}
                      loading={loading}
                      columns={[
                        {
                          label: t`tdReporting.projectoverviewPage.date`,
                          render: data => (
                            <div>
                              {format(
                                parseISO(data?.createdAt as unknown as string),
                                'dd.MM.yyyy',
                              )}
                            </div>
                          ),
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.person2`,
                          render: data => <div>{data?.createdBy}</div>,
                        },
                        {
                          label: t`tdReporting.projectoverviewPage.action`,
                          render: data => <div>{data?.action}</div>,
                        },
                      ]}
                    />
                  }
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </Form>
  );
};
export default OverviewHistory;
