import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
  useWatch,
} from 'react-hook-form';

export type FormValueProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, TFieldName>, 'control'> & {
  children: (
    value: FieldPathValue<TFieldValues, TFieldName>,
    onChange: (...event: any[]) => void,
  ) => JSX.Element | null;
};
function FormValue<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ children, ...controllerProps }: FormValueProps<TFieldValues, TFieldName>) {
  const value = useWatch<TFieldValues, TFieldName>(controllerProps);
  const { field } = useController<TFieldValues, TFieldName>(controllerProps);
  return children(value, field.onChange);
}
export default FormValue;
